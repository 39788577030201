/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Box, Flex } from '@rebass/emotion'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { length, toLower } from 'ramda'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'

import { addItemRevisionAlternative, removeItemRevisionAlternative } from 'ducks/warehouseState'
import { getWarehouseStateAllItemsAsArray } from 'selectors/warehouseState'

import { ReactComponent as PlusIcon } from 'assets/plus-icon.svg'
import { ReactComponent as CancelIcon } from 'assets/cancel.svg'

import Button from 'components/Button'
import IconBox from 'components/IconBox'
import Modal from 'components/Modal'
import theme from 'config/theme'
import { Roles } from 'constants/Roles'
import { errorHandler } from 'helpers/errorHandler'
import useDebounce from 'hooks/useDebounce'

import {
  buttonWrapperStyles,
  centerStyles,
  containerStyles,
  inputStyles,
  getFlexStyles,
  listHolderStyles,
  listStyles,
  spanStyles,
  paragraphStyles,
  wrapperCss,
  wrapperStyles
} from './alternativesModalStyles'

const TextField = ({ children }) => <span css={spanStyles}>{children}</span>

const NoDeclaredAlternativesInfoBox = () => (
  <Flex css={wrapperCss}>
    <TextField>
      <strong>
        Brak zadeklarowanych zamienników
      </strong>
    </TextField>
  </Flex>
)

const AddIcon = ({ onAdd }) => (
  <div onClick={onAdd}>
    <IconBox cursor='pointer'>
      <PlusIcon style={{ fill: theme.colors.white, width: 20, height: 20 }} />
    </IconBox>
  </div>
)

const RemoveIcon = ({ onRemove }) => (
  <div onClick={onRemove}>
    <IconBox cursor='pointer' color={theme.colors.red}>
      <CancelIcon style={{ fill: theme.colors.white, width: 20, height: 20 }} />
    </IconBox>
  </div>
)

const AlternativesModalHeaders = ({ isUserAllowedToEdit }) => {
  return (
    <Flex css={getFlexStyles(false, true)}>
      <Flex css={centerStyles}>Index</Flex>
      <Flex css={centerStyles}>Nazwa</Flex>
      <Flex css={centerStyles}>Nazwa produkcyjna</Flex>
      <Flex css={centerStyles}>Stan magazynowy</Flex>
      {isUserAllowedToEdit && (
        <Flex css={centerStyles}>Usuń zamiennik</Flex>
      )}
    </Flex>
  )
}

const Alternatives = ({ alternatives, isUserAllowedToEdit, onRemove, stagedAlternatives }) => {
  return (stagedAlternatives.length || alternatives.length) ? (
    <Fragment>
      {stagedAlternatives.map((alternative) => (
        <Flex css={getFlexStyles(true, false, true)} key={alternative.id}>
          <Flex css={centerStyles}>
            <TextField>{alternative.fullIndex}</TextField>
          </Flex>
          <Flex css={centerStyles}>
            <TextField>{alternative.name}</TextField>
          </Flex>
          <Flex css={centerStyles}>
            <TextField>{alternative.productionName || '--------'}</TextField>
          </Flex>
          <Flex css={centerStyles}>
            <TextField>{alternative.warehouseQuantity}</TextField>
          </Flex>
          <Flex css={centerStyles}>
            <RemoveIcon onRemove={() => onRemove(alternative.id)} />
          </Flex>
        </Flex>
      ))}
      {alternatives.map((alternative) =>
        (
          <Flex css={getFlexStyles(false)} key={alternative.id}>
            <Flex css={centerStyles}>
              <TextField>{alternative.fullIndex}</TextField>
            </Flex>
            <Flex css={centerStyles}>
              <TextField>{alternative.name}</TextField>
            </Flex>
            <Flex css={centerStyles}>
              <TextField>{alternative.productionName || '--------'}</TextField>
            </Flex>
            <Flex css={centerStyles}>
              <TextField>{alternative.warehouseQuantity}</TextField>
            </Flex>
            {isUserAllowedToEdit && (
              <Flex css={centerStyles} >
                <RemoveIcon onRemove={() => onRemove(alternative.id)} />
              </Flex>
            )}
          </Flex>
        ))}
    </Fragment>
  ) : <NoDeclaredAlternativesInfoBox />
}

const AlternativesPickList = ({ alternativeItems, onAddAlternative }) => {
  const [searchPhrase, setSearchPhrase] = useState('')
  const [results, setResults] = useState([])

  const debouncedSearchPhrase = useDebounce(toLower(searchPhrase), 1e3)

  const filteringFn = useCallback(item => {
    // code was splitted to switch case as it can return true faster
    // it is required that user puts 3 letters in order to make filter running
    const fullIndex = item.fullIndex ? toLower(item.fullIndex) : ''
    const name = item.name ? toLower(item.name) : ''
    const index = item.index ? toLower(item.index) : ''
    switch (true) {
      case !debouncedSearchPhrase || debouncedSearchPhrase.length < 3:
        return true
      case fullIndex.includes(debouncedSearchPhrase):
        return true
      case name.includes(debouncedSearchPhrase):
        return true
      case index.includes(debouncedSearchPhrase):
        return true
      default:
        return false
    }
  }, [debouncedSearchPhrase])

  useEffect(() => {
    setResults(alternativeItems.filter(filteringFn))
  }, [alternativeItems, filteringFn])

  const areThereAlternativeItems = useMemo(() => length(alternativeItems), [alternativeItems])
  const searchedResults = useMemo(() => length(results), [results])

  const mapToPositions = itemRevision => (
    <li css={listStyles} key={itemRevision.id}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>Index</p>
        <p css={paragraphStyles}><b>{itemRevision.fullIndex || ''}</b></p>
        <p>Nazwa</p>
        <p css={paragraphStyles}>{itemRevision.name || ''}</p>
        <p>Na magazynie:</p>
        <p css={paragraphStyles}>{itemRevision.warehouseQuantity || 0}</p>
      </div>
      <AddIcon onAdd={() => onAddAlternative(itemRevision)} />
    </li>
  )

  return (
    <Box mx={10} mt={10} mb={-30} style={{ width: '320px' }}>
      <input css={inputStyles} onChange={event => { setSearchPhrase(event.target.value) }} value={searchPhrase} placeholder='Wyszukaj' />
      {areThereAlternativeItems && (
        <ul css={listHolderStyles}>
          {searchPhrase ? searchedResults ? results.map(mapToPositions) : <p>No results</p> : alternativeItems.map(mapToPositions)}
        </ul>
      )}
    </Box>
  )
}

const AlternativesModal = ({ itemRevisionId, addAlternative, removeAlternative, allItems, onClose, userRole }) => {
  const { t } = useTranslation()
  const [stagedAlternatives, setStagedAlternatives] = useState([])
  const [isPosting, setIsPosting] = useState(false)
  const itemRevision = allItems.find(item => item.id === itemRevisionId)
  const { alternatives, fullIndex, name } = itemRevision
  const rolesAllowedToEdit = [Roles.ADMIN, Roles.TECHNOLOGIST]
  const isUserAllowedToEdit = rolesAllowedToEdit.includes(userRole)
  const alternativeItemRevisionsWithoutItselfAndAlreadyAdded = useMemo(() => allItems.filter(itemRevision => {
    switch (true) {
      // case when revision itself is on the list of addable alternatives
      case itemRevision.id === itemRevisionId:
        return false
      // case when we have this alternative already added we want to remove it from the list of addable alternatives
      case alternatives.some(alternative => alternative.id === itemRevision.id):
        return false
      // case itemRevision is already staged
      case stagedAlternatives.some(stagedAlternative => stagedAlternative.id === itemRevision.id):
        return false
      // if none of this cases happens, aliternative should be displayed on alternativesList
      default:
        return true
    }
  }), [itemRevisionId, allItems, alternatives, stagedAlternatives])

  const handleRemoveAlternative = useCallback((alternativeId) => {
    const isStaged = stagedAlternatives.some(stagedAlternative => stagedAlternative.id === alternativeId)
    if (isStaged) {
      setStagedAlternatives(oldStagedAlternatives => oldStagedAlternatives.filter(stagedAlternative => stagedAlternative.id !== alternativeId))
    } else {
      confirmAlert({
        title: t('catalogue.removeAlternativeQuestion'),
        buttons: [
          {
            label: t('catalogue.confirmationDialogue.yes'),
            onClick: () => removeAlternative({ alternativeId, itemRevisionId })
          },
          {
            label: t('catalogue.confirmationDialogue.no'),
            onClick: () => {}
          }
        ]
      })
    }
  }, [itemRevisionId, stagedAlternatives])

  const handleAddAlternatives = useCallback(async () => {
    setIsPosting(true)
    // i'm using for loop here I do really love them
    const queued = stagedAlternatives
    for (let i = 0; i < queued.length; i++) {
      const alternativeId = queued[i]['id']
      try {
        await addAlternative({ alternativeId, itemRevisionId })
      } catch (error) {
        errorHandler(error)
      } finally {
        setStagedAlternatives(previouslyStagedAlternatives => previouslyStagedAlternatives.filter(stagedAlternative => stagedAlternative.id !== alternativeId))
      }
    }
    setIsPosting(false)
  }, [stagedAlternatives, itemRevisionId])

  const hasStagedAlternatives = length(stagedAlternatives)

  const isButtonDisabled = !hasStagedAlternatives && !isPosting

  const onModalCloseFn = () => {
    if (isPosting) {
      return () => {}
    } else if (hasStagedAlternatives) {
      confirmAlert({
        title: t('catalogue.unsavedStagedAlternativesPrompt'),
        buttons: [
          {
            label: t('catalogue.confirmationDialogue.yes'),
            onClick: onClose
          },
          {
            label: t('catalogue.confirmationDialogue.no'),
            onClick: () => {}
          }
        ]
      })
      return () => {}
    } else {
      onClose()
      return () => {}
    }
  }

  return (
    <Modal headerTitle={`Zamienniki (${alternatives.length}) dla ${fullIndex} ${name}`} onClose={onModalCloseFn} zIndex={21}>
      <Flex css={css`flex-direction: row; height: 570px;`}>
        <Fragment>
          <Box mx={10} mb={8}>
            {isUserAllowedToEdit && (
              <div css={buttonWrapperStyles}>
                <Button color={theme.colors.navyBlue} disabled={isButtonDisabled} isLoading={isPosting} onClick={handleAddAlternatives}>Zapisz</Button>
              </div>
            )}
            <Flex css={containerStyles}>
              <AlternativesModalHeaders isUserAllowedToEdit={isUserAllowedToEdit} />
              <Flex css={wrapperStyles}>
                <Alternatives
                  alternatives={alternatives}
                  onRemove={handleRemoveAlternative}
                  isUserAllowedToEdit={isUserAllowedToEdit}
                  stagedAlternatives={stagedAlternatives}
                />
              </Flex>
            </Flex>
          </Box>
          {isUserAllowedToEdit &&
          <AlternativesPickList
            alternativeItems={alternativeItemRevisionsWithoutItselfAndAlreadyAdded}
            onAddAlternative={newAlternative => isPosting ? () => {} : setStagedAlternatives(oldStagedAlternatives => [newAlternative, ...oldStagedAlternatives])}
          />
          }
        </Fragment>
      </Flex>
    </Modal>
  )
}

const mapStateToProps = state => ({
  allItems: getWarehouseStateAllItemsAsArray(state),
  userRole: state.user.roles[0]
})

const mapDispatchToProps = dispatch => ({
  addAlternative: ({ itemRevisionId, alternativeId }) => dispatch(addItemRevisionAlternative({ itemRevisionId, alternativeId })),
  removeAlternative: ({ itemRevisionId, alternativeId }) => dispatch(removeItemRevisionAlternative({ itemRevisionId, alternativeId }))
})

export default connect(mapStateToProps, mapDispatchToProps)(AlternativesModal)
