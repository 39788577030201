const ON_INPUT = Symbol('ON_INPUT')
const RESET_INPUT = Symbol('RESET_INPUT')

export const receiveInput = (value) => ({ type: ON_INPUT, value })
export const resetInput = () => ({ type: RESET_INPUT })

const initState = {
  scannedData: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case ON_INPUT:
      return { ...state, value: action.value }
    case RESET_INPUT:
      return { ...state, value: '' }
    default:
      return state
  }
}
