import { connect } from 'react-redux'
import { compose } from 'redux'
import { reduxForm } from 'redux-form'
import { toast } from 'react-toastify'
import UserEditForm from 'forms/UserEditForm'
import { deleteUserById, editUserById, addUser, assignUserToStations } from 'ducks/users'
import { withTranslation } from 'react-i18next'
import * as R from 'ramda'
import { errorHandler } from 'helpers/errorHandler'
import i18n from 'i18next'
import { getWorkstationsAsUserEditOptions } from 'selectors/workstations'
import { getWorkstationsIfNeeded } from 'ducks/workstations'
import { includesInArray } from 'helpers/array'
import { Roles } from 'constants/Roles'
import { getUserRoles } from 'selectors/user'

const formName = 'UsersEditForm'

const Form = reduxForm({
  form: formName,
  validate: (values) => {
    const errors = {}
    const requiredFields = ['firstname', 'lastname']
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = i18n.t('common.fieldRequired')
      }
    })
    return errors
  },
  onSubmit: async (values, dispatch, ownProps) => {
    const isEdit = ownProps.user.id
    const payload = R.pipe(
      R.pick(['role', 'firstname', 'lastname', 'rfid', 'pin']),
      R.assoc('assemblyPostIds', R.map(R.prop('value'), values.assemblyPosts))
    )(values)
    if (isEdit) {
      try {
        const isAdmin = includesInArray(ownProps.userRoles, [Roles.ADMIN])
        const response = isAdmin
          ? await dispatch(editUserById(ownProps.user.id, payload))
          : await dispatch(assignUserToStations(ownProps.user.id, payload.assemblyPostIds))
        ownProps.closeModal()
        return Promise.resolve(response)
      } catch (e) {
        toast.error(ownProps.t('userEdit.editUserFail'), { containerId: 'statuses', autoClose: 15e3 })
        errorHandler(e)
        return Promise.reject(e)
      }
    } else {
      try {
        const response = await dispatch(addUser(payload))
        ownProps.closeModal()
        return Promise.resolve(response)
      } catch (e) {
        toast.error(ownProps.t('userEdit.addUserFail'), { containerId: 'statuses', autoClose: 15e3 })
        errorHandler(e)
        return Promise.reject(e)
      }
    }
  }
})(UserEditForm)

const mapStateToProps = state => ({
  workstationsOptions: getWorkstationsAsUserEditOptions(state),
  userRoles: getUserRoles(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteUser: () => dispatch(deleteUserById(ownProps.user.id)),
  getWorkstationsIfNeeded: compose(dispatch, getWorkstationsIfNeeded)
})

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Form)
