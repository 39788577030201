/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState, Fragment } from 'react'
import Breadcrumb from 'components/Breadcrumb'
import LabelledDivider from 'components/LabelledDivider'
import { useTranslation } from 'react-i18next'
import ItemDescription from 'components/ItemDescription'
import OrdersList from 'components/OrdersList'
import QrCodeReader from 'components/QrCodeReader'
import OrderDetails from 'components/OrderDetails'
import ChangeQuantityForm from 'connectors/forms/ChangeQuantityFormConnector'
import { path, pathOr, prop } from 'ramda'
import Button from 'components/Button'
import { ReactComponent as CircleCheckmark } from 'assets/check-mark-in-circle.svg'
import OutsideOrder from 'components/OutsideOrder'
import { scanParser } from 'helpers/scanParser'
import LoadingOverlay from 'components/LoadingOverlay'

const ReceiveSupplyScreen = ({ currentItem, match: { params: { itemCode } }, orders, getOrdersForItem, history, error }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [selectedOrder, selectOrder] = useState(null)
  const [isOutsideOrder, setOutsideOrder] = useState(false)
  const [code, setCode] = useState(itemCode)
  const [apiError, setError] = useState(null)

  const fetchOrders = async () => {
    try {
      setLoading(true)
      await getOrdersForItem(code)
      setError(null)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(e?.response?.data?.message)
    }
  }

  useEffect(() => {
    fetchOrders()
  }, [code])

  const navigateToConfirmScreen = ({ quantity, reportDiscrepancy }) => {
    history.replace(
      `/supply/confirm/${itemCode}`,
      {
        currentItemCode: currentItem.fullIndex,
        selectedOrderId: selectedOrder.id,
        selectedOrderItemId: selectedOrder.item.id,
        discrepancyComment: prop('value', reportDiscrepancy),
        quantity,
        reportDiscrepancy,
        itemCode
      }
    )
  }

  const handleScan = scanData => {
    const parsedScan = scanParser(scanData)
    setCode(parsedScan.data)
    history.replace(`/supply/item/${parsedScan.data}`)
  }

  const navigateToConfirmOutsideOrderScreen = ({ quantity }) => {
    history.replace(`/supply/confirm/${itemCode}`, { isOutsideOrder: true, quantity, itemCode })
  }

  useEffect(() => {
    fetchOrders()
  }, [itemCode])

  const getInitialQuantity = path(['item', 'quantity'], selectedOrder) - pathOr(0, ['item', 'deliveredQuantity'], selectedOrder)

  return (
    <div>
      <Breadcrumb>{t('supply.location')}</Breadcrumb>
      {isLoading && <LoadingOverlay />}
      <Fragment>
        <ItemDescription item={currentItem} error={apiError} />
        {!isLoading &&
        <Fragment>
          <LabelledDivider text={t('orders.orders')} />
          {!isOutsideOrder &&
            <Fragment>
              {selectedOrder
                ? <div>
                  <Fragment>
                    <OrderDetails
                      order={selectedOrder}
                      isSelected
                      currentItem={currentItem}
                      orderItem={selectedOrder.item}
                      selectOrder={selectOrder}
                    />
                    <ChangeQuantityForm
                      initialValues={{ quantity: getInitialQuantity }}
                      onSubmit={navigateToConfirmScreen}
                    />
                  </Fragment>
                </div>
                : <Fragment>
                  <OrdersList selectOrder={selectOrder} currentItem={currentItem} orders={orders} />
                  <LabelledDivider text={t('orders.anotherOptions')} />
                  <Button withIcon icon={CircleCheckmark} onClick={() => setOutsideOrder(true)}>
                    {t('supply.outsideOrder')}
                  </Button>
                </Fragment>
              }
            </Fragment>
          }
          {isOutsideOrder &&
            <Fragment>
              <OutsideOrder
                setOutsideOrder={setOutsideOrder}
              />
              <ChangeQuantityForm
                initialValues={{ quantity: 1 }}
                onSubmit={navigateToConfirmOutsideOrderScreen}
                isOutsideOrder={isOutsideOrder}
              />
            </Fragment>
          }
          <QrCodeReader onScan={handleScan} invisible />
        </Fragment>
        }
      </Fragment>
    </div>
  )
}

export default ReceiveSupplyScreen
