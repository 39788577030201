import React, { useState } from 'react'
import * as R from 'ramda'
import QrCode from 'qrcode.react'

const generatLocations = () => {
  let letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K']
  let columns = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12']
  let shelfs = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10']

  const makeCombinations = (letters, columns, shelfs) => (
    R.pipe(
      R.map(
        R.pipe(
          R.xprod(R.__, columns),
          R.xprod(R.__, shelfs),
          R.map(
            R.pipe(
              R.flatten,
              R.join('-')
            )
          )
        )
      ),
      R.flatten
    )(letters)
  )
  return makeCombinations(letters, columns, shelfs)
}

const Locations = () => {
  const locations = generatLocations()
  const [currentPage, setCurrentPage] = useState(0)

  const [totalPages] = useState(Math.ceil(locations.length / 50))
  const options = Array.from({ length: totalPages }).map((x, index) => index)
  return (
    <div>
      <label htmlFor='pagination'>Pagination</label>
      <select onChange={e => setCurrentPage(e.target.value)} name='pagination' id='pagination'>
        {options.map(option => <option value={option}>{option}</option>)}
      </select>
      {locations.slice(currentPage * 50, (currentPage + 1) * 50).map(code =>
        <div style={{ marginBottom: '300px', display: 'flex', padding: '25px', borderBottom: '.5px solid grey' }}>
          {code}
          <QrCode size={200} style={{ marginLeft: 'auto' }} value={`L${code}`} />
        </div>)}
    </div>
  )
}

export default Locations
