import { transformById } from 'helpers/redux'
import {
  GET_INVENTORY_STATE_FOR_ITEM_SUCCESS,
  HAND_OVER_ITEM_FROM_INVENTORY_SUCCESS,
  RECALCULATE_INVENTORY_QUANTITY_SUCCESS
} from 'ducks/warehouseState'

const initState = {
  entries: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_INVENTORY_STATE_FOR_ITEM_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          ...transformById(action.data.inventory)
        }
      }
    case RECALCULATE_INVENTORY_QUANTITY_SUCCESS:
    case HAND_OVER_ITEM_FROM_INVENTORY_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.inventory.id]: action.inventory
        }
      }
    default:
      return state
  }
}
