import { createSelector } from 'reselect'
import { toSnakeCase } from 'helpers/string'

const getHandOverPurposes = state => state.handOverPurposes.entries

export const getHandOverPurposesAsOptions = createSelector(
  getHandOverPurposes,
  handOverPurposes => Object
    .keys(handOverPurposes)
    .map((key) => ({ value: toSnakeCase(key), label: handOverPurposes[key] }))
)
