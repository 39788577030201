/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useEffect, useState } from 'react'
import Tooltip from 'components/Tooltip'
import { connect } from 'react-redux'
import theme from 'config/theme'
import Button from 'components/Button'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import { cellCss } from 'views/Catalogue/styles'
import { getSerialNumberFormatsIfNeeded } from 'ducks/serialNumberFormats'
import { getSerialNumberFormatsAsArray } from 'selectors/serialNumberFormats'
import { Flex } from '@rebass/emotion'
import { editItemRevision } from 'ducks/warehouseState'

const tooltipContentCss = css`
  padding: 10px;
  text-align: left;
`

const selectCss = css`
  min-width: 300px;
  max-width: 350px;
  cursor: pointer;
  border: none;
  background: ${theme.colors.white};
`

const buttonCss = css`
  max-width: 100px;
  margin-top: 20px;
`

const textPointerCss = css`cursor: pointer;`

// empty regex in order to add removing option in select menu

const EditRegex = ({ children, getSerialNumberFormatsIfNeeded, ...props }) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [selected, setSelected] = useState(props.item.serialNumberFormatId || '')

  const { serialNumberFormats } = props
  const serialNumberFormat = props.item.serialNumberFormat

  const closeTooltip = () => {
    setTooltipOpen(false)
    setEditing(false)
  }

  useEffect(() => {
    getSerialNumberFormatsIfNeeded()
  }, [getSerialNumberFormatsIfNeeded])

  const notEditingContent = <Fragment>
    <Flex alignItems='center' flexDirection='column'>
      <span css={textPointerCss}>{serialNumberFormat?.name || <b>dodaj</b>}</span>
      <span css={textPointerCss}>{serialNumberFormat?.format}</span>
    </Flex>
  </Fragment>

  const save = () => {
    confirmAlert({
      title: <div css={{ marginBottom: 20 }}>{t('catalogue.confirmationDialogue.confirmHeader')}</div>,
      message: <div>{t('catalogue.confirmationDialogue.editSerialNumberFormat')}</div>,
      buttons: [
        {
          label: t('catalogue.confirmationDialogue.yes'),
          onClick: async () => {
            await props.editItemRevision({ itemId: props.item.itemId, field: 'serialNumberFormatId', value: selected, revisionId: props.item.id })
            setEditing(false)
            setTooltipOpen(false)
          }
        },
        {
          label: t('catalogue.confirmationDialogue.no'),
          onClick: closeTooltip
        }
      ]
    })
  }

  const editingContent = <Fragment>
    <select id={props.item.itemId} css={selectCss} onChange={e => setSelected(e.target.value)} value={selected}>
      <option value={null} label='brak' />
      {serialNumberFormats.map((serialNumberFormat) =>
        <option value={serialNumberFormat.id} key={serialNumberFormat.id}>
          {serialNumberFormat.name}&nbsp;({serialNumberFormat.format})
        </option>
      )}
    </select>
    <Button css={buttonCss} onClick={save}>{t('catalogue.confirmationDialogue.save')}</Button>
  </Fragment>

  const renderContent = () => {
    return <div onClick={() => setEditing(true)} css={tooltipContentCss}>
      {editing ? editingContent : notEditingContent}
    </div>
  }
  const renderedChildrenContentStyles = css`
    display: flex; 
    justify-content: ${children ? 'flex-start' : 'center'}; 
    align-items: center; 
    padding-left: ${children ? 6 : 0}px; 
    width: 100%;
  `
  // if children content is undefined we need to render something anyhow, as empty components are self-closing and there is no way that field can be edited
  const renderedChildrenContent = (<div css={renderedChildrenContentStyles}>{children || `brak`}</div>)

  return (
    <Tooltip
      style={{ zIndex: 5 }}
      content={renderContent()}
      arrow
      hideOnClick={false}
      open={tooltipOpen}
      trigger='manual'
      onRequestClose={closeTooltip}
      css={cellCss}
    >
      <div
        css={css`
          cursor: pointer;
        `}
        className={props.className}
        onClick={() => setTooltipOpen(!tooltipOpen)}
      >
        {renderedChildrenContent}
      </div>
    </Tooltip>
  )
}

const mapStateToProps = state => ({
  serialNumberFormats: getSerialNumberFormatsAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  editItemRevision: (values) => dispatch(editItemRevision(values)),
  getSerialNumberFormatsIfNeeded: () => dispatch(getSerialNumberFormatsIfNeeded())
})

export default connect(mapStateToProps, mapDispatchToProps)(EditRegex)
