import UsersService from 'services/UsersService'
import { transformById } from 'helpers/redux'
import * as R from 'ramda'

const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
const GET_USERS_FAILED = 'GET_USERS_FAILED'

const getUsersRequest = () => ({ type: GET_USERS_REQUEST })
const getUsersSuccess = payload => ({ type: GET_USERS_SUCCESS, payload })
const getUsersFailure = () => ({ type: GET_USERS_FAILED })

export const getUsers = () => {
  return async dispatch => {
    dispatch(getUsersRequest())
    try {
      const { data } = await UsersService.getUsers()
      dispatch(getUsersSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(getUsersFailure(e))
      return Promise.reject(e)
    }
  }
}

const DELETE_USER_BY_ID_REQUEST = 'DELETE_USER_BY_ID_REQUEST'
const DELETE_USER_BY_ID_SUCCESS = 'DELETE_USER_BY_ID_SUCCESS'
const DELETE_USER_BY_ID_FAILED = 'DELETE_USER_BY_ID_FAILED'

const deleteUserByIdRequest = () => ({ type: DELETE_USER_BY_ID_REQUEST })
const deleteUserByIdSuccess = userId => ({ type: DELETE_USER_BY_ID_SUCCESS, userId })
const deleteUserByIdFailure = () => ({ type: DELETE_USER_BY_ID_FAILED })

export const deleteUserById = (userId) => {
  return async dispatch => {
    dispatch(deleteUserByIdRequest())
    try {
      const { data } = await UsersService.deleteUserById(userId)
      dispatch(deleteUserByIdSuccess(userId))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(deleteUserByIdFailure(e))
      return Promise.reject(e)
    }
  }
}

const EDIT_USER_BY_ID_REQUEST = 'EDIT_USER_BY_ID_REQUEST'
const EDIT_USER_BY_ID_SUCCESS = 'EDIT_USER_BY_ID_SUCCESS'
const EDIT_USER_BY_ID_FAILED = 'EDIT_USER_BY_ID_FAILED'

const editUserByIdRequest = () => ({ type: EDIT_USER_BY_ID_REQUEST })
const editUserByIdSuccess = (userId, data) => ({ type: EDIT_USER_BY_ID_SUCCESS, userId, data })
const editUserByIdFailure = () => ({ type: EDIT_USER_BY_ID_FAILED })

export const editUserById = (userId, values) => {
  return async dispatch => {
    dispatch(editUserByIdRequest())
    try {
      const { data } = await UsersService.editUserById(userId, values)
      dispatch(editUserByIdSuccess(userId, data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(editUserByIdFailure(e))
      return Promise.reject(e)
    }
  }
}

const ASSIGN_USER_TO_STATIONS_REQUEST = 'ASSIGN_USER_TO_STATIONS_REQUEST'
const ASSIGN_USER_TO_STATIONS_SUCCESS = 'ASSIGN_USER_TO_STATIONS_SUCCESS'
const ASSIGN_USER_TO_STATIONS_FAILED = 'ASSIGN_USER_TO_STATIONS_FAILED'

const assignUserToStationsRequest = () => ({ type: ASSIGN_USER_TO_STATIONS_REQUEST })
const assignUserToStationsSuccess = (userId, data) => ({ type: ASSIGN_USER_TO_STATIONS_SUCCESS, userId, data })
const assignUserToStationsFailure = () => ({ type: ASSIGN_USER_TO_STATIONS_FAILED })

export const assignUserToStations = (userId, values) => {
  return async dispatch => {
    dispatch(assignUserToStationsRequest())
    try {
      const { data } = await UsersService.assignUserToStations(userId, values)
      dispatch(assignUserToStationsSuccess(userId, data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(assignUserToStationsFailure(e))
      return Promise.reject(e)
    }
  }
}

const ADD_USER_BY_ID_REQUEST = 'ADD_USER_BY_ID_REQUEST'
const ADD_USER_BY_ID_SUCCESS = 'ADD_USER_BY_ID_SUCCESS'
const ADD_USER_BY_ID_FAILED = 'ADD_USER_BY_ID_FAILED'

const addUserRequest = () => ({ type: ADD_USER_BY_ID_REQUEST })
const addUserSuccess = (data) => ({ type: ADD_USER_BY_ID_SUCCESS, data })
const addUserFailure = () => ({ type: ADD_USER_BY_ID_FAILED })

export const addUser = (values) => {
  return async dispatch => {
    dispatch(addUserRequest())
    try {
      const { data } = await UsersService.addUser(values)
      dispatch(addUserSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(addUserFailure(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  users: {},
  wasFetched: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: transformById(action.payload),
        wasFetched: true
      }
    case DELETE_USER_BY_ID_SUCCESS:
      return R.evolve({
        users: R.dissoc(action.userId)
      }, state)
    case EDIT_USER_BY_ID_SUCCESS:
      return R.evolve({
        users: R.assoc(action.userId, action.data)
      }, state)
    case ADD_USER_BY_ID_SUCCESS:
      return R.evolve({
        users: R.assoc(action.data.id, action.data)
      }, state)
    default:
      return state
  }
}
