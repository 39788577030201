/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect } from 'react'
import { Field, Form } from 'redux-form'
import Input from 'components/Input'
import Container from 'components/Container'
import { inputCss } from 'forms/OnboardItemForm'
import LabelledDivider from 'components/LabelledDivider'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import { ReactComponent as Alert } from 'assets/alert.svg'
import { path } from 'ramda'
import Select from 'components/Select'
import i18n from 'i18next'

const required = value => value ? undefined : i18n.t('common.fieldRequired')

const ChangeQuantityForm = (props) => {
  const { t } = useTranslation()
  const { isOutsideOrder } = props
  const [hasChangedQuantity, setHasChangedQuantity] = useState(false)
  const [showReportSelect, setShowSelect] = useState(false)
  const [areCommentsLoading, setCommentsLoading] = useState(false)
  const quantityFieldActive = path(['quantity', 'active'], props.formMeta)

  useEffect(() => {
    if (quantityFieldActive && !showReportSelect) {
      setHasChangedQuantity(true)
    }
  }, [quantityFieldActive])

  const showSelect = async () => {
    setShowSelect(true)
    setHasChangedQuantity(false)
    setCommentsLoading(true)
    await props.getDiscrepancyComments()
    setCommentsLoading(false)
  }
  return (
    <div>
      <LabelledDivider text={t('warehouse.itemCount')} />
      <Container>
        <Form onSubmit={props.handleSubmit(props.onSubmit)}>
          <Field
            component={Input}
            css={inputCss}
            numericKeyboard
            name='quantity'
            type='number'
            min={0}
          />
          {hasChangedQuantity && !isOutsideOrder &&
            <Container flex centerItems>
              <Button onClick={showSelect} withIcon icon={Alert}>
                zgłoś problem z zamówieniem
              </Button>
            </Container>
          }
          {showReportSelect && !isOutsideOrder &&
            <Field
              component={Select}
              name='reportDiscrepancy'
              validate={required}
              css={{ margin: '12px 0' }}
              autoFocus
              placeholder={t('supply.selectDiscrepancy')}
              isLoading={areCommentsLoading}
              options={props.discrepancyComments}
            />
          }
          <LabelledDivider text={t('common.confirm')} noMargin css={{ margin: '12px 0' }} />
          <Container flex centerItems>
            <Button type='submit' withIcon icon={Checkmark} iconProps={{ style: { stroke: 'white' } }}>
              {t('supply.confirmDelivery')}
            </Button>
          </Container>
        </Form>
      </Container>
    </div>
  )
}

export default ChangeQuantityForm
