import { connect } from 'react-redux'
import Toaster from 'components/Toaster'
import { getNotificationsAsArray } from 'selectors/notifications'
import { markNotificationAsRead } from 'ducks/notifications'

const mapStateToProps = state => ({
  allNotifications: getNotificationsAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  markNotificationAsRead: id => dispatch(markNotificationAsRead(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Toaster)
