/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect } from 'react'
import { Box } from '@rebass/emotion'
import { Field, Form } from 'redux-form'
import Input from 'components/Input'
import { inputCss } from 'forms/OnboardItemForm'
import LabelledDivider from 'components/LabelledDivider'
import { useTranslation } from 'react-i18next'
import Select from 'components/Select'
import Button from 'components/Button'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import theme from 'config/theme'

const HandOverForm = (props) => {
  const { t } = useTranslation()
  const [areCommentsLoading, setCommentsLoading] = useState(false)
  const loadPurposes = async () => {
    setCommentsLoading(true)
    await props.getHandOverPurposes()
    setCommentsLoading(false)
  }

  useEffect(() => {
    loadPurposes()
  }, [])

  const emptyQuantity = () => props.change('quantity', '')
  return (
    <Form onSubmit={props.handleSubmit(props.onSubmit)}>
      <Box m={5}>
        <LabelledDivider text={t('warehouse.itemCount')} />
        <Field
          component={Input}
          css={inputCss}
          name='quantity'
          numericKeyboard
          type='number'
          onFocus={emptyQuantity}
        />
        <LabelledDivider text={t('warehouse.destination')} />
        <Field
          component={Select}
          name='destination'
          css={{ margin: '12px 0' }}
          autoFocus
          placeholder={t('warehouse.selectDestination')}
          isLoading={areCommentsLoading}
          options={props.handOverPurposes}
        />
      </Box>
      <Box m={5} mt={9}>
        <Button type='submit' withIcon icon={Checkmark} iconProps={{ style: { fill: theme.colors.white } }}>
          {t('warehouse.confirmHandOver')}
        </Button>
      </Box>
    </Form>
  )
}

export default HandOverForm
