import WarehouseService from 'services/WarehouseService'

const GET_HAND_OVER_COMMENTS = Symbol('GET_HAND_OVER_COMMENTS')
const GET_HAND_OVER_COMMENTS_SUCCESS = Symbol('GET_HAND_OVER_COMMENTS_SUCCESS')
const GET_HAND_OVER_COMMENTS_FAILED = Symbol('GET_HAND_OVER_COMMENTS_FAILED')

const requestGetHandOverPurposes = () => ({ type: GET_HAND_OVER_COMMENTS })
const getHandOverPurposesSuccess = (data) => ({ type: GET_HAND_OVER_COMMENTS_SUCCESS, data })
const getHandOverPurposesFailed = () => ({ type: GET_HAND_OVER_COMMENTS_FAILED })

export const getHandOverPurposes = () => {
  return async dispatch => {
    dispatch(requestGetHandOverPurposes())
    try {
      const { data } = await WarehouseService.getHandOverPurposes()
      return dispatch(getHandOverPurposesSuccess(data))
    } catch (e) {
      return dispatch(getHandOverPurposesFailed(e))
    }
  }
}

const initState = {
  entries: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_HAND_OVER_COMMENTS_SUCCESS:
      return {
        ...state,
        entries: action.data.purposes
      }
    default:
      return state
  }
}
