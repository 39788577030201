/** @jsx jsx */
import { jsx } from '@emotion/core'
import { LocationCodeBox, IconWrapper, StyledIcon } from './styled'

const LocationCodeItem = props => {
  return (
    <div style={{ display: 'flex' }}>
      <LocationCodeBox {...props} />
      <IconWrapper>
        <StyledIcon onClick={props.onDelete} />
      </IconWrapper>
    </div>
  )
}

export default LocationCodeItem
