import { css } from '@emotion/core'
import theme from 'config/theme'

export const buttonWrapperStyles = css`
  width: 200px;
  margin-bottom: -35px;
  position: relative;
`

export const wrapperCss = css`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding: 10px;
`

export const inputStyles = css`
  border: 0px;
  border-bottom: 1px ${theme.colors.darkGrey} solid;
  width: 100%;
  padding: 10px;
  margin-bottom: 8px ;
  `

export const listStyles = css`
  list-style: none;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%; 
  padding: 5px 0px;
  margin: 0px 0px;
  border-bottom: 1px solid ${theme.colors.darkGrey};
  `

export const listHolderStyles = css`
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-height: 400px;
  `

export const paragraphStyles = css`
  padding-bottom: 10px;
  `

export const wrapperStyles = css`
  flex-direction: column;
  border: 1px solid ${theme.colors.lightGrey};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow-y: scroll;
  max-height: 400px;
`

export const centerStyles = css`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
`

export const getFlexStyles = (withBottomBorder = false, forHeader = false, forStaged = false) => css`
  width: 1000px;
  height: ${forHeader ? 50 : 80}px;
  justify-content: center;
  align-items: center;
  background-color: ${forStaged ? theme.colors.lightGreen : 'initial'};
  border-bottom: ${withBottomBorder ? `1px solid ${theme.colors.lightGrey}` : 'none'};
  &:nth-of-type(even){
  ${forStaged ? '' : `background-color: ${theme.colors.concreteGray};`}
    
  }
`

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const spanStyles = css`
  word-break: break-all;
  align-self: center;
`
