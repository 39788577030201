import scriptLoader from 'react-async-script-loader'
import * as PropTypes from 'prop-types'

const KeyCapture = (props) => {
  window.EB && window.EB.KeyCapture.captureKey(props.shouldBubble, props.keyToCapture, props.callback)
  return null
}

export default scriptLoader('/ebapi-modules.js')(KeyCapture)

KeyCapture.propTypes = {
  callback: PropTypes.func.isRequired,
  keyToCapture: PropTypes.number.isRequired,
  shouldBubble: PropTypes.bool
}

KeyCapture.defaultProps = {
  shouldBubble: false
}
