/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Box } from '@rebass/emotion'
import Button from 'components/Button'
import Modal from 'components/Modal'
import theme from 'config/theme'
import Routes from 'constants/Routes'
import useOpenState from 'hooks/useOpenState'
import * as R from 'ramda'
import { Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { errorHandler } from 'helpers/errorHandler'
import NewBomsFormConnector from 'connectors/forms/NewBomsFormConnector'

const bomsButtonCss = { width: 'auto', display: 'inline-block', marginLeft: 10 }
const bomsTableCss = css`
  display: grid;
  grid-template-columns: max-content max-content 1fr max-content;
  border: 1px solid black;
  
  & > * {
    border: 1px solid black;
    padding: 8px;
    display: flex;
    align-items: center;  
  }
`

const BomsImportsList = ({ getBomsImports, bomsImports, uploadBomImport, history, deleteBomImportById, location: { state: locationState } }) => {
  const { t } = useTranslation()
  const [loadedFile, changeLoadedFile] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [loadingBomsImports, setLoadingBomsImports] = useState([])
  const [newBomsModalOpen, openNewBomsModal, closeNewBomsModal] = useOpenState(false)
  useEffect(() => {
    getBomsImports()
  }, [getBomsImports])

  const inputRef = useRef(null)

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files)
    changeLoadedFile(files[0])
  }

  useEffect(() => {
    if (locationState?.newBomIds?.length) {
      openNewBomsModal()
    }
  }, [locationState])

  const openInputForm = () => inputRef.current.click()

  const handleUpload = async () => {
    try {
      setLoading(true)
      const formData = new window.FormData()
      formData.append('file', loadedFile)
      await uploadBomImport(formData)
      changeLoadedFile(null)
      setLoading(false)
    } catch (e) {
      errorHandler(e)
      setLoading(false)
    }
  }

  const handleBomImportDelete = async (bomImportId) => {
    try {
      setLoadingBomsImports(R.append(bomImportId, loadingBomsImports))
      await deleteBomImportById(bomImportId)
      setLoadingBomsImports(R.without([bomImportId], loadingBomsImports))
    } catch (e) {
      setLoadingBomsImports(R.without([bomImportId], loadingBomsImports))
    }
  }

  return (
    <Box p={6}>
      <Box mb={6}>
        <Box mb={6}>
          {t('bomImports.loadNewBom')}
          <Button
            css={bomsButtonCss}
            onClick={openInputForm}
            disabled={isLoading}
          >
            {t('bomImports.loadBom')}
          </Button>
          {loadedFile && (
            <Fragment>
              <span css={{ marginLeft: 10 }}>Załadowany plik: {loadedFile.name}</span>
              <Button css={bomsButtonCss} onClick={handleUpload} disabled={isLoading}>
                {t('bomImports.send')}
              </Button>
            </Fragment>
          )}
          <input
            ref={inputRef}
            style={{ display: 'none' }}
            type='file'
            accept='application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            onChange={handleFileChange}
          />
        </Box>
        <Box css={bomsTableCss}>
          {bomsImports.map((bomImport, index) => {
            return (
              <Fragment key={bomImport.id}>
                <h2>#{index + 1}</h2>
                <h2>{bomImport.parsedInput?.mainElement?.index}</h2>
                <span>{bomImport.originalName}</span>
                <div>
                  <Button
                    css={bomsButtonCss}
                    onClick={() => history.push(Routes.bomsEdit.replace(':bomImportId', bomImport.id))}
                  >
                    {t('bomImports.edit')}
                  </Button>
                  <Button
                    css={bomsButtonCss}
                    color={theme.colors.error}
                    onClick={() => handleBomImportDelete(bomImport.id)}
                    disabled={R.includes(bomImport.id, loadingBomsImports)}
                  >
                    {t('bomImports.delete')}
                  </Button>
                </div>
              </Fragment>
            )
          })}
        </Box>
      </Box>
      {newBomsModalOpen && (
        <Modal
          closeOnOutsideClick={false}
          headerTitle={`Przydziel stanowiska i formaty numerów seryjnych nowym BOM'om`}
          onClose={closeNewBomsModal}
        >
          <NewBomsFormConnector
            newBomIds={locationState?.newBomIds}
            bom={locationState?.data}
            handleClose={closeNewBomsModal}
          />
        </Modal>
      )}
    </Box>
  )
}

export default BomsImportsList
