/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@rebass/emotion'
import theme from 'config/theme'
import Button from 'components/Button'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { ReactComponent as Order } from 'assets/order.svg'
import moment from 'moment'

const OrderModal = ({ row, createOrderForItem, onClose, getBomItemsQuantities, getDailyBomItems }) => {
  const [orderQuantity, setOrderQuantity] = useState(1)
  const [orderPurpose, setOrderPurpose] = useState('')
  const [error, setError] = useState(null)

  const handleSelectOrderPurpose = useCallback((event) => {
    setOrderPurpose(event.target.value)
    setError(null)
  }, [setError, setOrderPurpose])

  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const handleConfirm = async () => {
    if (orderPurpose !== '') {
      setLoading(true)
      await createOrderForItem([{
        bom_part_id: row.original.bomPartId,
        quantity: orderQuantity
      }], row.original.weeklyPlan.id, orderPurpose)
      getBomItemsQuantities()
      getDailyBomItems(moment().week())
      setLoading(false)
      onClose()
    } else {
      setError(true)
    }
  }
  return <div style={{ width: 550 }}>
    <Box css={{ padding: 30, backgroundColor: theme.colors.white, boxShadow: `1px 1px 15px ${theme.colors.darkGrey}`, position: 'relative' }}>
      <h2>{t('weeklyPlansForeman.modal.orderModalTitle')}</h2>
      <div onClick={onClose} style={{ position: 'absolute', top: 10, right: 10, fontSize: 24, cursor: 'pointer' }}>X
      </div>
      <Box my={4}>
        <Box mt={4} style={{ fontWeight: 700 }}>{row.original.name}</Box>
        <Box>{t('weeklyPlansForeman.modal.available')}: {row.original.availableQuantity}</Box>
        <Flex mt={4} justifyContent={'space-around'}>
          <Box>
            <label style={{ fontWeight: 700 }} htmlFor='orderQuantityInput'>{t('weeklyPlansForeman.modal.quantity')}</label>
            <input
              style={{ padding: '10px 5px', border: `2px solid ${theme.colors.darkGrey}`, borderRadius: '7.5px' }}
              value={orderQuantity}
              min={1}
              onChange={e => setOrderQuantity(e.target.value)}
              type='number'
              name='orderQuantityInput'
            />
          </Box>
          <Box>
            <label style={{ fontWeight: 700 }} htmlFor='orderReason'>{t('weeklyPlansForeman.modal.reason')}</label>
            <select
              value={orderPurpose}
              onChange={handleSelectOrderPurpose}
              name='orderReason'
              style={{
                border: `2px solid ${error ? theme.colors.error : theme.colors.darkGrey}`,
                backgroundColor: theme.colors.white,
                height: '42px'
              }}
            >
              <option value={''}>{t('weeklyPlansForeman.orderPurpose.choosePurpose')}</option>
              <option value={'production_damage_replacement'}>{t('weeklyPlansForeman.orderPurpose.productionDamageReplacement')}</option>
              <option value={'production_order_incomplete'}>{t('weeklyPlansForeman.orderPurpose.productionOrderIncomplete')}</option>
              <option value={'production_vendor_fault'}>{t('weeklyPlansForeman.orderPurpose.productionVendorFault')}</option>
              <option value={'production_internal_service'}>{t('weeklyPlansForeman.orderPurpose.productionInternalService')}</option>
            </select>
          </Box>
        </Flex>
      </Box>
      <Flex justifyContent={'flex-end'} my={3}>
        <Box width={1 / 2}>
          <Button
            withIcon
            color={theme.colors.lightBlue}
            icon={loading ? Loader : Order}
            disabled={loading}
            iconProps={{
              style: {
                fill: theme.colors.white
              }
            }}
            onClick={handleConfirm}
          >
            {t('weeklyPlansForeman.modal.confirm')}
          </Button>
        </Box>
      </Flex>
    </Box>
  </div>
}

export default OrderModal
