import { createSelector } from 'reselect'
import { transformToArray } from 'helpers/redux'

export const selectUsers = state => state.users.users
export const selectWasFetchedBefore = state => state.users.wasFetched

export const selectUsersAsArray = createSelector(
  selectUsers,
  transformToArray
)
