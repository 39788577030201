/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { ReactComponent as Loader } from 'assets/loader.svg'
import theme from 'config/theme'

const overlayCss = css`
  position: absolute;
  top: 52px;
  left: 0;
  z-index: 1;
  min-width: 100%;
  min-height: calc(100vh - 52px);
  background: rgba(255, 255, 255, 0.5);
`

const loaderIconCss = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const LoadingOverlay = (props) => {
  return (
    <div css={overlayCss}>
      <Loader stroke={theme.colors.darkGrey} css={loaderIconCss} width={50} height={50} />
    </div>
  )
}

export default LoadingOverlay
