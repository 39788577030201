import ConfirmItemDelivery from 'views/ConfirmItemDelivery'
import { connect } from 'react-redux'
import { getCurrentItem, getOrderByIdAndItemCode } from 'selectors/ordersForItem'
import { confirmItemDeliveryForOrder, confirmOutsideOrderDelivery } from 'ducks/ordersForItem'

const mapStateToProps = (state, ownProps) => ({
  order: getOrderByIdAndItemCode(state, ownProps.location.state.itemCode, ownProps.location.state.selectedOrderId),
  currentItem: getCurrentItem(state)
})

const mapDispatchToProps = dispatch => ({
  confirmItemDeliveryForOrder: ({ itemId, orderId, quantity, reportDiscrepancy, discrepancyComment }) => {
    return dispatch(confirmItemDeliveryForOrder({ itemId, orderId, quantity, reportDiscrepancy, discrepancyComment }))
  },
  confirmOutsideOrderDelivery: ({ itemCode, quantity }) => {
    return dispatch(confirmOutsideOrderDelivery({ itemCode, quantity }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmItemDelivery)
