/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import { ReactComponent as Cancel } from 'assets/cancel.svg'
import theme from 'config/theme'
import Container from 'components/Container'
import IconBox, { iconCss } from 'components/IconBox'
import { withTranslation } from 'react-i18next'
import { gte } from 'ramda'

export const orderCss = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center'
}

class OrderDetails extends Component {
  render () {
    const { order, index, t, orderItem, selectOrder, isSelected } = this.props
    const quantity = orderItem.quantity
    const getColor = index === 0 || isSelected ? theme.colors.black : theme.colors.grey
    const onClick = () => isSelected ? selectOrder(null) : selectOrder({ ...order, item: orderItem })
    const delivered = quantity - (orderItem.deliveredQuantity || 0)
    return (
      <div onClick={onClick} css={{ ...orderCss, color: getColor }}>
        <Container flex columns>
          <div css={{ fontWeight: 'bold' }}>{moment(order.expectedDeliveryDate).format('DD.MM.YYYY')}</div>
          <div css={{ margin: '5px 0' }}>{order.vendor.name}</div>
          <div>
            {t('orders.remaining')}: {gte(delivered, 0) ? delivered : 0}
          </div>
        </Container>
        <Container css={{ marginLeft: 'auto' }}>
          {isSelected
            ? <IconBox size={40} color={theme.colors.white} border={theme.colors.black}>
              <Cancel fill={theme.colors.black} css={iconCss} />
            </IconBox>
            : <IconBox size={40} color={getColor}>
              <Checkmark fill={theme.colors.white} css={iconCss} />
            </IconBox>
          }
        </Container>
      </div>
    )
  }
}

OrderDetails.propTypes = {
  order: PropTypes.object,
  index: PropTypes.number
}

export default withTranslation()(OrderDetails)
