/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from '@emotion/styled'
import Input from 'components/Input'
import theme from 'config/theme'

const StyledInput = styled(Input)`
  height: 48px;
  color: ${theme.colors.darkGrey};
  border-color: ${theme.colors.darkGrey};
  font-size: 20px;
  width: 100%;
  text-align: center;
`

const InputWithConstantValueWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`

const ConstantValue = styled('div')`
  height: 50px;
  min-width: 60px;
  display: flex;
  font-size: 22px;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  letter-spacing: 1px;
  background-color: #ccc;
  border-top: 2px solid ${theme.colors.black};
  border-bottom: 2px solid ${theme.colors.black};
  border-left: 2px solid ${theme.colors.black};
  margin-top: ${props => props.hasError ? '30px' : '0'};
`

const InputWithConstantValue = props => {
  const { meta: { touched, error } } = props

  const hasError = touched && error

  return (
    <InputWithConstantValueWrapper {...props}>
      {props.constantValue && <ConstantValue hasError={hasError}>{props.constantValue}</ConstantValue>}
      <StyledInput {...props} {...props.input} />
    </InputWithConstantValueWrapper>
  )
}

export default InputWithConstantValue
