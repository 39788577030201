/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Box, Flex } from '@rebass/emotion'
import { useEffect, useState, Fragment, useMemo, useRef, useCallback, memo } from 'react'
import { Redirect } from 'react-router-dom'
import * as R from 'ramda'
import { ReactComponent as Arrow } from 'assets/arrow.svg'
import theme from 'config/theme'
import Button from 'components/Button'
import useInput from 'hooks/useInput'
import useKeyHandler from 'hooks/useKeyHandler'
import {
  externalSerialNumberPath,
  extractParts,
  getSortedParts,
  hasParts,
  partsPath,
  serialNumberPath
} from 'helpers/deviceServiceHelpers'
import { toast } from 'react-toastify'
import Select from 'react-select'
import Routes from 'constants/Routes'
import { AVAILABLE_SERVICE_OPTIONS } from 'constants/deviceService'
import ProductionServiceService from 'services/ProductionServiceService'
import { useTranslation } from 'react-i18next'
import { errorHandler } from 'helpers/errorHandler'
import DeviceServicesView from 'components/DeviceServicesView'
import SteamJetGenerator from 'components/SteamJetGenerator'
import { ReactComponent as RepairIcon } from 'assets/tools.svg'
import { ReactComponent as ReplaceIcon } from 'assets/replace.svg'
import { isNotNilOrEmpty } from 'helpers/ramda'

const partMapper = (depth, setSelected, selected, repairId) => part => {
  return <PartComponent part={part} depth={depth} setSelected={setSelected} selected={selected} repairId={repairId} key={part.id} />
}

const PartComponent = memo(({ part, depth, setSelected, selected, repairId }) => {
  const boxRef = useRef(null)
  const isInsideParts = useCallback(part => {
    return (
      R.pathEq(serialNumberPath, repairId, part) ||
      R.pathEq(externalSerialNumberPath, repairId, part) ||
      (R.pathOr([], partsPath, part).findIndex(isInsideParts) >= 0)
    )
  }, [repairId])

  const isContext = useMemo(() => R.pipe(
    R.pathOr([], partsPath),
    R.findIndex(isInsideParts),
    R.lte(0)
  )(part), [part, isInsideParts])

  const isScanned = useMemo(() =>
    R.pathEq(serialNumberPath, repairId, part) ||
    R.pathEq(externalSerialNumberPath, repairId, part)
  , [part, repairId])

  const [opened, setOpened] = useState(isContext)

  useEffect(() => {
    isScanned && setSelected(part.id)
    isScanned && boxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [setSelected, isScanned])

  useEffect(() => {
    isContext && setOpened(true)
  }, [isContext])

  const hasChildren = hasParts(part)
  const isSelected = selected === part.id || selected === part.serialNumber
  const openChildren = (event) => {
    event.stopPropagation()
    setOpened(!opened)
  }

  const sortedChildren = useMemo(() => {
    return getSortedParts(part).filter(part => isNotNilOrEmpty(part.itemRevision.productionName))
  }, [part])

  const handleSelect = () => {
    setSelected(part.id)
    boxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  return (
    <Fragment>
      <Box ref={boxRef}>
        <Box onClick={handleSelect} p={6} css={partCss(depth, isSelected)}>
          {part.itemRevision.productionName || part.itemRevision.name}
          {hasChildren && isNotNilOrEmpty(sortedChildren) && (
            <Arrow onClick={openChildren} fill={opened ? theme.colors.lightGrey : theme.colors.black} css={arrowCss(opened)} />
          )}
        </Box>
      </Box>
      {hasChildren && opened && sortedChildren.map(partMapper(depth + 1, setSelected, selected, repairId))}
    </Fragment>
  )
})

const ServiceButton = props => {
  return (
    <Button
      css={{
        margin: 'unset',
        width: 'auto',
        textTransform: 'uppercase',
        marginBottom: 20
      }}
      omitTextTransform
      {...props}
    />
  )
}

const FinishService = ({ postServiceStatus }) => {
  const [status, setStatus] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [redirect, changeRedirect] = useState(false)
  const { t } = useTranslation()

  const handleSubmit = async () => {
    setIsLoading(true)

    const submitPayload = {
      status: status.value
    }

    try {
      await postServiceStatus(submitPayload)
      setIsLoading(false)
      changeRedirect(true)
    } catch (error) {
      setIsLoading(false)
      errorHandler(error)
    }
  }

  const isSubmitDisabled = isLoading || !status.label

  return (
    <Flex justifyContent='center' flexDirection='column' css={{ width: '430px' }}>
      <Select
        placeholder={t('service.finishServiceSelectPlaceholder')}
        onChange={setStatus}
        options={AVAILABLE_SERVICE_OPTIONS}
      />
      <ServiceButton
        onClick={handleSubmit}
        css={{ width: '430px', marginTop: 15 }}
        color={theme.colors.navyBlue}
        disabled={isSubmitDisabled}
      >
        {t('service.finishServiceSubmitButtonText')}
      </ServiceButton>
      { redirect && <Redirect to={Routes.serviceGetDevice} /> }
    </Flex>
  )
}

const DeviceService = ({
  getDeviceForService,
  match,
  device,
  serviceDevice,
  startDeviceService,
  finishDeviceService,
  isServiceAllowed,
  getProductionStations,
  currentService
}) => {
  const [scanValue, scanChange] = useInput('')
  const [selected, setSelected] = useState('')
  const [selectedPart, setSelectedPart] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [scannedPart, setScannedPart] = useState(match.params.serialNumber)
  const [comment, changeComment] = useInput('')
  const [newSerialNumber, changeNewSerialNumber] = useInput('')
  const [displayControls, setDisplayControls] = useState(false)
  const [serialNumbersArray, setSerialNumbersArray] = useState([])
  const [redirectPath, setRedirectPath] = useState('')
  const { t } = useTranslation()

  const isPartRepaired = useMemo(() => selectedStatus === 'fix', [selectedStatus])
  const isPartReplaced = useMemo(() => selectedStatus === 'new', [selectedStatus])

  const selectedStatusToPayload = selectedStatus === 'new' ? 'replaced' : selectedStatus === 'fix' ? 'repaired' : null
  const serialNumber = isPartReplaced ? { new_serial_number: newSerialNumber } : {}
  const givenComment = comment ? { comment } : {}
  const bomPartId = R.path([ 'id' ], selectedPart)
  const oldSerialNumber = R.path(['assembly', 0, 'serialNumber'], selectedPart)
  const oldExternalSerialNumber = R.path(['externalParts', 0, 'serialNumber'], selectedPart)
  const serialNumerPayload = oldSerialNumber || oldExternalSerialNumber
    ? newSerialNumber
      ? { old_serial_number: oldSerialNumber || oldExternalSerialNumber, new_serial_number: newSerialNumber }
      : { old_serial_number: oldSerialNumber || oldExternalSerialNumber }
    : {}

  const getDeviceAndStartService = async serialNumber => {
    try {
      const data = await getDeviceForService(serialNumber)
      data.serialNumber && await startDeviceService(data.serialNumber)
      setDisplayControls(true)
    } catch (error) {
      setDisplayControls(false)
      errorHandler(error)
      // if device was not found, user is redirected to enter serial number again
      setRedirectPath(Routes.serviceGetDevice)
    }
  }

  useEffect(() => {
    getProductionStations()
  }, [])

  const serialNumbersCollector = part => {
    part.serialNumber && setSerialNumbersArray(serialNumbersArray => [...serialNumbersArray, part.serialNumber])
    const hasParts = Boolean(part.parts)
    const parts = part.parts
    if (hasParts) {
      for (let i = 0; i < parts.length; i++) {
        const item = parts[i].assembly[0] || parts[i].externalParts?.[0]
        item && serialNumbersCollector(item)
      }
    }
  }

  useEffect(() => {
    serialNumbersCollector(device)
  }, [device])

  useEffect(() => {
    if (isServiceAllowed) {
      const serialNumber = match.params.serialNumber
      getDeviceForService && getDeviceAndStartService(serialNumber)
    }
  }, [getDeviceForService, isServiceAllowed])

  useEffect(() => {
    const findInParts = (id, object) => R.pipe(
      extractParts,
      R.find(part => R.equals(part.id, id))
    )(object)
    setSelectedPart(findInParts(selected, device))
    changeNewSerialNumber('')
    setSelectedStatus(null)
  }, [device, selected])

  const inputRef = useRef(null)

  const unfoldPart = async (scan) => {
    const isScannedValueUsed = serialNumbersArray ? serialNumbersArray.some(item => item === scan) : false
    if (isScannedValueUsed) {
      setSelected('')
      setScannedPart('')
      setScannedPart(scan)
      scanChange('')
    } else {
      try {
        await ProductionServiceService.getDeviceForService(scan)
        scanChange('')
      } catch (e) {
        errorHandler(e)
        scanChange('')
        // toast.error(<div>{t('service.errors.partOfDifferentComponentError')}</div>, { containerId: 'statuses', autoClose: 10e3 })
      }
    }
  }

  useKeyHandler(unfoldPart, inputRef, true)

  // TODO: @robson - this one was replaced with the one below
  // as it has fixed path and propSatisfies as API no longer returns "has_serial_number" field
  // const needsScanForPart = R.pathEq(['itemRevision', 'item', 'hasSerialNumbers'], true, selectedPart)
  const needsScanForPart = R.pathSatisfies(serialNumberFormat => !!serialNumberFormat, ['itemRevision', 'serialNumberFormatId'], selectedPart)

  const sortedParts = useMemo(() => getSortedParts(device).filter(part => isNotNilOrEmpty(part.itemRevision.productionName)), [device])

  const submit = async () => {
    const getAssemblyOfPart = (assembly, partId) => {
      if (assembly === undefined) {
        return undefined
      }
      if (assembly?.parts?.some(part => part.id === partId)) {
        return assembly
      }
      const parts = assembly.parts
      for (let i = 0; i < parts.length; i += 1) {
        const a = getAssemblyOfPart(parts[i].assembly[0] || parts[i].externalParts[0], partId)
        if (a !== undefined) {
          return a
        }
      }
      return undefined
    }

    const parentAssemblyId = getAssemblyOfPart(device, selected).id

    const payload = {
      parent_serial_number: selected === bomPartId ? device.serialNumber : selected,
      bom_part_id: bomPartId,
      status: selectedStatusToPayload,
      parent_service_id: currentService.id,
      ...serialNumerPayload,
      ...givenComment,
      ...serialNumber
    }

    try {
      await serviceDevice(parentAssemblyId, payload)
      const componentReplacedMessage = t('service.success.successfullyReplacedComponentMessage')
      const componentFixedMessage = t('service.success.successfullyFixedComponentMessage')
      toast.success(<div>{isPartReplaced ? componentReplacedMessage : componentFixedMessage }</div>, { containerId: 'statuses', autoClose: 5e3 })
      getDeviceForService(match.params.serialNumber)
    } catch (error) {
      errorHandler(error)
    }
  }

  const shouldNewPartIdFieldBeDisplayed = needsScanForPart && isPartReplaced
  const latestTestResult = R.path(['latestTestResult', 'isOk'], device)
  const wasAnyTestRunned = !R.isNil(latestTestResult)
  const isSubmitButtonDisabled = !selectedStatus && oldSerialNumber && !newSerialNumber
  const postServiceFinishStatus = async payload => {
    try {
      await finishDeviceService(match.params.serialNumber, payload)
    } catch (error) {
      errorHandler(error)
    }
  }

  if (!isServiceAllowed) {
    return <Flex justifyContent={'center'} mt={10}><h2>{t('service.serviceNotAllowedOnCurrentPost')}</h2></Flex>
  }
  const notAvailableStatus = t('service.detailsNotAvailable')
  const okStatus = t('service.detailsOfCurrentDevice.lastTestResultStatuses.ok')
  const errorStatus = t('service.detailsOfCurrentDevice.lastTestResultStatuses.error')

  return (
    <Flex>
      {process.env.NODE_ENV === 'development' && <SteamJetGenerator code={R.pathOr('', ['itemRevision', 'fullIndex'], selectedPart)} />}
      <Box flex={1}>
        <div css={{ width: 0, overflow: 'hidden' }}>
          <input type='text' onChange={scanChange} value={scanValue} ref={inputRef} css={{ opacity: 0, filter: 'alpha(opacity:0)' }} />
        </div>
        <Box px={9} py={6} css={{ position: 'sticky', top: 70, backgroundColor: 'white', zIndex: 1 }}>
          <Flex mb={8} css={deviceDescriptionCss}>
            <div>{t('service.detailsOfCurrentDevice.device')}</div>
            <div>
              <b>
                {
                  R.path(['itemRevision', 'productionName'], device) ||
                  R.pathOr(notAvailableStatus, ['itemRevision', 'name'], device)
                }
              </b>
            </div>
            <div>{t('service.detailsOfCurrentDevice.serialNumber')}</div>
            <div><b>{R.propOr(notAvailableStatus, 'serialNumber', device)}</b></div>
          </Flex>
          <Flex mb={8} css={deviceDescriptionCss}>
            <div>{t('service.detailsOfCurrentDevice.selectedPart')}</div>
            <div>
              <b>
                {
                  R.path(['itemRevision', 'productionName'], selectedPart) ||
                  R.pathOr(notAvailableStatus, ['itemRevision', 'name'], selectedPart)
                }
              </b>
            </div>
            <div>{t('service.detailsOfCurrentDevice.serialNumber')}</div>
            <div><b>{R.pathOr(
              R.pathOr(notAvailableStatus, ['externalParts', 0, 'serialNumber'], selectedPart),
              ['assembly', 0, 'serialNumber'],
              selectedPart
            )}</b></div>
          </Flex>
          <Flex mb={3} css={deviceDescriptionCss}>
            <div>{t('service.detailsOfCurrentDevice.lastTestResult')}</div>
            <div><b>{wasAnyTestRunned ? latestTestResult ? okStatus : errorStatus : notAvailableStatus}</b></div>
          </Flex>
        </Box>
        <Flex flexDirection='column' alignItems='flex-start' p={9}>
          {sortedParts.map(partMapper(0, setSelected, selected, scannedPart))}
        </Flex>
      </Box>
      {displayControls && (
        <Flex
          p={9}
          flexDirection='column'
          alignSelf={'flex-start'}
          alignItems='flex-start'
          flex={1}
          css={{ position: 'sticky', top: 76, maxHeight: 'calc(100vh - 76px)', overflow: 'scroll' }}
        >
          <FinishService postServiceStatus={postServiceFinishStatus} />
          <Flex justifyContent='space-between' css={{ minWidth: '430px' }}>
            <ServiceButton
              onClick={() => setSelectedStatus('fix')}
              color={isPartRepaired ? theme.colors.successGreen : theme.colors.darkBlue}
              css={{
                opacity: isPartReplaced ? 0.2 : 1,
                boxShadow: isPartRepaired && `0 0 1.25em rgba(0,0,0,0.5)`,
                width: '140px'
              }}
              withIcon
              icon={RepairIcon}
              iconProps={{ fill: 'white', width: '100%', height: '100%' }}
            >
              {t('service.fixButtonText')}
            </ServiceButton>
            <ServiceButton
              onClick={() => setSelectedStatus('new')}
              color={isPartReplaced ? theme.colors.successGreen : theme.colors.darkBlue}
              css={{
                opacity: isPartRepaired ? 0.2 : 1,
                boxShadow: isPartReplaced && `0 0 1.25em rgba(0,0,0,0.5)`,
                width: '140px'
              }}
              withIcon
              icon={ReplaceIcon}
              iconProps={{ fill: 'white', width: '100%', height: '100%' }}
            >
              {t('service.replacePartButtonText')}
            </ServiceButton>
          </Flex>
          {
            selectedStatus && (
              <textarea
                placeholder={t('service.commentBoxPlaceholder')}
                css={{ minWidth: 400, minHeight: 200, height: 'auto', border: '2px solid black', padding: 15 }}
                value={comment}
                onChange={changeComment}
              />
            )}
          {shouldNewPartIdFieldBeDisplayed && (
            <input
              placeholder={t('service.newSerialNumberBoxPlaceholder')}
              value={newSerialNumber}
              onChange={changeNewSerialNumber}
              css={{ minWidth: 400, padding: 15, border: '2px solid black', marginTop: 20 }}
            />
          )}
          {selectedStatus && (
            <Button
              onClick={submit}
              css={{ margin: '20px 0', width: '150px' }}
              disabled={isSubmitButtonDisabled}
            >
              {t('service.finishSubComponentFixtureOrReplacement')}
            </Button>
          )}
          <DeviceServicesView device={device} depth={0} />
        </Flex>
      )}
      {redirectPath ? <Redirect to={redirectPath} /> : null}
    </Flex>
  )
}

const deviceDescriptionCss = css`
  & > div:not(:first-child) {
    margin-left: 20px;
  }
`

const partCss = (depth, isSelected) => css`
  position: relative;
  box-shadow: 0 0 ${isSelected ? 20 : 5}px ${isSelected ? theme.colors.successGreen : 'rgba(0, 0, 0, 0.2)'};
  border-radius: 5px;
  margin-left: ${depth * 30}px;
  margin-bottom: 5px;
  font-weight: bold;
  transition: all 0.3s;
`

const arrowCss = (opened) => css`
  width: 20px;
  height: 20px;
  position: absolute;
  left: -30px;
  transform: rotate(${opened ? 90 : 0}deg);
  transition: all 0.3s;
`

export default DeviceService
