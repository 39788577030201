/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import Button from 'components/Button'
import theme from 'config/theme'
import { getBomsFromBomTreeAsArrayByIds } from 'helpers/bom'
import { errorHandler } from 'helpers/errorHandler'
import { transformById } from 'helpers/redux'
import PropTypes from 'prop-types'
import * as R from 'ramda'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { toast } from 'react-toastify'
import { Flex } from '@rebass/emotion'
import Routes from 'constants/Routes'

const invalidDataMessage = 'Błędne dane'

const pickValue = R.map(R.prop('value'))

const makeObjectWithArrayValues = (defaultValue = '') => R.reduce((acc, next) => {
  acc[next] = defaultValue
  return acc
}, {})

const formContainerCss = css`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2px;
  padding: 2px;
  background: black;
  align-items: center;
`
const rowHeaderCss = css`
  background: white;
  padding: 10px;
  align-self: stretch;
`

const rowHeaderFlex = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

const NewBomsForm = ({
  bom,
  newBomIds,
  productionStations,
  getSerialNumberFormatsIfNeeded,
  serialNumberFormats,
  getProductionStations,
  editItemRevision,
  updateBomFields,
  handleClose,
  history
}) => {
  const { t } = useTranslation()
  const newBomsItems = useMemo(() => getBomsFromBomTreeAsArrayByIds(bom, newBomIds), [newBomIds, bom])
  // prepares initial values from the form
  const makeInitialValues = (bomItems, defaultSerialNumberFormatValue = '', defaultAssemblyPostIdValue = []) => R.pipe(
    boms => transformById(boms, 'itemRevisionId'),
    Object.keys,
    R.applySpec({
      serialNumberFormats: makeObjectWithArrayValues(defaultSerialNumberFormatValue),
      assemblyPostIds: makeObjectWithArrayValues(defaultAssemblyPostIdValue)
    })
  )(bomItems)
  const [formValues, setFormValues] = useState(makeInitialValues(newBomsItems))
  const [hasErrors, setHasErrors] = useState(false)
  const [errors, setErrors] = useState(makeInitialValues(newBomsItems, false, false))
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getSerialNumberFormatsIfNeeded()
    getProductionStations()
  }, [getSerialNumberFormatsIfNeeded, getProductionStations])

  const handleStationSelect = useCallback(itemRevisionId => station => {
    setFormValues(R.evolve({
      assemblyPostIds: {
        [itemRevisionId]: R.always(station)
      }
    }))
  }, [setFormValues])

  const handleFormatSelect = useCallback(itemRevisionId => format => {
    setFormValues(R.evolve({
      serialNumberFormats: {
        [itemRevisionId]: R.always(format)
      }
    }))
  }, [setFormValues])

  const getBomIdByItemRevisionId = (itemRevisionId) => {
    const item = transformById(newBomsItems, 'itemRevisionId')[itemRevisionId]
    return R.pathOr('', ['itemRevision', 'bom', 'id'], item)
  }

  const handleSubmit = async () => {
    try {
      if (!hasErrors) {
        const promises = []

        setIsLoading(true)
        R.forEachObjIndexed(async (newSerialNumberFormat, itemRevisionId) => {
          promises.push(editItemRevision({
            revisionId: itemRevisionId,
            field: 'serialNumberFormatId',
            value: newSerialNumberFormat.value
          }))
        }, formValues.serialNumberFormats)

        R.forEachObjIndexed(async (newAssemblyPostIds, itemRevisionId) => {
          const bomId = getBomIdByItemRevisionId(itemRevisionId)
          promises.push(updateBomFields({ assemblyPostIds: pickValue(newAssemblyPostIds), bomId }))
        }, formValues.assemblyPostIds)

        await Promise.all(promises)
        setIsLoading(false)
        handleClose()
        history.replace(Routes.boms)
      } else {
        throw new Error(invalidDataMessage)
      }
    } catch (error) {
      if (error.message === invalidDataMessage) {
        return toast.error(<div>{error.message}</div>, { containerId: 'statuses', autoClose: 15e3 })
      }
      errorHandler(error)
    }
  }

  useEffect(() => {
    const currentErrors = makeInitialValues(newBomsItems, false, false)
    R.forEachObjIndexed((serialNumberFormat, itemRevisionId) => {
      if (!serialNumberFormat) {
        currentErrors.serialNumberFormats[itemRevisionId] = true
      }
    }, formValues.serialNumberFormats)

    R.forEachObjIndexed((assemblyPostId, itemRevisionId) => {
      if (!assemblyPostId.length) {
        currentErrors.assemblyPostIds[itemRevisionId] = true
      }
    }, formValues.assemblyPostIds)

    setErrors(currentErrors)
  }, [formValues, newBomsItems])

  const makeSelectStyles = hasError => ({
    control: styles => ({
      ...styles,
      borderColor: hasError ? theme.colors.error : styles.borderColor
    }),
    menu: styles => ({
      ...styles,
      position: 'absolute',
      top: '40px'
    })
  })

  useEffect(() => {
    const hasError = formFields => R.any(R.identity, R.values(formFields))
    const hasErrors = hasError(errors.serialNumberFormats) || hasError(errors.assemblyPostIds)
    setHasErrors(hasErrors)
  }, [errors, setHasErrors])

  return (
    <Flex flexDirection='column' justifyContent='space-between' css={{ minHeight: 400, maxHeight: '70vh', overflow: 'auto' }}>
      <div css={formContainerCss}>
        {newBomsItems.map((newBom) => {
          const itemRevisionId = R.prop('itemRevisionId', newBom)
          const selectedAssemblyPosts = R.path(['assemblyPostIds', itemRevisionId], formValues)
          const selectedFormat = R.path(['serialNumberFormats', itemRevisionId], formValues)
          const assemblyPostError = R.path(['assemblyPostIds', itemRevisionId], errors)
          const serialNumberFormatError = R.path(['serialNumberFormats', itemRevisionId], errors)
          return (
            <Fragment key={newBom.id}>
              <div css={css`${rowHeaderCss}; ${rowHeaderFlex};`}>
                <b>{R.pathOr('', ['itemRevision', 'fullIndex'], newBom)}</b>&nbsp;
                {R.pathOr('', ['itemRevision', 'item', 'name'], newBom)}
              </div>
              <Select
                value={selectedAssemblyPosts}
                placeholder={t('productionStations.chooseStationShort')}
                onChange={handleStationSelect(itemRevisionId)}
                name={'assemblyPosts'}
                isMulti
                options={productionStations}
                css={rowHeaderCss}
                closeMenuOnSelect={false}
                styles={makeSelectStyles(assemblyPostError)}
              />
              <Select
                value={selectedFormat}
                placeholder={'wybierz format numeru seryjnego'}
                onChange={handleFormatSelect(itemRevisionId)}
                options={serialNumberFormats}
                name={'serialNumberFormats'}
                css={rowHeaderCss}
                styles={makeSelectStyles(serialNumberFormatError)}
              />
            </Fragment>
          )
        })}
      </div>
      <div css={{ marginTop: 40 }}>
        <Button
          disabled={isLoading}
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          zapisz wszystko
        </Button>
      </div>
    </Flex>
  )
}

export default NewBomsForm

NewBomsForm.propTypes = {
  bom: PropTypes.object.isRequired,
  editItemRevision: PropTypes.func.isRequired,
  getProductionStations: PropTypes.func.isRequired,
  getSerialNumberFormatsIfNeeded: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  newBomIds: PropTypes.array.isRequired,
  productionStations: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
  serialNumberFormats: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })).isRequired,
  updateBomFields: PropTypes.func.isRequired
}
