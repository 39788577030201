import { createSelector } from 'reselect'
import { transformToArray } from 'helpers/redux'
import * as R from 'ramda'

const getInternallyOrderedItems = state => state.internalOrderedItems.entries

export const getInternallyOrderedItemsAsArrayByStatus = createSelector(
  getInternallyOrderedItems,
  (state, status) => status,
  (internalOrderedItems, status) => {
    const items = internalOrderedItems[status]
    return Array.isArray(items) ? items : items ? R.map(transformToArray, items) : []
  }
)
