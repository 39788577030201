import WarehouseState from 'views/WarehouseState/WarehouseState'
import { connect } from 'react-redux'
import { getWarehouseState, getWarehouseStateWithBlankItems } from 'ducks/warehouseState'
import {
  getWarehouseStateAllItemsAsArray,
  getWarehouseStateAsArray
} from 'selectors/warehouseState'
import { issueStocktakingForItem, resetError, registerMultipleIssueStocktaking } from 'ducks/stocktaking'

const mapStateToProps = state => ({
  warehouseState: getWarehouseStateAsArray(state),
  warehouseStateAllItems: getWarehouseStateAllItemsAsArray(state),
  error: state.stocktaking.error,
  userRole: state.user.roles[0]
})

const mapDispatchToProps = dispatch => ({
  getWarehouseState: () => dispatch(getWarehouseState()),
  getWarehouseStateWithBlankItems: () => dispatch(getWarehouseStateWithBlankItems({ include_inventory: 1 })),
  issueStocktakingForItem: code => dispatch(issueStocktakingForItem(code)),
  resetError: () => dispatch(resetError()),
  registerMultipleIssueStocktaking: itemsArray => dispatch(registerMultipleIssueStocktaking(itemsArray))
})

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseState)
