/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import { useEffect, useState } from 'react'
import theme from 'config/theme'
import { weeklyPlanStatus } from 'constants/weeklyPlansStatuses'

const EditStatus = ({ status, acceptedText, editedText, changedText, className }) => {
  const [color, setColor] = useState(null)
  const [text, setText] = useState('')

  useEffect(() => {
    switch (status) {
      case weeklyPlanStatus.accepted: {
        setColor(theme.colors.successGreen)
        setText(acceptedText)
        break
      }
      case weeklyPlanStatus.edited: {
        setColor(theme.colors.orange)
        setText(editedText)
        break
      }
      case weeklyPlanStatus.changed: {
        setColor(theme.colors.error)
        setText(changedText)
        break
      }
      default: {
        setColor(theme.colors.successGreen)
        setText(acceptedText)
        break
      }
    }
  }, [status])

  const statusCss = css`
    color: ${color};
    position: relative;
    font-size: 24px;
    &:before {
      content: '';
      position: absolute;
      left: -40px;
      top: 3px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: ${color};
    }
  `
  return (
    <div className={className}>
      <p css={statusCss}>{text}</p>
    </div>
  )
}

EditStatus.propTypes = {
  acceptedText: PropTypes.string,
  changedText: PropTypes.string,
  className: PropTypes.string,
  editedText: PropTypes.string,
  status: PropTypes.string.isRequired,
  successText: PropTypes.string
}

export default EditStatus
