/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useEffect, useState } from 'react'
import scriptLoader from 'react-async-script-loader'
import { Flex } from '@rebass/emotion'
import useBattery from 'react-use/lib/useBattery'
import batteryImageString from 'constants/batteryImageString'
import { ReactComponent as Wifi } from 'assets/wifi.svg'

const batteryContainerCss = css`
  border: 3px solid #333;
  width: 18px;
  height: 18px;
  padding: 2px;
  border-radius: 4px;
  position: relative;
  margin: 5px;
  
  &:before {
    content: '';
    height: 3px;
    width: 18px;
    background: #333;
    display: block;
    position: absolute;
    top: -6px;
    border-radius: 4px 4px 0 0;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 1px solid #fff;
    border-radius: 2px;
  }
`

const batteryInsideCss = (height) => css`
  height: ${(height / 100) * 18}px;
  background: ${height <= 10 ? '#e81309' : height <= 20 ? '#EFAF13' : '#30b455'};  
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  
  &:before {
    ${height <= 10 && `
      background-image: url(${batteryImageString});
      background-repeat: no-repeat;
      background-size: 18px;
      height: 18px;
      width: 18px;
      margin: -19px 0 0 2px;
      content: '';
      display: inline-block;
      position: absolute;
    `}; 
  }
`

const DeviceStatus = () => {
  const [{ signalStrength }, setWlanStatus] = useState({})
  const battery = useBattery()
  const batteryLevel = (battery?.level && Math.round(battery.level * 100)) || 0

  const getWlanStatus = wlanStatus => wlanStatus && setWlanStatus(wlanStatus)

  useEffect(() => {
    window.EB && window.EB.SignalIndicators.wlanStatus(getWlanStatus)

    return () => {
      window.EB && window.EB.SignalIndicators.stopWlanStatus(getWlanStatus)
    }
  }, [window.EB])

  return (
    <div>
      <Flex alignItems='center'><Wifi css={{ margin: 5 }} />sygnał: {signalStrength}%</Flex>
      <Flex alignItems='center'>
        <div css={batteryContainerCss}>
          <div css={batteryInsideCss(batteryLevel)} />
        </div>
        bateria: {batteryLevel}%
      </Flex>
    </div>
  )
}

export default scriptLoader('/ebapi-modules.js')(DeviceStatus)
