/** @jsx jsx */
import { Fragment, useMemo, useState } from 'react'
import { css, jsx } from '@emotion/core'
import { Flex } from '@rebass/emotion'
import { path, pathOr } from 'ramda'

import theme from 'config/theme'

import PortalModal from 'components/Modal'
import Button from 'components/Button'
import { NoItemsToShow } from 'components/FilterBox'

const cancelledEventCss = css`
  display: flex; 
  flex-direction: row; 
  width: 100%; 
  height: 50px; 
  padding: 8px; 
  justify-content: space-between; 
  align-items: center;
  border-bottom: ${theme.colors.darkGrey} 1px solid;
  &:last-child {
    border-bottom: none;
  }
`

const extraDataCss = css`
  padding-top: 7px;
`

export const CancelledInternalOrdersModal = ({ onClose, cancelledInternalOrders }) => {
  const [expandedDate, setExpandedDate] = useState(null)
  const [expandedPart, setExpandedPart] = useState(null)

  const areThereCancelledInternalOrders = useMemo(() => !!cancelledInternalOrders.length, [cancelledInternalOrders])
  const handleHideExpandedDate = () => { setExpandedDate(null) }

  return (
    <PortalModal onClose={onClose} headerTitle={`Anulowane zamówienia wewnętrzne ${expandedDate ? `z: ${expandedDate.createdAt}` : ''}`} zIndex={100}>
      {areThereCancelledInternalOrders ? (
        <div>
          {/* Headers */}
          <div css={cancelledEventCss}>
            <Flex width={1 / 3} justifyContent='center' alignItems='center' flexDirection='column'>
              <p>Utworzono</p>
              {expandedDate && <p css={extraDataCss}>{expandedDate.createdAt}</p>}
            </Flex>
            <Flex width={1 / 3} justifyContent='center' alignItems='center' flexDirection='column'>
              <p>Ilość jakiej dotyczy</p>
              {expandedDate && <p css={extraDataCss}>{expandedDate.ordersCount}</p>}
            </Flex>
            <Flex width={1 / 3} justifyContent='center' alignItems='center' flexDirection='column'>
              {expandedDate ? (
                <Button size='small' primary onClick={handleHideExpandedDate}>wróć</Button>
              ) : (
                <span>Więcej</span>
              )}
            </Flex>
          </div>
          <div css={css`max-height: 700px; overflow-y: scroll; width: 100%;`}>
            {expandedDate ? expandedDate?.orders.map(order => {
              const isOrderExpanded = expandedPart === path(['id'], order)
              const isAnyOrderExpanded = !!expandedPart
              const shouldOrderBeExpanded = !isAnyOrderExpanded || isOrderExpanded

              return (
                <Fragment key={order.id}>
                  {shouldOrderBeExpanded && (
                    <div
                      key={order.id}
                      css={css`display: flex; flex-direction: row; padding: 8px 0px; border-bottom: 1px solid ${theme.colors.darkGrey} &:isLastChild{ border: none;}`}
                    >
                      <Flex width={1} justifyContent='space-between'>
                        <Flex width={2 / 3} alignItems='center'>
                          <h4>{pathOr('brak nazwy', ['bomWeeklyPlan', 'bom', 'itemRevision', 'name'], order)}</h4>
                        </Flex>
                        <Flex width={1 / 3} mr={-3} justifyContent='center'>
                          <Button
                            primary
                            size={'small'}
                            onClick={() => isOrderExpanded ? setExpandedPart(null) : setExpandedPart(pathOr(null, ['id'], order))}
                          >
                            {isOrderExpanded ? 'Zamknij' : 'Rozwiń'}
                          </Button>
                        </Flex>
                      </Flex>
                    </div>
                  )}
                  {isOrderExpanded && (
                    // cancelled positions
                    <Flex my={4} py={8} style={{ border: `1px solid ${theme.colors.darkGrey}`, borderRadius: '8px' }} flexDirection='column'>
                      <div css={css`display: flex; flex-direction: row; width: 100%; padding-bottom: 10px; border-bottom: 1px solid ${theme.colors.lightGrey};`}>
                        <Flex width={1 / 2} justifyContent='center' alignItems='center'>
                          <p>Komponent</p>
                        </Flex>
                        <Flex width={1 / 2} justifyContent='center' alignItems='center'>
                          <p>Anulowana ilość</p>
                        </Flex>
                      </div>
                      {order.items.map(order => (
                        <Flex key={order.id} p={4} justifyContent='space-between'>
                          <Flex width={1 / 2}>
                            <h4>{pathOr('brak nazwy', ['itemRevision', 'name'], order)}</h4>
                          </Flex>
                          <Flex width={1 / 2} justifyContent='center' alignItems='center'>
                            <span>{pathOr('N/A', ['undeliveredQuantity'], order)}</span>
                          </Flex>
                        </Flex>
                      ))}
                    </Flex>
                  )}
                </Fragment>
              )
            }) : cancelledInternalOrders.map(cancellingEvent => {
              // case when there are no expanded cancellation dates
              return (
                <div key={cancellingEvent.id} css={cancelledEventCss}>
                  <Flex width={1 / 3} justifyContent='center'>
                    {cancellingEvent.createdAt}
                  </Flex>
                  <Flex width={1 / 3} justifyContent='center'>
                    {cancellingEvent.ordersCount}
                  </Flex>
                  <Flex width={1 / 3} justifyContent='center'>
                    <Button primary size={'small'} onClick={() => setExpandedDate(cancellingEvent)}>Szczegóły</Button>
                  </Flex>
                </div>
              )
            })}
          </div>
        </div>
      ) : (
        <NoItemsToShow />
      )}
    </PortalModal>
  )
}

export default CancelledInternalOrdersModal
