/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useEffect, useState } from 'react'
import Breadcrumb from 'components/Breadcrumb'
import { useTranslation } from 'react-i18next'
import ItemDescription from 'components/ItemDescription'
import { withRouter } from 'react-router-dom'
import Divider from 'components/Divider'
import LabelledDivider from 'components/LabelledDivider'
import { Field, Form } from 'redux-form'
import Container from 'components/Container'
import Input from 'components/Input'
import KeyCapture from 'components/KeyCapture'
import ScanInput from 'components/ScanInput'
import Button from 'components/Button'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import { inputCss } from 'forms/OnboardItemForm'
import { scanParser } from 'helpers/scanParser'
import Routes from 'constants/Routes'

const MoveItemOnWarehouse = ({ location, currentItem, handleSubmit, change, destinationCodeValue, activeFields, history }) => {
  const { t } = useTranslation()

  const onScan = async data => {
    const scan = scanParser(data)
    const isItem = scan.type === 'item'
    if (isItem) {
      history.replace(`/move/select/${scan.data}`)
    } else {
      change('destinationCode', scan.data)
    }
  }

  const isQuantityActive = activeFields?.quantity?.active

  const changeLocationFloor = direction => {
    let splittedLocationCode = destinationCodeValue?.split('-')
    if (splittedLocationCode && !isQuantityActive) {
      if (direction === 'down' && Number(splittedLocationCode[2]) > 0) {
        splittedLocationCode[2] = Number(splittedLocationCode[2]) - 1
      } else if (direction === 'up' && Number(splittedLocationCode[2]) < 10) {
        splittedLocationCode[2] = Number(splittedLocationCode[2]) + 1
      }
      change('destinationCode', splittedLocationCode.join('-'))
    }
  }

  const submit = values => {
    history.replace({
      pathname: Routes.onBoardingFinalize,
      state: {
        isMoveItem: true,
        sourceLocationId: location.state?.sourceInventory?.id,
        ...values,
        item: currentItem,
        itemCode: currentItem.fullIndex
      }
    })
  }

  const [scanVisible, setScanVisible] = useState(false)
  useEffect(() => {
    setScanVisible(true) // super awesome hack preventing QrCodeReader from mounting too soon after unmounting
  }, [])

  return (
    <div>
      <Breadcrumb>{t('warehouse.moveItems')}</Breadcrumb>
      <Fragment>
        <Divider noMargin thin />
        <ItemDescription item={currentItem} />
        <Divider noMargin thin />
        <LabelledDivider text={t('warehouse.itemCount')} />
        <div>
          <Form onSubmit={handleSubmit(submit)}>
            <Container>
              <Field
                component={Input}
                css={inputCss}
                numericKeyboard
                name='quantity'
                type='number'
                step='1'
              />
            </Container>
            <KeyCapture callback={() => changeLocationFloor('up')} keyToCapture={19} shouldBubble={isQuantityActive} />
            <KeyCapture callback={() => changeLocationFloor('down')} keyToCapture={20} shouldBubble={isQuantityActive} />
            <LabelledDivider text={t('warehouse.locationChangeFrom', { sourceInventoryCode: location.state.sourceInventory.warehouseLocation.code })} />
            {scanVisible && <Field
              component={ScanInput}
              name='destinationCode'
              onScan={onScan}
            />}
            <LabelledDivider text={t('warehouse.confirmingLocation')} />
            <Container flex centerItems>
              <Button type='submit' withIcon icon={Checkmark} iconProps={{ fill: '#fff' }}>
                {t('warehouse.confirmLocation')}
              </Button>
            </Container>
          </Form>
        </div>
      </Fragment>
    </div>
  )
}

export default withRouter(MoveItemOnWarehouse)
