/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import theme from 'config/theme'
import RevertButton from 'components/RevertButton'
import { resetInput } from 'ducks/searchInput'
import { connect } from 'react-redux'
import { useEffect } from 'react'

const containerStyles = {
  backgroundColor: theme.colors.darkGrey,
  color: theme.colors.white,
  padding: theme.space[7],
  textAlign: 'center',
  fontWeight: 'bold'
}

const StatusBox = (props) => {
  useEffect(() => {
    props.withRevertButton && props.resetInput()
  }, [])

  const handleFinish = () => {
    props.resetInput()
    props.onFinish()
  }
  return (
    <div css={containerStyles}>
      <h3 css={{ margin: props.withRevertButton && '10px 0' }}>{props.children}</h3>
      {props.withRevertButton && !props.isLoading && <RevertButton onFinish={handleFinish} onClickFinish={props.onClickFinish} />}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  resetInput: () => dispatch(resetInput())
})

export default connect(null, mapDispatchToProps)(StatusBox)

StatusBox.propTypes = {
  onClickFinish: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  resetInput: PropTypes.func,
  withRevertButton: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired
}
