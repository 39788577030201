import bomImportsActionTypes from 'constants/bomImportsActionTypes'
import { changeNodeAtPath, map as mapTreeView } from 'react-sortable-tree'
import * as R from 'ramda'
import { useCallback } from 'react'

const useBomModifications = (treeView, setTreeView) => {
  const handleIgnoreElement = useCallback((node, currentPath) => () => {
    const newNode = R.evolve({
      original: {
        processingDetails: {
          status: R.always(bomImportsActionTypes.IGNORE)
        }
      }
    }, node)

    const newTreeView = changeNodeAtPath({
      treeData: treeView,
      path: currentPath,
      newNode,
      getNodeKey: R.pathOr('none', ['node', 'original', 'id']),
      ignoreCollapsed: false
    })

    setTreeView(newTreeView)
  }, [treeView, setTreeView])

  const handleUnIgnoreElement = useCallback((node, currentPath) => () => {
    const newNode = R.evolve({
      original: {
        processingDetails: {
          status: R.always(bomImportsActionTypes.KEEP)
        }
      }
    }, node)

    const newTreeView = changeNodeAtPath({
      treeData: treeView,
      path: currentPath,
      newNode,
      getNodeKey: R.path(['node', 'original', 'id']),
      ignoreCollapsed: false
    })

    setTreeView(newTreeView)
  }, [treeView, setTreeView])

  const handleChangeNode = useCallback((node, currentPath) => (newQuantity, newStage, newAssemblyTime, newFinalAssemblyMmi) => {
    const quantity = Number(newQuantity)

    const newNode = R.evolve({
      original: {
        processingDetails: {
          quantity: R.always(quantity),
          stage: R.always(newStage),
          assemblyTime: R.always(newAssemblyTime),
          finalAssemblyMmi: R.always(newFinalAssemblyMmi)
        }
      }
    }, node)

    const newTreeView = changeNodeAtPath({
      treeData: treeView,
      path: currentPath,
      newNode,
      getNodeKey: R.path(['node', 'original', 'id']),
      ignoreCollapsed: false
    })

    if (!R.equals(treeView, newTreeView)) {
      setTreeView(newTreeView)
    }
  }, [treeView, setTreeView])

  const handleMoveNode = useCallback((action) => {
    const originalParentId = action.node.originalParentId
    const newParentId = action.nextParentNode?.original.id
    const isNewNode = R.pathEq(['node', 'original', 'processingDetails', 'action', 'type'], bomImportsActionTypes.CREATE, action)
    const getAction = () => {
      switch (true) {
        case isNewNode:
          return {
            parentId: newParentId
          }
        case newParentId !== originalParentId:
          return {
            type: bomImportsActionTypes.MOVE,
            originalParentId: action.node.originalParentId,
            newParentId: action.nextParentNode?.original.id
          }
        default:
          return { action: bomImportsActionTypes.KEEP }
      }
    }

    const nodeAction = nodeId => nodeId === action.node.original.id ? {
      action: R.mergeLeft(getAction())
    } : {}

    const newTreeView = action.nextParentNode ? mapTreeView({
      treeData: action.treeData,
      getNodeKey: R.path(['node', 'original', 'id']),
      callback: ({ node, parentNode }) => R.evolve({
        original: {
          processingDetails: {
            ...nodeAction(node.original?.id),
            sortOrder: R.pipe(
              () => R.findIndex(
                child => R.equals(child.original?.id, node.original?.id),
                R.propOr([], 'children', parentNode)
              ),
              R.ifElse(
                R.lt(0),
                R.add(1),
                R.always(1)
              )
            )
          }
        }
      }, node),
      ignoreCollapsed: false
    }) : action.treeData

    setTreeView(newTreeView)
  }, [setTreeView])

  return [handleIgnoreElement, handleUnIgnoreElement, handleMoveNode, handleChangeNode]
}

export default useBomModifications
