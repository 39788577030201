import ReceiveInternalOrderForm from 'forms/ReceiveInternalOrderForm'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import i18n from 'i18next'
import { confirmInternalOrderDelivery } from 'ducks/internalOrders'

let Form = reduxForm({
  enableReinitialize: true,
  validate: (values, ownProps) => {
    const errors = {}
    const requiredFields = ['quantity']
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = i18n.t('common.fieldRequired')
      }
    })

    if (values['quantity'] && !values['quantity'] > '0') {
      errors['quantity'] = i18n.t('common.numberMustBePositive')
    }

    if (values['quantity'] && !values['quantity'] > '0') {
      errors['quantity'] = i18n.t('common.numberMustBePositive')
    }

    return errors
  }
})(ReceiveInternalOrderForm)

const mapStateToProps = (state, ownProps) => ({
  form: 'ReceiveInternalForm-' + ownProps.item.id,
  initialValues: { ...ownProps.initialValues, internalOrderItemId: ownProps.item.id }
})

const mapDispatchToProps = dispatch => ({
  confirmInternalOrderDelivery: values => dispatch(confirmInternalOrderDelivery(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
