import BomsImportsList from 'views/BomsImportsList'
import { connect } from 'react-redux'
import { getBomsImportsAsArray } from 'selectors/bomsImports'
import { compose } from 'redux'
import { getBomsImports, uploadBomImport, deleteBomImportById } from 'ducks/bomsImports'

const mapStateToProps = state => ({
  bomsImports: getBomsImportsAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  getBomsImports: compose(dispatch, getBomsImports),
  uploadBomImport: compose(dispatch, uploadBomImport),
  deleteBomImportById: compose(dispatch, deleteBomImportById)
})

export default connect(mapStateToProps, mapDispatchToProps)(BomsImportsList)
