import 'moment/locale/pl.js'
import i18next from 'i18next'
import moment from 'moment'
import pl from './pl.json'
import { initReactI18next } from 'react-i18next'

const defaultConfig = {
  debug: process.env.NODE_ENV === 'development',
  contextSeparator: '.',
  interpolation: {
    escapeValue: false
  },
  fallbackLng: 'pl',
  resources: {
    pl: {
      translation: pl
    }
  },
  lng: 'pl'
}

const i18n = (config = defaultConfig) => i18next
  .use(initReactI18next)
  .init(config, () => {
    moment.locale(i18n.language)
  })

export default i18n
