/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Box, Flex } from '@rebass/emotion'
import ReactList from 'react-list'
import { useTranslation } from 'react-i18next'
import {
  nameCss,
  normalCellCss,
  sortableCss,
  arrowUpCss,
  arrowDownCss,
  catalogueWrapperCss,
  gridCss
} from 'views/Catalogue/styles'
import * as JsSearch from 'js-search'
import * as styles from 'views/WarehouseState/styles'
import { ReactComponent as CancelIcon } from 'assets/cancel.svg'
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'
import sort from 'fast-sort'
import { getHeaders } from 'views/Catalogue/headerColumns'
import { getColumns } from 'views/Catalogue/rowColumns'
import RenderCell from 'views/Catalogue/RenderCell'
import { includesInArray } from 'helpers/array'
import { Roles } from 'constants/Roles'
import theme from 'config/theme'
import * as R from 'ramda'
import useDebounce from 'hooks/useDebounce'
import useSumedItems from 'hooks/useSummedItems'
import ImagesModal from './ImagesModal'
import AlternativesModal from './AlternativesModal'
import ColorsModal from 'views/Catalogue/ColorsModal'
import StorageService, { LocalStorageKeys } from 'services/StorageService'
import { renderMarkers } from 'helpers/catalogue'

let allItemsIndex

const getBomItems = R.filter(R.where({
  item: R.whereEq({
    type: 'BOM',
    group: 'Produkt'
  })
}))

const MainCatalogueView = props => {
  const { t } = useTranslation()
  const [searchPhrase, setPhrase] = useState('')
  const [currentItems, setCurrentItems] = useState([])
  const [imagesModalContentId, setImagesModalContentId] = useState(null)
  const [alternativesModalId, setAlternativesModalId] = useState(null)
  const [colorsModalId, setColorsModalId] = useState(null)
  const [isProduct, setIsProduct] = useState(false)
  const [hasColorVariants, setHasColorVariants] = useState(null)
  const [itemsToRender, setItemsToRender] = useState([])
  const [storage, setStorage] = useState({})

  useEffect(() => {
    return searchPhrase.length >= 1
      ? setItemsToRender(currentItems)
      : setItemsToRender(props.items.filter(e => e.isInActiveBoms))
  }, [props.items, searchPhrase, currentItems])

  useEffect(() => {
    props.getWarehouseStateWithBlankItems()
    props.getItemsConfigIfNeeded()
    setLocalStorage()
  }, [])

  useEffect(() => {
    allItemsIndex = new JsSearch.Search('id')
    allItemsIndex.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    allItemsIndex.addIndex('fullIndex')
    allItemsIndex.addIndex(['productionName'])
    allItemsIndex.addIndex(['sanitizedName'])
    allItemsIndex.addIndex(['defaultVendor', 'name'])
    allItemsIndex.addDocuments(props.items)
    setCurrentItems(allItemsIndex.search(searchPhrase))
  }, [props.items])

  useEffect(() => {
    searchPhrase.length >= 1 && setCurrentItems(allItemsIndex.search(searchPhrase))
  }, [searchPhrase])

  const handleSearch = (e) => setPhrase(e.target.value)
  const handleInputIconClick = () => searchPhrase && setPhrase('')

  const debouncedSearchPhrase = useDebounce(searchPhrase, 200)
  const [summedItems, keys] = useSumedItems(itemsToRender, [debouncedSearchPhrase, props.items])

  const [sortConfig, setSorting] = useState({ asc: false })

  const sortBy = (column, columnName) => async () => {
    sortConfig.asc
      ? setItemsToRender(await sort(itemsToRender).asc(columnName))
      : setItemsToRender(await sort(itemsToRender).desc(columnName))
    setSorting({ asc: !sortConfig.asc })
  }

  const isSortedBy = (column) => {
    return column === sortConfig.column
  }

  const headers = getHeaders({
    userRoles: props.userRoles,
    t,
    bomItems: getBomItems(props.items),
    historyPush: props.history.push,
    showDelivery: storage.isBlueSectionActive,
    showQa: storage.isOrangeSectionActive,
    showProduction: storage.isGraySectionActive
  })

  const sumCells = keys.map(key => {
    const correspondingColumnIndex = R.findIndex(
      R.propEq('key', key.split('.')[0] + 'Header'),
      headers
    )
    const shouldDisplayOnColumn = correspondingColumnIndex + 3
    return correspondingColumnIndex >= 0
      ? <div
        key={key + 'SUM'}
        css={{
          gridColumnStart: shouldDisplayOnColumn,
          gridRow: '3 / 4',
          padding: 5,
          zIndex: 4
        }}
      >
        {R.propOr(0, key, summedItems)}
      </div>
      : null
  })

  const handleChangeSectionVisibility = storageKey => async () => {
    await StorageService.set(LocalStorageKeys[storageKey], !storage[storageKey])
    setLocalStorage()
  }

  const renderItem = useCallback((index, key) => {
    const item = itemsToRender[index] || {}

    const indexCellCss = css`
      ${normalCellCss};
      position: sticky;
      background-color: white;
      z-index: 6;
      left: 0;
    `
    if (R.isEmpty(item) || R.isNil(item)) {
      return null
    }

    const isItemProduct = item.group === 'Produkt'

    const openColorModal = () => {
      setColorsModalId(item.id)
      setIsProduct(isItemProduct)
      setHasColorVariants(item.hasColorVersions)
    }

    const openAlternativesModal = () => setAlternativesModalId(item.id)
    const openFilesModal = () => setImagesModalContentId(item.id)

    const columns = getColumns({
      item,
      index,
      userRoles: props.userRoles,
      items: props.items,
      historyPush: props.history.push,
      openColorModal,
      openAlternativesModal,
      openFilesModal,
      showDelivery: storage.isBlueSectionActive,
      showQa: storage.isOrangeSectionActive,
      showProduction: storage.isGraySectionActive
    })

    return (
      <Fragment key={key}>
        <Fragment>
          {[
            <RenderCell key={'code' + index} item={item} css={indexCellCss}>{item.fullIndex}</RenderCell>,
            <RenderCell
              key={'name' + index}
              css={nameCss}
              editable={includesInArray([Roles.ADMIN], props.userRoles)}
              item={item}
              fieldName='name'
              maxWidth={300}
            >
              {item.name}
            </RenderCell>,
            <RenderCell
              key={'productionName' + index}
              css={nameCss}
              editable={includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER], props.userRoles)}
              item={item}
              fieldName='productionName'
              maxWidth={300}
            >
              {item.productionName}
            </RenderCell>,
            ...columns
          ]}
        </Fragment>
      </Fragment>
    )
  }, [itemsToRender, sortConfig, props.items, storage])

  const imagesModalContent = imagesModalContentId ? itemsToRender.find(item => item.id === imagesModalContentId) : null

  const byItemPropertyName = propertyName => sortBy((item) => item[propertyName].trim().toLowerCase(), propertyName)

  const setLocalStorage = () => {
    const isBlueSectionActive = StorageService.get(LocalStorageKeys.isBlueSectionActive, true)
    const isOrangeSectionActive = StorageService.get(LocalStorageKeys.isOrangeSectionActive, true)
    const isGraySectionActive = StorageService.get(LocalStorageKeys.isGraySectionActive, true)

    setStorage({ isBlueSectionActive, isOrangeSectionActive, isGraySectionActive })
  }

  return (
    <Fragment>
      <Flex width={1} css={styles.searchWrapperCss}>
        <Box width={3 / 8} py={10} px={8}>
          <input autoFocus value={searchPhrase} onChange={handleSearch} css={styles.searchBarCss} />
          <div css={styles.resetCss} onClick={handleInputIconClick}>
            {searchPhrase
              ? <CancelIcon {...styles.iconDimensions} />
              : <SearchIcon {...styles.iconDimensions} />
            }
          </div>
        </Box>
      </Flex>
      <div>
        <div
          css={css`
            ${gridCss(headers.length + 2)};
            z-index: 20;
            background-color: ${theme.colors.white};
            position: sticky;
            top: 70px;
          `}
        >
          {[
            ...renderMarkers(storage, props.userRoles, handleChangeSectionVisibility),
            <div
              key={'codeHeader'}
              css={css`
                ${sortableCss};
                position: sticky;
                left: 0;
                z-index: 4;
              `}
              onClick={sortBy((item) => {
                return item.fullIndex.replace(' ', '').toLowerCase()
              }, 'fullIndex')}
            >
              <div
                css={css`
                  ${isSortedBy('fullIndex') && (sortConfig.asc ? arrowUpCss : arrowDownCss)};
                  padding-right: 30px;
                `}>{t('catalogue.code')}
              </div>
            </div>,
            <div
              key={'nameHeader'}
              css={css`${sortableCss}`}
              onClick={byItemPropertyName('name')}
            >
              <div css={css`${isSortedBy('name') && (sortConfig.asc ? arrowUpCss : arrowDownCss)}`}>
                {t('catalogue.name')}
              </div>
            </div>,
            <div
              key={'productionNameHeader'}
              css={css`${sortableCss}`}
              onClick={byItemPropertyName('productionName')}
            >
              <div css={css`${isSortedBy('productionName') && (sortConfig.asc ? arrowUpCss : arrowDownCss)}`}>
                {t('catalogue.productionName')}
              </div>
            </div>,
            ...headers,
            <div
              key={'itemsCount'}
              css={{
                gridColumnStart: 1,
                gridRow: '3 / 4',
                padding: 5,
                zIndex: 5,
                position: 'sticky',
                left: 0,
                background: theme.colors.white
              }}
            >
              Łącznie: {itemsToRender.length}
            </div>,
            <div
              key={'whiteBackground'}
              css={{
                gridColumn: '1 / -1',
                gridRow: '3 / 4',
                backgroundColor: theme.colors.white,
                zIndex: 1
              }}
            />,
            ...sumCells
          ]}
        </div>
        <div css={catalogueWrapperCss(headers.length + 2)}>
          <ReactList
            itemRenderer={renderItem}
            length={itemsToRender.length}
            itemSizeGetter={() => 41}
            type='variable'
          />
        </div>
      </div>
      {alternativesModalId && (
        <AlternativesModal
          itemRevisionId={alternativesModalId}
          onClose={() => {
            setIsProduct(false)
            setColorsModalId(null)
            setAlternativesModalId(null)
            setImagesModalContentId(null)
          }}
        />
      )}
      {colorsModalId && (
        <ColorsModal
          itemRevisionId={colorsModalId}
          getWarehouseState={props.getWarehouseStateWithBlankItems}
          isProduct={isProduct}
          hasColorVariants={hasColorVariants}
          onClose={() => {
            setIsProduct(false)
            setColorsModalId(null)
            setAlternativesModalId(null)
            setImagesModalContentId(null)
          }}
        />
      )}
      {imagesModalContent && (
        <ImagesModal
          item={imagesModalContent}
          onClose={() => {
            setIsProduct(false)
            setColorsModalId(null)
            setAlternativesModalId(null)
            setImagesModalContentId(null)
          }}
          addImage={props.addImage}
          removeImage={props.removeImage}
        />
      )}
    </Fragment>
  )
}

export default MainCatalogueView
