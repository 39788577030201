/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react'
import { jsx } from '@emotion/core'
import useToggle from 'react-use/lib/useToggle'
import useInput from 'hooks/useInput'
import APIInterceptor from 'services/APIInterceptor'
import Button from 'components/Button'
import QrCode from 'qrcode.react'

const SteamJetGenerator = (props) => {
  const [lastSN, setLastSN] = useState('')
  const [hidden, toggle] = useToggle(true)
  const [value, change] = useInput('')
  const [isLoading, setIsLoading] = useState(false)
  const createRandomSteamjet = async () => {
    try {
      setIsLoading(true)
      const { data: { serialNumber } } = await APIInterceptor.post(`/assembly/fake_assembly`, { full_index: value })
      setIsLoading(false)
      setLastSN(serialNumber)
    } catch (e) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    change(props.code || '')
  }, [props.code])
  return (
    <div css={{
      position: 'fixed',
      bottom: 0,
      left: 0,
      zIndex: 150,
      display: 'flex',
      alignItems: 'center',
      padding: 10,
      '& > *': {
        marginLeft: '10px !important'
      }
    }}>
      {hidden
        ? (
          <span onClick={toggle}>
            O
          </span>
        )
        : (
          <Fragment>
            <input type='text' value={value} onChange={change} />
            <Button
              disabled={isLoading}
              onClick={createRandomSteamjet}
            >
              Random create {value}
            </Button>
            {lastSN && <QrCode
              size={100}
              value={lastSN}
            />}
            {lastSN && <Button disabled={isLoading} onClick={() => navigator?.clipboard?.writeText(lastSN)}>copy</Button>}
            <h2>{lastSN}</h2>
            <span onClick={toggle}>X</span>
          </Fragment>
        )
      }
    </div>
  )
}

export default SteamJetGenerator
