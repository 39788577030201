import APIInterceptor from './APIInterceptor'

const interceptor = APIInterceptor

const OrdersService = {
  getOrdersForItem: (itemCode) => {
    return interceptor.get(`/buy_orders/searchByItem?code=${itemCode}`)
  },

  confirmItemDeliveryForOrder: ({ orderId, itemId, quantity, discrepancyComment, reportDiscrepancy }) => {
    return interceptor.post(
      `/buy_orders/${orderId}/delivery/${itemId}`,
      { buyOrderItemId: itemId, quantity, discrepancyComment, reportDiscrepancy }
    )
  },

  confirmOutsideOrderDelivery: ({ itemCode, quantity }) => {
    return interceptor.post('/buy_orders/unordered_delivery', { code: itemCode, quantity })
  }
}

export default OrdersService
