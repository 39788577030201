/** @jsx jsx */
import { jsx } from '@emotion/core'
import EditAssemblyTime from 'components/EditAssemblyTime'
import EditRegex from 'components/EditRegex'
import EditVendor from 'components/EditVendor'
import InlineEdit from 'components/InlineEdit'
import * as PropTypes from 'prop-types'
import { cellCss } from 'views/Catalogue/styles'

const RenderCell = ({ children, className, editable, fieldName, onClick, id, ...rest }) => {
  switch (true) {
    case editable && fieldName === 'defaultVendorId':
      return <EditVendor className={className} {...rest}>{children}</EditVendor>
    case editable && fieldName === 'assemblyTime':
      return <EditAssemblyTime className={className} {...rest}>{children}</EditAssemblyTime>
    case editable && fieldName === 'serialNumberFormat':
      return <EditRegex className={className} {...rest}>{children}</EditRegex>
    case editable:
      return <InlineEdit css={cellCss} className={className} fieldName={fieldName} {...rest}>{children}</InlineEdit>
    default:
      return <div id={id} onClick={onClick} css={cellCss} className={className}>{children}</div>
  }
}

RenderCell.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  editable: PropTypes.bool,
  item: PropTypes.object
}

export default RenderCell
