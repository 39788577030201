/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Box } from '@rebass/emotion'
import LabelledDivider from 'components/LabelledDivider'
import { Field, Form } from 'redux-form'
import Input from 'components/Input'
import { inputCss } from 'forms/OnboardItemForm'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import Button from 'components/Button'
import theme from 'config/theme'

const RecalculateForm = (props) => {
  const { t } = useTranslation()
  const emptyQuantity = () => props.change('quantity', '')
  return (
    <Form onSubmit={props.handleSubmit(props.onSubmit)}>
      <Box m={5}>
        <LabelledDivider text={t('warehouse.itemCount')} />
        <Field
          component={Input}
          css={inputCss}
          name='quantity'
          type='number'
          numericKeyboard
          onFocus={emptyQuantity}
        />
      </Box>
      <Box m={5} mt={9}>
        <Button type='submit' withIcon icon={Checkmark} iconProps={{ style: { fill: theme.colors.white } }}>
          {t('warehouse.confirmRecalculate')}
        </Button>
      </Box>
    </Form>
  )
}

export default RecalculateForm
