import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { login } from 'ducks/user'
import LoginForm from 'forms/LoginForm'

export const formName = 'LoginForm'

const mapDispatchToProps = dispatch => ({
  login: values => dispatch(login(values))
})

export default withRouter(connect(null, mapDispatchToProps)(LoginForm))
