/** @jsx jsx */
import { jsx } from '@emotion/core'
import theme from 'config/theme'

const Option = props => {
  return (
    <div
      {...props.innerProps}
      css={{
        backgroundColor: theme.colors.darkGrey,
        color: theme.colors.white,
        marginBottom: 10,
        padding: 10,
        fontWeight: 'bold'
      }}>
      <div>#{props.value.fullIndex}</div>
      <div>{props.value.name}</div>
    </div>
  )
}

export default Option
