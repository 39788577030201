/** @jsx jsx */
// eslint-disable-next-line
import React, { useState } from 'react'
import { jsx } from '@emotion/core'
import theme from 'config/theme'
import Carousel, { Modal, ModalGateway } from 'react-images'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'
import { useTranslation } from 'react-i18next'

const styles = {
  height: 200,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'center',
  marginTop: 20,
  color: 'white',
  background: 'white',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  borderRadius: 10,
  backgroundPosition: 'center',
  cursor: 'pointer'
}

const MorePhotosBox = () => {
  const { t } = useTranslation()
  return (
    <div css={{ textAlign: 'center', fontSize: 20, background: 'black', padding: '10px 20px' }}>
      {t('productionStation.morePhotos')}
    </div>
  )
}

const ImageGallery = ({ images, whiteBackground }) => {
  const [modal, setModal] = useState(false)
  const toggleModal = (e) => { e.stopPropagation(); setModal(!modal) }
  const isEmpty = isNil(images) || images.length === 0
  const ImagesAsObjects = images?.map(img => ({ src: img }))
  const numberOfImages = !isEmpty && images.length

  return (<>
    {!isEmpty ? (
      <div
        onClick={toggleModal}
        css={{
          ...styles,
          background: `url('${(images && images[0])}')`
        }}
      >
        {numberOfImages > 1 && <MorePhotosBox />}
      </div>
    ) : (
      <div
        css={{
          ...styles,
          width: 200,
          background: theme.colors.lightGrey,
          backgroundColor: whiteBackground ? theme.colors.white : theme.colors.concreteGray,
          cursor: 'default'
        }}
      />
    )}
    <ModalGateway closeOnBackdropClick>
      {modal && (
        <Modal
          allowFullscreen={false}
          closeOnBackdropClick
          closeOnEsc
          onClose={toggleModal}
          styles={{
            blanket: (base) => ({ ...base, zIndex: 1100 }),
            positioner: (base) => ({ ...base, zIndex: 1110 }),
            dialog: (base) => ({ ...base, zIndex: 1120 })
          }}
        >
          <Carousel
            views={ImagesAsObjects}
            styles={{
              view: (base) => ({
                ...base,
                height: '100vh',
                '& img': {
                  height: '100vh'
                }
              })
            }}
          />
        </Modal>
      )}
    </ModalGateway>
  </>)
}

ImageGallery.defaultProps = {
  images: [],
  whiteBackground: false
}

ImageGallery.propTypes = {
  images: PropTypes.array,
  whiteBackground: PropTypes.bool
}

export default ImageGallery
