import { transformToArray } from 'helpers/redux'
import { createSelector } from 'reselect'

const getSerialNumberFormats = state => state.serialNumberFormats.entries

export const getSerialNumberFormatsAsArray = createSelector(
  getSerialNumberFormats,
  transformToArray
)

export const getSerialNumberFormatsAsOptionsArray = createSelector(
  getSerialNumberFormats,
  formats => transformToArray(formats).map(format => ({
    value: format.id,
    label: format.name + ' (' + format.format + ')'
  }))
)
