import { css } from '@emotion/core'
import theme from 'config/theme'

export const centerStyles = css`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
`
export const flexStartStyles = css`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
`
export const getFlexStyles = (withBottomBorder = false) => css`
  width: 1000px;
  height: 80px;
  justify-content: center;
  align-items: center;
  border-bottom: ${withBottomBorder ? `1px solid ${theme.colors.lightGrey}` : 'none'};
  &:nth-of-type(even){
    background-color: ${theme.colors.concreteGray};
  }
`
export const containerStyles = css`
  justify-content: center;
  align-items: center;
  height: 100%;
`
export const wrapperStyles = css`
  flex-direction: column;
  border: 1px solid ${theme.colors.lightGrey};
  border-radius: 8px;
`
export const headerStyles = css`
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  & > div {
    font-weight: bold;
    font-size: 32px;
  }
`
export const modalContentStyles = css`
  width: 100%;
  height: 100%;
  min-height: inherit;
`
export const spanStyles = css`
  word-break: break-all;
  align-self: center;
`
