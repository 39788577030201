import * as R from 'ramda'
import { GENERATE_BOM_FROM_IMPORT_SUCCESS } from 'ducks/bomsImports'
import ProductionStationsService from 'services/ProductionStationsService'
import { transformById, transformToArray } from 'helpers/redux'
import { noop } from 'helpers/productionStation'
import { receiveEvents } from './events'

const GET_PRODUCTION_STATIONS_REQUEST = Symbol('GET_PRODUCTION_STATIONS_REQUEST')
const GET_PRODUCTION_STATIONS_SUCCESS = Symbol('GET_PRODUCTION_STATIONS_SUCCESS')
const GET_PRODUCTION_STATIONS_FAILED = Symbol('GET_PRODUCTION_STATIONS_FAILED')

const getProductionStationsRequest = () => ({ type: GET_PRODUCTION_STATIONS_REQUEST })
const getProductionStationsSuccess = (data) => ({ type: GET_PRODUCTION_STATIONS_SUCCESS, data })
const getProductionStationsFailed = () => ({ type: GET_PRODUCTION_STATIONS_FAILED })

export const getProductionStations = (query) => {
  return async dispatch => {
    dispatch(getProductionStationsRequest())
    try {
      const { data } = await ProductionStationsService.getProductionStations(query)
      dispatch(getProductionStationsSuccess(data))
    } catch (err) {
      dispatch(getProductionStationsFailed(err))
    }
  }
}

const GET_DAILY_PLANS_REQUEST = Symbol('GET_DAILY_PLANS_REQUEST')
const GET_DAILY_PLANS_SUCCESS = Symbol('GET_DAILY_PLANS_SUCCESS')
const GET_DAILY_PLANS_FAILED = Symbol('GET_DAILY_PLANS_FAILED')

const getDailyPlansRequest = () => ({ type: GET_DAILY_PLANS_REQUEST })
const getDailyPlansSuccess = data => ({ type: GET_DAILY_PLANS_SUCCESS, data })
const getDailyPlansFailed = () => ({ type: GET_DAILY_PLANS_FAILED })

export const getDailyPlans = date => {
  return async dispatch => {
    dispatch(getDailyPlansRequest())
    try {
      const { data } = await ProductionStationsService.getDailyPlans(date)
      dispatch(getDailyPlansSuccess(data))
    } catch (err) {
      dispatch(getDailyPlansFailed(err))
    }
  }
}

const LOGIN_TO_PRODUCTION_STATION = Symbol('LOGIN_TO_PRODUCTION_STATION')
const LOGIN_TO_PRODUCTION_STATION_SUCCESS = Symbol('LOGIN_TO_PRODUCTION_STATION_SUCCESS')
const LOGIN_TO_PRODUCTION_STATION_FAILED = Symbol('LOGIN_TO_PRODUCTION_STATION_FAILED')

const requestLoginToProductionStation = () => ({ type: LOGIN_TO_PRODUCTION_STATION })
const loginToProductionStationSuccess = (data) => ({ type: LOGIN_TO_PRODUCTION_STATION_SUCCESS, data })
const loginToProductionStationFailed = () => ({ type: LOGIN_TO_PRODUCTION_STATION_FAILED })

export const loginToProductionStation = (stationId) => {
  return async dispatch => {
    dispatch(requestLoginToProductionStation())
    try {
      const { data } = await ProductionStationsService.loginToProductionStation(stationId)
      return dispatch(loginToProductionStationSuccess(data))
    } catch (e) {
      return dispatch(loginToProductionStationFailed(e))
    }
  }
}

const GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_REQUEST = Symbol('GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_REQUEST')
const GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_SUCCESS = Symbol('GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_SUCCESS')
const GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_FAILED = Symbol('GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_FAILED')

const getWeeklyAndDailyPlansForWeekRequest = () => ({ type: GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_REQUEST })
const getWeeklyAndDailyPlansForWeekSuccess = (data, week) => ({ type: GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_SUCCESS, data, week })
const getWeeklyAndDailyPlansForWeekFailed = () => ({ type: GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_FAILED })

export const getWeeklyAndDailyPlansForWeek = (year, week) => {
  return async dispatch => {
    dispatch(getWeeklyAndDailyPlansForWeekRequest())
    try {
      const { data } = await ProductionStationsService.getWeeklyAndDailyPlansForWeek(year, week)
      dispatch(getWeeklyAndDailyPlansForWeekSuccess(data, week))
      return Promise.resolve(data)
    } catch (err) {
      dispatch(getWeeklyAndDailyPlansForWeekFailed(err))
      return Promise.reject(err)
    }
  }
}

const STORE_DAILY_PLANS_FOR_WEEK_REQUEST = Symbol('STORE_DAILY_PLANS_FOR_WEEK_REQUEST')
const STORE_DAILY_PLANS_FOR_WEEK_SUCCESS = Symbol('STORE_DAILY_PLANS_FOR_WEEK_SUCCESS')
const STORE_DAILY_PLANS_FOR_WEEK_FAILED = Symbol('STORE_DAILY_PLANS_FOR_WEEK_FAILED')

const storeDailyPlansForWeekRequest = () => ({ type: STORE_DAILY_PLANS_FOR_WEEK_REQUEST })
const storeDailyPlansForWeekSuccess = (data) => ({ type: STORE_DAILY_PLANS_FOR_WEEK_SUCCESS, data })
const storeDailyPlansForWeekFailed = () => ({ type: STORE_DAILY_PLANS_FOR_WEEK_FAILED })

export const storeDailyPlansForWeek = (details, year, week) => {
  return async dispatch => {
    dispatch(storeDailyPlansForWeekRequest())
    try {
      const { data } = await ProductionStationsService.storeDailyPlansForWeek(details, year, week)
      dispatch(storeDailyPlansForWeekSuccess(data))
      return Promise.resolve(data)
    } catch (err) {
      dispatch(storeDailyPlansForWeekFailed(err))
      return Promise.reject(err)
    }
  }
}

const GET_CURRENT_STATION_PLANS = Symbol('GET_CURRENT_STATION_PLANS')
const GET_CURRENT_STATION_PLANS_SUCCESS = Symbol('GET_CURRENT_STATION_PLANS_SUCCESS')
const GET_CURRENT_STATION_PLANS_FAILED = Symbol('GET_CURRENT_STATION_PLANS_FAILED')

const getCurrentStationPlansRequest = () => ({ type: GET_CURRENT_STATION_PLANS })
const getCurrentStationPlansSuccess = (data) => ({ type: GET_CURRENT_STATION_PLANS_SUCCESS, data })
const getCurrentStationPlansFailed = () => ({ type: GET_CURRENT_STATION_PLANS_FAILED })

export const getCurrentStationPlans = (stationId) => {
  return async (dispatch, getState) => {
    dispatch(getCurrentStationPlansRequest())
    try {
      const { data } = await ProductionStationsService.getCurrentStationPlans(stationId)
      if (!R.equals(data, transformToArray(getState().productionStations.currentStationPlans))) {
        dispatch(getCurrentStationPlansSuccess(data))
      }
      return Promise.resolve(data)
    } catch (e) {
      dispatch(getCurrentStationPlansFailed(e))
      return Promise.reject(e)
    }
  }
}

const MARK_ITEM_AS_ASSEMBLED = Symbol('MARK_ITEM_AS_ASSEMBLED')
const MARK_ITEM_AS_ASSEMBLED_SUCCESS = Symbol('MARK_ITEM_AS_ASSEMBLED_SUCCESS')
const MARK_ITEM_AS_ASSEMBLED_FAILED = Symbol('MARK_ITEM_AS_ASSEMBLED_FAILED')

const markItemAsAssembledRequest = () => ({ type: MARK_ITEM_AS_ASSEMBLED })
const markItemAsAssembledSuccess = (data) => ({ type: MARK_ITEM_AS_ASSEMBLED_SUCCESS, data })
const markItemAsAssembledFailed = () => ({ type: MARK_ITEM_AS_ASSEMBLED_FAILED })

export const markItemAsAssembled = (assebledItemData, partsCallback = noop) => {
  return async dispatch => {
    dispatch(markItemAsAssembledRequest())
    try {
      const response = await ProductionStationsService.markItemAsAssembled(assebledItemData)
      const { data, meta } = response
      // solution below needs to be hacked a bit as parts delivery status comes in quite specific way ( axios wont return it just like that )
      // eslint-disable-next-line camelcase
      const { parts_received_for_next_assembly } = JSON.parse(response?.request?.response)
      partsCallback(parts_received_for_next_assembly)
      // end of hacky solution
      dispatch(receiveEvents(meta?.eventIds))
      dispatch(markItemAsAssembledSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(markItemAsAssembledFailed(e))
      return Promise.reject(e)
    }
  }
}

const SET_SERVICE_RIGHT_ON_STATION_REQUEST = Symbol('SET_SERVICE_RIGHT_ON_STATION_REQUEST')
const SET_SERVICE_RIGHT_ON_STATION_SUCCESS = Symbol('SET_SERVICE_RIGHT_ON_STATION_SUCCESS')
const SET_SERVICE_RIGHT_ON_STATION_FAILED = Symbol('SET_SERVICE_RIGHT_ON_STATION_FAILED')

const setServiceRightOnStationRequest = (payload) => ({ type: SET_SERVICE_RIGHT_ON_STATION_REQUEST, data: { id: payload.id, value: payload.value } })
const setServiceRightOnStationSuccess = (data) => ({ type: SET_SERVICE_RIGHT_ON_STATION_SUCCESS, data })
const setServiceRightOnStationFailed = () => ({ type: SET_SERVICE_RIGHT_ON_STATION_FAILED })

export const setServiceRightOnStation = (payload) => {
  return async dispatch => {
    dispatch(setServiceRightOnStationRequest(payload))
    try {
      const { data } = await ProductionStationsService.setServiceRightOnStation(payload)
      dispatch(setServiceRightOnStationSuccess(data))
    } catch (err) {
      dispatch(setServiceRightOnStationFailed(err))
    }
  }
}

const initState = {
  entries: {},
  dailyPlans: {},
  plans: {},
  currentStationPlans: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_PRODUCTION_STATIONS_SUCCESS:
      return {
        ...state,
        entries: transformById(action.data)
      }
    case GENERATE_BOM_FROM_IMPORT_SUCCESS:
      return {
        ...state,
        entries: transformById(action.assemblyPosts)
      }
    case GET_DAILY_PLANS_SUCCESS:
      return {
        ...state,
        dailyPlans: transformById(action.data)
      }
    case GET_WEEKLY_AND_DAILY_PLANS_FOR_WEEK_SUCCESS:
      return {
        ...state,
        plans: {
          ...state.plans,
          [action.week]: transformById(action.data)
        }
      }
    case GET_CURRENT_STATION_PLANS_SUCCESS:
      return {
        ...state,
        currentStationPlans: transformById(action.data)
      }
    case MARK_ITEM_AS_ASSEMBLED_SUCCESS:
      return R.evolve({
        currentStationPlans: {
          [action.data.dailyPlan.id]: {
            manufactured: R.always(action.data.dailyPlan.manufactured)
          }
        }
      }, state)
    case SET_SERVICE_RIGHT_ON_STATION_REQUEST:
      return R.evolve({
        entries: {
          [action.data.id]: R.pipe(
            R.assoc('isLoading', true),
            R.assoc('isServiceAllowed', action.data.value)
          )
        }
      }, state)
    case SET_SERVICE_RIGHT_ON_STATION_SUCCESS:
      return R.evolve({
        entries: {
          [action.data.id]: R.pipe(
            R.mergeLeft(action.data),
            R.assoc('isLoading', false)
          )
        }
      }, state)
    case SET_SERVICE_RIGHT_ON_STATION_FAILED:
      return R.evolve({
        entries: {
          [action.data.id]: {
            isLoading: R.always(false)
          }
        }
      }, state)
    default:
      return state
  }
}
