/** @jsx jsx */
// eslint-disable-next-line
import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { jsx, css } from '@emotion/core'
import { Box, Flex } from '@rebass/emotion'
import Button from 'components/Button'
import LoadingOverlay from 'components/LoadingOverlay'
import useInput from 'hooks/useInput'
import useKeyHandler from 'hooks/useKeyHandler'
import getSerialNumberFromUrl from 'helpers/getSerialNumberFromUrl'
import Routes from 'constants/Routes'
import theme from 'config/theme'
import SteamJetGenerator from 'components/SteamJetGenerator'
import QualityControlService from 'services/QualityControlService'
import { errorHandler } from 'helpers/errorHandler'

const ServiceButton = props => {
  return (
    <Button
      css={{
        margin: 'unset',
        width: 'auto',
        textTransform: 'uppercase',
        marginBottom: 20
      }}
      omitTextTransform
      {...props}
    />
  )
}

const QualityControlDetails = ({ match, device, history }) => {
  const [scanValue, scanChange] = useInput('')
  const [isPartCorrect, setIsPartCorrect] = useState(true)
  const [comment, changeComment] = useInput('')
  const [assemblyData, setAssemblyData] = useState({})
  const [loading, setLoading] = useState(false)
  const [time, setTime] = useState(5)
  const inputRef = useRef(null)
  const { t } = useTranslation()

  const fetchData = async serialNumber => {
    try {
      setLoading(true)
      const { data } = await QualityControlService.getItemBySerialNumber(serialNumber)
      setLoading(false)
      if (data) {
        setAssemblyData(data)
      }
    } catch (e) {
      setLoading(false)
      console.error(e)
    }
  }

  useEffect(() => {
    match.params.serialNumber && fetchData(match.params.serialNumber)
  }, [match.params.serialNumber])

  useEffect(() => {
    const timeout = time === 0
    const timer = setTimeout(() => {
      if (!timeout && isPartCorrect && !assemblyDataIsEmpty) {
        setTime(R.subtract(time * 10, 1) / 10)
      }
      if (timeout) {
        handleSubmitGood()
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [time, isPartCorrect, assemblyData])

  const assemblyDataIsEmpty = R.isNil(assemblyData.assembledElements)

  const handleSubmitGood = async () => {
    if (!assemblyDataIsEmpty) {
      try {
        const serialNumber = R.path(['assembledElements', 0, 'serialNumber'], assemblyData)
        const { data } = await QualityControlService.registerPositiveQualityCheckResults({ serialNumber })
        toast.success(t('qualityControl.markedAsGood'), { containerId: 'statuses', autoClose: 3e3 })
        setTime(5)
        setAssemblyData({})
        Promise.resolve(data)
      } catch (e) {
        errorHandler(e, 5e3)
        toast.error(t('common.somethingWentWrong'), { containerId: 'statuses' })
        Promise.reject(e)
      }
    }
  }

  const handleSubmitBroken = async () => {
    if (!assemblyDataIsEmpty) {
      try {
        const serialNumber = R.path(['assembledElements', 0, 'serialNumber'], assemblyData)
        const { data } = await QualityControlService.registerNegativeQualityCheckResults({ serialNumber, comment })
        toast.success(t('qualityControl.markedAsBroken'), { containerId: 'statuses', autoClose: 3e3 })
        setTime(5)
        setAssemblyData({})
        setIsPartCorrect(true)
        Promise.resolve(data)
      } catch (e) {
        errorHandler(e, 5e3)
        toast.error(t('common.somethingWentWrong'), { containerId: 'statuses' })
        Promise.reject(e)
      }
    }
  }

  const scanHandler = async (scannedValue) => {
    // scans the next item, so I mark the current item as good
    if (time > 0 && !assemblyDataIsEmpty && isPartCorrect) {
      await handleSubmitGood()
    }
    setIsPartCorrect(true)
    setTime(5)
    changeComment('')
    const serialNumberForSteamJet = getSerialNumberFromUrl(scannedValue)
    const scannedSameNumberAsBefore = scannedValue === match.params.serialNumber
    history.push(Routes.qualityControlDetails.replace(':serialNumber', serialNumberForSteamJet || scannedValue))
    if (scannedSameNumberAsBefore) {
      fetchData(match.params.serialNumber)
    }
    scanChange('')
  }

  useKeyHandler(scanHandler, inputRef)

  return (
    <Flex flexDirection={'column'}>
      {loading && <LoadingOverlay />}
      {process.env.NODE_ENV === 'development' && <SteamJetGenerator code={'1-8-14309D5'} />}
      {!loading && (
        <Box flex={1}>
          <div css={{ width: 0, overflow: 'hidden' }}>
            <input
              type='text'
              onChange={scanChange}
              value={scanValue}
              ref={inputRef}
              css={{ opacity: 1, filter: 'alpha(opacity:1)' }}
            />
          </div>
          <Box
            px={9}
            py={6}
            css={{ position: 'sticky', top: 70, backgroundColor: 'white', zIndex: 1 }}
          >
            <Flex
              mb={8}
              css={deviceDescriptionCss}
              alignItems={'center'}
            >
              <div>{t('qualityControl.serialNumber')}</div>
              <div><b>{R.pathOr('N/A', ['assembledElements', 0, 'serialNumber'], assemblyData) || t('qualityControl.scanNextItem')}</b></div>
              {assemblyDataIsEmpty && <Link to={Routes.qualityControl}><Button>{t('qualityControl.orClickHereToType')}</Button></Link>}
            </Flex>
          </Box>
        </Box>
      )}
      {!assemblyDataIsEmpty && (
        <Flex
          p={9}
          flexDirection='row'
          alignItems='center'
          justifyContent={'space-around'}
        >
          <ServiceButton
            disabled={assemblyDataIsEmpty}
            onClick={() => {
              setIsPartCorrect(true)
              handleSubmitGood()
            }}
            color={theme.colors.successGreen}
            css={{
              opacity: !isPartCorrect ? 0.2 : 1,
              boxShadow: isPartCorrect ? `0 0 1.25em rgba(0,0,0,0.5)` : 'none'
            }}
          >
            {t('qualityControl.good')}
          </ServiceButton>
          <h2>{Math.floor(time)}</h2>
          <ServiceButton
            disabled={assemblyDataIsEmpty}
            onClick={() => setIsPartCorrect(false)}
            color={theme.colors.error}
            css={{
              opacity: isPartCorrect ? 0.2 : 1,
              boxShadow: !isPartCorrect ? `0 0 1.25em rgba(0,0,0,0.5)` : 'none'
            }}
          >
            {t('qualityControl.broken')}

          </ServiceButton>
        </Flex>
      )}
      {(!isPartCorrect) &&
      <Flex
        justifyContent={'center'}
        flexDirection={'column'}
        alignItems={'center'}
      >
        <textarea
          placeholder={t('qualityControl.writeComment')}
          css={{ minWidth: 400, minHeight: 200, height: 'auto', border: '2px solid black', padding: 15 }}
          value={comment}
          onChange={changeComment}
        />
        <Button
          onClick={handleSubmitBroken}
          css={{ margin: 0, marginTop: 20, width: 'auto' }}
        >
          {t('qualityControl.send')}
        </Button>
      </Flex>}
    </Flex>
  )
}

const deviceDescriptionCss = css`
  & > div:not(:first-child) {
    margin-left: 20px;
  }
`
export default QualityControlDetails
