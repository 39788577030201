/* eslint-disable */
/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import { isNotNil } from 'helpers/ramda'
import { isEmpty, pathOr } from 'ramda'
import Container from 'components/Container'
import Button from 'components/Button'
import Modal from 'components/Modal'
import AddLocationFormConnector from 'connectors/forms/AddLocationFormConnector'
import { AddButton, DeleteModalContent, LocationsWrapper } from './components/styled'
import LocationCodeItem from './components/LocationCodeItem'
import { LOCATION_TYPE } from 'constants/locations'
import { LocationTypeHeader } from 'views/ManageLocations/components/styled'

const ManageLocationsView = props => {
  const [section, setSection] = useState(null)
  const [column, setColumn] = useState(null)
  const [columns, setColumns] = useState(null)
  const [shelfs, setShelfs] = useState(null)
  const [locationType, setLocationType] = useState(null)
  const [itemToDeleteId, setItemToDeleteId] = useState(null)
  const [isModalVisible, setModalVisibility] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisibility] = useState(false)

  useEffect(() => {
    props.getLocationsList()
  }, [])

  const handleSectionClick = section => () => {
    setShelfs(null)
    setColumn(null)
    setSection(section)
    setColumns(section.children)
  }
  const handleColumnClick = column => () => {
    setColumn(column)
    setShelfs(column.children)
  }

  const openModal = type => () => {
    setLocationType(type)
    setModalVisibility(true)
  }

  const closeModal = () => {
    setLocationType(null)
    setModalVisibility(false)
  }

  const openDeleteModal = (id, type) => () => {
    setItemToDeleteId(id)
    setLocationType(type)
    setDeleteModalVisibility(true)
  }

  const closeDeleteModal = () => {
    setItemToDeleteId(null)
    setLocationType(null)
    setDeleteModalVisibility(false)
  }

  const getParentDetails = type => {
    switch (type) {
      case 'column':
        return ({
          id: section.id.toString(),
          code: section.code.toString()
        })
      case 'shelf':
        return ({
          id: column.id.toString(),
          code: column.code.toString()
        })
      default:
        return null
    }
  }

  const deleteLocation = locationType => async () => {
    await props.deleteLocation({ id: itemToDeleteId }, closeDeleteModal)

    let findIndex = -1
    let array = []

    switch (locationType) {
      case LOCATION_TYPE.COLUMN:
        findIndex = columns.findIndex(e => e.id === itemToDeleteId)
        array = columns
        array.splice(findIndex, 1)
        setColumns(array)
        if (isNotNil(shelfs) && (shelfs.find(shelf => shelf.parentId === itemToDeleteId)) || isEmpty(shelfs)) {
          setShelfs(null)
        }
        break
      case LOCATION_TYPE.SHELF:
        findIndex = shelfs.findIndex(e => e.id === itemToDeleteId)
        array = shelfs
        array.splice(findIndex, 1)
        setShelfs(array)
        break
      case LOCATION_TYPE.SECTION:
        if (isNotNil(columns) && (columns.find(column => column.parentId === itemToDeleteId) || isEmpty(columns))) {
          setColumns(null)
        }
        setShelfs(null)
        break
      default: return null
    }
  }

  const addLocationToState = async (type, location) => {
    switch (type) {
      case LOCATION_TYPE.COLUMN:
        const newLocation = { ...location, children: [] }
        setColumns([ ...columns, newLocation ])
        break
      case LOCATION_TYPE.SHELF:
        const newColumns = columns.map(column => {
          if (column.id === location.parentId) {
            column.children = [...column.children, location]
          }
          return column
        })

        setColumns(newColumns)
        setShelfs([ ...shelfs, location ])
        break
      default:
        return null
    }
  }

  return (
    <Container>
      <LocationsWrapper>
        <div>
          <LocationTypeHeader>Sekcja</LocationTypeHeader>
          <AddButton onClick={openModal('section')}>dodaj</AddButton>
          {props.locations.map((sectionItem, index) => (
            <LocationCodeItem
              isActive={isNotNil(section) && sectionItem.id === section.id}
              onDelete={openDeleteModal(sectionItem.id, 'section')}
              onClick={handleSectionClick(sectionItem)}
              key={`location__section-${index}`}
            >
              {sectionItem.code}
            </LocationCodeItem>
          ))}
        </div>
        <div>

          {isNotNil(columns) && <>
            <LocationTypeHeader>Kolumna</LocationTypeHeader>
            <AddButton onClick={openModal('column')}>dodaj</AddButton>
            {columns.map((columnItem, index) => (
              <LocationCodeItem
                isActive={isNotNil(column) && columnItem.id === column.id}
                onDelete={openDeleteModal(columnItem.id, 'column')}
                onClick={handleColumnClick(columnItem)}
                key={`location__column-${index}`}
              >
                {columnItem.code}
              </LocationCodeItem>
            ))}
          </>}
        </div>
        <div>
          {isNotNil(shelfs) && <>
            <LocationTypeHeader>Półka</LocationTypeHeader>
            <AddButton onClick={openModal('shelf')}>dodaj</AddButton>
            {shelfs.map((shelf, index) => (
              <LocationCodeItem
                onDelete={openDeleteModal(shelf.id, 'shelf')}
                key={`location__shelf-${index}`}
              >
                {shelf.code}
              </LocationCodeItem>
            ))}
          </>
          }
        </div>
      </LocationsWrapper>
      {isModalVisible && (
        <Modal
          headerTitle='Dodaj lokalizację'
          onClose={closeModal}
        >
          <div style={{ marginTop: 30 }}>
            <AddLocationFormConnector
              locationType={locationType}
              parentId={pathOr('', ['id'])(getParentDetails(locationType))}
              locationPath={pathOr('', ['code'])(getParentDetails(locationType))}
              closeModal={closeModal}
              addLocationToState={addLocationToState}
            />
          </div>
        </Modal>
      )}
      {isDeleteModalVisible && (
        <Modal
          headerTitle='Usuń lokalizację'
          onClose={closeDeleteModal}
        >
          <DeleteModalContent>
            <p>Czy na pewno chcesz usunąć wskazaną lokalizację?"</p>
            <div className='modal-buttons'>
              <Button onClick={closeDeleteModal}>Anuluj</Button>
              <Button onClick={deleteLocation(locationType)}>Potwierdź</Button>
            </div>
          </DeleteModalContent>
        </Modal>
      )}
    </Container>
  )
}

export default ManageLocationsView
