import * as R from 'ramda'
import { createSelector } from 'reselect'
import { transformToArray } from 'helpers/redux'

const getServiceDevices = state => state.productionService.entries
const getService = state => state.productionService.currentService

export const getDeviceForServiceBySerialNumber = createSelector(
  getServiceDevices,
  (state, serialNumber) => serialNumber,
  (devices, serialNumber) => {
    const device = R.propOr({}, serialNumber, devices)
    return R.evolve({ services: transformToArray }, device)
  }
)

export const getCurrentService = createSelector(
  getService,
  R.identity
)
