/** @jsx jsx */
import { jsx } from '@emotion/core'
import Container from 'components/Container'
import Divider from 'components/Divider'
import MenuLink from 'components/MenuLink'
import Clock from 'components/Clock'
import enbioLogo from 'assets/enbio-logo.png'
import { Fragment, useEffect } from 'react'
import { Box, Flex } from '@rebass/emotion'
import IconBox, { iconCss } from 'components/IconBox'
import { ReactComponent as LogoutIcon } from 'assets/logout-icon.svg'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { includesInArray } from 'helpers/array'
import { Roles } from 'constants/Roles'
import isZebraBrowser from 'helpers/isZebra'
import Routes from 'constants/Routes'
import { useTranslation } from 'react-i18next'
import DeviceStatus from 'components/DeviceStatus'
import StorageService, { LocalStorageKeys } from 'services/StorageService'

const mainMenuFooterCss = {
  position: isZebraBrowser() ? 'static' : 'fixed',
  bottom: 0,
  left: 0,
  height: 80
}

const testLocations = ['enbio-client.test.desmart.live', 'localhost', 'localhost:3000']

const MainMenu = ({ resetSearchInput, roles: userRoles, history, userRole }) => {
  const { t } = useTranslation()
  useEffect(() => {
    resetSearchInput()
    const stationId = StorageService.get(LocalStorageKeys.stationId)
    const isProductionWorker = includesInArray(userRoles, [Roles.PRODUCTION_WORKER])
    if ((!stationId || includesInArray(testLocations, [window.location.hostname, window.location.host])) && isProductionWorker) {
      history.push(Routes.productionStationChooseStation)
    } else if (stationId && isProductionWorker) {
      history.push(Routes.productionStation.base + stationId)
    }
  }, [])

  const selectMainView = () => {
    switch (userRole) {
      case Roles.FOREMAN:
        history.push(Routes.productionStations)
        break
      case Roles.PRODUCTION_MANAGER:
        history.push(Routes.weeklyPlans)
        break
      case Roles.ADMIN:
        history.push(Routes.catalogue)
        break
      case Roles.QUALITY_ASSURANCE:
        history.push(Routes.qualityControl)
        break
      case Roles.TECHNOLOGIST:
        history.push(Routes.boms)
        break
      default:
        return <Fragment>
          {includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN], userRoles) &&
          <MenuLink to={Routes.warehouse} text={t('warehouse.warehouse')} />
          }
          {includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.QUALITY_ASSURANCE, Roles.SUPPLY], userRoles) &&
          <MenuLink to={Routes.catalogue} text={t('catalogue.catalogue')} />
          }
          {includesInArray([Roles.ADMIN, Roles.FOREMAN], userRoles) &&
          <MenuLink to={Routes.warehouseOrdersList} text={t('warehouse.orders.internalOrdersList')} />
          }
          {includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER], userRoles) &&
          <MenuLink to={Routes.warehouseStocktaking} text={t('warehouse.stocktaking.stocktakingList')} />
          }
          {includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER], userRoles) &&
          <MenuLink to={Routes.weeklyPlans} text={t('warehouse.weeklyPlans')} />
          }
          {includesInArray([Roles.ADMIN, Roles.FOREMAN], userRoles) &&
          <MenuLink to={Routes.weeklyPlansForeman} text={t('warehouse.weeklyPlansForeman')} />
          }
          {includesInArray([Roles.ADMIN, Roles.FOREMAN], userRoles) &&
          <MenuLink to={Routes.productionStations} text={t('productionStations.productionStations')} />
          }
          <Divider />
        </Fragment>
    }
  }

  return (
    <div css={{ paddingBottom: isZebraBrowser() ? 0 : 80, position: 'relative' }}>
      <Container flex centerItems css={{ margin: '25px 12px' }}>
        <img src={enbioLogo} alt='logo' css={{ maxHeight: '12px' }} />
        <Clock />
      </Container>
      <Container>
        {isZebraBrowser() &&
          <Fragment>
            {includesInArray([Roles.WAREHOUSE, Roles.ADMIN, Roles.FOREMAN], userRoles) &&
              <MenuLink to={Routes.supplySearch} text={t('supply.pick')} />
            }
            {includesInArray([Roles.WAREHOUSE, Roles.ADMIN, Roles.FOREMAN], userRoles) &&
              <MenuLink to={Routes.onBoardingSearch} text={t('warehouse.onboard')} />
            }
            {includesInArray([Roles.WAREHOUSE, Roles.ADMIN, Roles.FOREMAN], userRoles) &&
              <MenuLink to={Routes.moveItemsSearch} text={t('warehouse.moveItems')} />
            }
            {includesInArray([Roles.WAREHOUSE, Roles.ADMIN, Roles.FOREMAN], userRoles) &&
              <MenuLink to={Routes.handOverSearch} text={t('warehouse.handOver')} />
            }
            {includesInArray([Roles.WAREHOUSE, Roles.ADMIN, Roles.FOREMAN], userRoles) &&
              <MenuLink to={Routes.recalculateSearch} text={t('warehouse.recalculate')} />
            }
            {includesInArray([Roles.WAREHOUSE, Roles.ADMIN, Roles.FOREMAN], userRoles) &&
              <MenuLink to={Routes.ordersList} text={t('warehouse.orders.internalOrders')} />
            }
            {includesInArray([Roles.WAREHOUSE, Roles.ADMIN, Roles.FOREMAN], userRoles) &&
              <MenuLink to={Routes.stocktakingList} text={t('warehouse.stocktaking.stocktakingList')} />
            }
            {includesInArray([Roles.WAREHOUSE, Roles.ADMIN, Roles.FOREMAN], userRoles) &&
              <MenuLink to={Routes.internalSubmissionsList} text={t('warehouse.internalSubmissionsList')} />
            }
            <Divider />
          </Fragment>
        }
        {!isZebraBrowser() && selectMainView()}
      </Container>
      <Box css={mainMenuFooterCss} bg='white' width={1}>
        <Divider medium noMargin />
        <Flex p={6} alignItems='center'>
          {isZebraBrowser() && <Fragment>
            <DeviceStatus />
            <Link replace to={Routes.logout} css={{ marginLeft: 'auto' }}>
              <IconBox size={40}>
                <LogoutIcon css={iconCss} />
              </IconBox>
            </Link>
          </Fragment>
          }
        </Flex>
      </Box>
    </div>
  )
}

const mapStateToProps = state => ({
  roles: state.user.roles || []
})

export default connect(mapStateToProps)(MainMenu)
