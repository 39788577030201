/** @jsx jsx */
import { jsx } from '@emotion/core'
import theme from 'config/theme'

export const errorCss = {
  color: theme.colors.error,
  marginBottom: 12,
  textAlign: 'center'
}

const Input = ({ input, meta: { touched, error }, errorCss: propsErrorCss, numericKeyboard: numbers, ...props }) => {
  const onFocus = (e) => {
    props.onFocus && props.onFocus()
    input.onFocus(e)
  }

  const numericKeyboard = numbers
    ? { pattern: '[0-9]*', inputMode: 'numeric' }
    : {}
  return (
    <div>
      {props.type !== 'hidden' && touched && error && <div css={{ ...errorCss, ...propsErrorCss }}>{error}</div>}
      <input {...props} {...input} onFocus={onFocus} {...numericKeyboard} />
    </div>
  )
}

export default Input
