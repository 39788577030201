/** @jsx jsx */
import { jsx } from '@emotion/core'
import * as R from 'ramda'
import theme from 'config/theme'
import { handleFloatNumber } from 'helpers/number'
import Tooltip from 'components/Tooltip'
import {
  componentPlannedIndicatorCss,
  componentPlannedIndicatorTooltipCss,
  nameCell,
  weeklyPlanColumn
} from 'views/WeeklyPlans/styles'
import * as styles from './styles'
import { Fragment } from 'react'

export const getGeneralInfoColumns = (t, setVisibleIds, renderIndexCell) => [
  {
    Header: t('weeklyPlansForeman.index'),
    id: 'itemIndex',
    accessor: d => d.fullIndex || R.path(['itemRevision', 'fullIndex'], d),
    style: { padding: 0, lineHeight: '32px' },
    width: 200,
    Cell: renderIndexCell
  },
  {
    Header: t('weeklyPlansForeman.name'),
    id: 'itemName',
    accessor: d => R.path(['name'], d) || R.path(['itemRevision', 'item', 'name'], d),
    width: 350,
    headerStyle: { borderRight: `3px solid ${theme.colors.darkGrey}` },
    style: { padding: '0 5px', borderRight: `3px solid ${theme.colors.darkGrey}` },
    Cell: row => <div
      onClick={() => setVisibleIds(row.original.parentId ? row.original.parentId + row.original.id : row.original.id)}
      css={nameCell(row.original.depth, row.original.hasChildren)}
    >
      {row.value}
    </div>
  },
  {
    Header: t('weeklyPlans.warehouse'),
    id: 'warehouseQuantity',
    minWidth: 80,
    accessor: 'warehouseQuantity',
    headerStyle: { borderRight: `3px solid ${theme.colors.darkGrey}` },
    style: { padding: '0', borderRight: `3px solid ${theme.colors.darkGrey}`, textAlign: 'center' },
    Cell: row => {
      const { alternatives = [], availableAlternativesQuantity = 0, warehouseQuantity = 0 } = row.original
      const alternativesCount = alternatives.length
      const floatItemOwnWarehouseQuantity = handleFloatNumber(warehouseQuantity)
      const floatAvailableAlternativesQuantity = handleFloatNumber(availableAlternativesQuantity)
      const displayedWarehouseQuantity = !alternativesCount ? `${floatItemOwnWarehouseQuantity}` : `${floatItemOwnWarehouseQuantity}/${floatAvailableAlternativesQuantity + floatItemOwnWarehouseQuantity}`
      const withPointer = withPointer => withPointer ? { cursor: 'pointer' } : {}
      const RenderCellContent = ({ pointer = false }) => (
        <div
          css={styles.commonCell(row.original.depth)}
        >
          <span
            style={row.value < row.original?.weeklyPlan?.quantity && row.original.depth > 0 ? { color: theme.colors.error, ...withPointer(pointer) } : { ...withPointer(pointer) }}
          >
            {displayedWarehouseQuantity}
          </span>
        </div>
      )
      return (
        <Fragment>
          {alternativesCount ? (
            <Tooltip size='big' content={
              <div style={{ padding: 10, fontSize: 18 }}>
                <h2>Ilość własna:</h2>
                <p style={{ textAlign: 'center', marginBottom: '10px' }}>{floatItemOwnWarehouseQuantity}</p>
                <h2>Zamienniki:</h2>
                {alternatives.map((alternative, index) => {
                  return <div key={index} style={{ padding: '20px', display: 'flex', flexDirection: 'column', marginBottom: '10px', border: `1px solid ${theme.colors.darkGrey}`, borderRadius: '5px' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '5px' }}>
                      <span>dostępne:</span>
                      <span style={{ paddingLeft: '20px', wordBreak: 'keep-all' }}>{alternative.warehouseQuantity}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '5px' }}>
                      <span>index:</span>
                      <span style={{ paddingLeft: '20px', wordBreak: 'keep-all' }}>{alternative.fullIndex}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '5px' }}>
                      <span>nazwa:</span>
                      <span style={{ paddingLeft: '25px', wordBreak: 'keep-all', textAlign: 'right' }}>{alternative.name}</span>
                    </div>
                  </div>
                })}
              </div>
            } delay={500}>
              <RenderCellContent pointer />
            </Tooltip>
          ) : <RenderCellContent />}
        </Fragment>
      )
    } }
]

export const getGeneralInfoColumnsForeman = (t, setVisibleIds, renderIndexCell) => {
  return [
    {
      id: 'itemIndex',
      accessor: d => d.fullIndex || R.path(['itemRevision', 'fullIndex'], d),
      style: { padding: 0, lineHeight: '32px' },
      width: 150,
      Cell: renderIndexCell
    },
    {
      accessor: 'name',
      width: 370,
      style: { padding: '0 5px' },
      Cell: row => <div
        onClick={() => setVisibleIds(row.original.parentId ? row.original.parentId + row.original.id : row.original.id)}
        css={nameCell(row.original.depth, row.original.hasChildren)}
      >
        {row.value}
      </div>
    },
    {
      id: 'plan',
      width: 70,
      headerStyle: {
        borderLeft: `3px solid ${theme.colors.darkGrey}`,
        borderRight: `1px solid ${theme.colors.darkGrey}`
      },
      accessor: R.pathOr('', ['weeklyPlan', 'quantity']),
      style: { textAlign: 'center', padding: 0, borderRight: `1px solid ${theme.colors.darkGrey}`, borderLeft: `3px solid ${theme.colors.darkGrey}` },
      Cell: row => {
        const dailyPlansQuantitySum = row.original.dailyPlans.reduce((total, next) => {
          return total + next.quantity
        }, 0)
        const result = row.original.weeklyPlan.quantity - dailyPlansQuantitySum
        const isFirstLvl = R.propEq('depth', 0, row.original)
        const wholePlanPlannedCorrectly = result === 0

        return <div
          style={{ color: result < 0 && isFirstLvl ? theme.colors.error : theme.colors.darkGrey }}
          css={weeklyPlanColumn(row.original.depth)}
        >
          {isFirstLvl && (
            <Tooltip
              content={wholePlanPlannedCorrectly ? t('weeklyPlansForeman.allPlanned') : t('weeklyPlansForeman.notAllPlanned')}
              css={componentPlannedIndicatorTooltipCss}
              delay={500}
            >
              <span css={componentPlannedIndicatorCss(wholePlanPlannedCorrectly)} />
            </Tooltip>
          )}
          {row.value}
        </div>
      }
    }, {
      id: 'planQuantityLeft',
      width: 80,
      headerStyle: {
        borderRight: `3px solid ${theme.colors.darkGrey}`
      },
      style: { textAlign: 'center', borderRight: `3px solid ${theme.colors.darkGrey}`, fontWeight: 700 },
      Cell: row => {
        const dailyPlansQuantitySum = row.original.dailyPlans.reduce((total, next) => {
          return total + next.quantity
        }, 0)
        const isFirstLvl = R.propEq('depth', 0, row.original)
        const result = row.original.weeklyPlan.quantity - dailyPlansQuantitySum

        return isFirstLvl ? <div style={result < 0 ? { color: theme.colors.error } : {}}>{dailyPlansQuantitySum}</div> : null
      }
    }
  ]
}
