import { transformToArray } from 'helpers/redux'
import { createSelector } from 'reselect'

const getStockTakingIssues = state => state.stocktaking.entries

export const getStocktakingIssuesAsArray = createSelector(
  getStockTakingIssues,
  stockTakingIssues => transformToArray(stockTakingIssues)
)

export const getStocktakingIssueById = createSelector(
  getStockTakingIssues,
  (state, issueId) => issueId,
  (stocktakingIssues, issueId) => stocktakingIssues[issueId]
)
