/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import Switch from 'react-switch'
import theme from 'config/theme'
import { Flex } from '@rebass/emotion'
import styled from '@emotion/styled'

const switchCss = (isLoading) => css({
  verticalAlign: 'middle',
  boxShadow: isLoading ? `-1px -1px 3px ${theme.colors.grey}` : `1px 1px 3px ${theme.colors.grey}`
})

const Label = styled('div')(
  ({ theme, ...props }) => ({
    color: theme.colors.white,
    marginLeft: props.checked ? '6px' : '-27px',
    lineHeight: `${props.height}px`
  })
)
const Toggle = ({ label, width, height, onChange, value, isLoading }) => {
  return (
    <Flex justifyContent={'space-between'} flexDirection={'row'} alignItems={'flex-start'}>
      <Switch
        onChange={onChange}
        checked={value}
        css={switchCss(isLoading)}
        offColor={theme.colors.grey}
        onColor={theme.colors.successGreen}
        height={height}
        width={width}
        disabled={isLoading}
        uncheckedIcon={label && <Label height={height}>{label}</Label>}
        checkedIcon={label && <Label checked height={height}>{label}</Label>}
      />
    </Flex>
  )
}

Toggle.defaultProps = {
  label: false,
  width: 56,
  height: 28
}

export default Toggle
