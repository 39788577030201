import InternalOrdersService from 'services/InternalOrdersService'
import * as R from 'ramda'

const GET_ALL_INTERNALLY_ORDERED_ITEMS = Symbol('GET_ALL_INTERNALLY_ORDERED_ITEMS')
const GET_ALL_INTERNALLY_ORDERED_ITEMS_SUCCESS = Symbol('GET_ALL_INTERNALLY_ORDERED_ITEMS_SUCCESS')
const GET_ALL_INTERNALLY_ORDERED_ITEMS_FAILED = Symbol('GET_ALL_INTERNALLY_ORDERED_ITEMS_FAILED')

const requestGetAllInternallyOrderedItems = () => ({ type: GET_ALL_INTERNALLY_ORDERED_ITEMS })
const getAllInternallyOrderedItemsSuccess = (data) => ({ type: GET_ALL_INTERNALLY_ORDERED_ITEMS_SUCCESS, data })
const getAllInternallyOrderedItemsFailed = () => ({ type: GET_ALL_INTERNALLY_ORDERED_ITEMS_FAILED })

export const getAllInternallyOrderedItems = () => {
  return async dispatch => {
    dispatch(requestGetAllInternallyOrderedItems())
    try {
      const { data } = await InternalOrdersService.getAllInternallyOrderedItems()
      return dispatch(getAllInternallyOrderedItemsSuccess(data))
    } catch (e) {
      return dispatch(getAllInternallyOrderedItemsFailed(e))
    }
  }
}

const PREPARE_INTERNAL_ORDER_ITEM = Symbol('PREPARE_INTERNAL_ORDER_ITEM')
const PREPARE_INTERNAL_ORDER_ITEM_SUCCESS = Symbol('PREPARE_INTERNAL_ORDER_ITEM_SUCCESS')
const PREPARE_INTERNAL_ORDER_ITEM_FAILED = Symbol('PREPARE_INTERNAL_ORDER_ITEM_FAILED')

const requestPrepareInternalOrderItem = () => ({ type: PREPARE_INTERNAL_ORDER_ITEM })
const prepareInternalOrderItemSuccess = (data) => ({ type: PREPARE_INTERNAL_ORDER_ITEM_SUCCESS, data })
const prepareInternalOrderItemFailed = () => ({ type: PREPARE_INTERNAL_ORDER_ITEM_FAILED })

export const prepareInternalOrderItem = (values) => {
  return async dispatch => {
    dispatch(requestPrepareInternalOrderItem())
    try {
      const { data } = await InternalOrdersService.prepareInternalOrderItem(values)
      dispatch(prepareInternalOrderItemSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(prepareInternalOrderItemFailed(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  entries: {}
}

window.R = R

export default (state = initState, action) => {
  switch (action.type) {
    case GET_ALL_INTERNALLY_ORDERED_ITEMS_SUCCESS:
      return {
        ...state,
        entries: action.data
      }
    default:
      return state
  }
}
