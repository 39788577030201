import APIInterceptor from 'services/APIInterceptor'

const interceptor = APIInterceptor

const FileUploadService = {
  uploadFile: (itemRevisionId, payload, config = {}) => {
    return interceptor.post(`/items/revisions/${itemRevisionId}/files`, payload, config)
  },
  removeFile: (itemRevisionId, fileId) => {
    return interceptor.delete(`items/revisions/${itemRevisionId}/files/${fileId}`)
  }
}

export default FileUploadService
