/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import theme from 'config/theme'
import { Flex } from '@rebass/emotion'
import Tooltip from 'components/Tooltip'
import { dissoc } from 'ramda'

const wrapperCss = css`
  border: 2px solid ${theme.colors.grey};
  display: inline-flex;
  width: 100%;
`
const errorCss = css`
  border: 2px solid ${theme.colors.error};
  display: inline-flex;
    width: 100%;
`

const inputCss = css`
  padding: 5px;
  color: ${theme.colors.grey};
  text-align: right;
  border: none;
  height: 30px;
  width: 100%;
  &:focus {
    border-color: ${theme.colors.black};
    color: ${theme.colors.black};
  }
  
  &:not([value=""]) {
    color: ${theme.colors.darkGrey};
`

const iconCss = css`
  width: 20px;
  padding-left: 10px;
  height: 20px;
  position: absolute;
`

const InputWithIcon = ({ icon, hasError, iconStyle, withTooltip, tooltipContent, ...props }) => {
  const Icon = icon
  return (
    withTooltip
      ? <Tooltip content={tooltipContent}>
        <Flex css={hasError ? errorCss : wrapperCss} flexDirection='row' alignItems='center'>
          {icon && <Icon css={{ iconCss, ...iconStyle }} />}
          <input {...props} css={inputCss} />
        </Flex>
      </Tooltip>
      : <Flex css={hasError ? errorCss : wrapperCss} flexDirection='row' alignItems='center'>
        {icon && <Icon css={{ width: 20, height: 20, padding: '0 5px', ...iconStyle }} />}
        <input {...dissoc('meta', props)} css={inputCss} />
      </Flex>
  )
}

export default InputWithIcon
