import { transformById, transformToArray } from 'helpers/redux'
import * as R from 'ramda'

const flattenBomTree = bomTree => {
  const getParts = R.pathOr([], ['itemRevision', 'bom', 'parts'])
  const allBoms = []
  const pushToAllBomsAndReturn = item => {
    R.path(['itemRevision', 'bom'], item) && allBoms.push(item)
    return item
  }
  const extractBomWithParts = (bom) => {
    R.pipe(
      pushToAllBomsAndReturn,
      getParts,
      R.map(extractBomWithParts)
    )(bom)
  }
  extractBomWithParts({ itemRevision: bomTree })
  return transformById(allBoms)
}

export const getBomsFromBomTreeAsArrayByIds = (bomTree, idsArray) => {
  const allBoms = flattenBomTree(bomTree)
  return R.pipe(
    R.pickBy((item) => idsArray.includes(R.pathOr('', ['itemRevision', 'bom', 'id'], item))),
    transformToArray
  )(allBoms)
}
