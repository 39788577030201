/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState } from 'react'

import { confirmAlert } from 'react-confirm-alert'

import { ReactComponent as Cancel } from 'assets/cancel.svg'

import Button from 'components/Button'
import IconBox from 'components/IconBox'
import Modal from 'components/Modal'

import theme from 'config/theme'

import { errorHandler } from 'helpers/errorHandler'

import {
  buttonHolderStyles,
  imageCss,
  imagePreviewCss,
  imagesPreviewWrapperCss,
  inputCss,
  inputHolderCss,
  ModalContentWrapperCss,
  NoImagesNotieCss,
  removeImageCss
} from './imagesModalStyles'

const NoImagesNotie = () => (
  <div css={NoImagesNotieCss}>
    <b>Brak wgranych zdjęć/schematów</b>
  </div>
)

const ImagesPreview = ({ images, onImageRemove }) => {
  const confirmDeleteModal = (fileName, onDelete) => {
    const normalizedFileName = fileName?.length > 15 ? `${fileName.slice(0, 14)}...` : fileName
    return confirmAlert({
      title: 'Potwierdź usunięcie',
      message: `Czy na pewno chcesz usunąć plik ${normalizedFileName}?`,
      buttons: [
        {
          label: 'Tak',
          onClick: onDelete
        },
        {
          label: 'Nie'
        }
      ]
    })
  }

  return <div css={imagesPreviewWrapperCss}>
    {images.map(({ url, originalName, id }) => (
      <div css={imagePreviewCss} key={id}>
        <img src={url} alt={originalName} css={imageCss} />
        <span css={removeImageCss} onClick={() => confirmDeleteModal(originalName, () => onImageRemove(id))}>
          <IconBox size={20} color={theme.colors.white} border={theme.colors.black}>
            <Cancel fill={theme.colors.black} css={{ width: '50%', height: '50%' }} />
          </IconBox>
        </span>
      </div>
    ))}
  </div>
}

const ImagesModal = ({ item, onClose, addImage, removeImage }) => {
  const { files, sanitizedName } = item
  const [inputFile, setInputFile] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const handleUploadImage = async file => {
    setIsLoading(true)
    // eslint-disable-next-line
      const fd = new FormData()
    fd.append('file', file, file.name)
    try {
      await addImage({ revisionId: item.id, file: fd })
    } catch (error) {
      errorHandler(error)
    }
    setIsLoading(false)
  }

  const handleRemoveUploadedFile = async fileId => {
    setIsLoading(true)
    const payload = {
      revisionId: item.id,
      fileId
    }
    try {
      await removeImage(payload)
    } catch (error) {
      errorHandler(error)
    }
    setIsLoading(false)
  }

  const fileAttachHandler = event => {
    const file = event.target.files[0]
    if (file) {
      const getFileSizeInMB = size => size / (1024 * 1024)
      const acceptedFileTypes = ['image/jpeg', 'image/png']
      const isFileImageType = acceptedFileTypes.includes(file.type)
      // case when file is not an image return window alert
      if (!isFileImageType) {
        return window.alert('Wybrany plik nie jest w formacie JPG')
      }
      return getFileSizeInMB(file.size) > 4 ? window.alert(`Rozmiar pliku ${file.name} przekracza rozmiar 4MB. Zastąp go plikiem o mniejszym rozmiarze.`)
        : setInputFile(file)
    }
  }

  useEffect(() => {
    if (inputFile) {
      handleUploadImage(inputFile)
      setInputFile(null)
    }
  }, [inputFile])

  return (
    <Modal headerTitle={`Zdjęcia/Schematy ${sanitizedName} (${files.length})`} onClose={onClose} closeOnOutsideClick zIndex={85}>
      <div css={ModalContentWrapperCss}>
        <div css={inputHolderCss}>
          <div css={buttonHolderStyles}>
            <Button
              color={theme.colors.lightBlue}
              isLoading={isLoading}
              disabled={isLoading || inputFile}
            >
              Dodaj Plik
            </Button>
            <input css={inputCss} type='file' accept='image/*' onChange={fileAttachHandler} />
          </div>
        </div>
        {files.length
          ? <ImagesPreview images={files} onImageRemove={handleRemoveUploadedFile} />
          : <NoImagesNotie />
        }
      </div>
    </Modal>
  )
}

export default ImagesModal
