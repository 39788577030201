/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useEffect, useState } from 'react'
import StorageService, { LocalStorageKeys } from 'services/StorageService'
import { Box, Flex } from '@rebass/emotion'
import enbioLogo from 'assets/enbio-logo.png'
import Select from 'react-select'
import Routes from 'constants/Routes'
import { useTranslation } from 'react-i18next'

const containerCss = css`
  height: 100vh;
  padding: 30px;
`

const logoCss = css`
  max-height: 30px;
  margin-bottom: 50px;
`

const selectWrapperCss = css`
  margin-top: 30px;
  min-width: 300px;
`

const ProductionStationChoose = (props) => {
  const { t } = useTranslation()
  const [selectedStation, select] = useState(null)

  useEffect(() => {
    props.getProductionStations()
  }, [])

  const handleStationSelect = async station => {
    StorageService.set(LocalStorageKeys.stationId, station.value)
    select(station)
    await props.loginToProductionStation(station.value)
    props.history.push(Routes.productionStation.base + station.value)
  }

  return (
    <Flex flexDirection='column' css={containerCss} alignItems='center' justifyContent='center'>
      <img src={enbioLogo} alt='logo' css={logoCss} />
      <h2>{t('productionStations.chooseStationLong')}</h2>
      <Box css={selectWrapperCss}>
        <Select
          value={selectedStation}
          placeholder={t('productionStations.chooseStationShort')}
          onChange={handleStationSelect}
          options={props.productionStations}
        />
      </Box>
    </Flex>
  )
}

export default ProductionStationChoose
