import * as R from 'ramda'
export const includesInArray = (arrayOne, arrayTwo) => arrayOne.some(element => arrayTwo.includes(element))

export const iterable = length => Array.from(Array(length).keys())

export const removeDuplicatesInArrayByProp = R.curry((prop, array) => R.uniqBy(R.prop(prop), array))

export const removeDuplicatesInArrayById = removeDuplicatesInArrayByProp('id')

const sortfn = ({ warehouseLocation: { code: codeA } }, { warehouseLocation: { code: codeB } }) => {
  if (codeA[0] === 'INBOUND') return 1
  if (codeB[0] === 'INBOUND') return -1
  if (codeA[0] > codeB[0]) return 1
  if (codeB[0] > codeA[0]) return -1
  if (Number(codeA[1]) > Number(codeB[1])) return 1
  if (Number(codeB[1]) > Number(codeA[1])) return -1
  if (Number(codeA[2]) > Number(codeB[2])) return 1
  if (Number(codeB[2]) > Number(codeA[2])) return -1
}

export const sortLocationsByCode = R.pipe(
  R.map(el => { el.warehouseLocation.code = el.warehouseLocation.code.split('-'); return el }),
  R.sort(sortfn),
  R.map(el => { el.warehouseLocation.code = el.warehouseLocation.code.join('-'); return el })
)

const makeSpec = (curr, next) => R.assoc(next, 0, curr)
const makePathFromKey = R.split('.')
const getValueFromItemBasedOnKey = (key, nextItem) => R.pathOr(
  0,
  makePathFromKey(key),
  nextItem
)

const mapSpecKeyToFunctionAddingValueFromNextItem = nextItem => (_, key) => R.add(getValueFromItemBasedOnKey(key, nextItem))
const sumAllValuesFromSpec = spec => (current, nextItem) =>
  R.evolve(
    R.mapObjIndexed(
      mapSpecKeyToFunctionAddingValueFromNextItem(nextItem),
      spec
    ),
    current
  )

export const sumBy = R.curry(
  (props, vals) => {
    const spec = R.reduce(makeSpec, {}, props)
    return R.reduce(
      sumAllValuesFromSpec(spec),
      spec,
      vals
    )
  }
)
