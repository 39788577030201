import { connect } from 'react-redux'
import ManageLocationsView from 'views/ManageLocations/ManageLocationsView'
import { createLocation, deleteLocation, getLocationsList } from 'ducks/locations'
import { fetchLocationsListAsArray } from 'selectors/locations'

const mapStateToProps = state => ({
  locations: fetchLocationsListAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  getLocationsList: () => dispatch(getLocationsList()),
  createLocation: ({ name, type, parentId }) => dispatch(createLocation({ name, type, parentId })),
  deleteLocation: async ({ id }, closeModal) => {
    if (typeof closeModal === 'function') closeModal()
    return dispatch(deleteLocation({ id }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(ManageLocationsView)
