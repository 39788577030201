import ApiInterceptor from './APIInterceptor'

const WorkstationsService = {
  getWorkstations: () => ApiInterceptor.get(`/assembly_posts?users=1&boms=1`),
  addWorkstation: (payload) => ApiInterceptor.post(`/assembly_posts`, payload),
  editWorkstationById: (workstationId, payload) => ApiInterceptor.patch(`/assembly_posts/${workstationId}`, payload),
  deleteWorkstationById: workstationId => ApiInterceptor.delete(`/assembly_posts/${workstationId}`),
  setBomsForWorkstationById: (workstationId, bomIds) => ApiInterceptor.patch(`/assembly_posts/${workstationId}/boms`, { bom_ids: bomIds })
}

export default WorkstationsService
