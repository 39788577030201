export const colors = {
  background: '#fff',
  black: '#000',
  body: '#333',
  darkGrey: '#444',
  grey: '#888',
  lightGrey: '#AAA',
  concreteGray: '#f2f2f2',
  successGreen: '#57aa62',
  lightGreen: '#b9d6b1',
  lighterGreen: '#F1FFF0',
  orange: '#ff9400',
  moodyOrange: '#e5b634',
  error: '#ff5854',
  violet: '#aa0097',
  lightRed: '#ffd8d7',
  lightBlue: '#4892C7',
  lightBlueTransparent: '#4892C766',
  darkBlue: '#1e4562',
  dustyGray: '#979797',
  barelyGrey: '#DADADA',
  catskillWhite: '#EDF2F6',
  white: '#fff',
  red: '#ff0000',
  navyBlue: '#0082CB',
  transparent: 'transparent'
}

const typography = {
  h1: {
    fontSize: 48,
    letterSpacing: -1.5
  },
  h2: {
    fontSize: 40,
    letterSpacing: -0.5
  },
  h3: {
    fontSize: 32,
    letterSpacing: 0
  },
  h4: {
    fontSize: 24,
    letterSpacing: 0.25
  },
  h5: {
    fontSize: 20,
    letterSpacing: 0
  },
  h6: {
    fontSize: 16,
    letterSpacing: 0.15
  },
  subtitle1: {
    fontSize: 16,
    letterSpacing: 0.15
  },
  subtitle2: {
    fontSize: 14,
    letterSpacing: 0.1
  },
  body1: {
    fontSize: 16,
    letterSpacing: 0.5
  },
  body2: {
    fontSize: 14,
    letterSpacing: 0.25
  },
  body3: {
    fontSize: 14,
    letterSpacing: 0.5
  },
  button: {
    fontSize: 14,
    letterSpacing: 1.25,
    color: colors.white
  },
  caption: {
    fontSize: 12,
    letterSpacing: 0.4
  },
  overline: {
    fontSize: 10,
    letterSpacing: 1.5
  }
}

const typographyComponentsMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'p',
  subtitle2: 'p',
  body1: 'p',
  body2: 'p',
  button: 'span',
  caption: 'span',
  overline: 'span'
}

const opacity = 0.6

export const weights = {
  light: 300,
  normal: 400,
  semibold: 600,
  bold: 700
}

const elevation = {
  modal: 9000,
  overlay: 8000,
  dropdown: 7000,
  navigation: 6000,
  underneath: -1,
  standOut: 2
}

// TODO: implement these in the near future :)
const fontWeight = []
const lineHeights = []
const letterSpacing = []
const shadows = []
const borders = []
const radii = {
  none: 0,
  small: 5,
  medium: 10,
  large: 30,
  round: 9999,
  circle: 9999
}

const modalSizes = {
  web: {
    small: 500,
    regular: 800
  }
}

// --- Position --- 0, 1, 2, 3,  4, 5,  6,  7,  8,  9, 10, 11
export let space = [0, 2, 4, 6, 8, 12, 16, 24, 32, 48, 64, 80]

const breakpoints = [320, 375, 768, 1024, 1367]

export const layout = {
  navigationHeight: 48
}

const theme = {
  borders,
  breakpoints,
  colors,
  elevation,
  fontWeight,
  layout,
  letterSpacing,
  lineHeights,
  modalSizes,
  opacity,
  radii,
  shadows,
  space,
  typography,
  typographyComponentsMap,
  weights
}

export default theme
