import SearchItemsSelect from './SearchItemsSelect'
import { connect } from 'react-redux'
import { receiveInput } from 'ducks/searchInput'

const mapStateToProps = state => ({
  inputValue: state.searchInput.value
})

const mapDispatchToProps = dispatch => ({
  onInputChange: (value, { action }) => {
    action === 'input-change' && dispatch(receiveInput(value))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemsSelect)
