import InternalOrderForm from 'forms/InternalOrderForm'
import { connect } from 'react-redux'
import { getFormValues, reduxForm } from 'redux-form'
import i18n from 'i18next'
import { createInternalOrder } from 'ducks/internalOrders'
import { submitItemsForWarehouse } from 'ducks/itemsSubmissions'
// import { getItemDetails } from 'ducks/warehouseState'

const formName = 'InternalOrderForm'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate: (values) => {
    const errors = {}
    const requiredFields = ['quantity']
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = i18n.t('common.fieldRequired')
      }
    })

    if (values['quantity'] && !(Number(values['quantity']) > 0)) {
      errors['quantity'] = i18n.t('common.numberMustBePositive')
    }

    return errors
  }
})(InternalOrderForm)

const mapStateToProps = (state, ownProps) => {
  return ({
    initialValues: ownProps.initialValues,
    formValues: getFormValues(formName)(state)
  })
}

const mapDispatchToProps = dispatch => ({
  onSubmitOrder: (values) => dispatch(createInternalOrder({ purpose: 'production', items: [values] })),
  onSubmitHandOver: (values) => dispatch(submitItemsForWarehouse({ code: values.code, quantity: parseFloat(values.quantity) }))
  // getItemDetails: (itemCode) => dispatch(getItemDetails(itemCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
