/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Component } from 'react'
import Breadcrumb from 'components/Breadcrumb'
import { withTranslation } from 'react-i18next'
import LabelledDivider from 'components/LabelledDivider'
import Container from 'components/Container'
import moment from 'moment'
import { pipe, propOr, pathOr } from 'ramda'
import QrCodeReader from 'components/QrCodeReader'
import StatusBox from 'components/StatusBox'
// import Divider from 'components/Divider'
// import Button from 'components/Button'
// import { ReactComponent as NavigateIcon } from 'assets/navigate.svg'
// import theme from 'config/theme'
import DeliveredItemDescription from 'components/DeliveredItemDescription'
import { withRouter } from 'react-router-dom'
import Routes from 'constants/Routes'

class ConfirmItemDelivery extends Component {
  state = {
    isLoading: true,
    status: this.props.t('common.sending'),
    delivered: pathOr(0, ['items', this.props.location.state.selectedOrderItemId, 'deliveredQuantity'], this.props.order)
  }
  getItems = propOr([], 'items')

  async componentDidMount () {
    const {
      selectedOrderItemId,
      quantity,
      reportDiscrepancy: reportDiscrepancyProp,
      discrepancyComment,
      isOutsideOrder,
      itemCode
    } = this.props.location.state
    const orderId = propOr('', 'id', this.props.order)
    const itemId = selectedOrderItemId
    const reportDiscrepancy = Boolean(reportDiscrepancyProp)
    try {
      if (isOutsideOrder) {
        await this.props.confirmOutsideOrderDelivery({ quantity, itemCode })
      } else {
        await this.props.confirmItemDeliveryForOrder({
          itemId,
          quantity,
          orderId,
          reportDiscrepancy,
          discrepancyComment
        })
      }
      this.setState({
        isLoading: false,
        status: this.props.t('supply.received'),
        delivered: this.state.delivered + Number(this.props.location.state.quantity)
      })
    } catch (e) {
      this.setState({ isLoading: false, status: this.props.t('common.somethingWentWrong') })
    }
  }

  // navigateToOnBoarding = () => this.props.history.replace(`/on-boarding/item/${this.props.currentItem.code}`)

  getQuantity = pipe(
    this.getItems,
    propOr({}, this.props.location.state.selectedOrderItemId),
    propOr(0, 'quantity')
  )(this.props.order)

  render () {
    const { t, order, currentItem } = this.props
    const { isOutsideOrder } = this.props.location.state

    return (
      <div>
        <Breadcrumb>{t('supply.location')}</Breadcrumb>
        <StatusBox
          withRevertButton
          isLoading={this.state.isLoading}
          onFinish={() => this.props.history.replace(Routes.supplySearch)}
          onClickFinish={() => this.props.history.replace(`/supply/item/${this.props.match.params.itemCode}`)}
        >
          {this.state.status}
        </StatusBox>
        <DeliveredItemDescription item={currentItem} quantity={this.props.location.state.quantity} />
        <LabelledDivider text={t('orders.order')} />
        {order &&
          <Container flex columns>
            <div css={{ fontWeight: 'bold' }}>{moment(order.expectedDeliveryDate).format('DD.MM.YYYY')}</div>
            <div css={{ margin: '5px 0' }}>{order.vendor.name}</div>
            <div>
              {t('orders.received')}: {this.state.delivered}/{this.getQuantity}
            </div>
          </Container>
        }
        {isOutsideOrder &&
          <Container>
            <h3>{t('supply.outsideOrderExpanded')}</h3>
          </Container>
        }
        <QrCodeReader method='replace' destination='/supply/item' invisible />
        {/* <Divider medium />
        {!this.state.isLoading && this.state.status === this.props.t('supply.received') &&
          <Button
            onClick={this.navigateToOnBoarding}
            withIcon
            icon={NavigateIcon}
            iconProps={{ fill: theme.colors.white }}
          >
            {t('supply.placeInWarehouse')}
          </Button>
        } */}
      </div>
    )
  }
}

export default withRouter(withTranslation()(ConfirmItemDelivery))
