import HandOverForm from 'forms/HandOverForm'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { getHandOverPurposes } from 'ducks/handOverPurposes'
import { getHandOverPurposesAsOptions } from 'selectors/handOverPurposes'
import { isEmpty } from 'ramda'
import i18n from 'i18next'

const formName = 'HandOverForm'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate: (values, ownProps) => {
    const errors = {}
    const requiredFields = ['destination', 'quantity']
    requiredFields.forEach(field => {
      if (!values[field] || isEmpty(values[field])) {
        errors[field] = i18n.t('common.fieldRequired')
      }
    })

    if (values['quantity'] && (values['quantity'] <= '0' || values['quantity'] > ownProps.initialValues.quantity)) {
      errors['quantity'] = i18n.t('common.numberMustBePositiveButNotGreaterThan', { count: ownProps.initialValues.quantity })
    }
    return errors
  }
})(HandOverForm)

const mapStateToProps = state => ({
  handOverPurposes: getHandOverPurposesAsOptions(state)
})

const mapDispatchToProps = dispatch => ({
  getHandOverPurposes: () => dispatch(getHandOverPurposes())
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
