import ColorsService from 'services/colorsService'
import { transformById } from 'helpers/redux'
import { errorHandler } from 'helpers/errorHandler'
import { toast } from 'react-toastify'
import { t } from 'i18next'

const GET_COLORS_LIST_REQUEST = Symbol('GET_COLORS_LIST_REQUEST')
const GET_COLORS_LIST_SUCCESS = Symbol('GET_COLORS_LIST_SUCCESS')
const GET_COLORS_LIST_FAILED = Symbol('GET_COLORS_LIST_FAILED')

const getColorsListRequest = () => ({ type: GET_COLORS_LIST_REQUEST })
const getColorsListSuccess = (data) => ({ type: GET_COLORS_LIST_SUCCESS, data })
const getColorsListFailed = () => ({ type: GET_COLORS_LIST_FAILED })

export const getColorsList = (revisionId) => {
  return async dispatch => {
    dispatch(getColorsListRequest())
    try {
      const { data } = await ColorsService.getColorsList(revisionId)
      return dispatch(getColorsListSuccess(data))
    } catch (e) {
      errorHandler(e)
      return dispatch(getColorsListFailed(e))
    }
  }
}

export const UPDATE_COLORS_LIST_REQUEST = Symbol('UPDATE_COLORS_LIST_REQUEST')
export const UPDATE_COLORS_LIST_SUCCESS = Symbol('UPDATE_COLORS_LIST_SUCCESS')
export const UPDATE_COLORS_LIST_FAILED = Symbol('UPDATE_COLORS_LIST_FAILED')

const updateColorsListRequest = () => ({ type: UPDATE_COLORS_LIST_REQUEST })
const updateColorsListSuccess = data => ({ type: UPDATE_COLORS_LIST_SUCCESS, data })
const updateColorsListFailed = () => ({ type: UPDATE_COLORS_LIST_FAILED })

export const updateColorsList = (revisionId, newList) => {
  return async dispatch => {
    dispatch(updateColorsListRequest())
    try {
      const { data } = await ColorsService.updateColorsList(revisionId, newList)
      dispatch(updateColorsListSuccess(data))
      toast.success(t('colors.alerts.colorsListUpdated'), { containerId: 'statuses', autoClose: 15e3 })
    } catch (e) {
      errorHandler(e)
      dispatch(updateColorsListFailed(e))
    }
  }
}

export const ADD_NEW_COLOR_REQUEST = Symbol('ADD_NEW_COLOR_REQUEST')
export const ADD_NEW_COLOR_SUCCESS = Symbol('ADD_NEW_COLOR_SUCCESS')
export const ADD_NEW_COLOR_FAILED = Symbol('ADD_NEW_COLOR_FAILED')

const addNewColorRequest = () => ({ type: ADD_NEW_COLOR_REQUEST })
const addNewColorSuccess = data => ({ type: ADD_NEW_COLOR_SUCCESS, data })
const addNewColorFailed = () => ({ type: ADD_NEW_COLOR_FAILED })

export const addNewColorVersion = (code, name) => async dispatch => {
  dispatch(addNewColorRequest())
  try {
    const { data } = await ColorsService.addNewColorVersion(code, name)
    dispatch(addNewColorSuccess(data))
    toast.success(t('colors.alerts.newColorAdded'), { containerId: 'statuses', autoClose: 15e3 })
  } catch (err) {
    console.error(err)
    errorHandler(err)
    dispatch(addNewColorFailed(err))
  }
}

export const DELETE_COLOR_REQUEST = Symbol('DELETE_COLOR_REQUEST')
export const DELETE_COLOR_SUCCESS = Symbol('DELETE_COLOR_SUCCESS')
export const DELETE_COLOR_FAILED = Symbol('DELETE_COLOR_FAILED')

const deleteColorRequest = () => ({ type: DELETE_COLOR_REQUEST })
const deleteColorSuccess = data => ({ type: DELETE_COLOR_SUCCESS, data })
const deleteColorFailed = () => ({ type: DELETE_COLOR_FAILED })

export const deleteColorVersion = colorId => async dispatch => {
  dispatch(deleteColorRequest())
  try {
    const { data } = await ColorsService.deleteColorVersion(colorId)
    dispatch(deleteColorSuccess(data))
    toast.success(t('colors.alerts.colorDeleted'), { containerId: 'statuses', autoClose: 15e3 })
  } catch (err) {
    console.error(err)
    errorHandler(err)
    dispatch(deleteColorFailed(err))
  }
}

export const UPDATE_COLOR_REQUEST = Symbol('UPDATE_COLOR_REQUEST')
export const UPDATE_COLOR_SUCCESS = Symbol('UPDATE_COLOR_SUCCESS')
export const UPDATE_COLOR_FAILED = Symbol('UPDATE_COLOR_FAILED')

const updateColorRequest = () => ({ type: UPDATE_COLOR_REQUEST })
const updateColorSuccess = data => ({ type: UPDATE_COLOR_SUCCESS, data })
const updateColorFailed = () => ({ type: UPDATE_COLOR_FAILED })

export const updateColorVersion = (colorId, name, code) => async dispatch => {
  dispatch(updateColorRequest())
  try {
    const { data } = await ColorsService.updateColorVersion(colorId, name, code)
    dispatch(updateColorSuccess(data))
    toast.success(t('colors.alerts.colorUpdated'), { containerId: 'statuses', autoClose: 15e3 })
  } catch (err) {
    console.error(err)
    errorHandler(err)
    dispatch(updateColorFailed(err))
  }
}

const initState = {
  entries: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_COLORS_LIST_SUCCESS:
    case UPDATE_COLORS_LIST_SUCCESS:
      return {
        ...state,
        entries: transformById(action.data)
      }
    default:
      return state
  }
}
