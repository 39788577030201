/** @jsx jsx */
import { useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { css, jsx } from '@emotion/core'
import { Box } from '@rebass/emotion'
import Button from 'components/Button'
import SteamJetGenerator from 'components/SteamJetGenerator'
import useInput from 'hooks/useInput'
import useKeyHandler from 'hooks/useKeyHandler'
import getSerialNumberFromUrl from 'helpers/getSerialNumberFromUrl'
import Routes from 'constants/Routes'
import theme from 'config/theme'

const QualityControl = (props) => {
  const [value, onChange] = useInput()
  const inputRef = useRef(null)

  const scanHandler = (scannedCode) => {
    const serialNumberForSteamJet = getSerialNumberFromUrl(scannedCode)
    onChange('')
    props.history.push(Routes.qualityControlDetails.replace(':serialNumber', serialNumberForSteamJet || scannedCode))
  }

  const handleClick = () => scanHandler(value)

  useKeyHandler(scanHandler, inputRef)
  return (
    <Box p={8}>
      {process.env.NODE_ENV === 'development' && <SteamJetGenerator code={'1-8-14309D5'} />}
      <div css={inputWrapperCss}>
        <h3>Zeskanuj lub wpisz numer seryjny:</h3>
        <input
          ref={inputRef}
          css={{ padding: '10px 5px', border: `2px solid ${theme.colors.darkGrey}`, borderRadius: '7.5px' }}
          value={value}
          onChange={onChange}
          type='text'
          name='handOverQuantityInput'
        />
        <Button
          css={actionButtonCss}
          onClick={handleClick}>Szukaj</Button>
      </div>
    </Box>
  )
}

const inputWrapperCss = css`
  display: flex;
  padding: 20px;
  align-items: center;

  & > * {
    margin: 5px;
  }
`

const actionButtonCss = css`
  width: unset;
  margin: unset;
`

export default withRouter(QualityControl)
