import React, { useEffect, useState } from 'react'
import Container from 'components/Container'

const checkTime = (time) => {
  if (time < 10) return '0' + time
  return time
}

const getTime = () => {
  const today = new Date()
  const h = today.getHours()
  const m = today.getMinutes()
  return checkTime(h) + ':' + checkTime(m)
}

const useCurrentTime = () => {
  const [currentTime, setTime] = useState(getTime())
  const updateTime = () => setTime(getTime())
  useEffect(() => {
    const timer = setInterval(updateTime, 15000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  return currentTime
}

const Clock = () => {
  const currentTime = useCurrentTime()
  return (
    <Container toRight noMargin>
      <span>
        {currentTime}
      </span>
    </Container>
  )
}

export default Clock
