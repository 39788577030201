import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumb from 'components/Breadcrumb'
import { Box, Heading, Text } from '@rebass/emotion'
import StatusBox from 'components/StatusBox'
import DeliveredItemDescription from 'components/DeliveredItemDescription'
import LabelledDivider from 'components/LabelledDivider'
import Divider from 'components/Divider'
import { withRouter } from 'react-router-dom'
import Routes from 'constants/Routes'

const RecalculateFinalizeScreen = (props) => {
  const { t } = useTranslation()
  const [status, setStatus] = useState(t('common.sending'))
  const [isLoading, setLoading] = useState(true)
  const { quantity, inventoryId, initialQuantity } = props.location.state
  const finalizeRecalculate = async () => {
    try {
      await props.recalculateInventoryQuantity({ quantity, inventoryId })
      setStatus(t('warehouse.itemsQuantityChanged'))
      setLoading(false)
    } catch (e) {
      setStatus(t('common.somethingWentWrong'))
      setLoading(false)
    }
  }

  useEffect(() => {
    finalizeRecalculate()
  }, [])

  return (
    <div>
      <Breadcrumb>{t('warehouse.recalculate')}</Breadcrumb>
      <StatusBox
        withRevertButton
        isLoading={isLoading}
        onFinish={() => props.history.replace(Routes.recalculateSearch)}
        onClickFinish={() => props.history.replace(`/warehouse/recalculate/${props.match.params.itemCode}`)}
      >
        {status}
      </StatusBox>
      <DeliveredItemDescription item={props.currentItem} quantity={quantity} />
      <LabelledDivider text={t('common.location')} />
      <Box>
        <Heading my={4} textAlign='center'>{props.inventory?.warehouseLocation?.code}</Heading>
        <Text my={4} textAlign='center'>
          ( {t('warehouse.lastTime')}: {initialQuantity} {props.currentItem.unit} )
        </Text>
      </Box>
      <Box px={5}>
        <Divider medium />
      </Box>
    </div>
  )
}

export default withRouter(RecalculateFinalizeScreen)
