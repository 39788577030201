/** @jsx jsx */
import { jsx } from '@emotion/core'
import * as R from 'ramda'
import { Field } from 'redux-form'
import Button from 'components/Button'
import theme from 'config/theme'
import { useTranslation } from 'react-i18next'
import { Flex } from '@rebass/emotion'
import Checkbox from 'components/Checkbox'
import Select from 'components/Select'
import { toast } from 'react-toastify'
import { errorHandler } from 'helpers/errorHandler'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import { ReactComponent as Cancel } from 'assets/cancel.svg'
import { useState } from 'react'

const renderCheckboxField = ({ input, label, name, ...props }) => {
  return (
    <Flex py={4} mt={7}>
      <Checkbox checked={input.value} onChange={input.onChange} {...props} />
      <label htmlFor={name} css={{ marginLeft: 20, flex: 1 }}>{label}</label>
    </Flex>
  )
}

const renderSelectField = (props) => {
  return (
    <Flex py={4}>
      <Select selected={props.input.value} {...props} css={{ minWidth: '100%' }} />
    </Flex>
  )
}

const renderInputField = ({ input, meta: { touched, error }, ...props }) => {
  return (
    <Flex py={4}>
      <input
        {...input}
        {...props}
        css={{
          display: 'block',
          border: `2px solid ${touched && error ? theme.colors.error : theme.colors.black}`,
          padding: 8,
          minWidth: 200,
          height: 24
        }}
      />
    </Flex>
  )
}

const FieldComponent = ({ valueType, label, name, ...props }) => {
  const isCheckBox = valueType === 'checkbox'
  const isSelect = valueType === 'select'
  const getComponent = () => {
    switch (true) {
      case isCheckBox:
        return renderCheckboxField
      case isSelect:
        return renderSelectField
      default:
        return renderInputField
    }
  }
  return (
    <div key={name} css={{ marginRight: 10, alignSelf: 'center' }}>
      {!isCheckBox && <label htmlFor={name}>{label}</label>}
      <Field
        name={name}
        label={label}
        valueType={valueType}
        component={getComponent()}
        type={valueType}
        {...props}
      />
    </div>
  )
}

const WorkstationEditForm = props => {
  const { handleSubmit, workstations, submitting } = props
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const workStationsAsOptions = R.pipe(
    R.filter(R.hasIn('subPosts')),
    R.map(R.applySpec({
      label: R.prop('fullName'),
      value: R.prop('id')
    }))
  )(workstations)

  const handleDelete = async () => {
    if (window.confirm(t('workstationEdit.deleteWorkstation'))) {
      try {
        setLoading(true)
        await props.deleteWorkstation()
        setLoading(false)
        props.closeModal()
        toast.success(t('workstationEdit.deleteWorkstationSuccess'), { containerId: 'statuses', autoClose: 15e3 })
      } catch (err) {
        setLoading(false)
        console.error(err)
        toast.error(t('workstationEdit.deleteWorkstationFail'), { containerId: 'statuses', autoClose: 15e3 })
        errorHandler(err)
      }
    }
  }

  const isLoading = submitting || loading

  return (
    <form onSubmit={handleSubmit} css={{
      width: '100%',
      minHeight: '50vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }} >
      <div css={{
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: '100%'
      }}>
        <div css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridTemplateRow: '1fr 1fr 1fr 1fr',
          gridGap: 20,
          maxHeight: '50vh'
        }}>
          <FieldComponent
            name='name'
            valueType='text'
            label={t(`workstationEdit.name`)}
          />
          <FieldComponent
            name='startsAt'
            valueType='time'
            label={t(`workstationEdit.startsAt`)}
          />
          <FieldComponent
            name='finishesAt'
            valueType='time'
            label={t(`workstationEdit.finishesAt`)}
          />
          <FieldComponent
            name='number'
            valueType='text'
            label={t(`workstationEdit.number`)}
          />
          <FieldComponent
            name='breakLength'
            valueType='number'
            label={t(`workstationEdit.breakLength`)}
          />
          <FieldComponent
            name='breakAt'
            valueType='time'
            label={t(`workstationEdit.breakAt`)}
          />
          <FieldComponent
            name='parentAssemblyPostId'
            valueType='select'
            options={workStationsAsOptions}
            label={t(`workstationEdit.parentAssemblyPostId`)}
          />
          <FieldComponent
            name='subindex'
            valueType='text'
            label={t(`workstationEdit.subindex`)}
          />
          {!props.parentAssemblyPostIdValue && (
            <FieldComponent
              name='isServiceAllowed'
              valueType='checkbox'
              label={t(`workstationEdit.isServiceAllowed`)}
            />
          )}
        </div>
      </div>
      <Flex justifyContent={'space-between'} flexDirection={'row'} flexWrap={'nowrap'} pt={8}>
        {props.station.id && (
          <div css={{ flexGrow: 1 }}>
            <Button
              color={theme.colors.red}
              onClick={handleDelete}
              withIcon
              icon={isLoading ? Loader : Cancel}
              disabled={isLoading}
              iconProps={{ fill: theme.colors.white }}
            >
              {t(`workstationEdit.delete`)}
            </Button>
          </div>
        )}
        <div css={{ flexGrow: 3 }}>
          <Button
            color={theme.colors.navyBlue}
            onClick={handleSubmit}
            disabled={isLoading}
            withIcon
            icon={isLoading ? Loader : Checkmark}
            iconProps={{ fill: theme.colors.white }}
          >
            {t(`workstationEdit.save`)}
          </Button>
        </div>
      </Flex>
    </form>
  )
}

export default WorkstationEditForm
