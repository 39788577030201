import APIInterceptor from './APIInterceptor'

const StocktakingService = {
  issueStocktakingForItem: (itemCode) => {
    return APIInterceptor.post(`/items/${itemCode}/stocktaking`)
  },
  getStocktakingIssues: () => {
    return APIInterceptor.get('/items/stocktaking')
  },
  registerInventoryStocktaking: ({ stocktakingIssueId, inventoryId, quantity }) => {
    return APIInterceptor.post(`/items/stocktaking/${stocktakingIssueId}/${inventoryId}`, { quantity })
  },
  deleteStocktakingOrder: stocktakingOrderId => {
    return APIInterceptor.delete(`/items/stocktaking/${stocktakingOrderId}`)
  },
  issueStocktakingForMultipleItems: itemsArray => {
    return APIInterceptor.post(`/items/stocktaking/`, { item_codes: itemsArray })
  }
}

export default StocktakingService
