/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Component } from 'react'
import OrderDetails from 'components/OrderDetails'
import Container from 'components/Container'
import { transformToArray } from 'helpers/redux'

class OrdersList extends Component {
  render () {
    const { orders, currentItem, selectOrder } = this.props
    return (
      <div>
        {orders.map(
          (order, index) =>
            transformToArray(order.items).map(
              orderItem => <OrderDetails
                key={orderItem.id}
                order={order}
                currentItem={currentItem}
                orderItem={orderItem}
                index={index}
                selectOrder={selectOrder}
              />
            )
        )
        }
        {!orders.length &&
          <Container>
            Brak zamówień
          </Container>
        }
      </div>
    )
  }
}

export default OrdersList
