/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment } from 'react'
import Container from 'components/Container'
import * as R from 'ramda'

const DeliveredItemDescription = (props) => {
  return (
    <Fragment>
      <Container flex>
        <h3>{R.prop('fullIndex', props.item)}</h3>
        <h3 css={{ marginLeft: 'auto' }}>{R.prop('quantity', props.item)}</h3>
      </Container>
      <Container flex>
        <div>{R.path(['item', 'name'], props.item)}</div>
      </Container>
    </Fragment>
  )
}

export default DeliveredItemDescription
