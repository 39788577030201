import { connect } from 'react-redux'
import { getBomItems, getBomItemsQuantities, updateWeeklyPlans } from 'ducks/weeklyPlans'
import { getBomItemsAsArray, getBomItemsQuantitiesSelector, getFetchingYears } from 'selectors/weeklyPlans'
import WeeklyPlans from 'views/WeeklyPlans/WeeklyPlans'

const mapStateToProps = state => ({
  bomItems: getBomItemsAsArray(state),
  bomItemsQuantities: getBomItemsQuantitiesSelector(state),
  fetchingYears: getFetchingYears(state)
})

const mapDispatchToProps = dispatch => ({
  getBomItems: (year) => dispatch(getBomItems(year)),
  getBomItemsQuantities: () => dispatch(getBomItemsQuantities()),
  updateWeeklyPlans: data => dispatch(updateWeeklyPlans(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyPlans)
