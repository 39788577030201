import WarehouseOrdersList from 'views/WarehouseOrdersList'
import { connect } from 'react-redux'
import { deleteInternalOrder, getPersonalOrders } from 'ducks/internalOrders'
import { getInternalOrdersAsArray } from 'selectors/internalOrders'
import { deleteSubmittedItem, listItemSubmissions } from 'ducks/itemsSubmissions'
import { getLatestHandOversAsArray } from 'selectors/latestHandOvers'

const mapStateToProps = state => ({
  orders: getInternalOrdersAsArray(state),
  latestHandOvers: getLatestHandOversAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  getPersonalOrders: () => dispatch(getPersonalOrders()),
  listItemSubmissions: () => dispatch(listItemSubmissions()),
  deleteInternalOrder: (itemId, orderId) => dispatch(deleteInternalOrder(itemId, orderId)),
  deleteSubmittedItem: (handOverId) => dispatch(deleteSubmittedItem(handOverId))
})

export default connect(mapStateToProps, mapDispatchToProps)(WarehouseOrdersList)
