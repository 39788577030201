import { compose } from 'redux'
import { connect } from 'react-redux'
import { isCurrentStationServiceAllowed } from 'selectors/productionStations'
import ServiceGetDeviceScreen from 'views/ServiceGetDeviceScreen'
import { withRouter } from 'react-router-dom'
import { getProductionStations } from 'ducks/productionStations'

const mapDispatchToProps = dispatch => ({
  getProductionStations: () => dispatch(getProductionStations())
})
const mapStateToProps = state => ({
  isServiceAllowed: isCurrentStationServiceAllowed(state)
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(ServiceGetDeviceScreen)
