import ProductionStations from 'views/ProductionStations/ProductionStations'
import { connect } from 'react-redux'
import {
  getProductionStations,
  getDailyPlans,
  getWeeklyAndDailyPlansForWeek,
  storeDailyPlansForWeek,
  setServiceRightOnStation
} from 'ducks/productionStations'
import {
  getDailyPlansAsArray,
  getProductionStationsAsArray,
  getWeeklyAndDailyPlansForWeekAsArray
} from 'selectors/productionStations'
import { compose } from 'redux'

const mapStateToProps = state => {
  return ({
    productionStations: getProductionStationsAsArray(state),
    dailyPlans: getDailyPlansAsArray(state),
    plans: getWeeklyAndDailyPlansForWeekAsArray(state)
  })
}

const mapDispatchToProps = dispatch => ({
  getProductionStations: (date) => dispatch(getProductionStations({ users: 1, date })),
  getDailyPlans: date => dispatch(getDailyPlans(date)),
  getWeeklyAndDailyPlansForWeek: compose(dispatch, getWeeklyAndDailyPlansForWeek),
  storeDailyPlansForWeek: compose(dispatch, storeDailyPlansForWeek),
  setServiceRightOnStation: data => dispatch(setServiceRightOnStation(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductionStations)
