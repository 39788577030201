import SerialNumberFormatsScreen from 'views/SerialNumberFormatsScreen'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getSerialNumberFormatsAsArray } from 'selectors/serialNumberFormats'
import {
  createSerialNumberFormat,
  deleteSerialNumberFormat,
  getSerialNumberFormats,
  updateSerialNumberFormat
} from 'ducks/serialNumberFormats'

const mapStateToProps = state => ({
  serialNumberFormats: getSerialNumberFormatsAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  getSerialNumberFormats: compose(dispatch, getSerialNumberFormats),
  deleteSerialNumberFormat: compose(dispatch, deleteSerialNumberFormat),
  createSerialNumberFormat: compose(dispatch, createSerialNumberFormat),
  updateSerialNumberFormat: compose(dispatch, updateSerialNumberFormat)
})

export default connect(mapStateToProps, mapDispatchToProps)(SerialNumberFormatsScreen)
