export const stopPropagation = (e) => {
  e.stopPropagation()
}

export const moveCursorToEnd = el => {
  if (typeof el.selectionStart === 'number') {
    el.selectionStart = el.selectionEnd = el.value.length
  } else if (typeof el.createTextRange !== 'undefined') {
    // eslint-disable-next-line no-unused-expressions
    el?.focus()
    const range = el.createTextRange()
    range.collapse(false)
    range.select()
  }
}
