import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { includesInArray } from 'helpers/array'
import Routes from 'constants/Routes'

const PrivateRoute = ({ component: Component, roles, allowedRoles, authenticated, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      switch (true) {
        case authenticated && includesInArray(allowedRoles, roles):
          return <Component {...props} />
        default:
          return (
            <Redirect to={{
              pathname: Routes.mainMenu,
              state: { from: props.location }
            }} />
          )
      }
    }} />
  )
}

PrivateRoute.defaultProps = {
  allowedRoles: []
}

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
  roles: state.user.roles || []
})

export default withRouter(connect(mapStateToProps)(PrivateRoute))
