/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useState } from 'react'
import ItemDescription from 'components/ItemDescription'
import Breadcrumb from 'components/Breadcrumb'
import { useTranslation } from 'react-i18next'
import LabelledDivider from 'components/LabelledDivider'
import InventoryTabs from 'components/InventoryTabs'
import HandOverFormConnector from 'connectors/forms/HandOverFormConnector'
import LoadingOverlay from 'components/LoadingOverlay'

const HandOverView = (props) => {
  const [selectedInventory, select] = useState(null)
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    setLoading(true)
    await props.getInventoryStateForItem(props.match.params.itemCode)
    setLoading(false)
  }, [props.match.params.itemCode])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onInventoryTap = (inventory) => {
    select(inventory)
  }

  const handOverItem = ({ quantity, inventoryId, destination: { value: purpose } }) => {
    props.history.replace(`/warehouse/hand-over/${props.match.params.itemCode}/finalize`, {
      quantity,
      inventoryId,
      purpose
    })
  }

  const inventories = props.currentItem?.inventory || []

  return (
    <div>
      <Breadcrumb>{t('warehouse.handOver')}</Breadcrumb>
      <ItemDescription item={props.currentItem} />
      {loading && <LoadingOverlay />}
      <LabelledDivider text={t('common.locations')} />
      <InventoryTabs
        inventories={inventories}
        currentItem={props.currentItem}
        onInventoryTap={onInventoryTap}
      />
      {selectedInventory &&
        <HandOverFormConnector
          onSubmit={handOverItem}
          initialValues={{ quantity: selectedInventory.quantity, inventoryId: selectedInventory.id }}
        />
      }
    </div>
  )
}

export default HandOverView
