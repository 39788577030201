import NotificationsService from 'services/NotificationsService'
import { transformById } from 'helpers/redux'
import { dissoc } from 'ramda'

const GET_UNREAD_NOTIFICATIONS = Symbol('GET_UNREAD_NOTIFICATIONS')
const GET_UNREAD_NOTIFICATIONS_SUCCESS = Symbol('GET_UNREAD_NOTIFICATIONS_SUCCESS')
const GET_UNREAD_NOTIFICATIONS_FAILED = Symbol('GET_UNREAD_NOTIFICATIONS_FAILED')

const requestGetUnreadNotifications = () => ({ type: GET_UNREAD_NOTIFICATIONS })
const getUnreadNotificationsSuccess = (data) => ({ type: GET_UNREAD_NOTIFICATIONS_SUCCESS, data })
const getUnreadNotificationsFailed = () => ({ type: GET_UNREAD_NOTIFICATIONS_FAILED })

export const getUnreadNotifications = () => {
  return async dispatch => {
    dispatch(requestGetUnreadNotifications())
    try {
      const { data } = await NotificationsService.getUnreadNotifications()
      return dispatch(getUnreadNotificationsSuccess(data))
    } catch (e) {
      return dispatch(getUnreadNotificationsFailed(e))
    }
  }
}

const MARK_NOTIFICATION_AS_READ = Symbol('MARK_NOTIFICATION_AS_READ')
const MARK_NOTIFICATION_AS_READ_SUCCESS = Symbol('MARK_NOTIFICATION_AS_READ_SUCCESS')
const MARK_NOTIFICATION_AS_READ_FAILED = Symbol('MARK_NOTIFICATION_AS_READ_FAILED')

const requestMarkNotificationAsRead = () => ({ type: MARK_NOTIFICATION_AS_READ })
const markNotificationAsReadSuccess = (id) => ({ type: MARK_NOTIFICATION_AS_READ_SUCCESS, id })
const markNotificationAsReadFailed = () => ({ type: MARK_NOTIFICATION_AS_READ_FAILED })

export const markNotificationAsRead = (id) => {
  return async dispatch => {
    dispatch(requestMarkNotificationAsRead())
    try {
      await NotificationsService.markNotificationAsRead(id)
      return dispatch(markNotificationAsReadSuccess(id))
    } catch (e) {
      return dispatch(markNotificationAsReadFailed(e))
    }
  }
}

const initState = {
  entries: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        entries: transformById(action.data)
      }
    case MARK_NOTIFICATION_AS_READ_SUCCESS:
      return {
        ...state,
        entries: dissoc(action.id, state.entries)
      }
    default:
      return state
  }
}
