import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import AddLocationForm from 'forms/AddLocationForm'
import { createLocation, getLocationsList } from 'ducks/locations'
import validate from 'forms/validators/AddLocationFormValidator'

const formName = 'addLocationForm'

const Form = reduxForm({
  form: formName,
  validate,
  onSubmit: async (values, dispatch, ownProps) => {
    try {
      const formValues = {
        ...values,
        type: ownProps.locationType,
        parentId: ownProps.parentId
      }
      const newLocation = await dispatch(createLocation(formValues))
      await dispatch(getLocationsList())
      if (typeof ownProps.addLocationToState === 'function') ownProps.addLocationToState(ownProps.locationType, newLocation)
      if (typeof ownProps.closeModal === 'function') ownProps.closeModal()
    } catch (err) {
      console.error(err)
    }
  }
})(AddLocationForm)

export default connect(null)(Form)
