/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'components/Button'
import { machineStatusGetterByProcessName } from 'helpers/productionStation'

// styles for VacuumTestSteeringBox
const wrapperCss = css`
      background-color: rgba(255,255,255,0.9);
      padding: 8px;
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
`
const statusWrapperCss = css`
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
`

const buttonWrapperCss = css`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    height: 100%;
`

const VacuumTestSteeringBox = ({ serialNumber, lastTestActionStatus, processName, onLaunchTest, onStopTest, onGetReport, currentReport }) => {
  const { t } = useTranslation()

  const vacuumTest = currentReport?.vacuumTest[1] || 0
  const stabilization = currentReport?.stabilization[1] || 0

  const leakage = Math.abs(Number(vacuumTest) - Number(stabilization)).toFixed(3)

  const isLaunchButtonDisabled = processName !== 'IDLE'
  const isStopButtonDisabled = processName !== 'VACUUM'
  const isGetReportButtonDisabled = processName !== 'IDLE'

  return (
    <Fragment>
      <div css={css`display: flex; justify-content: center; width: 100%;`}>
        <h4>Sterowanie testem Vacuum</h4>
      </div>
      <div css={wrapperCss}>
        <div css={buttonWrapperCss}>
          <Button css={{ width: 'auto', margin: 10 }} disabled={isLaunchButtonDisabled} onClick={onLaunchTest}>Uruchom test ponownie</Button>
          <Button css={{ width: 'auto', margin: 10 }} disabled={isStopButtonDisabled} onClick={onStopTest}>Zatrzymaj test</Button>
          <Button css={{ width: 'auto', margin: 10 }} disabled={isGetReportButtonDisabled} onClick={onGetReport}>Wydrukuj raport</Button>
        </div>
        <div css={statusWrapperCss}>
          <p><b>Numer seryjny:</b></p>
          <p css={{ marginBottom: '5px' }}>{` ${serialNumber || '----------'} `}</p>
          <p><b>Stan maszyny:</b></p>
          <p css={{ marginBottom: '5px' }}>{` ${machineStatusGetterByProcessName(processName) || '----------'} `}</p>
          <p><b>Stan testu:</b></p>
          <p css={{ marginBottom: '5px' }}>{` ${lastTestActionStatus || '----------'} `}</p>
          {currentReport && (
            currentReport?.reportStatus === 'ABORTED BY USER'
              ? <h2>{t('productionStation.testAbortedByUser')}</h2>
              : <h2>Wynik testu dla SN: {currentReport?.serialNumber} . Wyciek: {leakage === 'NaN' ? '0' : leakage}bar</h2>
          )}
        </div>
      </div>
    </Fragment>
  )
}

export default VacuumTestSteeringBox
