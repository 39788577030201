/** @jsx jsx */
import { jsx } from '@emotion/core'
import Button from 'components/Button'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { ReactComponent as Refresh } from 'assets/refresh.svg'
import { useTranslation } from 'react-i18next'

const RefreshButton = (props) => {
  const { t } = useTranslation()
  return (
    <Button
      iconProps={{ style: { fill: 'white', width: 20, height: 20 } }}
      onClick={props.refreshFunction}
      disabled={props.isLoading}
      withIcon
      icon={props.isLoading ? Loader : Refresh}
    >
      {t('weeklyPlansForeman.refresh')}
    </Button>
  )
}

export default RefreshButton
