import ProductionServiceService from 'services/ProductionServiceService'
import * as R from 'ramda'

const GET_DEVICE_FOR_SERVICE = Symbol('GET_DEVICE_FOR_SERVICE')
const GET_DEVICE_FOR_SERVICE_SUCCESS = Symbol('GET_DEVICE_FOR_SERVICE_SUCCESS')
const GET_DEVICE_FOR_SERVICE_FAILED = Symbol('GET_DEVICE_FOR_SERVICE_FAILED')

const getDeviceForServiceRequest = () => ({ type: GET_DEVICE_FOR_SERVICE })
const getDeviceForServiceSuccess = (data, serialNumber) => ({ type: GET_DEVICE_FOR_SERVICE_SUCCESS, data, serialNumber })
const getDeviceForServiceFailed = () => ({ type: GET_DEVICE_FOR_SERVICE_FAILED })

export const getDeviceForService = (serialNumber) => {
  return async dispatch => {
    dispatch(getDeviceForServiceRequest())
    try {
      const { data: { mainAssembly: data } } = await ProductionServiceService.getDeviceForService(serialNumber)
      dispatch(getDeviceForServiceSuccess(data, serialNumber))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(getDeviceForServiceFailed(e))
      return Promise.reject(e)
    }
  }
}

const SERVICE_DEVICE = Symbol('SERVICE_DEVICE')
const SERVICE_DEVICE_SUCCESS = Symbol('SERVICE_DEVICE_SUCCESS')
const SERVICE_DEVICE_FAILED = Symbol('SERVICE_DEVICE_FAILED')

const serviceDeviceRequest = () => ({ type: SERVICE_DEVICE })
const serviceDeviceSuccess = (data) => ({ type: SERVICE_DEVICE_SUCCESS, data })
const serviceDeviceFailed = () => ({ type: SERVICE_DEVICE_FAILED })

export const serviceDevice = (serialNumber, payload) => {
  return async dispatch => {
    dispatch(serviceDeviceRequest())
    try {
      const { data } = await ProductionServiceService.serviceDevice(serialNumber, payload)
      dispatch(serviceDeviceSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(serviceDeviceFailed(e))
      return Promise.reject(e)
    }
  }
}

const START_SERVICE_ACTION_REQUEST = Symbol('START_SERVICE_ACTION_REQUEST')
const START_SERVICE_ACTION_SUCCESS = Symbol('START_SERVICE_ACTION_SUCCESS')
const START_SERVICE_ACTION_FAILURE = Symbol('START_SERVICE_ACTION_FAILURE')

const startServiceRequest = () => ({ type: START_SERVICE_ACTION_REQUEST })
const startServiceSuccess = (data) => ({ type: START_SERVICE_ACTION_SUCCESS, data })
const startServiceFailure = () => ({ type: START_SERVICE_ACTION_FAILURE })

export const startService = (serialNumber) => {
  return async dispatch => {
    dispatch(startServiceRequest())
    try {
      const { data } = await ProductionServiceService.startDeviceService(serialNumber)
      dispatch(startServiceSuccess(data))
      return Promise.resolve(data)
    } catch (error) {
      dispatch(startServiceFailure())
      return Promise.reject(error)
    }
  }
}

const FINISH_SERVICE_ACTION_REQUEST = Symbol('FINISH_SERVICE_ACTION_REQUEST')
const FINISH_SERVICE_ACTION_SUCCESS = Symbol('FINISH_SERVICE_ACTION_SUCCESS')
const FINISH_SERVICE_ACTION_FAILURE = Symbol('FINISH_SERVICE_ACTION_FAILURE')

const finishServiceRequest = () => ({ type: FINISH_SERVICE_ACTION_REQUEST })
const finishServiceSuccess = () => ({ type: FINISH_SERVICE_ACTION_SUCCESS })
const finishServiceFailure = () => ({ type: FINISH_SERVICE_ACTION_FAILURE })

export const finishService = (serialNumber, payload) => {
  return async dispatch => {
    dispatch(finishServiceRequest())
    try {
      const { data } = await ProductionServiceService.finishDeviceService(serialNumber, payload)
      dispatch(finishServiceSuccess())
      return Promise.resolve(data)
    } catch (error) {
      dispatch(finishServiceFailure())
      return Promise.reject(error)
    }
  }
}

const initState = {
  entries: {},
  currentService: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_DEVICE_FOR_SERVICE_SUCCESS:
      return R.evolve({
        entries: R.assoc(action.serialNumber, action.data)
      }, state)
    case START_SERVICE_ACTION_SUCCESS:
      return R.evolve({
        currentService: R.always(action.data)
      }, state)
    case SERVICE_DEVICE_SUCCESS:
      return {
        ...state
      }
    default:
      return state
  }
}
