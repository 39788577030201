import StructuredCatalogueView from 'views/Catalogue/StructuredCatalogueView'
import { connect } from 'react-redux'
import { getBomIfNeeded } from 'ducks/bom'
import { getBomsAsArray } from 'selectors/bom'
import { getUserRoles } from 'selectors/user'

const mapStateToProps = state => ({
  boms: getBomsAsArray(state),
  userRoles: getUserRoles(state)
})

const mapDispatchToProps = dispatch => ({
  getBomIfNeeded: (bomCode) => dispatch(getBomIfNeeded(bomCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(StructuredCatalogueView)
