/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import Container from 'components/Container'
import Divider from 'components/Divider'

const textCss = (fontSize = 10) => ({
  fontWeight: 'bold',
  fontSize: `${fontSize}px`,
  textTransform: 'uppercase',
  minWidth: 'fit-content'
})

const LabelledDivider = (props) => {
  return (
    <Container flex centerItems noMargin={props.noMargin} className={props.className}>
      <span css={{ ...textCss(props.thickness === 'thick' ? 20 : 10), ...props.textCss }}>{props.text}</span>
      <Divider
        noMargin
        color={props.color}
        medium={props.thickness === 'medium'}
        thin={props.thickness === 'thin'}
        thick={props.thickness === 'thick'}
        css={{ marginLeft: 8 }}
      />
    </Container>
  )
}

LabelledDivider.propTypes = {
  className: PropTypes.string,
  noMargin: PropTypes.bool,
  text: PropTypes.string,
  thickness: PropTypes.oneOf(['medium', 'thin', undefined, 'thick'])
}

LabelledDivider.defaultProps = {
  thickness: 'medium'
}

export default LabelledDivider
