/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Field, Form } from 'redux-form'
import LabelledDivider from 'components/LabelledDivider'
import theme from 'config/theme'
import { Box, Flex } from '@rebass/emotion'
import Input from 'components/Input'
import Button from 'components/Button'
import { ReactComponent as HandOverIcon } from 'assets/hand-over.svg'
import { ReactComponent as OrderIcon } from 'assets/order.svg'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

const textCss = {
  fontSize: 20,
  fontWeight: '100',
  color: theme.colors.lightGrey
}

const buttonCss = css`
  width: 45%;
  margin: 0;
  font-size: 20px;
`

const inputCss = css`
  padding: 10px;
  margin-top: 25px;
  color: ${theme.colors.darkGrey};
  border-color: ${theme.colors.darkGrey};
  font-size: 20px;
  width: 95%;
`

const availableCss = css`
  margin-top: ${theme.space[8]};
`

const errorCss = {
  marginTop: theme.space[8]
}

const InternalOrderForm = (props) => {
  const { t } = useTranslation()
  useEffect(() => {
    props.initialize(props.initialValues)
    // !props.availableQuantity && props.getItemDetails(props.initialValues.code)
  }, [])

  // useEffect(() => {
  //   // props.getItemDetails(props.initialValues.code)
  // }, [props.availableQuantity])

  const [loadingOrder, setOrderLoading] = useState(false)
  const [loadingHandOver, setHandOverLoading] = useState(false)
  const [orderButtonColor, setOrderButtonColor] = useState(theme.colors.lightGrey)
  const [handOverButtonColor, setHandOverButtonColor] = useState(theme.colors.lightGrey)

  const submitOrder = async () => {
    try {
      setOrderLoading(true)
      await props.onSubmitOrder(props.formValues)
      // await props.getItemDetails(props.formValues.code)
      setOrderLoading(false)
      setOrderButtonColor(theme.colors.successGreen)
      props.initialize({ ...props.initialValues, quantity: '' })
    } catch (e) {
      setOrderLoading(false)
      setOrderButtonColor(theme.colors.error)
    }
  }

  const submitHandOver = async () => {
    try {
      setHandOverLoading(true)
      await props.onSubmitHandOver(props.formValues)
      // await props.getItemDetails(props.formValues.code)
      setHandOverLoading(false)
      setHandOverButtonColor(theme.colors.successGreen)
      props.initialize({ ...props.initialValues, quantity: '' })
    } catch (e) {
      setHandOverLoading(false)
      setHandOverButtonColor(theme.colors.error)
    }
  }

  const handleSubmit = (type) => async () => props.handleSubmit(type === 'order' ? submitOrder : submitHandOver)()

  return (
    <Form onSubmit={() => {}}>
      <Box mt={8}>
        <LabelledDivider thickness={'thin'} text='Liczba sztuk' noMargin textCss={textCss} />
        <Field
          component={Input}
          css={inputCss}
          numericKeyboard
          errorCss={errorCss}
          name='quantity'
        />
        <Box
          as='h3'
          css={availableCss}
        >
          {t('warehouse.orders.available')}: { props.availableQuantity }
        </Box>
        <Field
          component={Input}
          name='code'
          type='hidden'
        />
      </Box>
      <Flex mt={8} justifyContent={'space-between'}>
        <Button
          color={handOverButtonColor}
          css={buttonCss}
          withIcon
          disabled={loadingHandOver}
          icon={loadingHandOver ? Loader : HandOverIcon}
          onClick={handleSubmit('handOver')}
        >
          {t('warehouse.orders.handOver')}
        </Button>
        <Button
          color={orderButtonColor}
          css={buttonCss}
          withIcon
          disabled={loadingOrder}
          icon={loadingOrder ? Loader : OrderIcon}
          onClick={handleSubmit('order')}
          iconProps={{ fill: theme.colors.white }}
        >
          {t('warehouse.orders.order')}
        </Button>
      </Flex>
    </Form>
  )
}

export default InternalOrderForm
