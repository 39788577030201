import HandOverFinalizeScreen from 'views/HandOverFinalizeScreen'
import { connect } from 'react-redux'
import { handOverItemFromInventory } from 'ducks/warehouseState'
import { getInventoryById } from 'selectors/warehouseInventories'
import { getCurrentItem } from 'selectors/warehouseState'

const mapStateToProps = (state, ownProps) => ({
  currentItem: getCurrentItem(state),
  inventory: getInventoryById(state, ownProps.location.state.inventoryId)
})

const mapDispatchToProps = dispatch => ({
  handOverItemFromInventory: ({ quantity, inventoryId, purpose }) => {
    return dispatch(handOverItemFromInventory({ quantity, inventoryId, purpose }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(HandOverFinalizeScreen)
