const validate = values => {
  const errors = {}

  if (!values.name) {
    errors.name = 'To pole jest wymagane'
  }

  return errors
}

export default validate
