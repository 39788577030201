import StocktakingIssuesList from 'views/StocktakingIssuesList'
import StocktakingIssuesListZebra from 'views/StocktakingIssuesList.zebra'
import { connect } from 'react-redux'
import { deleteStocktakingOrder, getStocktakingIssues } from 'ducks/stocktaking'
import { getStocktakingIssuesAsArray } from 'selectors/stocktakingIssues'
import isZebraBrowser from 'helpers/isZebra'

const Component = isZebraBrowser() ? StocktakingIssuesListZebra : StocktakingIssuesList

const mapStateToProps = state => ({
  stocktakingIssues: getStocktakingIssuesAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  getStocktakingIssues: () => dispatch(getStocktakingIssues()),
  deleteStocktakingOrder: stocktakingIssueId => dispatch(deleteStocktakingOrder(stocktakingIssueId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Component)
