export const SERVICE_STATUSES = {
  ABANDONED: 'abandoned',
  FIXED: 'fixed',
  SALVAGED: 'salvaged',
  DESTROYED: 'destroyed'
}

export const AVAILABLE_SERVICE_OPTIONS = [
  {
    value: SERVICE_STATUSES.FIXED,
    label: 'urządzenie naprawione'
  },
  // {
  //   value: SERVICE_STATUSES.SALVAGED,
  //   label: 'urządzenie rozmontowane'
  // },
  {
    value: SERVICE_STATUSES.DESTROYED,
    label: 'urządzenie zniszczone'
  },
  {
    value: SERVICE_STATUSES.ABANDONED,
    label: 'naprawa porzucona'
  }
]
