/** @jsx jsx */
import { css, Global, jsx } from '@emotion/core'
import styled from '@emotion/styled'
import { Box, Flex } from '@rebass/emotion'
import { ReactComponent as Edit } from 'assets/edit.svg'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { ReactComponent as Save } from 'assets/save.svg'
import AddItemModal from 'components/AddItemModal'
import Button from 'components/Button'
import EditStatus from 'components/EditStatus'
import Modal from 'components/Modal'
import NodeForm from 'components/NodeForm'
import theme from 'config/theme'
import bomImportsActionTypes from 'constants/bomImportsActionTypes'
import Routes from 'constants/Routes'
import { errorHandler } from 'helpers/errorHandler'
import { transformById } from 'helpers/redux'
import useBomModifications from 'hooks/useBomModifications'
import useInput from 'hooks/useInput'
import * as R from 'ramda'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Prompt } from 'react-router-dom'
import SortableTree, { map as mapTreeView } from 'react-sortable-tree'
import 'react-sortable-tree/style.css'
import { toast } from 'react-toastify'

const sortableTreeOverwriteCss = css`
  .rst__rowWrapper {
    padding: 0;
  }

  .rst__placeholder {
    &:before {
      z-index: 1;
    }
  }
  
  .rst__rowWrapper .rst__rowSearchMatch {
    outline: none;
  }

  .rst__rowWrapper .rst__rowSearchFocus {
    outline: none;
  }
  
  .rst__rowWrapper .rst__rowSearchFocus .rst__rowContents {
    box-shadow: inset 0 0 15px ${theme.colors.orange};
  }
`

const modalStyles = css`
  min-height: 100px;
  min-width: unset;
`

const labelCss = (button) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  height: '100%',
  marginRight: 12,
  cursor: button ? 'pointer' : 'default'
})

const BomMenuBotton = styled(Button)({ width: '100%', margin: '0 0 10px' })

export const getExpandedDepth = (node, depth = 0) => {
  switch (true) {
    case !node:
      return 0
    case !node.children || (node.children.length && !node.expanded):
      return depth
    case typeof node.children === 'function':
      return depth + 1
    default:
      return node.children.reduce((deepest, child) => Math.max(deepest, getExpandedDepth(child, depth + 1)), depth)
  }
}

const baseStyles = {
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  cursor: 'pointer'
}

const bomsButtons = (handleIgnoreElement, handleUnIgnoreElement, selectNode, node) => {
  const isIgnored = node.original?.processingDetails?.status === bomImportsActionTypes.IGNORE
  return [
    <div css={{ ...baseStyles, width: 51 }} onClick={selectNode}>
      {R.pathOr('', ['original', 'processingDetails', 'quantity'], node)}
    </div>,
    <div css={{ ...baseStyles, width: 120 }}>
      <Button
        css={{
          width: 90,
          padding: 6
        }}
        color={isIgnored ? theme.colors.successGreen : theme.colors.violet}
        onClick={isIgnored ? handleUnIgnoreElement : handleIgnoreElement}
      >
        {isIgnored ? 'odblokuj' : 'zablokuj'}
      </Button>
    </div>,
    <div css={{ ...baseStyles, width: 57 }} onClick={selectNode}>
      <Edit fill={theme.colors.black} width={25} height={25} />
    </div>,
    <div css={{ display: 'flex', alignItems: 'center', marginLeft: 12, height: '100%', minWidth: 500 }}>
      <span>{node?.title?.defaultVendor}</span>
    </div>
  ]
}

const customSearchMethod = ({ node, searchQuery }) => searchQuery && (
  node.title.name.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
  node.title.index.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1 ||
  node.title.defaultVendor.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1
)

const BomsEditScreen = (props) => {
  const {
    getBomImportDetails,
    match: { params: { bomImportId } },
    bomImport,
    saveBomImport,
    generateBomFromImport,
    history
  } = props
  const { t } = useTranslation()
  const [treeView, setTreeView] = useState([])
  const [cache, setCache] = useState([])
  const [searchQuery, changeSearchQuery] = useInput('')
  const [searchFocusIndex, setSearchFocusIndex] = useState(0)
  const [searchFoundCount, setSearchFoundCount] = useState(null)
  const [hasChanges, setHasChanges] = useState(false)

  const mainTreeDepth = getExpandedDepth(treeView[0] || {})
  const cacheTreeDepth = getExpandedDepth(cache[0] || {})

  useEffect(() => {
    bomImportId && getBomImportDetails(bomImportId)
  }, [bomImportId, getBomImportDetails])

  useEffect(() => {
    const getComparisonKeys = (node) => R.applySpec({
      children: R.pipe(
        R.prop('children'),
        R.map(getComparisonKeys)
      ),
      original: R.prop('original')
    })(node)

    const comparisonTree = mapTreeView({
      treeData: treeView,
      getNodeKey: R.path(['node', 'original', 'id']),
      callback: ({ node }) => getComparisonKeys(node),
      ignoreCollapsed: false
    })

    const comparisonBomImport = R.map(getComparisonKeys, [bomImport])

    setHasChanges(R.not(R.equals(comparisonTree, comparisonBomImport)))
  }, [bomImport, treeView])

  useEffect(() => {
    setTreeView([bomImport])
  }, [bomImport, setTreeView])

  const selectPrevMatch = () => {
    const newFocus = searchFocusIndex !== null
      ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
      : searchFoundCount - 1
    setSearchFocusIndex(newFocus)
  }

  const selectNextMatch = () => {
    const newMatch = searchFocusIndex !== null ? (searchFocusIndex + 1) % searchFoundCount : 0
    setSearchFocusIndex(newMatch)
  }

  const searchFinishCallback = matches => {
    setSearchFoundCount(matches.length)
    setSearchFocusIndex(matches.length > 0 ? searchFocusIndex % matches.length : 0)
  }

  const [
    handleIgnoreElement,
    handleUnIgnoreElement,
    handleMoveNode,
    handleChangeNode
  ] = useBomModifications(treeView, setTreeView)

  const handleReset = () => {
    setTreeView([bomImport])
  }

  const [isSaving, setSaving] = useState(false)
  const [isGenerating, setGenerating] = useState(false)

  const handleSave = async () => {
    const mapChild = part => {
      return ({
        ...part.original,
        parts: {
          ...transformById(R.map(mapChild, part.children))
        }
      })
    }
    const mapPartsToSave = (parts) => R.map(mapChild, parts)
    const partsToSave = mapPartsToSave(treeView)

    const data = R.pathOr([], [0, 'parts'], partsToSave)

    const mainComponent = R.pipe(
      R.propOr({}, 0),
      R.dissoc('parts')
    )(partsToSave)

    const payload = R.assoc(mainComponent.id, mainComponent, data)

    if (cache.length === 0) {
      try {
        setSaving(true)
        await saveBomImport(bomImportId, payload)
        setSaving(false)
        toast.success(t('bomImports.successSave'), { containerId: 'statuses' })
      } catch (e) {
        setSaving(false)
        errorHandler(e)
      }
    } else {
      return toast.error(t('bomImports.noCacheAllowed'), { containerId: 'statuses' })
    }
  }

  const handleBomGeneration = async () => {
    if (cache.length === 0) {
      try {
        setGenerating(true)
        const { newBomIds, data } = await generateBomFromImport(bomImportId)
        setGenerating(false)
        toast.success(t('bomImports.successGeneration'), { containerId: 'statuses' })
        history.push({ pathname: Routes.boms, state: { newBomIds, data } })
      } catch (e) {
        errorHandler(e)
        setGenerating(false)
      }
    } else {
      return toast.error(t('bomImports.noCacheAllowed'), { containerId: 'statuses' })
    }
  }
  const [nodeData, setNodeData] = useState(null)

  const selectNode = (node, currentPath) => () => setNodeData({ node, currentPath })
  const handleClose = () => setNodeData(null)

  const handleAddItem = (item) => {
    setCache([item, ...cache])
  }

  useEffect(() => {
    if (hasChanges) {
      window.onbeforeunload = () => true
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [hasChanges])

  return (
    <Flex flexDirection={'row'} css={{ height: 'calc(100vh - 70px - 36px - 36px)' }} pt={6} pl={6}>
      <Box css={{ flex: 3 }}>
        <Prompt
          when={hasChanges}
          message={t('bomImports.unsavedChanges')}
        />
        <Global styles={sortableTreeOverwriteCss} />
        <Flex py={4}>
          <h1 style={{ color: hasChanges ? 'orange' : 'green' }}>{t('bomImports.workingTree')}</h1>
          <form
            style={{ display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', marginLeft: 10 }}
            onSubmit={event => {
              event.preventDefault()
            }}
          >
            <input
              id='find-box'
              type='text'
              placeholder={t('bomImports.search')}
              css={{ fontSize: '1rem', padding: 10 }}
              value={searchQuery}
              onChange={changeSearchQuery}
            />
            <Button
              css={{ width: 'auto', padding: 10, margin: '0 10px' }}
              type='button'
              disabled={!searchFoundCount}
              onClick={selectPrevMatch}
            >
              &lt;
            </Button>
            <Button
              css={{ width: 'auto', padding: 10, margin: '0 10px' }}
              type='submit'
              disabled={!searchFoundCount}
              onClick={selectNextMatch}
            >
              &gt;
            </Button>
            <span>&nbsp;{searchFoundCount > 0 ? searchFocusIndex + 1 : 0}&nbsp;/&nbsp;{searchFoundCount || 0}</span>
          </form>
        </Flex>
        <SortableTree
          treeData={treeView}
          onChange={setTreeView}
          dndType={'item'}
          canDrop={({ nextParent }) => Boolean(nextParent)}
          rowHeight={44}
          searchMethod={customSearchMethod}
          searchQuery={searchQuery}
          searchFocusOffset={searchFocusIndex}
          searchFinishCallback={searchFinishCallback}
          onlyExpandSearchedNodes={searchQuery.length > 0}
          getNodeKey={R.pathOr('none', ['node', 'original', 'id'])}
          onMoveNode={handleMoveNode}
          generateNodeProps={({ node, path: currentPath }) => {
            return ({
              title: (
                <div
                  css={{
                    paddingLeft: (mainTreeDepth - (currentPath.length - 1)) * 44,
                    width: 500 + ((mainTreeDepth - (currentPath.length - 1)) * 44),
                    display: 'flex',
                    flexFlow: 'row nowrap'
                  }}
                >
                  <span css={{ flex: 1, paddingRight: 10 }}>{node.title.index}</span>
                  <span css={{
                    flex: 4,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: 10
                  }}>{node.title.name}</span>
                </div>
              ),
              buttons: R.equals(R.path(['original', 'depth'], node), '0') ? [
                <div css={labelCss()}>
                  ({t('bomImports.amount')})
                </div>,
                <div css={labelCss()}>
                  ({t('bomImports.visibility')})
                </div>,
                <div css={labelCss(true)} onClick={selectNode(node, currentPath)}>
                  ({t('bomImports.edit')})
                </div>,
                <div css={labelCss()}>
                  ({t('bomImports.vendor')})
                </div>
              ] : bomsButtons(
                handleIgnoreElement(node, currentPath),
                handleUnIgnoreElement(node, currentPath),
                selectNode(node, currentPath),
                node
              )
            })
          }}
        />
        {nodeData && (
          <Modal
            headerTitle={(
              <Fragment>
                <span css={{ paddingRight: 10 }}>{nodeData.node?.title?.index}</span>
                <span css={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  paddingRight: 10
                }}>{nodeData.node.title?.name}</span>
              </Fragment>
            )}
            onClose={handleClose}
            styles={modalStyles}
          >
            <NodeForm
              handleChangeNode={handleChangeNode}
              handleClose={handleClose}
              {...nodeData || {}}
            />
          </Modal>
        )}
      </Box>
      <Flex css={{ flex: 1 }} flexDirection='column' px={4}>
        <Box pt={6}>
          <EditStatus
            css={{ paddingLeft: 35, marginBottom: 10 }}
            status={hasChanges ? 'edited' : 'accepted'}
            acceptedText={t('bomImports.status.accepted')}
            editedText={t('bomImports.status.edited')}
          />
          <BomMenuBotton
            onClick={handleSave}
            withIcon
            color={theme.colors.navyBlue}
            iconProps={{ fill: theme.colors.white }}
            icon={isSaving ? Loader : Save}
            disabled={isSaving || !hasChanges || isGenerating}
          >
            {t('bomImports.save')}
          </BomMenuBotton>
          <BomMenuBotton
            disabled={!hasChanges}
            onClick={handleReset}
          >
            {t('bomImports.reset')}
          </BomMenuBotton>
          <BomMenuBotton
            withIcon={isGenerating}
            icon={Loader}
            disabled={isSaving || hasChanges || isGenerating}
            onClick={handleBomGeneration}
          >
            {t('bomImports.generateBom')}
          </BomMenuBotton>
        </Box>
        <Box css={{ flex: 1 }}>
          <h1 style={{ color: cache.length ? 'red' : 'inherit' }}>{t('bomImports.temporaryTree')}</h1>
          <AddItemModal
            onAddItem={handleAddItem}
          />
          <SortableTree
            treeData={cache}
            onChange={setCache}
            dndType={'item'}
            canDrop={({ nextParent }) => !nextParent}
            canNodeHaveChildren={() => false}
            rowHeight={44}
            generateNodeProps={({ node, ...rest }) => ({
              title: (
                <span
                  css={{ paddingLeft: (cacheTreeDepth - (rest.path.length - 1)) * 44 }}
                >{node.title.index}&nbsp;{node.title.name}</span>
              )
            })}
          />
        </Box>
      </Flex>
      <div id='confirmPrompt' />
    </Flex>
  )
}

export default BomsEditScreen
