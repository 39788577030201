import { connect } from 'react-redux'
import AssemblyDetailsScreen from 'views/AssemblyDetailsScreen'
import { Roles } from 'constants/Roles'

const mapStateToProps = state => ({
  isAdmin: state.user.roles.includes(Roles.ADMIN)
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(AssemblyDetailsScreen)
