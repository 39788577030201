import WorkstationsService from 'services/WorkstationsService'
import { transformById } from 'helpers/redux'
import * as R from 'ramda'

const GET_WORKSTATIONS_REQUEST = Symbol('GET_WORKSTATIONS_REQUEST')
const GET_WORKSTATIONS_SUCCESS = Symbol('GET_WORKSTATIONS_SUCCESS')
const GET_WORKSTATIONS_FAILED = Symbol('GET_WORKSTATIONS_FAILED')

const getWorkstationsRequest = () => ({ type: GET_WORKSTATIONS_REQUEST })
const getWorkstationsSuccess = payload => ({ type: GET_WORKSTATIONS_SUCCESS, payload })
const getWorkstationsFailed = error => ({ type: GET_WORKSTATIONS_FAILED, error })

export const getWorkstations = () => {
  return async dispatch => {
    dispatch(getWorkstationsRequest())
    try {
      const { data } = await WorkstationsService.getWorkstations()
      dispatch(getWorkstationsSuccess(data))
      return Promise.resolve(data)
    } catch (error) {
      dispatch(getWorkstationsFailed(error))
      return Promise.reject(error)
    }
  }
}

export const getWorkstationsIfNeeded = () => {
  return async (dispatch, getState) => {
    const workstations = getState().workstations.entries
    if (R.isEmpty(workstations)) {
      return dispatch(getWorkstations())
    }
  }
}

const ADD_WORKSTATION_REQUEST = Symbol('ADD_WORKSTATION_REQUEST')
const ADD_WORKSTATION_SUCCESS = Symbol('ADD_WORKSTATION_SUCCESS')
const ADD_WORKSTATION_FAILED = Symbol('ADD_WORKSTATION_FAILED')

const addWorkstationRequest = () => ({ type: ADD_WORKSTATION_REQUEST })
const addWorkstationSuccess = (workstationId, data) => ({ type: ADD_WORKSTATION_SUCCESS, data, workstationId })
const addWorkstationFailed = error => ({ type: ADD_WORKSTATION_FAILED, error })

export const addWorkstation = (payload) => {
  return async dispatch => {
    dispatch(addWorkstationRequest())
    try {
      const { data } = await WorkstationsService.addWorkstation(payload)
      dispatch(addWorkstationSuccess(data.id, data))
      return Promise.resolve(data)
    } catch (error) {
      dispatch(addWorkstationFailed(error))
      return Promise.reject(error)
    }
  }
}

const DELETE_WORKSTATION_BY_ID_REQUEST = Symbol('DELETE_WORKSTATION_BY_ID_REQUEST')
const DELETE_WORKSTATION_BY_ID_SUCCESS = Symbol('DELETE_WORKSTATION_BY_ID_SUCCESS')
const DELETE_WORKSTATION_BY_ID_FAILED = Symbol('DELETE_WORKSTATION_BY_ID_FAILED')

const deleteWorkstationByIdRequest = () => ({ type: DELETE_WORKSTATION_BY_ID_REQUEST })
const deleteWorkstationByIdSuccess = workstationId => ({ type: DELETE_WORKSTATION_BY_ID_SUCCESS, workstationId })
const deleteWorkstationByIdFailed = error => ({ type: DELETE_WORKSTATION_BY_ID_FAILED, error })

export const deleteWorkstationById = (workstationId) => {
  return async dispatch => {
    dispatch(deleteWorkstationByIdRequest())
    try {
      await WorkstationsService.deleteWorkstationById(workstationId)
      dispatch(deleteWorkstationByIdSuccess(workstationId))
      dispatch(getWorkstations())
      return Promise.resolve()
    } catch (error) {
      dispatch(deleteWorkstationByIdFailed(error))
      return Promise.reject(error)
    }
  }
}

const EDIT_WORKSTATION_BY_ID_REQUEST = Symbol('EDIT_WORKSTATION_BY_ID_REQUEST')
const EDIT_WORKSTATION_BY_ID_SUCCESS = Symbol('EDIT_WORKSTATION_BY_ID_SUCCESS')
const EDIT_WORKSTATION_BY_ID_FAILED = Symbol('EDIT_WORKSTATION_BY_ID_FAILED')

const editWorkstationByIdRequest = () => ({ type: EDIT_WORKSTATION_BY_ID_REQUEST })
const editWorkstationByIdSuccess = (workstationId, data) => ({ type: EDIT_WORKSTATION_BY_ID_SUCCESS, data, workstationId })
const editWorkstationByIdFailed = error => ({ type: EDIT_WORKSTATION_BY_ID_FAILED, error })

export const editWorkstationById = (workstationId, payload) => {
  return async dispatch => {
    dispatch(editWorkstationByIdRequest())
    try {
      const { data } = await WorkstationsService.editWorkstationById(workstationId, payload)
      dispatch(editWorkstationByIdSuccess(workstationId, data))
      return Promise.resolve(data)
    } catch (error) {
      dispatch(editWorkstationByIdFailed(error))
      return Promise.reject(error)
    }
  }
}

const SET_BOMS_FOR_WORKSTATION_BY_ID_REQUEST = Symbol('SET_BOMS_FOR_WORKSTATION_BY_ID_REQUEST')
const SET_BOMS_FOR_WORKSTATION_BY_ID_SUCCESS = Symbol('SET_BOMS_FOR_WORKSTATION_BY_ID_SUCCESS')
const SET_BOMS_FOR_WORKSTATION_BY_ID_FAILED = Symbol('SET_BOMS_FOR_WORKSTATION_BY_ID_FAILED')

const setBomsForWorkstationByIdRequest = () => ({ type: SET_BOMS_FOR_WORKSTATION_BY_ID_REQUEST })
const setBomsForWorkstationByIdSuccess = (workstationId, data) => ({ type: SET_BOMS_FOR_WORKSTATION_BY_ID_SUCCESS, data, workstationId })
const setBomsForWorkstationByIdFailed = error => ({ type: SET_BOMS_FOR_WORKSTATION_BY_ID_FAILED, error })

export const setBomsForWorkstationById = (workstationId, bomIds) => {
  return async dispatch => {
    dispatch(setBomsForWorkstationByIdRequest())
    try {
      const { data } = await WorkstationsService.setBomsForWorkstationById(workstationId, bomIds)
      dispatch(setBomsForWorkstationByIdSuccess(workstationId, data))
      return Promise.resolve(data)
    } catch (error) {
      dispatch(setBomsForWorkstationByIdFailed(error))
      return Promise.reject(error)
    }
  }
}

const initState = {
  entries: {},
  wasFetched: false
}

const extractSubPostsFromWorkstations = (acc, next) => {
  acc.push(next)
  next.subPosts.map(subPost => acc.push(subPost))
  return acc
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_WORKSTATIONS_SUCCESS:
      return {
        ...state,
        entries: R.pipe(
          R.reduce(extractSubPostsFromWorkstations, []),
          transformById
        )(action.payload),
        wasFetched: true
      }
    case DELETE_WORKSTATION_BY_ID_SUCCESS:
      return R.evolve({
        entries: R.dissoc(action.workstationId)
      }, state)
    case EDIT_WORKSTATION_BY_ID_SUCCESS:
    case ADD_WORKSTATION_SUCCESS:
      return R.evolve({
        entries: R.assoc(action.workstationId, action.data)
      }, state)
    default:
      return state
  }
}
