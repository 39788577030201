import OrdersService from 'services/OrdersService'
import { transformById } from 'helpers/redux'
import { receiveEvents } from 'ducks/events'
import * as R from 'ramda'

const GET_ORDERS_FOR_ITEM = Symbol('GET_ORDERS_FOR_ITEM')
const GET_ORDERS_FOR_ITEM_SUCCESS = Symbol('GET_ORDERS_FOR_ITEM_SUCCESS')
const GET_ORDERS_FOR_ITEM_FAILED = Symbol('GET_ORDERS_FOR_ITEM_FAILED')

const CONFIRM_ITEM_DELIVERY_FOR_ORDER = Symbol('CONFIRM_ITEM_DELIVERY_FOR_ORDER')
const CONFIRM_ITEM_DELIVERY_FOR_ORDER_SUCCESS = Symbol('CONFIRM_ITEM_DELIVERY_FOR_ORDER_SUCCESS')
const CONFIRM_ITEM_DELIVERY_FOR_ORDER_FAILED = Symbol('CONFIRM_ITEM_DELIVERY_FOR_ORDER_FAILED')

const CONFIRM_OUTSIDE_ORDER_DELIVERY = Symbol('CONFIRM_OUTSIDE_ORDER_DELIVERY')
const CONFIRM_OUTSIDE_ORDER_DELIVERY_SUCCESS = Symbol('CONFIRM_OUTSIDE_ORDER_DELIVERY_SUCCESS')
const CONFIRM_OUTSIDE_ORDER_DELIVERY_FAILED = Symbol('CONFIRM_OUTSIDE_ORDER_DELIVERY_FAILED')

const requestGetOrdersForItem = () => ({ type: GET_ORDERS_FOR_ITEM })
const getOrdersForItemSuccess = (data) => ({ type: GET_ORDERS_FOR_ITEM_SUCCESS, data })
const getOrdersForItemFailed = (error) => ({ type: GET_ORDERS_FOR_ITEM_FAILED, error })

const requestConfirmItemDeliveryForOrder = () => ({ type: CONFIRM_ITEM_DELIVERY_FOR_ORDER })
const confirmItemDeliveryForOrderSuccess = (data) => ({ type: CONFIRM_ITEM_DELIVERY_FOR_ORDER_SUCCESS, data })
const confirmItemDeliveryForOrderFailed = () => ({ type: CONFIRM_ITEM_DELIVERY_FOR_ORDER_FAILED })

const requestConfirmOutsideOrderDelivery = () => ({ type: CONFIRM_OUTSIDE_ORDER_DELIVERY })
const confirmOutsideOrderDeliverySuccess = (data) => ({ type: CONFIRM_OUTSIDE_ORDER_DELIVERY_SUCCESS, data })
const confirmOutsideOrderDeliveryFailed = () => ({ type: CONFIRM_OUTSIDE_ORDER_DELIVERY_FAILED })

export const getOrdersForItem = (itemCode) => {
  return async dispatch => {
    dispatch(requestGetOrdersForItem())
    try {
      const { data } = await OrdersService.getOrdersForItem(itemCode)
      dispatch(getOrdersForItemSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      const errorType = e.response.status === 404 ? 'itemNotFound' : 'somethingWentWrong'
      dispatch(getOrdersForItemFailed(errorType))
      return Promise.reject(e)
    }
  }
}

export const confirmItemDeliveryForOrder = ({ itemId, orderId, quantity, reportDiscrepancy, discrepancyComment }) => {
  return async dispatch => {
    dispatch(requestConfirmItemDeliveryForOrder())
    try {
      const { data, meta } = await OrdersService.confirmItemDeliveryForOrder({ itemId, orderId, quantity, reportDiscrepancy, discrepancyComment })
      dispatch(confirmItemDeliveryForOrderSuccess(data))
      dispatch(receiveEvents(meta?.eventIds))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(confirmItemDeliveryForOrderFailed(e))
      return Promise.reject(e)
    }
  }
}

export const confirmOutsideOrderDelivery = ({ itemCode, quantity }) => {
  return async dispatch => {
    dispatch(requestConfirmOutsideOrderDelivery())
    try {
      const { data, meta } = await OrdersService.confirmOutsideOrderDelivery({ itemCode, quantity })
      dispatch(confirmOutsideOrderDeliverySuccess(data))
      dispatch(receiveEvents(meta?.eventIds))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(confirmOutsideOrderDeliveryFailed(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  currentItem: {},
  entries: {},
  error: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_ORDERS_FOR_ITEM_SUCCESS:
      return {
        ...state,
        error: null,
        currentItem: action.data.itemRevision,
        entries: {
          ...state.entries,
          [action.data.itemRevision.fullIndex]: transformById(action.data.orders.map(order => ({
            ...order,
            items: transformById(order.items)
          })))
        }
      }
    case CONFIRM_ITEM_DELIVERY_FOR_ORDER_SUCCESS:
      return {
        ...state,
        entries: R.evolve({
          [action.data.itemRevision.fullIndex]: {
            [action.data.buyOrderId]: {
              items: {
                [action.data.id]: {
                  deliveredQuantity: R.always(action.data.deliveredQuantity)
                }
              }
            }
          }
        }, state.entries)
      }
    case CONFIRM_OUTSIDE_ORDER_DELIVERY:
      return {
        ...state
      }
    case GET_ORDERS_FOR_ITEM_FAILED:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}
