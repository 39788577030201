import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import AddColorVariantForm from 'forms/AddColorVariantForm'
import { addNewColorVersion, getColorsList } from 'ducks/colors'
import { errorHandler } from 'helpers/errorHandler'

const formName = 'addColorVersion'

const Form = reduxForm({
  form: formName,
  onSubmit: async (values, dispatch, ownProps) => {
    try {
      await dispatch(addNewColorVersion(values.code, values.name))
      await dispatch(getColorsList(ownProps.revisionId))
      ownProps.resetForm()
    } catch (err) {
      errorHandler(err)
      console.error(err)
    }
  }
})(AddColorVariantForm)

export default connect(null)(Form)
