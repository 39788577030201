import AuthService from 'services/AuthService'
import StorageService, { LocalStorageKeys } from 'services/StorageService'
import { includesInArray } from 'helpers/array'
import { Roles } from 'constants/Roles'
import moment from 'moment'

const LOGIN = Symbol('LOGIN')
const LOGIN_SUCCESS = Symbol('LOGIN_SUCCESS')
const LOGIN_FAILED = Symbol('LOGIN_FAILED')

const requestLogin = () => ({ type: LOGIN })
const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, data })
const loginFailed = () => ({ type: LOGIN_FAILED })

export const login = ({ pin }) => {
  return async dispatch => {
    dispatch(requestLogin())
    try {
      const { data } = await AuthService.login({ pin })
      StorageService.set(LocalStorageKeys.user, data)
      const isProductionWorker = includesInArray(data.roles, [Roles.PRODUCTION_WORKER])
      if (isProductionWorker) {
        !moment(StorageService.get(LocalStorageKeys.loginTime)).isSame(moment(), 'day') && StorageService.set(LocalStorageKeys.loginTime, new Date())
      }
      return dispatch(loginSuccess(data))
    } catch (e) {
      dispatch(loginFailed(e))
      return Promise.reject(e)
    }
  }
}

const LOGOUT_SUCCESS = Symbol('LOGOUT_SUCCESS')

const loggedOut = () => ({ type: LOGOUT_SUCCESS })

export const logout = (noApi) => {
  return async dispatch => {
    try {
      // !noApi && await AuthService.logout()
      StorageService.remove(LocalStorageKeys.user)
      StorageService.remove(LocalStorageKeys.token)
      return dispatch(loggedOut())
    } catch (e) {}
  }
}

let userData = {}
let authenticated = false

try {
  authenticated = !!StorageService.get(LocalStorageKeys.token)
} catch (err) {}

try {
  userData = StorageService.get(LocalStorageKeys.user, {})
} catch (err) {}

const initState = {
  authenticated: authenticated,
  roles: userData.roles || [],
  details: {
    id: userData.id,
    name: userData.name
  }
}

export default (state = initState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        authenticated: true,
        roles: action.data.roles,
        details: action.data
      }
    case LOGOUT_SUCCESS:
      return {
        authenticated: false,
        details: {},
        roles: []
      }
    default:
      return state
  }
}
