/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core'
import { useCallback, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { Box, Flex } from '@rebass/emotion'
import { useTranslation } from 'react-i18next'
import SerialNumberFormatRow from 'components/SerialNumberFormatRow'
import theme from 'config/theme'
import Button from 'components/Button'
import useOpenState from 'hooks/useOpenState'
import Modal from 'components/Modal'
import CreateSerialNumberFormatFormConnector from 'connectors/forms/CreateSerialNumberFormatFormConnector'

const tableCss = css`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 2fr 4fr 6fr 1fr;
  grid-gap: 2px;
  padding: 2px;
  background-color: ${theme.colors.black};
  & > * {
    background-color: ${theme.colors.white};
    padding: ${theme.space[5]}px;
  }
`

const tableHeaderCss = css`
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
`

const tooltipStyle = () => css`
  .tippy-popper {
    max-width: unset;
  }
  
  .react-confirm-alert-overlay {
    background: rgba(0, 0, 0, 0.5);
    z-index: 1125;
  }
`

const SerialNumberFormatsScreen = ({ serialNumberFormats, getSerialNumberFormats, deleteSerialNumberFormat, createSerialNumberFormat, updateSerialNumberFormat }) => {
  const { t } = useTranslation()
  const [createModalOpened, openCreateModal, closeCreateModal] = useOpenState()

  useEffect(() => {
    getSerialNumberFormats()
  }, [getSerialNumberFormats])

  const handleDeleteSerialNumberFormat = useCallback((serialNumberFormat) => () => {
    confirmAlert({
      message: t('serialNumberFormats.confirmDeleteTitle') + ': ' + serialNumberFormat.name,
      closeOnEscape: true,
      buttons: [
        {
          label: t('catalogue.confirmationDialogue.yes'),
          onClick: async () => {
            await deleteSerialNumberFormat(serialNumberFormat.id)
          }
        },
        {
          label: t('catalogue.confirmationDialogue.no')
        }
      ]
    })
  }, [deleteSerialNumberFormat])

  return (
    <Box p={6} pt={9}>
      <Global styles={tooltipStyle} />
      <Flex alignItems='center'>
        <h1>{t('serialNumberFormats.title')}</h1>
        <Button css={{ width: 'unset', margin: '0 0 0 12px' }} color={theme.colors.successGreen} onClick={openCreateModal}>dodaj nowy</Button>
      </Flex>
      {createModalOpened && (
        <Modal
          headerTitle={t('serialNumberFormats.addNewFormat')}
          onClose={closeCreateModal}
        >
          <CreateSerialNumberFormatFormConnector onSubmit={createSerialNumberFormat} close={closeCreateModal} />
        </Modal>
      )}
      <div css={tableCss}>
        <div css={tableHeaderCss}>{t('serialNumberFormats.name')}</div>
        <div css={tableHeaderCss}>{t('serialNumberFormats.format')}</div>
        <div css={tableHeaderCss}>{t('serialNumberFormats.usedIn')}</div>
        <div css={tableHeaderCss}>{t('serialNumberFormats.actions')}</div>
        {serialNumberFormats.map((serialNumberFormat) => {
          return (
            <SerialNumberFormatRow
              key={serialNumberFormat.id}
              deleteSerialNumberFormat={handleDeleteSerialNumberFormat(serialNumberFormat)}
              serialNumberFormat={serialNumberFormat}
              updateSerialNumberFormat={updateSerialNumberFormat}
            />
          )
        })}
      </div>
    </Box>
  )
}

export default SerialNumberFormatsScreen
