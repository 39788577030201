import { toast } from 'react-toastify'

export const noop = () => {}

export const machineStatusGetterByProcessName = processName => {
  switch (processName) {
    case 'IDLE':
      return 'maszyna podłączona'
    case 'VACUUM':
      return 'maszyna w trakcie testu'
    case 'No machines connected':
      return 'brak połączenia'
    default:
      return processName
  }
}

export const displayNoPartsForProductionErrorToast = () => {
  toast.error('Brak dostarczonych elementów do produkcji. Zgłoś się do Brygadzisty', { containerId: 'statuses', autoClose: 5e3 })
}

export const setPartsStatusInCurrent = (prevCurrentValue, setCurrentFn, value) => {
  const currentWithPartsMarkedAsDelivered = {
    ...prevCurrentValue,
    partsReceivedForNextAssembly: value
  }
  setCurrentFn(currentWithPartsMarkedAsDelivered)
}
