/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState } from 'react'
import * as PropTypes from 'prop-types'
import * as R from 'ramda'
import Routes from 'constants/Routes'

const BomLink = (props) => {
  const item = props.items[props.partOf]
  const [parent, setParent] = useState(null)

  const getPart = part => {
    const partOf = R.prop('partOf', props.items[part])
    return partOf.length ? R.map(getPart, partOf) : props.items[part]
  }

  const getParent = R.pipe(R.map(getPart), R.flatten, R.prop(0))

  useEffect(() => {
    setParent(getParent(R.propOr([], 'partOf', item)))
  }, [])

  const handleRedirect = () => props.historyPush({
    pathname: Routes.catalogueStructuredView,
    state: {
      parentCode: parent ? parent.fullIndex : item.fullIndex,
      bomId: item.id,
      itemId: props.itemId
    }
  })

  return (
    <div onClick={handleRedirect} css={{ cursor: 'pointer' }}>
      {item?.fullIndex}
    </div>
  )
}

BomLink.propTypes = {
  itemId: PropTypes.any,
  partOf: PropTypes.string
}

export default BomLink
