import { connect } from 'react-redux'
import InternalSubmissionsList from 'views/InternalSubmissionsList'
import { listAllPendingSubmissions } from 'ducks/itemsSubmissions'
import { getSubmissionsAsArray } from 'selectors/internalSubmissions'

const mapStateToProps = (state) => ({
  pendingSubmissions: getSubmissionsAsArray(state)
})

const mapDispatchToPops = dispatch => ({
  listAllPendingSubmissions: () => dispatch(listAllPendingSubmissions())
})

export default connect(mapStateToProps, mapDispatchToPops)(InternalSubmissionsList)
