/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Slide, ToastContainer } from 'react-toastify'
import { css } from 'glamor'
import theme from 'config/theme'
import 'react-toastify/dist/ReactToastify.css'
import { ReactComponent as Close } from 'assets/cancel.svg'
import { Fragment, useEffect, useState } from 'react'
import { showNotification } from 'helpers/notifiacations'

const toastCss = css({
  zIndex: 9999,
  position: 'fixed !important',
  width: '100%',
  top: 0,
  padding: 12,
  boxSizing: 'border-box',
  background: theme.colors.white,
  borderBottom: `8px solid ${theme.colors.lightBlue}`,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
})

const CloseComponent = ({ closeToast }) => {
  return <Close onClick={closeToast} style={{ width: 20, height: 20 }} />
}

const Toaster = ({ allNotifications, markNotificationAsRead }) => {
  const [displayedNotifications, setDisplayedNotifications] = useState(false)
  useEffect(() => {
    const shouldDisplayNotifications = allNotifications.length && !displayedNotifications
    shouldDisplayNotifications && allNotifications.forEach(notification => showNotification(notification, markNotificationAsRead))
    shouldDisplayNotifications && setDisplayedNotifications(true)
  }, [allNotifications])

  return (
    <Fragment>
      <ToastContainer
        draggablePercent={50}
        position={'top-center'}
        transition={Slide}
        closeButton={<CloseComponent />}
        enableMultiContainer
        containerId='notifications'
        hideProgressBar
        autoClose={false}
        style={{ marginLeft: 0, width: '100%', top: 0, left: 0, right: 0 }}
        toastClassName={toastCss}
        bodyClassName={css({ minHeight: '90px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 20, color: theme.colors.darkGrey })}
      />
      <ToastContainer
        position={'bottom-left'}
        enableMultiContainer
        containerId='statuses'
        className={'siemanko123456789'}
        autoClose={5000}
      />
    </Fragment>
  )
}

export default Toaster
