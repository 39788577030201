/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Box, Flex } from '@rebass/emotion'
import { Draggable } from 'react-beautiful-dnd'
import theme from 'config/theme'
import { ReactComponent as CheckIcon } from 'assets/check-mark-in-circle.svg'
import { ReactComponent as WarningIcon } from 'assets/alert.svg'
import SetQuantityModal from './SetQuantityModal'
import { Fragment, memo, useEffect, useState } from 'react'
import Tooltip from 'components/Tooltip'

const progressCss = (offset, width, color, tooMuch) => css`
  height: 4px;
  width: ${width}%;
  background-color: ${color};
  margin-top: 4px;
  position: relative;
  border-radius: 2px;
  left: ${offset}%;
`

const tooMuchCss = (offset, tooMuch) => css`
  height: 4px;
  width: ${tooMuch}%;
  background-color: ${theme.colors.orange};
  margin-top: 4px;
  position: absolute;
  left: ${offset}%;
  top: 8px;
  border-radius: 2px;
`

const greyColor = { color: theme.colors.grey }
const textEllipsis = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }

const BomItem = memo(({ index, bomId, bom: bomItem, list, dailyPlansQuantity, singlePlanQuantity, editPlan, dailyPlan,
  maxQuantity, times = {}, finishTimes = {}, dayIndex, currentWeek, onDoubleClick, highlightedElement }) => {
  const [isDisabled, setDisabled] = useState(true)
  const [isModalOpen, setModalOpen] = useState(false)

  const isBomsList = list === 'bomsList'
  const isFull = dailyPlansQuantity === bomItem?.quantity && isBomsList
  const isTooMuch = dailyPlansQuantity > bomItem?.quantity && isBomsList
  const isSomethingManufactured = dailyPlan?.manufactured > 0

  let bgColor = null
  let icon = null

  switch (true) {
    case isFull:
      bgColor = theme.colors.lighterGreen
      icon = <CheckIcon style={{ fill: theme.colors.successGreen, width: 15, height: 15 }} />
      break
    case isTooMuch:
      bgColor = theme.colors.lightRed
      icon = <WarningIcon style={{ fill: theme.colors.error, width: 15, height: 15 }} />
      break
    default:
      bgColor = theme.colors.white
  }

  const editCurrentPlan = (quantity) => {
    editPlan(dailyPlan, quantity)
  }

  const openModal = () => setModalOpen(!isBomsList)
  const closeModal = () => setModalOpen(false)

  useEffect(() => {
    dailyPlan?.shouldOpenModal && openModal()
    setTimeout(() => setDisabled(false), 10)
  }, [])

  const { progressTime = 0, planTime = 0, offset = 0, tooMuch = 0 } = times

  const { planFinishTime, offset: planFinishTimeOffset } = finishTimes

  const tooltipContent = isBomsList
    ? (
      <div css={{ padding: 50, fontSize: 24 }}>
        <div css={{ paddingBottom: 20 }}><b>{bomItem?.bom.itemRevision.productionName || bomItem?.bom.itemRevision.name}</b></div>
        <div>indeks: {bomItem?.bom.itemRevision.fullIndex}</div>
        <div>zlecenie: {bomItem?.quantity}</div>
        <div>zaplanowano: {dailyPlansQuantity}</div>
        <div>wyprodukowano: {bomItem?.manufactured}</div>
      </div>
    )
    : (
      <Fragment>
        <SetQuantityModal
          purpose={'editPlan'}
          onClose={closeModal}
          editPlan={editCurrentPlan}
          maxQuantity={maxQuantity}
          manufactured={dailyPlan?.manufactured}
          currentValue={singlePlanQuantity}
          onTooltip
          bom={bomItem}
          currentWeek={currentWeek}
          dayIndex={dayIndex}
          planFinishTime={planFinishTime}
          planFinishTimeOffset={planFinishTimeOffset}
        />
      </Fragment>
    )

  const isHighlighted = highlightedElement ? bomId.includes(highlightedElement) : false

  const getColorForHighlight = (highlightedColor, notHighlightedColor, nothingHighlightedColour) => isHighlighted
    ? highlightedColor
    : highlightedElement
      ? notHighlightedColor
      : nothingHighlightedColour

  const getBoxShadow = () => {
    switch (true) {
      case isHighlighted:
        return `1px 1px 10px ${theme.colors.navyBlue}`
      case isFull:
        return 'none'
      default:
        return `0 0 3px ${getColorForHighlight(theme.colors.navyBlue, 'lightgrey', theme.colors.lightGrey)}`
    }
  }
  const getFaintedColorIfNotHighlighted = color => getColorForHighlight(color, color + '40', color)

  return <Draggable
    isDragDisabled={isFull || isTooMuch || isSomethingManufactured}
    draggableId={bomId}
    key={bomId}
    index={index}>
    {provided => {
      return (
        <div
          onDoubleClick={onDoubleClick || openModal}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          css={{
            padding: '5px 10px',
            paddingBottom: isBomsList ? '0px' : '10px',
            border: `1px solid ${getColorForHighlight(theme.colors.navyBlue, 'lightgrey', theme.colors.grey)}`,
            width: '85%',
            margin: '5px 0',
            boxShadow: getBoxShadow(),
            background: !isBomsList && dailyPlan?.manufactured >= singlePlanQuantity ? theme.colors.lighterGreen : bgColor,
            borderRadius: '5px',
            ...provided.draggableProps.style
          }}
        >
          <Tooltip
            trigger={isBomsList ? 'mouseenter' : 'manual'}
            open={isModalOpen}
            zIndex={502}
            unmountHTMLWhenHide
            followCursor={isBomsList}
            noFlip={isBomsList}
            delay={50}
            position={'right'}
            interactive
            onRequestClose={closeModal}
            content={tooltipContent}
            css={{ width: '100%', height: '100%', display: 'block' }}
          >
            <Tooltip
              trigger={'mouseenter'}
              zIndex={501}
              disabled={isModalOpen || isBomsList || isDisabled}
              unmountHTMLWhenHide
              delay={50}
              position={'right'}
              css={{ width: '100%', height: '100%', display: 'block !important' }}
              content={(
                <Box css={{ padding: 50, fontSize: 24 }}>
                  <Box css={{ fontSize: 24 }}><b>{bomItem?.bom.itemRevision.productionName || bomItem?.bom.itemRevision.name}</b></Box>
                  <Box css={{ marginBottom: 10, fontSize: 18 }}><b>index:</b> {bomItem?.bom.itemRevision.fullIndex}</Box>
                  <Flex flexDirection='column' alignItems='center' css={{ marginRight: 10 }}>
                    <Box><b css={greyColor}>zaplanowano:</b> {singlePlanQuantity}</Box>
                    <Box><b css={greyColor}>wykonano:</b> {dailyPlan?.manufactured}</Box>
                    <Box><b css={greyColor}>planowany czas skończenia:</b> {planFinishTime}</Box>
                  </Flex>
                </Box>
              )}
            >
              <Flex
                justifyContent={'space-between'}
                style={{
                  ...textEllipsis,
                  fontSize: '14px',
                  color: getColorForHighlight(theme.colors.black, theme.colors.concreteGray, theme.colors.lightGrey)
                }}
              >
                <div style={{ marginBottom: 5, maxWidth: isBomsList ? '45%' : '90%', ...textEllipsis }}>{bomItem?.bom.itemRevision.productionName || bomItem?.bom.itemRevision.name}</div>
                {isBomsList && <div style={{ marginBottom: 5, maxWidth: '45%', ...textEllipsis }}>{bomItem?.bom.itemRevision.fullIndex}</div>}
                {icon || null}
              </Flex>
              {isBomsList
                ? (
                  <Flex flexDirection='column'>
                    <div
                      css={progressCss(
                        0,
                        (dailyPlansQuantity / bomItem?.quantity) * 100,
                        getFaintedColorIfNotHighlighted(theme.colors.successGreen)
                      )}
                    />
                  </Flex>
                )
                : (
                  <Flex flexDirection='column' css={{ position: 'relative' }}>
                    <div css={progressCss(offset, progressTime, getFaintedColorIfNotHighlighted(theme.colors.successGreen))} />
                    <div css={progressCss(offset, planTime, getFaintedColorIfNotHighlighted(theme.colors.lightBlue), tooMuch)} />
                    <div css={tooMuchCss(offset + planTime, tooMuch)} />
                  </Flex>
                )
              }
            </Tooltip>
          </Tooltip>
        </div>
      )
    }
    }
  </Draggable>
})

export default BomItem
