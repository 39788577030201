/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState } from 'react'
import { Box, Flex } from '@rebass/emotion'
import LoadingOverlay from 'components/LoadingOverlay'
import theme from 'config/theme'
import Button from 'components/Button'
import Modal from 'components/Modal'
import WorkstationEditFormConnector from 'connectors/forms/WorkstationEditFormConnector'
import WorkstationBomsFormConnector from 'connectors/forms/WorkstationBomsFormConnector'
import { EMPTY_WORKSTATION } from 'constants/workstations'
import { useTranslation } from 'react-i18next'
import {
  centerStyles,
  containerStyles,
  getFlexStyles,
  modalContentStyles,
  spanStyles,
  wrapperStyles
} from 'views/Workstations/styles'
import * as R from 'ramda'
import { Roles } from 'constants/Roles'
import { includesInArray } from 'helpers/array'

const StationRow = ({ station, onEditWorkstation, onEditBoms, isAdmin }) => {
  const { number, name, breakLength, subPosts, subindex } = station
  const { t } = useTranslation()

  const TextField = ({ children }) => <span css={spanStyles}>{children}</span>

  return (
    <Flex css={getFlexStyles()}>
      <Flex css={centerStyles}>
        <TextField>{number} {subindex}</TextField>
      </Flex>
      <Flex css={centerStyles}>
        <TextField>{name}</TextField>
      </Flex>
      <Flex css={centerStyles}>
        <TextField>{breakLength}</TextField>
      </Flex>
      <Flex css={centerStyles}>
        {subPosts?.length
          ? (
            <ul>
              {subPosts.map((post, i) => (
                <li key={i}>{`${post.fullName}`}</li>
              ))}
            </ul>
          )
          : t('workstationEdit.noSubstation')
        }
      </Flex>
      <Flex css={centerStyles} px={5}>
        {isAdmin && <Button color={theme.colors.navyBlue} onClick={onEditWorkstation}>{t('workstationEdit.edit')}</Button>}
        {!station.parentAssemblyPostId && (
          <Button
            color={theme.colors.successGreen}
            onClick={onEditBoms}
            css={{ minWidth: 150, marginLeft: 10 }}
          >
            {t('workstationEdit.boms')}
          </Button>
        )}
      </Flex>
    </Flex>
  )
}

const workstationModal = (station, closeModal, workstations) => {
  const selectValueSpec = {
    value: R.identity,
    label: value => R.pipe(
      R.find(R.propEq('id', value)),
      R.prop('fullName')
    )(workstations)
  }
  const initialValues = R.evolve({
    parentAssemblyPostId: R.ifElse(
      R.is(String),
      R.applySpec(selectValueSpec),
      R.identity
    )
  }, station)
  return (
    <Flex css={modalContentStyles}>
      <WorkstationEditFormConnector
        station={station}
        initialValues={initialValues}
        closeModal={closeModal}
        workstations={workstations}
      />
    </Flex>
  )
}

const bomsModal = (station, closeModal) => {
  const initialValues = R.pipe(
    R.prop('boms'),
    R.map(R.applySpec({
      label: item => R.path(['itemRevision', 'fullIndex'], item) + ' ' + R.path(['itemRevision', 'name'], item),
      value: R.prop('id')
    }))
  )(station)
  return (
    <Flex css={modalContentStyles}>
      <WorkstationBomsFormConnector
        station={station}
        initialValues={{ boms: initialValues }}
        closeModal={closeModal}
      />
    </Flex>
  )
}

const WorkstationsView = ({ getWorkstations, wasFetched, workstations, userRoles }) => {
  const { t } = useTranslation()
  const [workstationEditModalData, setWorkstationEditModalData] = useState(null)
  const [isWorkStationEditModalOpen, setIsWorkstationEditModalOpen] = useState(false)

  const [workstationBomsModalData, setWorkstationBomsModalData] = useState(null)
  const [isWorkStationBomsModalOpen, setIsWorkstationBomsModalOpen] = useState(false)
  useEffect(() => {
    getWorkstations()
  }, [])

  const closeWorkstationEditModal = () => {
    setIsWorkstationEditModalOpen(false)
    setWorkstationEditModalData(null)
  }

  const closeWorkstationBomsModal = () => {
    setIsWorkstationBomsModalOpen(false)
    setWorkstationBomsModalData(null)
  }

  const isAdmin = includesInArray(userRoles, [Roles.ADMIN])

  return wasFetched ? (
    <Box mx={10} my={10}>
      <Flex css={containerStyles}>
        <Flex css={wrapperStyles}>
          <Flex css={getFlexStyles(true)}>
            <Flex css={centerStyles}>
              {t('workstationEdit.number')}
            </Flex>
            <Flex css={centerStyles}>
              {t('workstationEdit.name')}
            </Flex>
            <Flex css={centerStyles}>
              {t('workstationEdit.breakDuration')}
            </Flex>
            <Flex css={centerStyles}>
              {t('workstationEdit.subStations')}
            </Flex>
            <Flex css={centerStyles}>
              {isAdmin && (
                <Button
                  color={theme.colors.successGreen}
                  onClick={() => {
                    setWorkstationEditModalData(EMPTY_WORKSTATION)
                    setIsWorkstationEditModalOpen(true)
                  }}
                >
                  {t('workstationEdit.add')}
                </Button>
              )}
            </Flex>
          </Flex>
          {workstations.length ? (
            workstations.map((workstation, i) =>
              <StationRow
                key={workstation.id}
                index={i}
                isAdmin={isAdmin}
                station={workstation}
                onEditWorkstation={() => {
                  setWorkstationEditModalData(workstation)
                  setIsWorkstationEditModalOpen(true)
                }}
                onEditBoms={() => {
                  setWorkstationBomsModalData(workstation)
                  setIsWorkstationBomsModalOpen(true)
                }}
              />
            )
          ) : t('workstationEdit.noWorkstations')}
        </Flex>
        {isWorkStationEditModalOpen && (
          <Modal
            headerTitle={
              workstationEditModalData?.id
                ? `${t('workstationEdit.workstationEdit')}: ${workstationEditModalData?.fullName}`
                : t('workstationEdit.addWorkstation')}
            onClose={closeWorkstationEditModal}
          >
            {workstationModal(workstationEditModalData, closeWorkstationEditModal, workstations)}
          </Modal>
        )}
        {isWorkStationBomsModalOpen && (
          <Modal
            headerTitle={`${t('workstationEdit.editBoms')}: ${workstationBomsModalData?.fullName}`}
            onClose={closeWorkstationBomsModal}
          >
            {bomsModal(workstationBomsModalData, closeWorkstationBomsModal)}
          </Modal>
        )}
      </Flex>
    </Box>
  ) : (
    <LoadingOverlay />
  )
}

export default WorkstationsView
