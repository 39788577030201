import DiscrepancyService from 'services/DiscrepancyService'

const GET_DISCREPANCY_COMMENTS = Symbol('GET_DISCREPANCY_COMMENTS')
const GET_DISCREPANCY_COMMENTS_SUCCESS = Symbol('GET_DISCREPANCY_COMMENTS_SUCCESS')
const GET_DISCREPANCY_COMMENTS_FAILED = Symbol('GET_DISCREPANCY_COMMENTS_FAILED')

const requestGetDiscrepancyComments = () => ({ type: GET_DISCREPANCY_COMMENTS })
const getDiscrepancyCommentsSuccess = (data) => ({ type: GET_DISCREPANCY_COMMENTS_SUCCESS, data })
const getDiscrepancyCommentsFailed = () => ({ type: GET_DISCREPANCY_COMMENTS_FAILED })

export const getDiscrepancyComments = () => {
  return async dispatch => {
    dispatch(requestGetDiscrepancyComments())
    try {
      const { data } = await DiscrepancyService.getDiscrepancyComments()
      return dispatch(getDiscrepancyCommentsSuccess(data))
    } catch (e) {
      return dispatch(getDiscrepancyCommentsFailed(e))
    }
  }
}

const initState = {
  entries: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_DISCREPANCY_COMMENTS_SUCCESS:
      return {
        ...state,
        entries: [
          ...new Set([...state.entries, ...action.data.comments])
        ]
      }
    default:
      return state
  }
}
