/** @jsx jsx */
import { jsx } from '@emotion/core'
import { includesInArray } from 'helpers/array'
import { Roles } from 'constants/Roles'
import { headerCss } from 'views/Catalogue/styles'
import Tooltip from 'components/Tooltip'
import Routes from 'constants/Routes'

const BomLink = ({ item, historyPush }) => {
  const handleRedirect = () => historyPush({
    pathname: Routes.catalogueStructuredView,
    state: {
      parentCode: item.fullIndex,
      itemId: item.id
    }
  })

  return (
    <div onClick={handleRedirect} css={{ cursor: 'pointer' }}>
      {item?.fullIndex}
    </div>
  )
}

const bomHeaderWithTooltip = (t, bomItems, historyPush) =>
  <Tooltip
    content={bomItems.map((item) =>
      <BomLink
        item={item}
        key={`header_${item.id}`}
        historyPush={historyPush}
      />)}
    position={'bottom'}
    interactive
    zIndex={100}
  >
    {t('catalogue.BOM')}
  </Tooltip>

const bomHeader = (userRoles, t, bomItems, historyPush) => includesInArray([Roles.ADMIN, Roles.FOREMAN, Roles.PRODUCTION_MANAGER, Roles.SUPPLY], userRoles)
  ? [
    <div css={headerCss} key={'partOfHeader'}>
      {bomItems.length ? bomHeaderWithTooltip(t, bomItems, historyPush) : t('catalogue.BOM')}
    </div>
  ]
  : []

const deliveryColumnsHeaders = (userRoles, t, showDelivery) => {
  if (includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.SUPPLY], userRoles)) {
    return showDelivery
      ? [
        <div key={'defaultVendorHeader'} css={headerCss}>{t('catalogue.supplier')}</div>,
        <div key={'productionMoqHeader'} css={headerCss}>{t('catalogue.MOQP')}</div>,
        <div key={'moqHeader'} css={headerCss}>{t('catalogue.MOQ')}</div>,
        <div key={'minimumQuantityHeader'} css={headerCss}>{t('catalogue.minimumQuantity')}</div>,
        <div key={'leadTimeHeader'} css={headerCss}>{t('catalogue.leadTime')}</div>,
        <div key={'leadTimeMarginHeader'} css={headerCss}>{t('catalogue.leadTimeMargin')}</div>,
        <div key={'averageLeadTimeHeader'} css={headerCss}>{t('catalogue.averageLeadTime')}</div>,
        <div key={'packingQuantityHeader'} css={headerCss}>{t('catalogue.packSize')}</div>,
        <div key={'itemsPerUnitHeader'} css={headerCss}>{t('catalogue.itemsPerUnit')}</div>,
        <div key={'preferredWarehouseLocationIdHeader'} css={headerCss}>{t('catalogue.preferredLocation')}</div>,
        <div key={'priceHeader'} css={headerCss}>{t('catalogue.price')}</div>,
        <div key={'paymentConditionsHeader'} css={headerCss}>{t('catalogue.paymentConditions')}</div>,
        <div key={'transportPriceHeader'} css={headerCss}>{t('catalogue.transportPrice')}</div>,
        <div key={'customsPriceHeader'} css={headerCss}>{t('catalogue.customsPrice')}</div>,
        <div key={'deliveryConditionsHeader'} css={headerCss}>{t('catalogue.deliveryConditions')}</div>
      ] : [
        <div key={'delivery-header-trigger'} css={headerCss} />
      ]
  } else {
    return []
  }
}

const qualityAssuranceColumnsHeaders = (userRoles, t, showQa) => {
  if (includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.QUALITY_ASSURANCE, Roles.SUPPLY, Roles.FOREMAN], userRoles)) {
    return showQa
      ? [
        <div key={'contractHeader'} css={headerCss}>{t('catalogue.contract')}</div>,
        <div key={'qualityBookHeader'} css={headerCss}>{t('catalogue.qualityBook')}</div>,
        <div key={'instructionHeader'} css={headerCss}>{t('catalogue.instruction')}</div>,
        <div key={'entryControlHeader'} css={headerCss}>{t('catalogue.entryControl')}</div>,
        <div key={'currentComplaintsLevelHeader'} css={headerCss}>{t('catalogue.currentComplaintsLevel')}</div>,
        <div key={'productControlHeader'} css={headerCss}>{t('catalogue.productControl')}</div>
      ] : [
        <div key={'qa-header-trigger'} css={headerCss} />
      ]
  } else {
    return []
  }
}

const productionColumnsHeaders = (userRoles, t, showProduction) => {
  if (includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.SUPPLY], userRoles)) {
    return showProduction
      ? [
        <div key={'currentNokLevelHeader'} css={headerCss}>{t('catalogue.currentNokLevel')}</div>,
        <div key={'assemblyPostHeader'} css={headerCss}>{t('catalogue.assemblyPost')}</div>,
        <div key={'assemblyTimeHeader'} css={headerCss}>{t('catalogue.assemblyTime')}</div>,
        <div key={'currentAssemblyEfficiencyHeader'} css={headerCss}>{t('catalogue.currentAssemblyEfficiency')}</div>,
        <div key={'pieceworkRateHeader'} css={headerCss}>{t('catalogue.pieceworkRate')}</div>,
        <div key={'pieceworkRateNokHeader'} css={headerCss}>{t('catalogue.pieceworkRateNok')}</div>,
        <div key={'serialNumberFormatHeader'} css={headerCss}>{t('catalogue.serialNumberFormat')}</div>,
        <div key={'alternatives'} css={headerCss}>{t('catalogue.alternatives')}</div>,
        <div key={'colorsHeader'} css={headerCss}>{t('catalogue.colors')}</div>,
        <div key={'photosHeader'} css={headerCss}>{t('catalogue.photos')}</div>
      ] : [
        <div key={'production-header-trigger'} css={headerCss} />
      ]
  } else {
    return []
  }
}

export const getHeaders = ({ userRoles, t, bomItems = [], historyPush, showDelivery = true, showQa = true, showProduction = true }) =>
  bomHeader(userRoles, t, bomItems, historyPush)
    .concat(deliveryColumnsHeaders(userRoles, t, showDelivery))
    .concat(qualityAssuranceColumnsHeaders(userRoles, t, showQa))
    .concat(productionColumnsHeaders(userRoles, t, showProduction))
