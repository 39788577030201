/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import Select from 'react-select'
import Button from './Button'
import { Flex } from '@rebass/emotion'
import { iterable } from 'helpers/array'
import { uuidv4 } from 'helpers/uuidv4'
import ProductionStationsService from 'services/ProductionStationsService'
import scriptLoader from 'react-async-script-loader'

const BarcodePrinter = (props) => {
  const [printer, setPrinter] = useState(null)
  const [stickerNb, selectNb] = useState({ value: 1, label: 'sztuk: 1' })

  useEffect(() => {
    if (window.BrowserPrint) {
      window.BrowserPrint.getDefaultDevice('printer', (device) => setPrinter(device))
    }
  }, [window.BrowserPrint, setPrinter])

  const printStickers = async () => {
    const uuids = iterable(stickerNb.value).map(uuidv4)
    const { data } = await ProductionStationsService.getZplQrCode(uuids)
    const zplCodes = Object.values(data)
    zplCodes
      .map(code => code.replace('^FO50,50', '^FO5,5')) // fixes padding's on printed labels
      .forEach(code => printer.send(code, res => console.log('Success!', res), err => console.warn(err)))
  }

  const options = useMemo(() => iterable(100).map(item => ({ value: item + 1, label: 'sztuk: ' + (item + 1) })), [])

  const handleSelectNumber = useCallback((value) => selectNb(value), [])

  return printer
    ? (
      <Flex alignItems='center' mt={5} ml={3}>
        <span>Dodruk naklejek</span>
        <div css={{ width: 200, marginLeft: 30 }}>
          <Select
            value={stickerNb}
            onChange={handleSelectNumber}
            options={options}
          />
        </div>
        <Button css={{ width: 200, margin: 'unset', marginLeft: 30 }} onClick={printStickers}>drukuj</Button>
      </Flex>
    )
    : null
}

export default scriptLoader('/BrowserPrint.js')(BarcodePrinter)
