import { dissoc } from 'ramda'

export const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign({}, stateProps, dispatchProps, ownProps)

export const transformById = (collection, idKey = 'id') =>
  collection.reduce((hash, entity) => {
    hash[entity[idKey]] = entity

    return hash
  }, {})

export const transformToArray = collection => Object.keys(collection).map(id => collection[id])

export const transformToIdsArray = arr => arr.map(element => element.id)

export const transformToIdsCollection = (collection, idKey = 'id') => transformToArray(collection).map(item => ({ id: item[idKey] }))

export const itemRemover = (entries, orderId, itemId) => {
  let modifiedBody = {
    ...entries[orderId],
    items: entries[orderId].items.filter(item => item.id !== itemId)
  }
  return modifiedBody.items.length
    ? { ...entries, [orderId]: modifiedBody }
    : dissoc(orderId, entries)
}
