import * as R from 'ramda'

export const makeItemsOptions = R.pipe(
  R.propOr([], 'data'),
  R.map(
    R.applySpec({
      value: R.identity,
      label: R.prop('code')
    })
  )
)
