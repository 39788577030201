import WorkstationEditForm from 'forms/WorkstationEditForm'
import { connect } from 'react-redux'
import { formValueSelector, reduxForm } from 'redux-form'
import * as R from 'ramda'
import { addWorkstation, deleteWorkstationById, editWorkstationById, getWorkstations } from 'ducks/workstations'
import { toast } from 'react-toastify'
import { withTranslation } from 'react-i18next'
import { errorHandler } from 'helpers/errorHandler'

const formName = 'workstation'

const Form = reduxForm({
  form: formName,
  validate: (values, ownProps) => {
    const errors = {}
    const requiredFields = ['name', 'startsAt', 'worktime']
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = ownProps.t('common.fieldRequired')
      }
    })
    return errors
  },
  onSubmit: async (values, dispatch, ownProps) => {
    const isEdit = Boolean(ownProps.station.id)
    try {
      const preparedValues = R.evolve({
        parentAssemblyPostId: R.propOr('', 'value')
      }, values)

      isEdit
        ? await dispatch(editWorkstationById(ownProps.station.id, preparedValues))
        : await dispatch(addWorkstation(preparedValues))
      ownProps.closeModal()
      dispatch(getWorkstations())
      return Promise.resolve()
    } catch (e) {
      if (isEdit) {
        toast.error(ownProps.t('workstationEdit.editWorkstationFail'), { containerId: 'statuses', autoClose: 15e3 })
      } else {
        toast.error(ownProps.t('workstationEdit.addWorkstationFail'), { containerId: 'statuses', autoClose: 15e3 })
      }
      errorHandler(e)
      return Promise.reject(e)
    }
  }
})(WorkstationEditForm)

const selector = formValueSelector(formName)

const mapStateToProps = state => ({
  parentAssemblyPostIdValue: selector(state, 'parentAssemblyPostId')
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  deleteWorkstation: () => dispatch(deleteWorkstationById(ownProps.station.id))
})

export default R.compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTranslation()
)(Form)
