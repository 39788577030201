import APIInterceptor from './APIInterceptor'

export default {
  getBomsImports: () => APIInterceptor.get('/boms/imports'),
  getBomImportDetails: (bomImportId) => APIInterceptor.get(`/boms/imports/${bomImportId}`),
  deleteBomImportById: (bomImportId) => APIInterceptor.delete(`/boms/imports/${bomImportId}`),
  uploadBomImport: (file) => APIInterceptor.post('/boms/excel', file),
  saveBomImport: (bomImportId, data) => APIInterceptor.patch(`/boms/imports/${bomImportId}`, { data }),
  generateBomFromImport: (bomImportId) => APIInterceptor.post(`/boms/imports/${bomImportId}/generate`)
}
