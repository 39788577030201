/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { includesInArray } from 'helpers/array'
import { Roles } from 'constants/Roles'
import theme from 'config/theme'

const markersCss = (from, to, color) => css`
  grid-column: ${from} / ${to};
  height: 5px;
  margin: 2px;
  background-color: ${color};
  cursor: pointer;
`

const closedMarkerCss = (column, color) => css`
  grid-column: ${column} / ${column};
  justify-self: stretch;
  height: 5px;
  margin: 2px;
  background-color: ${color};
  cursor: pointer;
`
export const renderMarkers = (storage, userRoles, onChangeFunc) => {
  if (includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.SUPPLY], userRoles)) {
    switch (true) {
      case !storage.isBlueSectionActive && storage.isOrangeSectionActive && storage.isGraySectionActive:
        return [
          <div key={'marker1'} css={closedMarkerCss(5, theme.colors.lightBlue)} onClick={onChangeFunc('isBlueSectionActive')} />,
          <div key={'marker2'} css={markersCss(6, 11, theme.colors.orange)} onClick={onChangeFunc('isOrangeSectionActive')} />,
          <div key={'marker3'} css={markersCss(11, -1, theme.colors.lightGrey)} onClick={onChangeFunc('isGraySectionActive')} />
        ]
      case storage.isBlueSectionActive && !storage.isOrangeSectionActive && storage.isGraySectionActive:
        return [
          <div key={'marker1'} css={markersCss(4, 20, theme.colors.lightBlue)} onClick={onChangeFunc('isBlueSectionActive')} />,
          <div key={'marker2'} css={closedMarkerCss(20, theme.colors.orange)} onClick={onChangeFunc('isOrangeSectionActive')} />,
          <div key={'marker3'} css={markersCss(21, -1, theme.colors.lightGrey)} onClick={onChangeFunc('isGraySectionActive')} />
        ]
      case storage.isBlueSectionActive && storage.isOrangeSectionActive && !storage.isGraySectionActive:
        return [
          <div key={'marker1'} css={markersCss(4, 20, theme.colors.lightBlue)} onClick={onChangeFunc('isBlueSectionActive')} />,
          <div key={'marker2'} css={markersCss(20, 26, theme.colors.orange)} onClick={onChangeFunc('isOrangeSectionActive')} />,
          <div key={'marker3'} css={closedMarkerCss(26, theme.colors.lightGrey)} onClick={onChangeFunc('isGraySectionActive')} />
        ]
      case !storage.isBlueSectionActive && !storage.isOrangeSectionActive && storage.isGraySectionActive:
        return [
          <div key={'marker1'} css={closedMarkerCss(5, theme.colors.lightBlue)} onClick={onChangeFunc('isBlueSectionActive')} />,
          <div key={'marker2'} css={closedMarkerCss(6, theme.colors.orange)} onClick={onChangeFunc('isOrangeSectionActive')} />,
          <div key={'marker3'} css={markersCss(7, -1, theme.colors.lightGrey)} onClick={onChangeFunc('isGraySectionActive')} />
        ]
      case !storage.isBlueSectionActive && storage.isOrangeSectionActive && !storage.isGraySectionActive:
        return [
          <div key={'marker1'} css={closedMarkerCss(5, theme.colors.lightBlue)} onClick={onChangeFunc('isBlueSectionActive')} />,
          <div key={'marker2'} css={markersCss(6, 12, theme.colors.orange)} onClick={onChangeFunc('isOrangeSectionActive')} />,
          <div key={'marker3'} css={closedMarkerCss(12, theme.colors.lightGrey)} onClick={onChangeFunc('isGraySectionActive')} />
        ]
      case storage.isBlueSectionActive && !storage.isOrangeSectionActive && !storage.isGraySectionActive:
        return [
          <div key={'marker1'} css={markersCss(4, 20, theme.colors.lightBlue)} onClick={onChangeFunc('isBlueSectionActive')} />,
          <div key={'marker2'} css={closedMarkerCss(20, theme.colors.orange)} onClick={onChangeFunc('isOrangeSectionActive')} />,
          <div key={'marker3'} css={closedMarkerCss(21, theme.colors.lightGrey)} onClick={onChangeFunc('isGraySectionActive')} />
        ]
      case !storage.isBlueSectionActive && !storage.isOrangeSectionActive && !storage.isGraySectionActive:
        return [
          <div key={'marker1'} css={closedMarkerCss(5, theme.colors.lightBlue)} onClick={onChangeFunc('isBlueSectionActive')} />,
          <div key={'marker2'} css={closedMarkerCss(6, theme.colors.orange)} onClick={onChangeFunc('isOrangeSectionActive')} />,
          <div key={'marker3'} css={closedMarkerCss(7, theme.colors.lightGrey)} onClick={onChangeFunc('isGraySectionActive')} />
        ]
      default:
        return [
          <div key={'marker1'} css={markersCss(4, 20, theme.colors.lightBlue)} onClick={onChangeFunc('isBlueSectionActive')} />,
          <div key={'marker2'} css={markersCss(20, 26, theme.colors.orange)} onClick={onChangeFunc('isOrangeSectionActive')} />,
          <div key={'marker3'} css={markersCss(26, -1, theme.colors.lightGrey)} onClick={onChangeFunc('isGraySectionActive')} />
        ]
    }
  }
}
