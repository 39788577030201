import APIInterceptor from './APIInterceptor'
import qs from 'query-string'

import { toSnakeCase } from 'helpers/string'

const interceptor = APIInterceptor

const WarehouseService = {
  getCancelledInternalOrders: () => interceptor.get(`/internal_orders/autocancelled`),
  getWarehouseState: (query) => interceptor.get(`/warehouse?${qs.stringify(query)}`),
  getWarehouseStateWithBlankItems: (include) => interceptor.get(`/items?${qs.stringify(include)}`),
  getItems: (query, locationCode) => {
    const params = qs.stringify({ query, location_code: locationCode })
    return interceptor.get(`/items/search?${params}`)
  },
  getInventoryStateForItem: (itemCode) => interceptor.get(`/warehouse/inventory/searchByItem?code=${itemCode}`),
  getHandOverPurposes: () => interceptor.get('/warehouse/inventory/hand_over_purposes'),
  handOverItemFromInventory: ({ quantity, inventoryId, purpose }) => {
    return interceptor.post(`/warehouse/inventory/${inventoryId}/hand_over`, { quantity, purpose })
  },
  recalculateInventoryQuantity: ({ quantity, inventoryId, purpose }) => {
    return interceptor.post(`/warehouse/inventory/${inventoryId}/correct_quantity`, { quantity, purpose })
  },
  submitItemsForWarehouse: ({ code, quantity }) => {
    return interceptor.post(`/warehouse/item_submission`, { code, quantity })
  },
  listItemSubmissions: () => {
    return interceptor.get('/warehouse/item_submission/mine')
  },
  listAllPendingSubmissions: () => {
    return interceptor.get('/warehouse/item_submission/pending')
  },
  acknowledgeItemSubmission: ({ itemCode, quantity, destinationCode }) => {
    return interceptor.post(`/warehouse/item_submission/${itemCode}/acknowledge`, { quantity, destinationCode })
  },
  deletePendingSubmittedItem: (itemId) => {
    return interceptor.delete(`/warehouse/item_submission/${itemId}`)
  },
  editItemRevision: ({ itemRevisionId, field, value }) => {
    return interceptor.patch(`items/revisions/${itemRevisionId}`, { [toSnakeCase(field)]: value })
  },
  addItemRevisionAlternative: ({ itemRevisionId, alternativeId }) => {
    return interceptor.post(`items/revisions/${itemRevisionId}/alternatives`, { alternative_id: alternativeId })
  },
  removeItemRevisionAlternative: ({ itemRevisionId, alternativeId }) => {
    return interceptor.delete(`items/revisions/${itemRevisionId}/alternatives/${alternativeId}`)
  }
}

export default WarehouseService
