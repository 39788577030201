import { connect } from 'react-redux'
import {
  executeWeeklyPlanMaterialsOrder, getBomItemsQuantities,
  getDailyBomItems,
  updateDailyPlans
} from 'ducks/weeklyPlans'
import { getDailyBomItemsAsArray } from 'selectors/weeklyPlans'
import WeeklyPlansForeman from 'views/WeeklyPlans/WeeklyPlansForeman'
import { createInternalOrder } from 'ducks/internalOrders'
import { submitItemsForWarehouse } from 'ducks/itemsSubmissions'

const mapStateToProps = state => ({
  bomDailyItems: getDailyBomItemsAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  getDailyBomItems: (week) => dispatch(getDailyBomItems(week)),
  getBomItemsQuantities: () => dispatch(getBomItemsQuantities()),
  updateDailyPlans: (data, week) => dispatch(updateDailyPlans(data, week)),
  executeWeeklyPlanMaterialsOrder: ({ week, year }) => dispatch(executeWeeklyPlanMaterialsOrder({ week, year })),
  createInternalOrder: (itemsArray, weeklyPlanId, purpose) => dispatch(createInternalOrder({ purpose: purpose, items: itemsArray, bom_weekly_plan_id: weeklyPlanId })),
  submitItemsForWarehouse: (code, quantity) => dispatch(submitItemsForWarehouse(code, quantity))
})

export default connect(mapStateToProps, mapDispatchToProps)(WeeklyPlansForeman)
