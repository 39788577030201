/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { Box, Flex } from '@rebass/emotion'
import { useTranslation } from 'react-i18next'
import ReceiveInternalOrderFormConnector from 'connectors/forms/ReceiveInternalOrderFormConnector'
import InternalOrderRow from 'components/InternalOrderRow'
import RefreshButton from 'components/RefreshButton'
import orderStatuses, { handedOverOrders, statusesOrder } from 'constants/orderStatuses'
import { filteredHandOvers, FiltersBox, noFilterTranslated, NoItemsToShow } from 'components/FilterBox'
import * as R from 'ramda'
import { rowWrapperCss } from 'views/WarehouseState/styles'
import theme from 'config/theme'
import { ReactComponent as Arrow } from 'assets/arrow.svg'

const arrowCss = (opened) => css`
  width: 20px;
  height: 20px;
  transform: rotate(${opened ? 90 : 0}deg);
  transition: all 0.3s;
`

const Wrapper = (props) => (
  <Flex width={1} flexDirection='column' flexWrap='nowrap' onClick={props.onClick} css={props.className}>
    {props.children}
  </Flex>
)

const itemsLens = R.lensProp('items')
const orderItemRevisionLens = R.lensPath(['bomWeeklyPlan', 'bom', 'itemRevision'])

const WarehouseOrdersList = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [openedOrders, setOpenedOrders] = useState([])

  const toggleOrderExpansion = (orderId) => () => {
    const newOpenedOrders = R.includes(orderId, openedOrders)
      ? R.without([orderId], openedOrders)
      : R.append(orderId, openedOrders)
    setOpenedOrders(newOpenedOrders)
  }

  const refreshList = async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.getPersonalOrders(),
        props.listItemSubmissions()
      ])
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    refreshList()
  }, [])

  const getOrderStatus = (order, item) => {
    switch (true) {
      case item.status === orderStatuses.WAITING_FOR_AVAILABILITY:
        return <Box>{t(`warehouse.orders.statuses.waitingForAvailability`)}</Box>
      case item.status === orderStatuses.PROCESSING && item.undeliveredQuantity > 0 && item.notReceivedQuantity === 0:
        return <Box>{t(`warehouse.orders.statuses.${item.status}`)}</Box>
      case [orderStatuses.READY, orderStatuses.PROCESSING].includes(item.status):
        return <ReceiveInternalOrderFormConnector
          order={order}
          item={item}
          initialValues={{ quantity: item.notReceivedQuantity }}
        />
      default:
        return <Box>{t(`warehouse.orders.statuses.${item.status}`)}</Box>
    }
  }

  const getHandOverStatus = handOver => <Box>{t(`warehouse.handOvers.statuses.${handOver.status}`)}</Box>

  const [handedOverFilter, setHandedOverFilter] = useState(noFilterTranslated())

  const handleSelectHandOversFilter = newFilter => setHandedOverFilter(newFilter)

  const sortingByStatus = useCallback((a, b) => {
    return statusesOrder[a.status] > statusesOrder[b.status]
      ? 1
      : statusesOrder[a.status] < statusesOrder[b.status]
        ? -1
        : a.id.localeCompare(b.id)
  }, [])

  const sortedOrders = R.map(R.over(itemsLens, R.sort(sortingByStatus)))

  const displayedOrders = sortedOrders(props.orders)

  return (
    <Box m={10}>
      <Box>
        <Flex width={1}>
          <Box width={3 / 8} p={10} style={{ fontWeight: 700 }}>
            {t('warehouse.orders.currentOrders')}
          </Box>
          <Flex width={2 / 8}>
            <RefreshButton refreshFunction={refreshList} isLoading={loading} />
          </Flex>
          <Flex width={3 / 8} p={10} />
        </Flex>
        <Wrapper>
          <Flex width={9 / 12} p={6} flexWrap='nowrap' direction='row' css={rowWrapperCss}>
            <Flex width={0.5 / 9} />
            <Flex width={1.5 / 9}>{t('warehouse.orders.fullIndex')}</Flex>
            <Flex width={3 / 9}>{t('warehouse.orders.itemName')}</Flex>
            <Flex width={1 / 9}>{t('warehouse.orders.count')}</Flex>
            <Flex width={3 / 9}>{t('warehouse.orders.status')}</Flex>
          </Flex>
        </Wrapper>
        <Wrapper>
          {displayedOrders.length === 0
            ? <NoItemsToShow />
            : displayedOrders.map(order => {
              const isOpened = openedOrders.includes(order.id)
              const orderItemRevision = R.view(orderItemRevisionLens, order)
              const weeklyPlanQuantity = R.pathOr(t('warehouse.orders.notAvailable'), ['bomWeeklyPlan', 'quantity'], order)
              const week = R.pathOr(t('warehouse.orders.notAvailable'), ['bomWeeklyPlan', 'week'], order)
              return (
                <Fragment key={order.id}>
                  <Flex width={9 / 12} p={4} flexWrap='nowrap' direction='row' onClick={toggleOrderExpansion(order.id)} css={rowWrapperCss}>
                    <Flex width={0.5 / 9}>
                      <Arrow fill={isOpened ? theme.colors.lightGrey : theme.colors.black} css={arrowCss(isOpened)} />
                    </Flex>
                    <Flex width={1.5 / 9}>
                      {orderItemRevision?.fullIndex}
                    </Flex>
                    <Flex width={3 / 9}>
                      {orderItemRevision?.productionName || orderItemRevision?.name}
                    </Flex>
                    <Flex width={4 / 9}>
                      {t('warehouse.orders.weeklyPlanQuantity')}({week}): {weeklyPlanQuantity}
                    </Flex>
                  </Flex>
                  {isOpened && order.items?.map((item) => {
                    return (
                      <InternalOrderRow
                        key={item.id}
                        subjectId={order.id}
                        id={item.id}
                        deleteItemFunction={props.deleteInternalOrder}
                        item={item.itemRevision}
                        quantity={item.quantity}
                        apiStatus={order.status}
                        deliveredQuantity={item.deliveredQuantity}
                        status={getOrderStatus(order, item)}
                      />
                    )
                  })
                  }
                </Fragment>
              )
            })
          }
        </Wrapper>
        <Flex width={1} mt={10}>
          <Box width={1 / 2} p={9}>
            {t('warehouse.orders.latestHandOvers')}
          </Box>
        </Flex>
        <Wrapper>
          <FiltersBox optionsObj={handedOverOrders} value={handedOverFilter} onChange={handleSelectHandOversFilter} />
          {filteredHandOvers(props.latestHandOvers, handedOverFilter).length === 0
            ? <NoItemsToShow />
            : filteredHandOvers(props.latestHandOvers, handedOverFilter).map(handOver => {
              return <InternalOrderRow
                key={handOver.id}
                id={handOver.id}
                subjectId={handOver.id}
                deleteItemFunction={props.deleteSubmittedItem}
                item={handOver.itemRevision}
                quantity={handOver.quantity}
                apiStatus={handOver.status}
                createdAt={handOver.createdAt}
                status={getHandOverStatus(handOver)}
              />
            })}
        </Wrapper>
      </Box>
    </Box>
  )
}

export default WarehouseOrdersList
