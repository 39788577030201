import ApiInterceptor from './APIInterceptor'

const QualityControlService = {
  getItemBySerialNumber: (serialNumber) => ApiInterceptor.get(`/assembly/serial/${serialNumber}`),
  registerPositiveQualityCheckResults: ({ serialNumber }) => {
    return ApiInterceptor.post(`/qa/assembly/check_results`, { serialNumber, result: 'passed' })
  },
  registerNegativeQualityCheckResults: ({ serialNumber, comment }) => {
    return ApiInterceptor.post(`/qa/assembly/check_results`, { serialNumber, result: 'failed', comment })
  }
}

export default QualityControlService
