import React from 'react'
import { Field, Form } from 'redux-form'
import Button from 'components/Button'
import InputWithConstantValue from 'components/InputWithConstantValue'

const AddLocationForm = props => {
  const { locationPath } = props
  return (
    <div style={{ width: 400, margin: '0 auto' }}>
      <Form onSubmit={props.handleSubmit}>
        <Field
          component={InputWithConstantValue}
          name='name'
          label='nazwa'
          type='text'
          constantValue={locationPath ? `${locationPath}-` : null}
        />
        <Button type='submit'>Dodaj</Button>
      </Form>
    </div>
  )
}

export default AddLocationForm
