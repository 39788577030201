import * as R from 'ramda'

export const partsPath = ['assembly', 0, 'parts']
export const serialNumberPath = ['assembly', 0, 'serialNumber']
export const externalSerialNumberPath = ['externalParts', 0, 'serialNumber']

export const hasParts = R.pipe(
  R.pathOr([], partsPath),
  R.length,
  R.lt(0)
)

export const getSortedParts = R.pipe(
  item => R.propOr(R.pathOr([], partsPath, item), 'parts', item),
  R.sortBy(R.pipe(R.pathOr([], partsPath), R.length)),
  R.reverse
)
export const findParts = obj => R.propOr(R.pathOr([], partsPath, obj), 'parts', obj)

export const extractParts = R.pipe(
  findParts,
  res => R.concat(R.map(extractParts, res), res),
  R.flatten
)
