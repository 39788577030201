import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import i18n from 'config/i18n'
import 'config/polyfills/objectEntries'

if (console.everything === undefined && process.env.NODE_ENV === 'production') {
  console.everything = []

  console.defaultLog = console.log.bind(console)
  console.log = function () {
    console.everything.push({ 'type': 'log', 'datetime': Date().toLocaleString(), 'value': Array.from(arguments) })
    console.defaultLog.apply(console, arguments)
  }
  console.defaultError = console.error.bind(console)
  console.error = function () {
    console.everything.push({ 'type': 'error', 'datetime': Date().toLocaleString(), 'value': Array.from(arguments) })
    console.defaultError.apply(console, arguments)
  }
  console.defaultWarn = console.warn.bind(console)
  console.warn = function () {
    console.everything.push({ 'type': 'warn', 'datetime': Date().toLocaleString(), 'value': Array.from(arguments) })
    console.defaultWarn.apply(console, arguments)
  }
  console.defaultDebug = console.debug.bind(console)
  console.debug = function () {
    console.everything.push({ 'type': 'debug', 'datetime': Date().toLocaleString(), 'value': Array.from(arguments) })
    console.defaultDebug.apply(console, arguments)
  }
}

i18n()

ReactDOM.render(<App />, document.getElementById('root'))

serviceWorker.unregister()
