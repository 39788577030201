import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const Container = styled('div')(
  ({ theme, ...props }) => ({
    margin: !props.noMargin && theme.space[5],
    display: props.flex ? 'flex' : 'block',
    marginLeft: props.toRight && 'auto',
    alignItems: props.centerItems && 'center',
    flexFlow: props.columns && 'column nowrap',
    backgroundColor: props.backgroundColor || 'unset'
  })
)

Container.propTypes = {
  noMargin: PropTypes.bool,
  flex: PropTypes.bool,
  centerItems: PropTypes.bool,
  columns: PropTypes.bool,
  toRight: PropTypes.bool,
  backgroundColor: PropTypes.string
}

export default Container
