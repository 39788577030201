/** @jsx jsx */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { jsx, css } from '@emotion/core'
import { Flex } from '@rebass/emotion'
import { ReactComponent as DeleteIcon } from 'assets/cancel.svg'
import { ReactComponent as Loader } from 'assets/loader.svg'
import * as styles from 'views/WarehouseState/styles'
import orderStatuses from 'constants/orderStatuses'
import { includes } from 'ramda'
import moment from 'moment'
import Tooltip from 'components/Tooltip'
import { useTranslation } from 'react-i18next'
import { isFloat } from 'helpers/number'

const deleteIconCss = css`
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const InternalOrderRow = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const deleteItem = async () => {
    try {
      setLoading(true)
      await props.deleteItemFunction(props.id, props.subjectId)
    } catch (e) {
      setLoading(false)
    }
  }

  const displayDeleteIcon = includes(props.apiStatus, [orderStatuses.NEW, orderStatuses.PENDING])

  const shouldHighlight = props.createdAt && moment().subtract(1, 'd').isAfter(moment(props.createdAt))
  const quantityCss = css`
    color: ${shouldHighlight && 'red'};
  `

  return (
    <Flex width={9 / 12} p={6} css={styles.rowWrapperCssNoPointer} alignItems='center'>
      <Flex width={0.5 / 9}>
        {displayDeleteIcon &&
        (loading ? <Loader style={{ stroke: 'black' }} /> : <DeleteIcon onClick={deleteItem} css={deleteIconCss} />)}
      </Flex>
      <Flex width={1.5 / 9} as='h3'>{props.item?.fullIndex}</Flex>
      <Flex width={3 / 9} as='p'>{props.item?.name}</Flex>
      <Flex width={1 / 9} as='h3' css={quantityCss}>
        <Tooltip disabled={!shouldHighlight} content={t('warehouse.orders.createdMoreThenOneDayAgo')}>
          {Number.isInteger(props.deliveredQuantity) || isFloat(props.deliveredQuantity)
            ? `${props.deliveredQuantity} / ${props.quantity}`
            : props.quantity
          }
        </Tooltip>
      </Flex>
      <Flex width={3 / 9} as='h3'>
        {props.status}
      </Flex>
    </Flex>
  )
}

InternalOrderRow.propTypes = {
  apiStatus: PropTypes.string,
  createdAt: PropTypes.any,
  deleteItemFunction: PropTypes.func,
  id: PropTypes.string,
  item: PropTypes.object,
  quantity: PropTypes.number,
  status: PropTypes.any,
  subjectId: PropTypes.any
}

export default InternalOrderRow
