import APIInterceptor from './APIInterceptor'

const SerialNumberService = {
  getSerialNumberFormats: () => APIInterceptor.get(`/serial_number_formats`),
  createSerialNumberFormat: ({ name, format }) => APIInterceptor.post(`/serial_number_formats`, { name, format }),
  updateSerialNumberFormat: ({ name, format, serialNumberFormatId }) => APIInterceptor.patch(`/serial_number_formats/${serialNumberFormatId}`, { name, format }),
  deleteSerialNumberFormat: (serialNumberFormatId) => APIInterceptor.delete(`/serial_number_formats/${serialNumberFormatId}`)
}

export default SerialNumberService
