import { toast } from 'react-toastify'
import * as R from 'ramda'

export const errorHandler = (error, timeout = 15e3) => {
  console.error(error)
  const apiErrorMessage = error.response?.data?.message
  const api500 = R.pipe(
    R.path(['response', 'status']),
    R.toString,
    R.startsWith('50')
  )(error)
  const apiErrors = error.response?.data?.errors
  const apiErrorMessages = apiErrors && Object.values(apiErrors)
  const errorContainer = { containerId: 'statuses', autoClose: timeout }
  if (apiErrors) {
    apiErrorMessages.forEach(error => toast.error(`${error[0]}`, errorContainer))
  } else if (api500) {
    toast.error('Wystąpił błąd 50* wskazuje to na problemy w API', errorContainer)
  } else {
    toast.error(`${apiErrorMessage}`, errorContainer)
  }
}

export const vacuumTestErrorHandler = (error, timeout = 15e3) => {
  const errorMessage = R.pathOr('brak połączenia', ['response', 'data'], error)
  const errorContainer = { containerId: 'statuses', autoClose: timeout }
  toast.error(`${errorMessage}`, errorContainer)
}
