/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Field, Form } from 'redux-form'
import { useEffect } from 'react'
import Container from 'components/Container'
import LabelledDivider from 'components/LabelledDivider'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import Input from 'components/Input'
import ScanInput from 'components/ScanInput'
import { withRouter } from 'react-router-dom'
import { scanParser } from 'helpers/scanParser'
import Button from 'components/Button'
import Routes from 'constants/Routes'
import { propEq } from 'ramda'
import KeyCapture from 'components/KeyCapture'

export const inputCss = { display: 'block', border: '2px solid black', padding: 10, width: 'calc(100vw - 48px)' }

const OnboardItemForm = (props) => {
  const { t } = useTranslation()
  const item = props.location?.state?.item

  const onScan = async data => {
    const scan = scanParser(data)
    const isItem = scan.type === 'item'
    if (isItem) {
      props.pendingSubmissions.length < 1 && await props.listAllPendingSubmissions()
      const scannedItem = props.pendingSubmissions.find(propEq('code', scan.data))
      if (props.isInternalSubmission) {
        scannedItem && props.history.replace({
          pathname: `/internal-submission-view/item/${scan.data}`,
          state: { item: scannedItem }
        })
      } else {
        props.history.replace(`/on-boarding/item/${scan.data}`)
      }
    } else {
      props.change('destinationCode', scan.data)
    }
  }

  const submit = values => {
    const { quantity, destinationCode } = values

    const commonState = {
      quantity,
      destinationCode,
      itemCode: props.match.params.itemCode
    }

    props.isInternalSubmission
      ? props.history.replace({
        pathname: Routes.internalSubmissionFinalize,
        state: {
          ...commonState,
          item: props.location?.state?.item
        }
      })
      : props.history.replace({
        pathname: Routes.onBoardingFinalize,
        state: {
          sourceLocationId: props.warehouseInboundLocation.id,
          ...commonState
        }
      })
  }

  useEffect(
    () => {
      props.change('quantity', props.warehouseInboundLocation.quantity)
    }, [props?.warehouseInboundLocation])

  useEffect(() => {
    props.isInternalSubmission && props.change('quantity', props.item['totalNotAcknowledgedWarehouseSubmissionQuantity'])
  }, [props?.item?.totalNotAcknowledgedWarehouseSubmissionQuantity])

  useEffect(() => {
    props.isInternalSubmission && props.change('quantity', item['totalNotAcknowledgedWarehouseSubmissionQuantity'])
  }, [item?.totalNotAcknowledgedWarehouseSubmissionQuantity])

  const isQuantityActive = props.activeFields?.quantity?.active

  const changeLocationFloor = direction => {
    let splitted = props?.destinationCodeValue?.split('-')
    if (splitted && !isQuantityActive) {
      if (direction === 'down' && Number(splitted[2]) > 0) {
        splitted[2] = Number(splitted[2]) - 1
      } else if (direction === 'up' && Number(splitted[2]) < 10) {
        splitted[2] = Number(splitted[2]) + 1
      }
      props.change('destinationCode', splitted.join('-'))
    }
  }

  return (
    <div>
      <Form onSubmit={props.handleSubmit(submit)}>
        <Container>
          <Field
            component={Input}
            css={inputCss}
            numericKeyboard
            name='quantity'
            type='number'
            step='1'
          />
        </Container>
        <KeyCapture callback={() => changeLocationFloor('up')} keyToCapture={19} shouldBubble={isQuantityActive} />
        <KeyCapture callback={() => changeLocationFloor('down')} keyToCapture={20} shouldBubble={isQuantityActive} />
        <LabelledDivider text={t('common.location')} />
        <Field
          component={ScanInput}
          name='destinationCode'
          onScan={onScan}
        />
        <LabelledDivider text={t('warehouse.confirmingLocation')} />
        <Container flex centerItems>
          <Button type='submit' withIcon icon={Checkmark} iconProps={{ fill: '#fff' }}>
            {t('warehouse.confirmLocation')}
          </Button>
        </Container>
      </Form>
    </div>
  )
}

export default withRouter(OnboardItemForm)
