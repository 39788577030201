/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import QrCodeReader from 'components/QrCodeReader'
import { useTranslation } from 'react-i18next'
import Breadcrumb from 'components/Breadcrumb'
import Container from 'components/Container'
import SearchItemsSelect from 'components/SearchItemsSelect/index'
import LabelledDivider from 'components/LabelledDivider'

const GetSupplyScreen = () => {
  const { t } = useTranslation()
  const [isSelectFocused, setSelectFocused] = useState(false)

  const target = '/supply/item'

  return (
    <div>
      <Breadcrumb>{t('supply.location')}</Breadcrumb>
      <LabelledDivider text={t('common.reading')} />
      <Container>
        <SearchItemsSelect
          target={target}
          isSelectFocused={isSelectFocused}
          setSelectFocused={setSelectFocused}
        />
        {!isSelectFocused && <div css={{ textAlign: 'center', margin: '20px 0' }}>{t('common.or')}</div>}
        <QrCodeReader destination={target} noHeight />
      </Container>
    </div>
  )
}

export default GetSupplyScreen
