import { createSelector } from 'reselect'
import { transformToArray } from 'helpers/redux'
import * as R from 'ramda'

const getBomItems = state => state.weeklyPlans.entries
const getDailyBomItems = state => state.weeklyPlans.dailyPlansEntries
const getBomItemsQuantities = state => state.weeklyPlans.availableInWarehouse

export const getBomItemsAsArray = createSelector(
  getBomItems,
  transformToArray
)

export const getFetchingYears = createSelector(
  state => state.weeklyPlans.fetchingYears,
  R.identity
)

export const getDailyBomItemsAsArray = createSelector(
  getDailyBomItems,
  R.map(transformToArray)
)

export const getBomItemsQuantitiesSelector = createSelector(
  getBomItemsQuantities,
  quantities => quantities
)
