import ApiInterceptor from './APIInterceptor'
import * as qs from 'query-string'

const WeeklyPlansService = {
  getBomItems: (year) => ApiInterceptor.get(`/weekly_plans/items?${qs.stringify({ year })}`),
  getDailyBomItems: (week) => ApiInterceptor.get(`/daily_plans/items?${qs.stringify({ week })}`),
  getBomItemsQuantities: () => ApiInterceptor.get('/weekly_plans/quantities'),
  updateWeeklyPlans: data => ApiInterceptor.patch('/weekly_plans', { data }),
  updateDailyPlans: data => ApiInterceptor.patch('/daily_plans', { data }),
  executeWeeklyPlanMaterialsOrder: ({ week, year }) => ApiInterceptor.post(`/weekly_plans/${year}/${week}/execute`)
}

export default WeeklyPlansService
