/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@rebass/emotion'
import theme from 'config/theme'
import Button from 'components/Button'
import { getAssemblyTime } from './StationBox'
import moment from 'moment'

const keysHandler = (onEnter, onEsc) => event => {
  switch (event.keyCode) {
    case 13:
      return onEnter()
    case 27:
      return onEsc
    default:
      return event
  }
}

const SetQuantityModal = ({ onClose, updatePlans, maxQuantity, cancelModal, purpose, editPlan, manufactured,
  currentValue, onTooltip, bom, planFinishTimeOffset, dayIndex, currentWeek }) => {
  const { t } = useTranslation()
  const [quantity, setQuantity] = useState(purpose === 'setPlan' ? 1 : Math.min(currentValue, maxQuantity))
  const [forceRefresh, setForceRefresh] = useState(false)
  const [error, setError] = useState(null)
  const acceptQuantity = useCallback(() => {
    if (!error) {
      purpose === 'setPlan'
        ? updatePlans(quantity)
        : editPlan(quantity)
      onClose()
    }
  }, [quantity, updatePlans, editPlan, error])

  useEffect(() => {
    !(quantity >= Math.max(1, manufactured) && quantity <= maxQuantity)
      ? setError(true)
      : setError(false)
  }, [setError, quantity, maxQuantity, manufactured])

  const close = () => {
    if (purpose === 'setPlan') cancelModal()
    onClose()
  }

  const inputRef = useRef(null)

  useEffect(() => {
    setTimeout(() => setForceRefresh(!forceRefresh), 100)
  }, [])

  // eslint-disable-next-line no-unused-expressions
  inputRef.current?.focus()

  const planDuration = (quantity - manufactured) * getAssemblyTime(bom)
  const isToday = (dayIndex === moment().day() && currentWeek === moment().week())
  const planFinishTime = isToday
    ? moment().add(planFinishTimeOffset + planDuration, 'seconds')
    : moment().hour(7).minutes(0).seconds(0).add(Math.max(planFinishTimeOffset + planDuration, 0), 'seconds')

  return <div>
    <Box css={{
      padding: 30,
      backgroundColor: theme.colors.white,
      boxShadow: onTooltip ? 'none' : `1px 1px 15px ${theme.colors.darkGrey}`,
      position: 'relative',
      width: 'auto'
    }}>
      <Fragment>
        <Box css={{ margin: '10px 0' }}>
          <Box css={{ fontSize: 24 }}><b>{bom?.bom.itemRevision.roductionName || bom?.bom.itemRevision.name}</b></Box>
          <Box css={{ fontSize: 18 }}><b>index:</b> {bom?.bom.itemRevision.fullIndex}</Box>
        </Box>
        <Flex css={{ padding: 20, fontSize: 24 }}>
          <div
            onClick={close}
            style={{
              position: 'absolute',
              top: 10,
              right: 10,
              fontSize: 24,
              cursor: 'pointer'
            }}
          >
                X
          </div>
          <Flex flexDirection='column' alignItems='center'>
            <Box>
              <b css={{ color: theme.colors.black }}>zaplanowano:</b>
              <input
                ref={inputRef}
                style={{
                  width: '70px',
                  border: error ? `1px solid ${theme.colors.error}` : 'none',
                  boxShadow: error ? `inset 0 0 5px ${theme.colors.error}` : 'none',
                  display: 'inline-block',
                  marginLeft: 10
                }}
                value={quantity}
                onKeyDown={keysHandler(acceptQuantity, close)}
                min={purpose === 'setPlan' ? 1 : Math.max(1, manufactured)}
                max={maxQuantity}
                onChange={e => setQuantity(Math.min(maxQuantity, Math.max(Number(e.target.value), 1)))}
                type='number'
                name='quantityInput'
                autoFocus
              />
            </Box>
            <Box><b css={{ color: theme.colors.grey }}>wykonano:</b> {manufactured}</Box>
            <Box><b css={{ color: theme.colors.grey }}>planowany czas skończenia:</b> {
              planFinishTime.isAfter(moment().endOf('day'))
                ? planFinishTime.format('LLL')
                : planFinishTime.format('HH:mm')
            }</Box>
          </Flex>
        </Flex>
      </Fragment>
      <Flex justifyContent={'center'} my={3}>
        <Box width={1 / 2}>
          <Button
            color={theme.colors.lightBlue}
            onClick={acceptQuantity}
          >
            {t('weeklyPlansForeman.modal.confirm')}
          </Button>
        </Box>
      </Flex>
    </Box>
  </div>
}

export default SetQuantityModal
