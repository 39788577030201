/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import { useCallback, useEffect, useState } from 'react'
import { cellCss, inlineInputCss } from 'views/Catalogue/styles'
import { editItemRevision } from 'ducks/warehouseState'
import { connect } from 'react-redux'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { useTranslation } from 'react-i18next'

const InlineEdit = (props) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const getDefaultValue = children => Array.isArray(children) ? children.join('') : children || ''
  const [text, setText] = useState(getDefaultValue(props.children))

  useEffect(() => {
    setText(getDefaultValue(props.children))
  }, [props.children])

  const handleEditStart = useCallback(() => setEditing(true), [])
  const handleEditItem = () => {
    confirmAlert({
      title: <div css={{ marginBottom: 20 }}>{t('catalogue.confirmationDialogue.confirmHeader')}</div>,
      message: <div>
        {t('catalogue.confirmationDialogue.from')}
        <br /><br />
        <b>{props.children || 'n/a'}</b>
        <br /><br />
        {t('catalogue.confirmationDialogue.to')}
        <br /><br />
        <b>{text}</b>
      </div>,
      onKeypressEscape: handleInputBlur,
      buttons: [
        {
          label: t('catalogue.confirmationDialogue.yes'),
          onClick: async () => {
            await props.editItemRevision({ itemId: props.item.itemId, field: props.fieldName, value: text, revisionId: props.item.id })
            setEditing(false)
          }
        },
        {
          label: t('catalogue.confirmationDialogue.no'),
          onClick: handleInputBlur
        }
      ]
    })
  }

  const handleInputBlur = () => {
    setEditing(false)
    setText(getDefaultValue(props.children))
  }
  const handleInputChange = useCallback((e) => setText(e.target.value), [])

  const handleKeyDown = event => {
    const keyCode = event.which || event.keyCode
    keyCode === 13 && handleEditItem()
    keyCode === 27 && handleInputBlur()
  }

  return editing
    ? <div css={css`
        ${cellCss};
        padding: 5px;
        display: flex;
        width: ${props.maxWidth || 60}px;
      `}>
      <input
        name='value'
        value={text}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        autoFocus
        type='text'
        onBlur={handleInputBlur}
        css={inlineInputCss}
      />
    </div>
    : <div
      css={css`
        ${cellCss};
        cursor: pointer;
      `}
      className={props.className}
      onClick={handleEditStart}
    >
      {text}
    </div>
}

const mapDispatchToProps = dispatch => ({
  editItemRevision: values => dispatch(editItemRevision(values))
})

export default connect(null, mapDispatchToProps)(InlineEdit)

InlineEdit.propTypes = {
  className: PropTypes.string,
  editItemRevision: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  maxWidth: PropTypes.number
}
