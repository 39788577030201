import OnboardItemForm from 'forms/OnboardItemForm'
import { connect } from 'react-redux'
import { reduxForm, formValueSelector, getFormMeta } from 'redux-form'
import i18n from 'i18next'
import { listAllPendingSubmissions } from 'ducks/itemsSubmissions'
import { getSubmissionsAsArray } from 'selectors/internalSubmissions'

const formName = 'OnboardItemForm'

const selector = formValueSelector(formName)

let Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate: (values, props) => {
    const errors = {}
    const requiredFields = ['quantity', 'destinationCode']
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = i18n.t('common.fieldRequired')
      }
    })

    if (values['quantity'] && !(Number(values['quantity']) > 0)) {
      errors['quantity'] = i18n.t('common.numberMustBePositive')
    }

    if (!props.isInternalSubmission && Number(values['quantity']) > props.warehouseInboundLocation.quantity) {
      errors['quantity'] = i18n.t('errors.form.maxCount', { number: props.warehouseInboundLocation.quantity })
    }

    if (props.isInternalSubmission && Number(values['quantity']) > props.item.totalNotAcknowledgedWarehouseSubmissionQuantity) {
      errors['quantity'] = i18n.t('errors.form.maxCount', { number: props.item.totalNotAcknowledgedWarehouseSubmissionQuantity })
    }

    return errors
  }
})(OnboardItemForm)

const mapStateToProps = state => ({
  warehouseInboundLocation: state.inboundZone.warehouseInboundLocation,
  pendingSubmissions: getSubmissionsAsArray(state),
  destinationCodeValue: selector(state, 'destinationCode'),
  activeFields: getFormMeta(formName)(state)
})

const mapDispatchToProps = dispatch => ({
  listAllPendingSubmissions: () => dispatch(listAllPendingSubmissions())
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
