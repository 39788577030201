import BomService from 'services/BomService'
import * as R from 'ramda'
import { transformById } from 'helpers/redux'

const GET_ALL_BOMS = Symbol('GET_ALL_BOMS')
const GET_ALL_BOMS_SUCCESS = Symbol('GET_ALL_BOMS_SUCCESS')
const GET_ALL_BOMS_FAILED = Symbol('GET_ALL_BOMS_FAILED')

const getAllBomsRequest = () => ({ type: GET_ALL_BOMS })
const getAllBomsSuccess = (data) => ({ type: GET_ALL_BOMS_SUCCESS, data })
const getAllBomsFailed = () => ({ type: GET_ALL_BOMS_FAILED })

export const getAllBoms = (bomCode) => {
  return async dispatch => {
    dispatch(getAllBomsRequest())
    try {
      const { data } = await BomService.getAllBoms(bomCode)
      return dispatch(getAllBomsSuccess(data))
    } catch (e) {
      return dispatch(getAllBomsFailed(e))
    }
  }
}

const GET_BOM_BY_CODE = Symbol('GET_BOM_BY_CODE')
const GET_BOM_BY_CODE_SUCCESS = Symbol('GET_BOM_BY_CODE_SUCCESS')
const GET_BOM_BY_CODE_FAILED = Symbol('GET_BOM_BY_CODE_FAILED')

const requestGetBom = () => ({ type: GET_BOM_BY_CODE })
const getBomSuccess = (data) => ({ type: GET_BOM_BY_CODE_SUCCESS, data })
const getBomFailed = () => ({ type: GET_BOM_BY_CODE_FAILED })

const getBom = (bomCode) => {
  return async dispatch => {
    dispatch(requestGetBom())
    try {
      const { data } = await BomService.getBom(bomCode)
      return dispatch(getBomSuccess(data))
    } catch (e) {
      return dispatch(getBomFailed(e))
    }
  }
}

export const getBomIfNeeded = bomCode => {
  return async (dispatch, getState) => {
    const { entries } = getState().bom
    const currentBom = R.filter(bom => bom.code === bomCode, entries)
    if (R.isEmpty(currentBom)) {
      await dispatch(getBom(bomCode))
    }
  }
}

const UPDATE_BOM_FIELDS = Symbol('UPDATE_BOM_FIELDS')
export const UPDATE_BOM_FIELDS_SUCCESS = Symbol('UPDATE_BOM_FIELDS_SUCCESS')
const UPDATE_BOM_FIELDS_FAILED = Symbol('UPDATE_BOM_FIELDS_FAILED')

const updateBomFieldsRequest = () => ({ type: UPDATE_BOM_FIELDS })
const updateBomFieldsSuccess = (data, itemId) => ({ type: UPDATE_BOM_FIELDS_SUCCESS, data, itemId })
const updateBomFieldsFailed = () => ({ type: UPDATE_BOM_FIELDS_FAILED })

export const updateBomFields = (values, itemId) => {
  return async dispatch => {
    dispatch(updateBomFieldsRequest())
    try {
      const { data } = await BomService.updateBom(values)
      dispatch(updateBomFieldsSuccess(data, itemId))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(updateBomFieldsFailed(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  entries: {},
  allBomsEntries: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_BOM_BY_CODE_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.data.id]: action.data
        }
      }
    case GET_ALL_BOMS_SUCCESS:
      return R.evolve({
        allBomsEntries: R.always(transformById(action.data))
      }, state)
    default:
      return state
  }
}
