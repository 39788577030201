import { createSelector } from 'reselect'
import { transformToArray } from 'helpers/redux'
import * as R from 'ramda'

export const selectWorkstations = state => state.workstations.entries
export const selectWereWorkstationsFeched = state => state.workstations.wasFetched

export const selectWorkstationsAsArray = createSelector(
  selectWorkstations,
  transformToArray
)

export const getWorkstationsAsUserEditOptions = createSelector(
  selectWorkstations,
  stations => {
    const stationsArray = transformToArray(stations).filter(station => R.isNil(R.prop('parentAssemblyPostId', station)))
    return stationsArray.reduce((allStations, nextStation) => {
      const mappedStation = nextStation.subPosts?.length
        ? nextStation.subPosts.map(post => ({
          value: post.id,
          label: post.number
            ? `${post.number} ${post.name} ${post.subindex}`
            : `${post.name} ${post.subindex}`
        }))
        : [{ value: nextStation.id, label: nextStation.number ? nextStation.number + ' ' + nextStation.name : nextStation.name }]
      return [...allStations, ...mappedStation]
    }, [])
  }
)
