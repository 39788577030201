/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { Box, Flex } from '@rebass/emotion'
import theme from 'config/theme'
import QrCodeReader from 'components/QrCodeReader'
import { scanParser } from 'helpers/scanParser'
import { withRouter } from 'react-router-dom'
import { find } from 'ramda'
import { sortLocationsByCode } from 'helpers/array'

const quantityCss = {
  position: 'absolute',
  right: 0,
  bottom: 0
}

const InventoryTab = ({ inventory, selectInventory, currentItem, isSelected }) => {
  return (
    <Flex
      bg={isSelected ? 'white' : 'darkGrey'}
      p={4}
      pr={6}
      pb={7}
      mb={6}
      color={isSelected ? 'darkGrey' : 'white'}
      width={0.47}
      css={{
        position: 'relative',
        border: `2px solid ${theme.colors.darkGrey}`
      }}
      onClick={selectInventory(inventory)}
    >
      <Box><h3>{inventory.warehouseLocation?.code}</h3></Box>
      <Box p='8px' fontSize='12px' css={quantityCss}>{inventory.quantity} {currentItem.unit}</Box>
    </Flex>
  )
}

const InventoryTabs = (props) => {
  const [selectedInventoryId, selectId] = useState('')
  const selectInventory = (inventory) => () => {
    selectId(inventory.id)
    props.onInventoryTap(inventory)
    setTimeout(() => window.scrollBy(0, 15000), 0)
  }

  const onScan = data => {
    const scan = scanParser(data)
    const isItem = scan.type === 'item'
    if (isItem) {
      switch (true) {
        case props.isMoveItemScreen:
          return props.history.replace(`/move/select/${scan.data}`)
        case props.isRecalculationScreen:
          return props.history.replace(`/warehouse/recalculate/${scan.data}`)
        default:
          return props.history.replace(`/warehouse/hand-over/${scan.data}`)
      }
    } else {
      const item = find(inventory => inventory.warehouseLocation?.code === scan.data, props.inventories)
      item && selectInventory(item)()
    }
  }

  return (
    <Flex p={5} color='white' flexWrap='wrap' justifyContent='space-between'>
      <QrCodeReader onScan={onScan} invisible />
      {sortLocationsByCode(props.inventories)?.map((inventory) => {
        return (
          <InventoryTab
            key={inventory.id}
            selectInventory={selectInventory}
            isSelected={inventory.id === selectedInventoryId}
            currentItem={props.currentItem}
            inventory={inventory}
          />
        )
      })}
    </Flex>
  )
}

export default withRouter(InventoryTabs)
