/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import {
  makeClearIndicatorStyles,
  makeControlStyles,
  makeDropdownStyles,
  makeMenuStyles,
  makeSeparatorStyles
} from 'components/Select'
import { useEffect, useState } from 'react'
import ReactSelect from 'react-select/lib/Async'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Option from './Option'
import DropdownIndicator from './DropdownIndicator'
import { makeItemsOptions } from 'helpers/select'
import debounce from 'lodash.debounce'
import WarehouseService from 'services/WarehouseService'

const controlCss = {
  border: 'none',
  borderBottom: '1px solid black',
  margin: '10px 0'
}

const deboucedApiCall = debounce(WarehouseService.getItems, 100, { leading: true })

const SearchItemsSelect = ({ inputValue, history, setSelectFocused, onInputChange, target, ...props }) => {
  const { t } = useTranslation()
  const getNoOptionMessage = props => props.inputValue.length >= 3
    ? <span>{t('common.nothingFoundFor')} <b>{props.inputValue}</b></span>
    : <span>{t('common.startSearch')}</span>
  const getLoadingMessage = () => t('common.searching')

  const [defaultOptions, setOptions] = useState([])

  const loadOptions = async (value = '', viewOptions) => {
    if (value.length >= 3) {
      const response = await deboucedApiCall(value, props.locationCode)
      return viewOptions(makeItemsOptions(response))
    }
  }

  const getInitialOptions = async () => {
    await loadOptions(inputValue, setOptions)
  }

  useEffect(() => {
    inputValue && getInitialOptions()
  }, [])

  return (
    <ReactSelect
      noOptionsMessage={getNoOptionMessage}
      loadingMessage={getLoadingMessage}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      closeOnSelect={false}
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      onChange={option => history.push(`${target}/${option.value.fullIndex}`)}
      placeholder={t('common.searchInDatabase')}
      components={{
        Option,
        DropdownIndicator
      }}
      styles={{
        container: (provided) => ({
          ...provided,
          width: '100%',
          display: 'flex',
          flexFlow: 'row nowrap',
          justifyContent: 'center'
        }),
        control: (provided, state) => ({
          ...makeControlStyles(provided, state),
          ...controlCss,
          width: state.isFocused ? '90%' : '70%'
        }),
        indicatorSeparator: makeSeparatorStyles,
        dropdownIndicator: makeDropdownStyles,
        clearIndicator: makeClearIndicatorStyles,
        menu: makeMenuStyles
      }}
      autoFocus={!!inputValue}
      onBlur={(e) => {
        e.preventDefault()
        setSelectFocused(false)
      }}
      onFocus={() => setSelectFocused(true)}
      defaultOptions={defaultOptions}
      defaultMenuIsOpen={!!inputValue}
      name={'search'}
      loadOptions={loadOptions}
      onInputChange={onInputChange}
      inputValue={inputValue}
    />
  )
}

SearchItemsSelect.propTypes = {
  target: PropTypes.string.isRequired,
  setSelectFocused: PropTypes.func.isRequired
}

export default withRouter(SearchItemsSelect)
