import ChangeQuantityForm from 'forms/ChangeQuantityForm'
import { connect } from 'react-redux'
import { getFormMeta, reduxForm } from 'redux-form'
import { getDiscrepancyComments } from 'ducks/discrepancyComments'
import i18n from 'i18next'

const formName = 'ChangeQuantityForm'

let Form = reduxForm({
  form: formName,
  validate: values => {
    const errors = {}
    const requiredFields = ['quantity']
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = i18n.t('common.fieldRequired')
      }
    })

    if (values['quantity'] && !values['quantity'] > '0') {
      errors['quantity'] = i18n.t('common.numberMustBePositive')
    }

    return errors
  }
})(ChangeQuantityForm)

const mapStateToProps = state => ({
  formMeta: getFormMeta(formName)(state),
  discrepancyComments: state.discrepancyComments.entries.map(comment => ({ value: comment, label: comment }))
})

const mapDispatchToProps = dispatch => ({
  getDiscrepancyComments: () => dispatch(getDiscrepancyComments())
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
