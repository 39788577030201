import { authorizationError, events } from 'services/APIInterceptor'
import React, { useEffect, useState } from 'react'
import Routes from 'constants/Routes'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from 'ducks/user'

const LogoutWhenBlacklisted = (props) => {
  const [isTokenBlacklisted, blackListToken] = useState(false)

  const handleError = () => blackListToken(true)

  const logoutWhenBlacklisted = async () => {
    props.authenticated && events.on(authorizationError, handleError)
    props.authenticated && isTokenBlacklisted && await props.logout(true)
    props.authenticated && isTokenBlacklisted && props.history.push(Routes.login)
    blackListToken(false)
  }

  useEffect(() => {
    logoutWhenBlacklisted()
    return () => events.off(authorizationError, handleError)
  })

  return (
    <div>
      {props.children}
    </div>
  )
}

const mapStateToProps = state => ({
  authenticated: state.user.authenticated
})

const mapDispatchToProps = dispatch => ({
  logout: (noApi) => dispatch(logout(noApi))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LogoutWhenBlacklisted))
