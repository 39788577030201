import APIInterceptor from 'services/APIInterceptor'

const interceptor = APIInterceptor

const ColorsService = {
  getColorsList: (revisionId) => {
    return interceptor.get(`/items/revisions/${revisionId}/color-versions`)
  },
  updateColorsList: (revisionId, body) => {
    return interceptor.patch(`/items/revisions/${revisionId}/color-versions/update`, { color_versions: body })
  },
  addNewColorVersion: (code, name) => {
    return interceptor.post(`/color-versions`, { code, name })
  },
  deleteColorVersion: (colorVersionId) => {
    return interceptor.delete(`/color-versions/${colorVersionId}`)
  },
  updateColorVersion: (colorVersionId, name, code) => {
    return interceptor.patch(`/color-versions/${colorVersionId}`, { name, code })
  }
}

export default ColorsService
