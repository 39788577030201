import ApiInterceptor from './APIInterceptor'

const UsersService = {
  getUsers: () => ApiInterceptor.get('/users'),
  deleteUserById: (userId) => ApiInterceptor.delete(`/users/${userId}`),
  editUserById: (userId, values) => ApiInterceptor.patch(`/users/${userId}`, values),
  assignUserToStations: (userId, values) => ApiInterceptor.patch(`/users/${userId}/assembly_posts`, { assembly_post_ids: values }),
  addUser: (values) => ApiInterceptor.post(`/users`, values)
}

export default UsersService
