import styled from '@emotion/styled'
import theme from 'config/theme'

const defaultSize = 30

export const iconCss = {
  width: '50%',
  height: '50%',
  fill: theme.colors.white
}

const IconBox = styled('div')(
  ({ ...props }) => {
    const getSize = props.size ? props.size : defaultSize
    return ({
      width: getSize,
      height: getSize,
      background: props.color || theme.colors.black,
      border: props.border && `2px solid ${props.border}`,
      borderRadius: getSize / 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: props.cursor || 'initial'
    })
  }
)

export default IconBox
