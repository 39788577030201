/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useEffect, useState } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Flex } from '@rebass/emotion'
import theme from 'config/theme'
import { connect } from 'react-redux'
import { navbarItems } from 'constants/navbarItems'
import { useTranslation } from 'react-i18next'
import enbioLogo from 'assets/enbio-logo.png'
import { ReactComponent as LogoutIcon } from 'assets/logout-icon.svg'
import Routes from 'constants/Routes'
import { getUserName, getUserRoles } from 'selectors/user'
import { Roles } from 'constants/Roles'
import { includesInArray } from 'helpers/array'
import * as R from 'ramda'
import CancelledInternalOrdersModal from 'views/WarehouseState/CancelledInternalOrdersModal'
import { selectAllCancelledInternalOrders } from 'selectors/warehouseState'
import { getCancelledInternalOrders } from 'ducks/warehouseState'

const navbarCss = css`
    background-color: ${theme.colors.white};
    z-index: 999;
    height: 70px;
    background-color: ${theme.colors.white};
    box-shadow: 0 0 10px ${theme.colors.darkGrey};
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    font-weight: 700;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
  `

const menuItemCss = (isActive) => css`
    transition: all .3s ease-in-out;
    padding: 20px;
    position: relative;
    color: ${isActive ? theme.colors.lightBlue : theme.colors.darkGrey};
    
      &:hover {
        color: ${theme.colors.lightBlue};
      }
      
      & > div {
        display: inline-block;
        text-align: center;
      }
   `

const dropdownMenuContentStyles = isVisible => {
  const commonStyle = css`  
  background-color: ${theme.colors.white};
  box-shadow: 0px 10px 10px 0px rgba(0,0,0,0.25);
  position: absolute;
  top: 45px;
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;`

  return (isVisible ? css`
  ${commonStyle};
  visibility: visible;
  opacity: 1;
  transition: opacity 0.1s linear;
` : css`
  ${commonStyle};
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 0.2s, opacity 0.2s linear;
`)
}

const dropdownMenuPositionStyles = (isActive = false, hasBottomBorder = false) => css`
    padding: 20px 15px;
    position: relative;
    border-bottom: ${hasBottomBorder ? `1px solid ${theme.colors.darkGrey}` : 'none'} ;
    transition: all 0.3s;
    color: ${isActive ? theme.colors.lightBlue : theme.colors.darkGrey};
    
      &:hover {
        color: ${theme.colors.lightBlue};
      }
      & > div {
        display: inline-block;
        text-align: center;
      }
`

const visibleFor = [Roles.ADMIN, Roles.FOREMAN, Roles.PRODUCTION_MANAGER]

const MenuDropDown = ({ currentPath, catalogueElement, currentUserRoles }) => {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation()

  const visibilityHandler = targetValue => () => {
    const shouldBeVisible = includesInArray(currentUserRoles, visibleFor)

    if (shouldBeVisible) {
      setIsVisible(targetValue)
    } else {
      return () => {}
    }
  }

  const isCataloguePathActive = Routes.catalogue === currentPath
  const isUsersPathActive = Routes.users === currentPath
  const isWorkstationsPathActive = Routes.workstations === currentPath
  const isBomsPathActive = currentPath.includes(Routes.boms)
  const isSerialNumberFormatsPathActive = Routes.serialNumberFormats === currentPath
  const shouldDisplayBomsImports = includesInArray(currentUserRoles, [Roles.ADMIN, Roles.TECHNOLOGIST])
  const isAdmin = includesInArray(currentUserRoles, [Roles.ADMIN])

  return (
    <div
      css={{ position: 'relative' }}
      onMouseOver={visibilityHandler(true)}
      onMouseOut={visibilityHandler(false)
      }>
      <Link
        to={Routes.catalogue}
        css={menuItemCss(isCataloguePathActive)}
      >
        {catalogueElement.name}
      </Link>
      <div css={dropdownMenuContentStyles(isVisible)}>
        <Link
          to={Routes.catalogue}
          css={dropdownMenuPositionStyles(isCataloguePathActive, true)}
        >
          <div>{t('navbar.dropDownMenu.items')}</div>
        </Link>
        <Link
          to={Routes.users}
          css={dropdownMenuPositionStyles(isUsersPathActive, true)}
        >
          <div>{t('navbar.dropDownMenu.users')}</div>
        </Link>
        <Link
          to={Routes.workstations}
          css={dropdownMenuPositionStyles(isWorkstationsPathActive, shouldDisplayBomsImports)}
        >
          <div>{t('navbar.dropDownMenu.stations')}</div>
        </Link>
        {shouldDisplayBomsImports && (
          <Link
            to={Routes.boms}
            css={dropdownMenuPositionStyles(isBomsPathActive, isAdmin)}
          >
            <div>{t('navbar.dropDownMenu.boms')}</div>
          </Link>
        )}
        {isAdmin && (
          <Link
            to={Routes.serialNumberFormats}
            css={dropdownMenuPositionStyles(isSerialNumberFormatsPathActive)}
          >
            <div>{t('navbar.dropDownMenu.serialNumberFormats')}</div>
          </Link>
        )}
      </div>
    </div>
  )
}

const WarehouseDropdown = ({ currentUserRoles }) => {
  const [isVisible, setIsVisible] = useState(false)

  const setVisibility = bool => () => {
    setIsVisible(bool)
  }

  const isAdmin = includesInArray(currentUserRoles, [Roles.ADMIN])

  return (
    <div
      css={{ position: 'relative' }}
      onMouseOver={setVisibility(true)}
      onMouseOut={setVisibility(false)
      }>
      {isAdmin && (
        <Link
          to={Routes.warehouse}
          css={menuItemCss(isVisible)}
        >
          <div>Magazyn</div>
        </Link>
      )}
      <div css={dropdownMenuContentStyles(isVisible)}>
        <Link
          to={Routes.manageLocations}
          css={dropdownMenuPositionStyles(false)}
        >
          <div>Lokalizacje</div>
        </Link>
      </div>
    </div>
  )
}

const ReportsDropdown = ({ currentUserRoles, cancelledInternalOrders, getCancelledInternalOrders }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [cancelledInternalOrdersModalVisibility, setCancelledInternalOrdersModalVisibility] = useState(false)

  useEffect(() => {
    getCancelledInternalOrders()
  }, [])

  const setVisibility = bool => () => {
    setIsVisible(bool)
  }

  const allowedRoles = [Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN]
  const areReportsAllowedForCurrentRole = includesInArray(currentUserRoles, allowedRoles)

  return areReportsAllowedForCurrentRole ? (
    <Fragment>
      <div
        css={css`position: relative; z-index: 300;`}
        onMouseOver={setVisibility(true)}
        onMouseOut={setVisibility(false)
        }>
        <div css={menuItemCss(isVisible)}>
          <div>Raporty</div>
        </div>
        <div css={dropdownMenuContentStyles(isVisible)}>
          <div css={dropdownMenuPositionStyles(false)} onClick={() => {
            setIsVisible(false)
            setCancelledInternalOrdersModalVisibility(true)
          }}>
            <div>Anulowane Zamówienia</div>
          </div>
        </div>
      </div>
      {cancelledInternalOrdersModalVisibility && (
        <CancelledInternalOrdersModal
          onClose={() => setCancelledInternalOrdersModalVisibility(false)}
          cancelledInternalOrders={cancelledInternalOrders}
        />
      )}
    </Fragment>
  ) : null
}

const Navbar = (props) => {
  if (!props.userRoles) return null
  const { t } = useTranslation()
  const currentPath = props.location.pathname

  return (
    <div>
      <div style={{ height: 70, width: '100%' }} />
      <Flex css={navbarCss}>
        <img style={{ width: '150px' }} src={enbioLogo} alt={'Enbio Logo'} />
        <Flex alignItems={'center'}>
          {navbarItems(t).map((element, index) => {
            const currentElementPath = element.path
            const currentElementName = element.name
            const isReportSection = !!element.isReportSection
            const currentUserRoles = props.userRoles
            const isCatalogue = currentElementPath === Routes.catalogue
            const isWarehouse = currentElementPath === Routes.warehouse
            const isActive = currentPath === currentElementPath

            switch (true) {
              case R.not(includesInArray(element.roles, currentUserRoles)):
                return null
              case isReportSection:
                return <ReportsDropdown
                  key={currentElementName}
                  currentUserRoles={currentUserRoles}
                  cancelledInternalOrders={props.cancelledInternalOrders}
                  getCancelledInternalOrders={props.getCancelledInternalOrders}
                />
              case isCatalogue:
                return (<MenuDropDown
                  key={currentElementName}
                  currentPath={currentPath}
                  catalogueElement={element}
                  currentUserRoles={currentUserRoles}
                />)
              case isWarehouse:
                return (
                  <WarehouseDropdown
                    key={currentElementName}
                    currentUserRoles={currentUserRoles}
                  />
                )
              case !isCatalogue:
                return (<Link
                  key={currentElementName}
                  css={menuItemCss(isActive)}
                  to={currentElementPath}>
                  <div key={`menuItem-${index}`}>
                    {currentElementName}
                  </div>
                </Link>)
            }
          })}
          <Flex
            style={{
              height: '70px',
              alignItems: 'center',
              borderLeft: `1px solid ${theme.colors.lightGrey}`,
              paddingLeft: '20px',
              marginLeft: '20px',
              color: theme.colors.lightGrey }}>
            <p>{props.userName}</p>
          </Flex>
          <Link to={Routes.logout}>
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              style={{
                padding: 10,
                marginLeft: 20,
                borderRadius: '10px',
                width: 40,
                height: 40,
                backgroundColor: theme.colors.error
              }}>
              <LogoutIcon style={{ fill: theme.colors.white }} />
            </Flex>
          </Link>

        </Flex>
      </Flex>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    userRoles: getUserRoles(state),
    userName: getUserName(state),
    cancelledInternalOrders: selectAllCancelledInternalOrders(state)
  }
}

const mapDispatchToProps = dispatch => ({
  getCancelledInternalOrders: () => dispatch(getCancelledInternalOrders())
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
