import ConfigService from 'services/ConfigService'
import * as R from 'ramda'
import camel from 'camelcase-keys'
import { uuidV4Regex } from 'constants/regexps'

const GET_ITEMS_CONFIG = Symbol('GET_ITEMS_CONFIG')
const GET_ITEMS_CONFIG_SUCCESS = Symbol('GET_ITEMS_CONFIG_SUCCESS')
const GET_ITEMS_CONFIG_FAILED = Symbol('GET_ITEMS_CONFIG_FAILED')

const requestGetItemsConfig = () => ({ type: GET_ITEMS_CONFIG })
const getItemsConfigSuccess = (data) => ({ type: GET_ITEMS_CONFIG_SUCCESS, data })
const getItemsConfigFailed = () => ({ type: GET_ITEMS_CONFIG_FAILED })

const getItemsConfig = () => {
  return async dispatch => {
    dispatch(requestGetItemsConfig())
    try {
      const { data } = await ConfigService.getItemsConfig()
      const transformedData = camel(data, { deep: true, exclude: [uuidV4Regex] })
      return dispatch(getItemsConfigSuccess(transformedData))
    } catch (e) {
      return dispatch(getItemsConfigFailed(e))
    }
  }
}

export const getItemsConfigIfNeeded = () => {
  return async (dispatch, getState) => {
    const config = getState().itemsConfig
    if (R.any(R.isEmpty, R.values(config))) {
      await dispatch(getItemsConfig())
    }
  }
}

const initState = {
  deliveryConditions: {},
  groups: {},
  types: {},
  units: {},
  vendors: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_ITEMS_CONFIG_SUCCESS:
      return {
        ...state,
        deliveryConditions: action.data.deliveryConditions,
        groups: action.data.groups,
        types: action.data.types,
        units: action.data.units,
        vendors: action.data.vendors
      }
    default:
      return state
  }
}
