/** @jsx jsx */
import { jsx } from '@emotion/core'
import * as R from 'ramda'
import { Field } from 'redux-form'
import { Roles } from 'constants/Roles'
import Button from 'components/Button'
import theme from 'config/theme'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@rebass/emotion'
import { toast } from 'react-toastify'
import { errorHandler } from 'helpers/errorHandler'
import { useEffect, useState } from 'react'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import { ReactComponent as Cancel } from 'assets/cancel.svg'
import { renderMultiSelectField } from './WorkstationBomsForm'
import { includesInArray } from 'helpers/array'
export const labelCss = { marginTop: 20 }

const editableFields = [
  'firstname',
  'lastname'
]

const renderInputField = ({ input, meta: { touched, error }, ...props }) => {
  return (
    <Flex py={4}>
      <input
        {...input}
        {...props}
        css={{
          display: 'block',
          border: `2px solid ${touched && error ? theme.colors.error : theme.colors.black}`,
          padding: 10,
          minWidth: 200
        }}
      />
    </Flex>
  )
}

const createFormField = (fieldName) => {
  const { t } = useTranslation()

  return <div key={fieldName} >
    <label htmlFor={fieldName} css={labelCss}>{t(`userEdit.${fieldName}`)}</label>
    <Field name={fieldName} component={renderInputField} type='text' />
  </div>
}

const createUserRoleField = (val) => {
  const { t } = useTranslation()

  return <div key={val} css={{ display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '5px 0'
  }}>
    <Field name='role' component='input' type='radio' value={val} />
    <label htmlFor='role' css={{ paddingLeft: 10 }}> {t(`userEdit.roles.${val}`)}</label>
  </div>
}

let UserEditForm = ({ t, submitting, workstationsOptions, getWorkstationsIfNeeded, ...props }) => {
  const userFormFields = R.map(createFormField, editableFields)
  const userRolesFields = R.map(createUserRoleField, R.values(Roles))
  const tokenField = createFormField('rfid')
  const pinField = createFormField('pin')
  const [loading, setLoading] = useState(false)
  const isAdmin = includesInArray(props.userRoles, [Roles.ADMIN])

  useEffect(() => {
    getWorkstationsIfNeeded()
  }, [getWorkstationsIfNeeded])

  const handleDelete = async () => {
    if (window.confirm(t('userEdit.deleteUser'))) {
      try {
        setLoading(true)
        await props.deleteUser()
        setLoading(false)
        props.closeModal()
        toast.success(t('userEdit.deleteUserSuccess'), { containerId: 'statuses', autoClose: 15e3 })
      } catch (err) {
        setLoading(false)
        console.error(err)
        toast.error(t('userEdit.deleteUserFail'), { containerId: 'statuses', autoClose: 15e3 })
        errorHandler(err)
      }
    }
  }

  const isLoading = submitting || loading
  return (
    <form
      onSubmit={props.handleSubmit}
      css={{
        width: '100%',
        minHeight: 400,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      {isAdmin && (
        <div css={{ display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'flex-start',
          width: '100%'
        }}>
          <div>
            {userFormFields}
          </div>
          <div>
            <label css={labelCss}>{t(`userEdit.roles.roles`)}</label>
            {userRolesFields}
          </div>
          <div>
            {tokenField}
            {pinField}
          </div>
        </div>
      )}
      <Box mt={6}>
        <Field
          name='assemblyPosts'
          component={renderMultiSelectField}
          options={workstationsOptions}
        />
      </Box>
      <Flex justifyContent={'space-between'} flexDirection={'row'} mt={6}>
        {props.user.id && isAdmin && (
          <div css={{ flexGrow: 1 }}>
            <Button
              color={theme.colors.red}
              onClick={handleDelete}
              withIcon
              icon={isLoading ? Loader : Cancel}
              disabled={isLoading}
              iconProps={{ fill: theme.colors.white }}
            >
              {t(`userEdit.delete`)}
            </Button>
          </div>
        )}
        <div css={{ flexGrow: 3 }}>
          <Button
            color={theme.colors.navyBlue}
            onClick={props.handleSubmit}
            disabled={isLoading}
            withIcon
            icon={isLoading ? Loader : Checkmark}
            iconProps={{ fill: theme.colors.white }}
          >
            {t(`userEdit.save`)}
          </Button>
        </div>
      </Flex>
    </form>
  )
}

export default UserEditForm
