import React, { useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from 'ducks/user'
import Routes from 'constants/Routes'

const Logout = (props) => {
  useEffect(() => {
    props.logout()
  })

  return (
    <Redirect to={Routes.login} />
  )
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout())
})

export default connect(null, mapDispatchToProps)(Logout)
