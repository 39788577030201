export const Roles = {
  WAREHOUSE: 'warehouse',
  FOREMAN: 'foreman',
  QUALITY_ASSURANCE: 'quality_assurance',
  PRODUCTION_MANAGER: 'production_manager',
  PRODUCTION_WORKER: 'production_worker',
  ADMIN: 'admin',
  SUPPLY: 'supply',
  TECHNOLOGIST: 'technologist'
}
