import * as R from 'ramda'
import { getListOfOnesPositionsInBitwiseNumber } from './number'

export const toSnakeCase = string => string ? string.split(/(?=[A-Z])/).join('_').toLowerCase() : string

export const capitalize = (string) => {
  if (typeof string !== 'string') return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const toCamelCase = str => {
  return str.trim().split(' ').map((word, index) => {
    return index === 0
      ? word.toLowerCase()
      : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  }).join('').replace('.', '')
}

const getStabilization = R.pipe(
  R.find(line => line.match(/^(Stabilization)(\s{3})/g)),
  R.slice(16, Infinity),
  R.split(/\s+/)
)

const getVacuumTest = R.pipe(
  R.find(line => line.match(/^(Vacuum test)(\s{3})/g)),
  R.slice(16, Infinity),
  R.split(/\s+/)
)

const getSerialNumber = R.pipe(
  R.find(line => line.match(/^(SN:)/g)),
  R.slice(4, Infinity),
  R.trim
)

const getStatus = status => status.includes('ERROR:') ? getListOfOnesPositionsInBitwiseNumber(status.match(/\d+/)) : status

export const parseReport = R.pipe(
  R.split('\n'),
  R.applySpec({
    reportStatus: lines => getStatus(lines[R.findIndex((line) => line.match(/^(End)(\s{13})/g), lines) + 2]),
    stabilization: getStabilization,
    vacuumTest: getVacuumTest,
    serialNumber: getSerialNumber
  })
)

export const decodeHTMLEntities = str => {
  let newString = ''
  if (str && typeof str === 'string') {
    const element = document.createElement('div')
    element.innerHTML = str
      .replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '')
      .replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')
    newString = element.textContent
    element.textContent = ''
  }
  return newString
}
