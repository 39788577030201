import StocktakingService from 'services/StocktakingService'
import { transformById } from 'helpers/redux'
import isZebraBrowser from 'helpers/isZebra'
import { stocktakingOrder } from 'constants/stocktakingStatuses'
import { dissoc } from 'ramda'

const ISSUE_STOCKTAKING_FOR_ITEM = Symbol('ISSUE_STOCKTAKING_FOR_ITEM')
const ISSUE_STOCKTAKING_FOR_ITEM_SUCCESS = Symbol('ISSUE_STOCKTAKING_FOR_ITEM_SUCCESS')
const ISSUE_STOCKTAKING_FOR_ITEM_FAILED = Symbol('ISSUE_STOCKTAKING_FOR_ITEM_FAILED')

const requestIssueStocktakingForItem = () => ({ type: ISSUE_STOCKTAKING_FOR_ITEM })
const issueStocktakingForItemSuccess = (data) => ({ type: ISSUE_STOCKTAKING_FOR_ITEM_SUCCESS, data })
const issueStocktakingForItemFailed = (error) => ({ type: ISSUE_STOCKTAKING_FOR_ITEM_FAILED, error })

export const issueStocktakingForItem = (itemCode) => {
  return async dispatch => {
    dispatch(requestIssueStocktakingForItem())
    try {
      const { data } = await StocktakingService.issueStocktakingForItem(itemCode)
      dispatch(issueStocktakingForItemSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      if (e.response.status === 422 && e.response.data.errors.itemCode[0]) {
        const errorText = e.response.data.errors.itemCode[0]
        const errorType = errorText === 'Selected item is only in INBOUND'
          ? 'inboundError' : errorText === 'This item already has unfinished stocktaking order.'
            ? 'issueAlreadyCreated' : 'somethingWentWrong'
        dispatch(issueStocktakingForItemFailed(errorType))
      }
      return Promise.reject(e)
    }
  }
}

const REGISTER_MULTIPLE_ISSUE_STOCKTAKING = Symbol('REGISTER_MULTIPLE_ISSUE_STOCKTAKING')
const REGISTER_MULTIPLE_ISSUE_STOCKTAKING_SUCCESS = Symbol('REGISTER_MULTIPLE_ISSUE_STOCKTAKING_SUCCESS')
const REGISTER_MULTIPLE_ISSUE_STOCKTAKING_FAILED = Symbol('REGISTER_MULTIPLE_ISSUE_STOCKTAKING_FAILED')

const requestRegisterMultipleIssueStocktaking = () => ({ type: REGISTER_MULTIPLE_ISSUE_STOCKTAKING })
const registerMultipleIssueStocktakingSuccess = (data) => ({ type: REGISTER_MULTIPLE_ISSUE_STOCKTAKING_SUCCESS, data })
const registerMultipleIssueStocktakingFailed = (error) => ({ type: REGISTER_MULTIPLE_ISSUE_STOCKTAKING_FAILED, error })

export const registerMultipleIssueStocktaking = itemsArray => {
  return async dispatch => {
    dispatch(requestRegisterMultipleIssueStocktaking())
    try {
      const { data } = await StocktakingService.issueStocktakingForMultipleItems(itemsArray)
      dispatch(registerMultipleIssueStocktakingSuccess(data))
    } catch (e) {
      dispatch(registerMultipleIssueStocktakingFailed(e))
    }
  }
}

const GET_STOCKTAKING_ISSUES = Symbol('GET_STOCKTAKING_ISSUES')
const GET_STOCKTAKING_ISSUES_SUCCESS = Symbol('GET_STOCKTAKING_ISSUES_SUCCESS')
const GET_STOCKTAKING_ISSUES_FAILED = Symbol('GET_STOCKTAKING_ISSUES_FAILED')

const requestGetStocktakingIssues = () => ({ type: GET_STOCKTAKING_ISSUES })
const getStocktakingIssuesSuccess = (data) => ({ type: GET_STOCKTAKING_ISSUES_SUCCESS, data })
const getStocktakingIssuesFailed = () => ({ type: GET_STOCKTAKING_ISSUES_FAILED })

export const getStocktakingIssues = () => {
  return async dispatch => {
    dispatch(requestGetStocktakingIssues())
    try {
      const { data } = await StocktakingService.getStocktakingIssues()
      return dispatch(getStocktakingIssuesSuccess(data))
    } catch (e) {
      return dispatch(getStocktakingIssuesFailed(e))
    }
  }
}

const RESET_ERROR = Symbol('RESET_ERROR')

export const resetError = () => ({ type: RESET_ERROR })

const DELETE_STOCKTAKING_ORDER = Symbol('DELETE_STOCKTAKING_ORDER')
const DELETE_STOCKTAKING_ORDER_SUCCESS = Symbol('DELETE_STOCKTAKING_ORDER_SUCCESS')
const DELETE_STOCKTAKING_ORDER_FAILED = Symbol('DELETE_STOCKTAKING_ORDER_FAILED')

const deleteStocktakingOrderRequest = () => ({ type: DELETE_STOCKTAKING_ORDER })
const deleteStocktakingOrderSuccess = (stocktakingOrderId) => ({ type: DELETE_STOCKTAKING_ORDER_SUCCESS, stocktakingOrderId })
const deleteStocktakingOrderFailed = () => ({ type: DELETE_STOCKTAKING_ORDER_FAILED })

export const deleteStocktakingOrder = (stocktakingOrderId) => {
  return async dispatch => {
    dispatch(deleteStocktakingOrderRequest())
    try {
      await StocktakingService.deleteStocktakingOrder(stocktakingOrderId)
      dispatch(deleteStocktakingOrderSuccess(stocktakingOrderId))
      return Promise.resolve()
    } catch (e) {
      dispatch(deleteStocktakingOrderFailed(e))
      return Promise.reject(e)
    }
  }
}

const REGISTER_INVENTORY_STOCKTAKING = Symbol('REGISTER_INVENTORY_STOCKTAKING')
const REGISTER_INVENTORY_STOCKTAKING_SUCCESS = Symbol('REGISTER_INVENTORY_STOCKTAKING_SUCCESS')
const REGISTER_INVENTORY_STOCKTAKING_FAILED = Symbol('REGISTER_INVENTORY_STOCKTAKING_FAILED')

const requestRegisterInventoryStocktaking = () => ({ type: REGISTER_INVENTORY_STOCKTAKING })
const registerInventoryStocktakingSuccess = (data, inventoryId) => ({ type: REGISTER_INVENTORY_STOCKTAKING_SUCCESS, data, inventoryId })
const registerInventoryStocktakingFailed = () => ({ type: REGISTER_INVENTORY_STOCKTAKING_FAILED })

export const registerInventoryStocktaking = (values) => {
  return async dispatch => {
    dispatch(requestRegisterInventoryStocktaking())
    try {
      const { data } = await StocktakingService.registerInventoryStocktaking(values)
      return dispatch(registerInventoryStocktakingSuccess(data, values.inventoryId))
    } catch (e) {
      return dispatch(registerInventoryStocktakingFailed(e))
    }
  }
}

const initState = {
  entries: {},
  error: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_STOCKTAKING_ISSUES_SUCCESS:
      const sortByStatus = array => array.sort((a, b) => stocktakingOrder[a.status] - stocktakingOrder[b.status])
      return {
        ...state,
        entries: transformById(isZebraBrowser() ? sortByStatus(action.data) : action.data),
        error: null
      }
    case ISSUE_STOCKTAKING_FOR_ITEM_SUCCESS:
      const { id: newStocktakingIssueId } = action.data
      return {
        ...state,
        entries: {
          ...state.entries,
          [newStocktakingIssueId]: action.data
        },
        error: null
      }
    case ISSUE_STOCKTAKING_FOR_ITEM_FAILED:
      return {
        ...state,
        error: action.error
      }
    case RESET_ERROR:
      return {
        ...state,
        error: null
      }
    case REGISTER_INVENTORY_STOCKTAKING_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.data.id]: {
            ...state.entries[action.data.id],
            ...action.data
          }
        }
      }
    case DELETE_STOCKTAKING_ORDER_SUCCESS:
      return {
        ...state,
        entries: dissoc(action.stocktakingOrderId, state.entries)
      }
    default:
      return state
  }
}
