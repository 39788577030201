import { createSelector } from 'reselect'
import { transformToArray } from 'helpers/redux'
import { propOr } from 'ramda'

export const getOrdersByItemCode = state => state.ordersForItem.entries
export const getCurrentItem = state => state.ordersForItem.currentItem

export const getOrdersForItemAsArray = createSelector(
  getOrdersByItemCode,
  (state, itemCode) => itemCode,
  (ordersByItemCode, itemCode) => transformToArray(propOr({}, itemCode, ordersByItemCode))
)

export const getOrderByIdAndItemCode = createSelector(
  getOrdersByItemCode,
  (state, itemCode, orderId) => ({ itemCode, orderId }),
  (ordersByItemCode, { itemCode, orderId }) => propOr({}, itemCode, ordersByItemCode)[orderId]
)
