/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useCallback, useEffect, useMemo, useState, Fragment } from 'react'
import ReactTable from 'react-table'
import { Box, Flex } from '@rebass/emotion'
import ProgressBar from 'components/ProgressBar'
import theme from 'config/theme'
import moment from 'moment'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import * as R from 'ramda'
import * as styles from './styles'
import Button from 'components/Button'
import { iterable } from 'helpers/array'
import { ReactComponent as Arrow } from 'assets/arrow.svg'
import { getGeneralInfoColumnsForeman } from 'views/WeeklyPlans/commonColumns'
import { getItemsOnTopOfTheList } from 'helpers/items'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import OrderModal from './components/OrderModal'
import HandOverModal from './components/HandOverModal'
import Tooltip from 'components/Tooltip'
import { flippedGt, handleFloatNumber } from 'helpers/number'
import { toast } from 'react-toastify'
import { PARTS_DELIVERY_STATUSES } from 'constants/weeklyPlansForemanStatuses'

const getColoredDotStyles = color => css`
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  background-color: ${theme.colors[color]};
`

const getColoredDotByPartsDeliveryStatus = (status, t) => {
  switch (status) {
    case PARTS_DELIVERY_STATUSES.partial:
      return { styles: getColoredDotStyles('orange'), content: t('weeklyPlansForeman.partialDelivered') }
    case PARTS_DELIVERY_STATUSES.full:
      return { styles: getColoredDotStyles('successGreen'), content: t('weeklyPlansForeman.allDelivered') }

    default:
      return { styles: {}, content: t('weeklyPlansForeman.noneDelivered') }
  }
}

const flexStyles = css`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`

const columns = (t, renderIndexCell, setVisibleIds, currentWeek, createOrderForItem, getBomItemsQuantities, getDailyBomItems, submitItemsForWarehouse) => {
  return [
    {
      style: { borderRight: `3px solid ${theme.colors.darkGrey}` },
      columns: [...getGeneralInfoColumnsForeman(t, setVisibleIds, renderIndexCell, createOrderForItem), {
        Header: t('weeklyPlansForeman.warehouse'),
        id: `warehouse`,
        width: 90,
        headerStyle: {
          borderRight: `1px solid ${theme.colors.darkGrey}`
        },
        accessor: 'warehouseQuantity',
        style: {
          borderRight: `1px solid ${theme.colors.darkGrey}`,
          textAlign: 'center',
          padding: 0
        },
        Cell: row => {
          const { alternatives = [], availableAlternativesQuantity = 0, warehouseQuantity = 0 } = row.original
          const alternativesCount = alternatives.length
          const floatItemOwnWarehouseQuantity = handleFloatNumber(warehouseQuantity)
          const floatAvailableAlternativesQuantity = handleFloatNumber(availableAlternativesQuantity)
          const displayedWarehouseQuantity = !alternativesCount ? `${floatItemOwnWarehouseQuantity}` : `${floatItemOwnWarehouseQuantity}/${floatAvailableAlternativesQuantity + floatItemOwnWarehouseQuantity}`
          const withPointer = withPointer => withPointer ? { cursor: 'pointer' } : {}
          const RenderCellContent = ({ pointer = false }) => (
            <div
              css={styles.commonCell(row.original.depth)}
            >
              <span
                style={row.value < row.original?.weeklyPlan?.quantity && row.original.depth > 0 ? { color: theme.colors.error, ...withPointer(pointer) } : { ...withPointer(pointer) }}
              >
                {displayedWarehouseQuantity}
              </span>
            </div>
          )
          return (
            <Fragment>
              {alternativesCount ? (
                <Tooltip size='big' content={
                  <div style={{ padding: 10, fontSize: 18 }}>
                    <h2>Ilość własna:</h2>
                    <p style={{ textAlign: 'center', marginBottom: '10px' }}>{floatItemOwnWarehouseQuantity}</p>
                    <h2>Zamienniki:</h2>
                    {alternatives.map((alternative, index) => {
                      return <div key={index} style={{ padding: '20px', display: 'flex', flexDirection: 'column', marginBottom: '10px', border: `1px solid ${theme.colors.darkGrey}`, borderRadius: '5px' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '5px' }}>
                          <span>dostępne:</span>
                          <span style={{ paddingLeft: '20px', wordBreak: 'keep-all' }}>{alternative.warehouseQuantity}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '5px' }}>
                          <span>index:</span>
                          <span style={{ paddingLeft: '20px', wordBreak: 'keep-all' }}>{alternative.fullIndex}</span>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '5px' }}>
                          <span>nazwa:</span>
                          <span style={{ paddingLeft: '25px', wordBreak: 'keep-all', textAlign: 'right' }}>{alternative.name}</span>
                        </div>
                      </div>
                    })}
                  </div>
                } delay={500}>
                  <RenderCellContent pointer />
                </Tooltip>
              ) : <RenderCellContent />}
            </Fragment>
          )
        }
      }, {
        Header: t('weeklyPlansForeman.ordered'),
        id: `ordered`,
        width: 90,
        accessor: 'orderedQuantity',
        headerStyle: {
          borderRight: `1px solid ${theme.colors.darkGrey}`
        },
        style: {
          textAlign: 'center',
          padding: 0,
          borderRight: `1px solid ${theme.colors.darkGrey}`
        },
        Cell: row => {
          const handleClick = () => {
            confirmAlert({
              customUI: ({ onClose }) => {
                return <OrderModal
                  row={row}
                  createOrderForItem={createOrderForItem}
                  onClose={onClose}
                  getBomItemsQuantities={getBomItemsQuantities}
                  getDailyBomItems={getDailyBomItems} />
              },
              closeOnEscape: false,
              closeOnClickOutside: false
            })
          }

          const orderedMoreThanNeeded = row.original.weeklyPlan?.quantity < row.value
          const purposes = row.original?.purposes
          const shouldRedDotBeDisplayed = !row.original.hasChildrenParts
          let purposesArray = purposes
            ? R.pipe(
              R.mapObjIndexed((value, key) => ({ purpose: key, quantity: value })),
              R.filter(value => R.not(R.propEq('purpose', 'production', value))),
              R.values
            )(purposes)
            : []

          const RedDot = (
            <Tooltip content={t('weeklyPlansForeman.insufficientStock')} delay={500}>
              <Flex css={flexStyles}><span css={getColoredDotStyles('red')} /></Flex>
            </Tooltip>
          )

          if (row.original.depth) {
            return orderedMoreThanNeeded
              ? (
                <Tooltip
                  content={
                    <div style={{ padding: 10, fontSize: 18, textAlign: 'left' }}>
                      {purposesArray.map((element, index) => {
                        return <div key={index}>
                          <span style={{ display: 'inline-block', marginRight: '15px' }}>+{element?.quantity}&nbsp;</span>
                          <span>{t(`weeklyPlansForeman.orderPurpose.${element.purpose}`)}</span>
                        </div>
                      })}
                    </div>
                  }
                >
                  <div
                    style={{ cursor: 'pointer', backgroundColor: theme.colors.concreteGray, color: theme.colors.orange }}
                    css={styles.commonCell(row.original.depth)}
                    onClick={handleClick}
                  >
                    <span>
                      {row.value}
                    </span>
                  </div>
                </Tooltip>
              ) : (
                <div
                  style={{ cursor: 'pointer', backgroundColor: theme.colors.concreteGray }}
                  css={styles.commonCell(row.original.depth)}
                  onClick={handleClick}
                >
                  <span>
                    {row.value}
                  </span>
                </div>
              )
          } else {
            return shouldRedDotBeDisplayed
              ? RedDot
              : <Tooltip
                style={{ display: 'block', width: '100%', height: '100%' }}
                content={'wszystkie materiały dostępne'}
                delay={500}
              />
          }
        }
      }]
    },
    {
      Header: t('weeklyPlansForeman.available'),
      id: `available`,
      width: 50,
      accessor: 'partsDeliveryStatus',
      headerStyle: {
        borderRight: `1px solid ${theme.colors.darkGrey}`
      },
      style: {
        textAlign: 'center',
        padding: 0,
        borderRight: `1px solid ${theme.colors.darkGrey}`
      },
      Cell: row => {
        const isDepthZero = row.original.depth === 0
        const deliveredQuantity = row.original.deliveredQuantity
        const partsDeliveryStatus = row.value
        const dot = getColoredDotByPartsDeliveryStatus(partsDeliveryStatus, t)
        return (
          <Flex css={flexStyles} px={2}>
            {isDepthZero
              ? (
                <Tooltip
                  css={{
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    display: 'flex !important',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                  content={dot.content}
                  delay={500}
                >
                  <span css={dot.styles} />
                </Tooltip>
              )
              : <Box>{deliveredQuantity}</Box>
            }
          </Flex>
        )
      }
    },
    {
      Header: t('weeklyPlansForeman.inbounded'),
      id: `inbounded`,
      width: 70,
      accessor: 'receivedQuantity',
      headerStyle: {
        borderRight: `3px solid ${theme.colors.darkGrey}`
      },
      style: {
        textAlign: 'center',
        padding: 0,
        borderRight: `3px solid ${theme.colors.darkGrey}`
      },
      Cell: row => <div
        css={styles.commonCell(row.original.depth)}
      >
        <span>
          {row.value}
        </span>
      </div>
    }, {
      columns: [
        ...iterable(5).map(
          index => ({
            Header: moment().isoWeekday(index + 1).format('dddd'),
            width: 100,
            id: `week${index}`,
            style: { padding: 0, lineHeight: '32px', borderRight: `1px solid ${theme.colors.darkGrey}` },
            headerStyle: {
              borderRight: `1px solid ${theme.colors.darkGrey}`,
              backgroundColor: index + 1 === moment().day() && currentWeek === moment().week() ? 'rgba(72, 146, 199, .4)' : 'transparent'
            },
            accessor: R.pipe(
              R.propOr([], 'dailyPlans'),
              R.find(plan => Number(plan.day) === index + 1),
              R.propOr('', 'quantity')
            ),
            Cell: row => {
              const isCurrentDayAndWeek = index + 1 === moment().day() && currentWeek === moment().week()
              const plans = row.original.dailyPlans.filter(plan => String(plan.day) === String(index + 1))
              const plan = R.reduce(
                (acc, next) => ({
                  manufactured: acc.manufactured + next.manufactured,
                  quantity: acc.quantity + next.quantity
                }),
                { manufactured: 0, quantity: 0 }
              )(plans)
              const fontColor = row.original.depth === 0 ? theme.colors.darkGrey : theme.colors.lightGrey
              const fontWeight = row.original.depth === 0 ? 700 : 400
              const backgroundColor = isCurrentDayAndWeek ? theme.colors.lightBlueTransparent : 'transparent'
              return (
                <Flex>
                  <Tooltip
                    css={{
                      textAlign: 'center',
                      width: '50%',
                      fontWeight,
                      backgroundColor,
                      color: fontColor
                    }}
                    delay={500}
                    content={t('weeklyPlansForeman.plannedOnThisStation')}
                  >
                    {handleFloatNumber(plan?.quantity)}
                  </Tooltip>
                  <Tooltip
                    css={{
                      width: '50%',
                      textAlign: 'center',
                      borderLeft: `1px solid ${theme.colors.lightGrey}`,
                      backgroundColor,
                      fontWeight,
                      color: fontColor
                    }}
                    delay={500}
                    content={t('weeklyPlansForeman.manufacturedOnThisStation')}
                  >
                    {handleFloatNumber(plan?.manufactured)}
                  </Tooltip>
                </Flex>
              )
            }
          })
        ),
        {
          Header: t('weeklyPlansForeman.progress'),
          accessor: 'progress',
          width: 110,
          headerStyle: {
            borderRight: `1px solid ${theme.colors.darkGrey}`,
            width: 110
          },
          style: {
            textAlign: 'center',
            padding: 0,
            borderRight: `1px solid ${theme.colors.darkGrey}`,
            width: 110
          },
          Cell: row => {
            const currentPlan = R.prop('weeklyPlan', row.original)
            const isFirstLvl = R.propEq('depth', 0, row.original)
            const manufactured = R.propOr(0, 'manufactured', currentPlan)
            const quantity = R.propOr(0, 'quantity', currentPlan)

            if (isFirstLvl && quantity !== 0) {
              return <div style={{ width: '100%' }}>
                <Tooltip content={
                  <p style={{ fontSize: '18px' }}>
                    {t('weeklyPlansForeman.left')}: {Math.max(quantity - manufactured, 0)}
                  </p>
                }>
                  <ProgressBar current={manufactured} max={quantity} />
                </Tooltip>
              </div>
            } else {
              return null
            }
          }
        }]
    },
    {
      columns: [
        {
          Header: t('weeklyPlansForeman.manufactured'),
          id: 'manufactured',
          accessor: R.pathOr('', ['weeklyPlan', 'manufactured']),
          width: 105,
          headerStyle: {
            borderLeft: `2px solid ${theme.colors.darkGrey}`,
            borderRight: `1px solid ${theme.colors.darkGrey}`
          },
          style: {
            padding: 0,
            height: 33,
            lineHeight: '32px',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
            borderLeft: `2px solid ${theme.colors.darkGrey}`,
            borderRight: `1px solid ${theme.colors.darkGrey}`
          },
          Cell: row => {
            const isFirstLvl = R.propEq('depth', 0, row.original)
            const currentPlanQuantity = R.pathOr(0, ['weeklyPlan', 'quantity'], row.original)
            const handleClick = useCallback(() => {
              confirmAlert({
                customUI: ({ onClose }) => {
                  return <HandOverModal
                    row={row} submitItemsForWarehouse={submitItemsForWarehouse}
                    onClose={onClose}
                    getBomItemsQuantities={getBomItemsQuantities}
                    getDailyBomItems={getDailyBomItems} />
                },
                closeOnEscape: false,
                closeOnClickOutside: false
              })
            })
            return isFirstLvl &&
            <div style={{ cursor: 'pointer', backgroundColor: theme.colors.concreteGray }} onClick={handleClick} css={styles.commonCell(row.original.depth)}>
              {row.value > currentPlanQuantity
                ? <p>{row.value} (+<span
                  style={{ color: theme.colors.orange }}>{row.value - currentPlanQuantity}</span>)
                </p>
                : <p>{row.value}</p>
              }}
            </div>
          }
        },
        {
          Header: t('weeklyPlansForeman.acknowledged'),
          id: 'acknowledged',
          width: 105,
          headerStyle: {
            borderRight: `3px solid ${theme.colors.darkGrey}`
          },
          accessor: R.pathOr('', ['weeklyPlan', 'acknowledged']),
          style: { textAlign: 'center', padding: 0, borderRight: `3px solid ${theme.colors.darkGrey}` },
          Cell: row => {
            const isFirstLvl = R.propEq('depth', 0, row.original)
            const shouldColor = row.value !== 0 && row.value < row.original.weeklyPlan?.manufactured
            return <div
              style={{ backgroundColor: shouldColor && isFirstLvl ? theme.colors.orange : theme.colors.white }}
              css={styles.commonCell(row.original.depth)}>{isFirstLvl && row.value}</div>
          }
        }
      ]
    }
  ]
}

const generateTreeLines = (depth, isLast) => {
  const emptyArray = iterable(depth)
  const TreeLines = () => emptyArray.map((i) => {
    return <span key={i} css={styles.treeLines(depth, i, isLast)} />
  })
  return <TreeLines />
}

const WeeklyPlansForeman = (props) => {
  const [currentWeek, setCurrentWeek] = useState(moment().week())
  const [visibleIds, setVisible] = useState([])
  const [allPlans, setPlans] = useState([])
  const [triggerSuccessToastCounter, toggleTriggerSuccessToast] = useState(0)
  const [triggerFailureToastCounter, toggleTriggerFailureToast] = useState(0)
  const { t } = useTranslation()

  useEffect(() => {
    triggerSuccessToastCounter !== 0 && toast.success(<div>{t('weeklyPlansForeman.orderMessage.success')}</div>, { containerId: 'statuses', autoClose: 5e3 })
  }, [triggerSuccessToastCounter])

  useEffect(() => {
    triggerFailureToastCounter !== 0 && toast.error(<div>{t('weeklyPlansForeman.orderMessage.error')}</div>, { containerId: 'statuses', autoClose: 10e3 })
  }, [triggerFailureToastCounter])

  useEffect(() => {
    props.getDailyBomItems(currentWeek)
  }, [currentWeek])

  const setDefaultDailyPlans = () => {
    setPlans(getItemsOnTopOfTheList(props.bomDailyItems[currentWeek] || []))
  }

  useEffect(() => {
    setDefaultDailyPlans()
  }, [props.bomDailyItems, currentWeek])

  const updatePlans = (weekDay, newValue, bomId) => {
    const updatedBomIndex = R.findIndex(R.propEq('id', bomId))(allPlans)
    const updatedPlans = R.assocPath([updatedBomIndex, 'dailyPlans', weekDay, 'quantity'], newValue, allPlans)
    setPlans(updatedPlans)
  }

  const isVisible = id => visibleIds.includes(id)

  const itemsEvolve = useCallback((parent) => {
    return ({
      items: R.map(item => {
        const currentDay = moment().day()
        return R.assoc(
          'dailyPlans',
          parent.dailyPlans.map((plan, index, array) => {
            const calculatedPlan = R.assoc(
              'quantity',
              handleFloatNumber(plan?.quantity * item?.quantity),
              plan
            )
            return R.assoc(
              'canProduce',
              R.propOr(0, 'warehouseQuantity', item) - array.slice(currentDay, index + 1).reduce((sum, num) => num?.quantity + sum, 0) > 0,
              calculatedPlan
            )
          }),
          item
        )
      })
    })
  }, [])
  const prepareData = (data, depth = 0) => data.reduce((all, next) => {
    const nextItems = R.propOr([], 'items', R.evolve(itemsEvolve(next), next))
    all.push({
      ...next,
      depth: depth,
      hasChildren: !R.isEmpty(nextItems),
      // APICHANGED ?
      hasChildrenParts: next.items.every(R.cond([
        [R.pathEq(['item', 'isSmalls'], true), R.always(true)],
        [R.propEq('orderedQuantity', 0), item => (item.quantity * next.weeklyPlan.quantity) < item.warehouseQuantity],
        [R.pipe(R.prop('orderedQuantity'), flippedGt(0)), item => (item.orderedQuantity - item.deliveredQuantity) < item.warehouseQuantity]
      ])),
      plannedForWeekCount: R.reduce((acc, val) => R.add(acc, val?.quantity), 0, next.dailyPlans),
      updatePlan: (weekDay, newValue) => {
        updatePlans(weekDay, Number(newValue), next.id)
      }
    })
    const flatten = flatDepth => (item, index, allItems) => {
      all.push({
        ...item,
        depth: flatDepth,
        parentId: next.id,
        isLast: index === (allItems.length - 1),
        weeklyPlan: R.assoc(
          'quantity',
          handleFloatNumber(next.weeklyPlan?.quantity * item?.quantity),
          next.weeklyPlan
        )
      })
    }
    isVisible(next.id) && nextItems.map(flatten(depth + 1))
    return all
  }, [])

  const setVisibleIds = (id) => visibleIds.includes(id)
    ? setVisible(visibleIds.filter(visibleId => visibleId !== id))
    : setVisible([...visibleIds, id])

  const renderIndexCell = useCallback((row) => {
    const id = row.original.parentId ? row.original.parentId + row.original.id : row.original.id
    const isItemVisible = R.includes(id, visibleIds)
    return (
      <div
        onClick={() => setVisibleIds(id)}
        css={styles.expanderCell(isItemVisible, row.original.depth, row.original.hasChildren)}
      >
        {generateTreeLines(row.original.depth, row.original.isLast)}
        <div css={styles.indexCell(row.original.depth, row.original.hasChildren)}>
          {row.value}
        </div>
      </div>
    )
  }, [visibleIds])

  const thisWeek = useMemo(() => moment().week(), [])
  const thisYear = useMemo(() => moment().year(), [])

  useEffect(() => {
    let refreshInterval = setInterval(() => {
      props.getDailyBomItems(currentWeek)
    }, 60000)
    return () => clearInterval(refreshInterval)
  }, [])

  const [executingMaterialsOrder, setExecutingMaterialsOrder] = useState(false)
  const [weekDate, setWeekDate] = useState(moment())

  const getWeekDate = () => {
    const fromDate = weekDate.startOf('isoWeek').format('DD-MM-YYYY')
    const toDate = weekDate.endOf('isoWeek').format('DD-MM-YYYY')

    return {
      from: fromDate,
      to: toDate
    }
  }

  const executeWeeklyPlanMaterialsOrder = () => {
    confirmAlert({
      title: t('weeklyPlansForeman.orderQuestion'),
      closeOnEscape: true,
      buttons: [
        {
          label: t('common.yes'),
          onClick: async () => {
            setExecutingMaterialsOrder(true)
            try {
              await props.executeWeeklyPlanMaterialsOrder({ week: currentWeek, year: thisYear })
              toggleTriggerSuccessToast(value => value + 1)
            } catch (e) {
              toggleTriggerFailureToast(value => value + 1)
            }
            props.getDailyBomItems(currentWeek)
            setExecutingMaterialsOrder(false)
          }
        },
        {
          label: t('common.no'),
          onClick: () => false
        }
      ]
    })
  }

  return (
    <Box mx={10} mb={6}>
      <Box mb={6} mt={6}>
        <Flex mt={6} justifyContent={'space-between'} alignItems={'center'} css={{ width: '100%' }}>
          <Flex>
            <Flex alignItems={'center'} style={{ width: 40 }}>
              {currentWeek >= thisWeek &&
              <Arrow
                onClick={() => {
                  setWeekDate(weekDate.subtract(7, 'days'))
                  setCurrentWeek(currentWeek - 1)
                }}
                style={{ transform: 'rotate(180deg)', cursor: 'pointer' }} fill={theme.colors.darkGrey}
              />
              }
            </Flex>
            <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'} css={{ margin: '0 30px' }}>
              <h1 css={{ fontSize: 35 }}>{t('weeklyPlansForeman.week')} {currentWeek}</h1>
              <h3 css={{ fontSize: 20 }}>{`${getWeekDate().from} - ${getWeekDate().to}`}</h3>
            </Flex>
            <Flex alignItems={'center'} css={{ width: 40 }}>
              {currentWeek <= thisWeek &&
              <Arrow
                onClick={() => {
                  setWeekDate(weekDate.add(7, 'days'))
                  setCurrentWeek(currentWeek + 1)
                }}
                css={{ cursor: 'pointer' }} fill={theme.colors.darkGrey}
              />
              }
            </Flex>
          </Flex>
          <Box css={{ width: '300px' }}>
            <Button
              color={theme.colors.navyBlue}
              onClick={executeWeeklyPlanMaterialsOrder}
              disabled={executingMaterialsOrder}
            >
              {t('weeklyPlansForeman.orderItems')}
            </Button>
          </Box>
        </Flex>
      </Box>
      <div css={styles.foremanHeader}>
        <div />
        <div>{t('weeklyPlansForeman.plan')}</div>
        <div>{t('weeklyPlansForeman.components')}</div>
        <div>{t('weeklyPlansForeman.production')}</div>
        <div>{t('weeklyPlansForeman.realization')}</div>
      </div>
      <div css={styles.headerDetailsForeman}>
        <div>{t('weeklyPlansForeman.index')}</div>
        <div>{t('weeklyPlansForeman.name')}</div>
        <div style={{ borderLeft: `3px solid ${theme.colors.darkGrey}` }}>{t('weeklyPlansForeman.order')}</div>
        <div style={{ borderRight: `3px solid ${theme.colors.darkGrey}` }}>{t('weeklyPlansForeman.schedule')}</div>
        <div>{t('weeklyPlansForeman.warehouse')}</div>
        <div>{t('weeklyPlansForeman.ordered')}</div>
        <div>{t('weeklyPlansForeman.available')}</div>
        <div style={{ borderRight: `3px solid ${theme.colors.darkGrey}` }}>{t('weeklyPlansForeman.inbounded')}</div>
        {iterable(5).map((e, i) => <div key={i}>{moment().isoWeekday(i + 1).format('dddd')}</div>)}
        <div>{t('weeklyPlansForeman.progress')}</div>
        <div style={{ borderLeft: `3px solid ${theme.colors.darkGrey}` }}>{t('weeklyPlansForeman.manufactured')}</div>
        <div style={{ borderRight: `3px solid ${theme.colors.darkGrey}` }}>{t('weeklyPlansForeman.acknowledged')}</div>
      </div>
      <ReactTable
        data={prepareData(allPlans)}
        columns={columns(t, renderIndexCell, setVisibleIds, currentWeek, props.createInternalOrder, props.getBomItemsQuantities, props.getDailyBomItems, props.submitItemsForWarehouse)}
        showPagination={false}
        minRows={17}
        className={'-highlight -striped'}
        sortable={false}
        resizable={false}
        filterable={false}
        NoDataComponent={() => null}
        defaultPageSize={200}
        style={{ width: '1790px' }}
        {...styles.tableProps}
      />
    </Box>
  )
}

export default WeeklyPlansForeman
