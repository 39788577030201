/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core'
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Box, Flex } from '@rebass/emotion'
import moment from 'moment'
import useInterval from 'hooks/useInterval'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import {
  containerCss,
  currentElementCss,
  planCss,
  plansWrapperCss,
  timeCss,
  nameCss,
  progressBarCss,
  planDoneCss,
  subHeaderTextCss,
  stepCss
} from 'views/ProductionStation/styles'
import Button from 'components/Button'
import StorageService, { LocalStorageKeys } from 'services/StorageService'
import { APIInterceptorFactory } from 'services/APIInterceptor'
import QrCode from 'qrcode.react'
import 'react-simple-keyboard/build/css/index.css'
import RevertButton from 'components/RevertButton'
import Tooltip from 'components/Tooltip'
import DayPlanTooltipContent from 'components/DayPlanTooltipContent'
import PartBox from 'components/PartBox'
import { toast } from 'react-toastify'
import useFullscreen from 'hooks/useFullscreen'
import ProductionStationsService from 'services/ProductionStationsService'
import print from 'print-js'
import useInput from 'hooks/useInput'
import createPersistedState from 'use-persisted-state'
import { includesInArray } from 'helpers/array'
import SteamJetGenerator from 'components/SteamJetGenerator'
import ImageGallery from 'components/ImageGallery'
import { confirmAlert } from 'react-confirm-alert'
import { errorHandler, vacuumTestErrorHandler } from 'helpers/errorHandler'
import VacuumTestSteeringBox from './VacuumSteeringBox'
import { mapFilesToReactImagesComponent } from 'helpers/files'
import EmergencySteeringModal from './EmergencySteeringModal' // vacuum steering controls modal
import BarcodePrinter from 'components/BarcodePrinter'
import { displayNoPartsForProductionErrorToast, machineStatusGetterByProcessName, noop, setPartsStatusInCurrent } from 'helpers/productionStation'
import { isNotNilOrEmpty } from 'helpers/ramda'

const styleOverwrites = () => css`
  .tippy-popper {
    max-width: unset;
  }
  
  .react-confirm-alert-button-group {
    button {
      padding: 20px 60px;
      font-size: 20px;
    }
  }
`

const testLocations = ['enbio-client.test.desmart.live', 'enbio-client.beta.desmart.live', 'localhost', 'localhost:3000']

const MMIControllerAPIInterceptor = new APIInterceptorFactory('http://localhost:5731')

const useCurrentReportState = createPersistedState(LocalStorageKeys.currentReport)
const useIdsForCurrentItemState = createPersistedState(LocalStorageKeys.idsForCurrentItem)
const useSelectedIndexState = createPersistedState(LocalStorageKeys.selectedIndex)
const useActionsState = createPersistedState(LocalStorageKeys.actions)
const useActionIndexState = createPersistedState(LocalStorageKeys.actionIndex)

const initialIdsForCurrentItem = {}
const initialRaport = null
const initialActionsState = {}
const initialActionIndex = 1
const initialSelectedIndex = 0

const isTestEnv = (process.env.NODE_ENV === 'development' || includesInArray(testLocations, [window.location.hostname, window.location.host]))

const ProductionStation = ({ getCurrentStationPlans, currentStationPlans,
  markItemAsAssembled, getProductionStations, currentProductionStation, userName
}) => {
  const { t } = useTranslation()
  const [time, setTime] = useState(moment().format('HH:mm'))
  const [date, setDate] = useState(moment().format('DD/MM/YYYY'))
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const [stopPolling, setStopPolling] = useState(false)
  const [current, setCurrent] = useState({})
  const [idsForCurrentItem, setPartsIdsForCurrentItem] = useIdsForCurrentItemState(initialIdsForCurrentItem)
  const [actions, setActions] = useActionsState(initialActionsState)
  const [actionIndex, setActionIndex] = useActionIndexState(initialActionIndex)
  const [selectedIndex, selectIndex] = useSelectedIndexState(initialSelectedIndex)
  const [currentReport, setReport] = useCurrentReportState(initialRaport)
  const [tooltip, setTooltip] = useState(false)
  const [emergencySteeringModalVisibility, setEmergencySteeringModalVisibility] = useState(false)
  // eslint-disable-next-line

  const resetPartsAndActionsForNewItem = () => {
    const defaultIds = obligatoryScans.reduce((acc, next) => {
      acc[next.itemRevision.fullIndex] = []
      return acc
    }, {})

    setPartsIdsForCurrentItem(defaultIds)
    setActions({ 0: { parts: defaultIds, partsIndex: 0 } })
  }

  useInterval(() => {
    setTime(moment().format('HH:mm'))
    setDate(moment().format('DD/MM/YYYY'))
  }, 60e3)

  useEffect(() => {
    getCurrentStationPlans()
  }, [getCurrentStationPlans])

  // disable production if parts were not received
  const [isProductionAllowed, setIsProductionAllowed] = useState(null) // null as it is implemented in logic used in useEffect requesting API to check if parts were delivered
  const [refreshCheckForPartsDeliveryInterval, setRefreshCheckForPartsDeliveryInterval] = useState(null)

  // TODO:
  // 1) Are parts delivered for single item or for entire plan?
  //    when shall i check up in API?
  // UPDATE: Data will be fixed in order to handle this situation

  // set if there was delivery for production parts
  useEffect(() => {
    if (!isNotNilOrEmpty(current)) {
      getCurrentStationPlans()
    } else {
      // when validation is skipped it is allowed to use undelivered parts ( development purposes only )
      if (!StorageService.get('skip-validation')) {
        const isProductionAllowed = R.propOr(false, 'partsReceivedForNextAssembly', current)
        setIsProductionAllowed(isProductionAllowed)
      } else {
        setIsProductionAllowed(true)
      }
    }
  }, [current])

  useEffect(() => {
    if (isProductionAllowed) {
      setRefreshCheckForPartsDeliveryInterval(null)
    } else if (isProductionAllowed === false && !refreshCheckForPartsDeliveryInterval) {
      // case when plans were fetched already and parts were not delivered yet
      // and if there is no interval already checking if there was part delivery
      setRefreshCheckForPartsDeliveryInterval(15e3)
      displayNoPartsForProductionErrorToast()
    }
  }, [isProductionAllowed, refreshCheckForPartsDeliveryInterval])

  const checkIfThereArePartsForCurrentAssembly = async () => {
    const dailyPlanId = R.prop('id', current)
    if (dailyPlanId) {
      try {
        await ProductionStationsService.checkPartsReceived(dailyPlanId)

        // upadate current with parts marked as delivered ( response 200 )
        setPartsStatusInCurrent(current, setCurrent, true)
      } catch (error) {
        // in case there are no parts received for assembly there is response code 422
        // this solution needs to be discussed as i have some efficiency douts about this solution
        setPartsStatusInCurrent(current, setCurrent, false)
        errorHandler(error, 5e3) // <- this one will be used when the endpoint will return sth else then 404 error
      }
    }
  }

  useInterval(() => {
    try {
      checkIfThereArePartsForCurrentAssembly()
    } catch (error) {
      errorHandler(error)
    }
  }, refreshCheckForPartsDeliveryInterval)
  // end

  const fetchStationPlans = useMemo(() => {
    return stopPolling ? () => {} : getCurrentStationPlans
  }, [stopPolling, getCurrentStationPlans])

  useInterval(fetchStationPlans, 300e3)

  useEffect(() => {
    getProductionStations()
  }, [getProductionStations])

  const firstNotManufacturedIndex = useMemo(() => {
    return R.pipe(
      R.sortBy(R.prop('sortOrder')),
      R.findIndex(item => item.manufactured < item.quantity)
    )(currentStationPlans)
  }, [currentStationPlans])

  const filteredChildrenPlan = R.evolve({ weeklyPlan: { bom: {
    parts: R.filter(R.pathSatisfies(serialNumberFormat => !!serialNumberFormat, ['itemRevision', 'serialNumberFormatId']))
  } } })

  useEffect(() => {
    const firstNotManufactured = filteredChildrenPlan(currentStationPlans[firstNotManufacturedIndex])
    const hasEmptyFields = R.pipe(R.values, R.any(R.complement(R.isEmpty)), R.not)(idsForCurrentItem)
    if (R.isEmpty(current)) {
      setCurrent(firstNotManufactured || {})
    } else if (R.equals(current.id, firstNotManufactured.id) && hasEmptyFields) {
      setCurrent(firstNotManufactured)
    } else if (R.isEmpty(firstNotManufactured)) {
      setCurrent({})
    } else if (!stopPolling && !R.equals(current.id, firstNotManufactured.id)) {
      if (window.confirm('Wykryto zmianę w planie dziennym. Potwierdź klikając ok (podmieni aktualnie wykonywany przedmiot) lub anuluj by dokończyć wykonywanie przedmiotu')) {
        fetchStationPlans()
        setActionIndex(initialActionIndex)
        selectIndex(initialSelectedIndex)
        setCurrent(firstNotManufactured)
        resetPartsAndActionsForNewItem()
      } else {
        setStopPolling(true)
      }
    } else if (stopPolling && !R.equals(current.id, firstNotManufactured.id)) {
      setCurrent(firstNotManufactured)
      setStopPolling(false)
    }
  }, [currentStationPlans])

  useEffect(() => {
    !StorageService.get(LocalStorageKeys.assemblyTime) && StorageService.set(LocalStorageKeys.assemblyTime, new Date())
  }, [])

  const isFinalAssembly = useMemo(() => R.path(['weeklyPlan', 'bom', 'itemRevision', 'finalAssemblyMmi'], current), [current])

  // steamjet logic
  const [serialNumber, setSerialNumber] = useState('')
  const [processName, setProcessName] = useState('')
  const [lastTestActionStatus, setLastTestActionStatus] = useState('')

  const getSerialNumber = async () => {
    try {
      const data = await MMIControllerAPIInterceptor.get('/serial_number')
      setSerialNumber(data.data)
      return data
    } catch (error) {
      vacuumTestErrorHandler(error)
    }
  }

  const getProcessName = async () => {
    try {
      const { data } = await MMIControllerAPIInterceptor.get('/process_name')
      setProcessName(data)
    } catch (error) {
      vacuumTestErrorHandler(error)
    }
  }

  const getReport = async () => {
    try {
      const { data: report } = await MMIControllerAPIInterceptor.get('/report')
      setReport(report)
      if (report) {
        const { data: response } = await ProductionStationsService.sendReport({ results: report })
        response.productCardUrl && print({
          printable: response.productCardUrl,
          onError: error => toast.error(error, { containerId: 'statuses' })
        })
      }
      setSerialNumber(null)
    } catch (error) {
      vacuumTestErrorHandler(error)
    }
  }

  const startTest = async () => {
    try {
      const { data } = await MMIControllerAPIInterceptor.post('/start_test_confirm', 2)
      setLastTestActionStatus(data)
      toast.success(data, { containerId: 'statuses', autoClose: 15e3 })
      return data
    } catch (error) {
      vacuumTestErrorHandler(error)
    }
  }

  const stopTest = async () => {
    try {
      const { data } = await MMIControllerAPIInterceptor.post('/stop_test', 2)
      setLastTestActionStatus(data)
      // setSerialNumber(null)
      return data
    } catch (error) {
      vacuumTestErrorHandler(error)
    }
  }

  // vacuum interval
  useInterval(async () => {
    if ((isFinalAssembly || serialNumber) && !StorageService.get('skip-validation')) {
      try {
        const { data } = await MMIControllerAPIInterceptor.get('/process_name')
        if (processName === 'IDLE' && data === 'VACUUM') {
          // case when test just started
          setProcessName(data)
        } else if (processName === 'VACUUM' && data === 'IDLE') {
          // case when test just finished ( doesn't matter if aborted or not )
          setProcessName(data)
          setLastTestActionStatus('Test finished')
          toast.success('Test finished', { containerId: 'statuses', autoClose: 15e3 })
          // we generate report after machine has finished test
          try {
            await getReport()
          } catch (error) {
            vacuumTestErrorHandler(error)
          }
        } else if (processName === 'No machines connected') {
          // case when connection with machine was lost
          await getSerialNumber()
          if (data === 'IDLE') {
            // case when machine is idle now
            setProcessName(data)
          } else if (data === 'VACUUM') {
            // case when machine is taking a vacuum test
            setProcessName(data)
            setLastTestActionStatus('Started VACUUM')
          }
        } else if (!processName) {
          // for initial conection state purposes
          await getSerialNumber()
          setProcessName(data)
          // case when connection was restored after connection cord was disconnected
          if (data === 'IDLE') {
            // case when machine is idle now
            setProcessName(data)
          } else if (data === 'VACUUM') {
            // case when machine is taking a vacuum test
            setProcessName(data)
            setLastTestActionStatus('Started VACUUM')
          }
        }
      } catch (error) {
        // case when error was returned ( no machines connected / connection error )
        setProcessName(R.pathOr('błąd połączenia', ['response', 'data'], error))
        // printing on error was outcommented as it was causing problems and was showing unexpected print prompts
        setSerialNumber('')
        // print({
        //   printable: process.env.REACT_APP_API_URL + `/assembly/product_card/${serialNumber}`,
        //   onError: error => toast.error(error, { containerId: 'statuses' })
        // })
        setLastTestActionStatus('----------')
      }
    }
  }, 10e3)

  const launchTest = async () => {
    // combo for vacuum test launch
    try {
      await getSerialNumber()
      await getProcessName()
      await startTest()
    } catch (error) {
      vacuumTestErrorHandler(error)
    }
  }
  // end of steamJet Logic

  const handleItemAssembly = async (scannedCode) => {
    if (loading) {
      return toast.warn('przetwarzam inny event', { containerId: 'statuses' })
    }
    try {
      if (isFinalAssembly && !StorageService.get('skip-validation')) {
        StorageService.set(LocalStorageKeys.lastTestRun, new Date())
        const { data: SN } = await getSerialNumber()
        SN !== scannedCode && await MMIControllerAPIInterceptor.post('/serial_number', scannedCode)
        setSerialNumber(scannedCode)
      }

      const currentItemAssemblyTime = moment().diff(moment(StorageService.get(LocalStorageKeys.assemblyTime)), 's')
      setLoading(true)

      // due to change from fullIndex to bom part Id now the payload has to be mapped
      // here we collect just the bomPartIds of parts
      const currentToPartIdsArray = current.weeklyPlan.bom.parts.map(part => part.id)
      // here we do use a mappingFn which returns bomPartId instead of fullIndex ( as previously )
      const toPayload = Object.entries(idsForCurrentItem).map(([partFI, partSN], index) => {
        const bomPartId = currentToPartIdsArray[index]
        return [bomPartId, partSN]
      })
      const payload = {
        assembly_post_id: currentProductionStation?.id,
        bom_daily_plan_id: current.id,
        assembly_time: currentItemAssemblyTime,
        serial_number: scannedCode,
        bom_stage_id: current.stage?.id,
        components: toPayload.map(component => ({
          bom_part_id: component[0],
          serial_numbers: component[1]
        }))
      }

      // TODO @robson - use this/familiar solution for bulk assembly
      // in response after assambly where is an information about parts status for next assembly
      // it needs to be set in current plan
      const markPartsStatusCallback = werePartsReceived => {
        if (!werePartsReceived) {
          // notify user if parts for next assembly are missing
          displayNoPartsForProductionErrorToast()
        }
        setPartsStatusInCurrent(current, setCurrent, werePartsReceived)
      }

      // mark item as assembled
      await markItemAsAssembled(payload, markPartsStatusCallback)

      setLoading(false)
      toast.success('Poprawnie wytworzono sztukę :-)', { containerId: 'statuses' })
      StorageService.set(LocalStorageKeys.assemblyTime, new Date())
      selectIndex(0)
      const nextCurrent = current.quantity - current.manufactured === 1
        ? {}
        : R.evolve({ manufactured: R.inc }, current)
      setCurrent(nextCurrent)
      changeFinishValue('')

      if (isFinalAssembly && processName === 'IDLE' && !StorageService.get('skip-validation')) {
        setSerialNumber(scannedCode)
        setReport(null)
        await launchTest()
      }
    } catch (error) {
      setLoading(false)
      const errors = R.pipe(
        R.pathOr({}, ['response', 'data', 'errors']),
        R.values,
        R.flatten,
        R.map(item => <div css={{ marginTop: 20 }} key={item}>{item}<br /></div>)
      )(error)
      const errorStatus = error.response?.status
      if (isFinalAssembly && error?.response?.data === 'No machines connected') {
      } else if (errorStatus === 401) {
        toast.error(
          <div>Wystąpił problem z autoryzacją. Spróbuj zalogować się ponownie</div>, {
            containerId: 'statuses',
            autoClose: 30e3
          }
        )
      }
      toast.error(<div><b>Coś poszło nie tak:</b><br /><br />{errors}</div>, {
        containerId: 'statuses',
        autoClose: 15e3
      })
      changeFinishValue('')
    }
  }

  const obligatoryScans = useMemo(() => R.pathOr([], ['weeklyPlan', 'bom', 'parts'], current), [current])

  const notEnoughCurrentSelectedScanned = useCallback(index => {
    return idsForCurrentItem[obligatoryScans[index]?.itemRevision.fullIndex]?.length < obligatoryScans[index]?.quantity
  }, [idsForCurrentItem, obligatoryScans])

  useEffect(() => {
    if (selectedIndex === 0) {
      resetPartsAndActionsForNewItem()
    }
  }, [current, selectedIndex])

  const scanHandler = async (code, onErrorCb = noop, onSuccessCb = noop) => {
    if (!isProductionAllowed) {
      displayNoPartsForProductionErrorToast()
      // onErrorCb removes value from current input ( when parts are missing for currrent assembly )
      onErrorCb()
      // nothing else will happen as long as parts were not received
      return null
    }
    // successCb blurs focus on current input ( when there is enough parts for current assembly )
    onSuccessCb()
    setErrors({})
    try {
      const isScanForParts = obligatoryScans.length && (selectedIndex < obligatoryScans.length)

      if (isScanForParts && notEnoughCurrentSelectedScanned(selectedIndex)) {
        const currentRegex = new RegExp(obligatoryScans[selectedIndex]?.itemRevision.serialNumberFormat.format, 'ig')
        const doesntPassIncludedRegex = !currentRegex.test(code)
        if (doesntPassIncludedRegex) {
          throw Error('Niepoprawny kod części')
        }

        const value = R.is(Array, code)
          ? R.map(value => R.match(currentRegex, value)[0], code)
          : R.match(currentRegex, code)[0]

        const payload = { itemCode: obligatoryScans[selectedIndex]?.id, serialNumber: value }
        await ProductionStationsService.checkForSerialNumberValidity(payload)

        setActions(R.assoc(actionIndex, { parts: idsForCurrentItem, partsIndex: selectedIndex }, actions))
        setActionIndex(actionIndex + 1)

        const newIdsSet = R.evolve({
          [obligatoryScans[selectedIndex].itemRevision.fullIndex]: R.is(Array, value) ? R.always(value) : R.append(value)
        }, idsForCurrentItem)

        setPartsIdsForCurrentItem(newIdsSet)
        !(newIdsSet[obligatoryScans[selectedIndex]?.itemRevision.fullIndex]?.length < obligatoryScans[selectedIndex]?.quantity) && selectIndex(selectedIndex + 1)
      } else if (isScanForParts && !notEnoughCurrentSelectedScanned(selectedIndex)) {
        setActions(R.assoc(actionIndex, { parts: idsForCurrentItem, partsIndex: selectedIndex }, actions))
        setActionIndex(actionIndex + 1)
        selectIndex(selectedIndex + 1)
      } else {
        const currentItemRegex = new RegExp(current?.weeklyPlan?.bom?.itemRevision.serialNumberFormat.format, 'ig')
        const codeMatch = code.match(currentItemRegex)
        const assemblyCode = R.prop(0, codeMatch)
        return !loading && handleItemAssembly(assemblyCode)
      }
      return Promise.resolve(code)
    } catch (e) {
      const apiErrors = e.response?.data?.errors?.serialNumber
      const key = obligatoryScans[selectedIndex].id || 'currentlyProduced'
      const mappedApiErrors = R.map(item => <span key={item}>{item}<br /></span>)(apiErrors || [])
      const errorStatus = e.response?.status
      setErrors({ [key]: apiErrors ? mappedApiErrors : e.message })
      if (errorStatus === 401) {
        toast.error(<div>Wystąpił problem z autoryzacją. Spróbuj zalogować się ponownie</div>, { containerId: 'statuses', autoClose: 30e3 })
      }
      return Promise.reject(e)
    }
  }

  const rollBackAction = (acI) => {
    setPartsIdsForCurrentItem(actions[acI]?.parts)
    selectIndex(actions[acI]?.partsIndex)
    setActionIndex(acI)
  }

  const planDone = useMemo(() => {
    return currentStationPlans.findIndex(item => item.manufactured < item.quantity) < 0
  }, [currentStationPlans])

  const fields = obligatoryScans.map((part, index) => {
    return (
      <PartBox
        currentValue={idsForCurrentItem[part.itemRevision.fullIndex] || []}
        scanHandler={scanHandler}
        key={'step-' + index + current.manufactured}
        errors={errors}
        index={index}
        selectedIndex={selectedIndex}
        part={part}
      />
    )
  })

  const finishEstimationSeconds = currentStationPlans.reduce((acc, next) => {
    return acc + R.pathOr(0, ['weeklyPlan', 'bom', 'assemblyTime', 'seconds'], next) * (next.quantity - next.manufactured)
  }, 0)

  const progressForDay = () => {
    const expectedFinishTime = currentStationPlans.reduce((acc, next) => {
      return acc + R.pathOr(0, ['weeklyPlan', 'bom', 'assemblyTime', 'seconds'], next) * (next.quantity)
    }, 0)
    const currentTimeProgress = currentStationPlans.reduce((acc, next) => {
      return acc + R.pathOr(0, ['weeklyPlan', 'bom', 'assemblyTime', 'seconds'], next) * (next.manufactured)
    }, 0)

    return Math.min(100, (Math.round((currentTimeProgress / expectedFinishTime) * 100) || 0))
  }

  const currentProgress = Math.min(Math.round(((current.manufactured) / current.quantity) * 100), 100)

  const shouldDisplayRevert = R.pipe(
    R.values,
    R.all(R.isEmpty),
    R.not
  )(idsForCurrentItem)

  process.env.NODE_ENV !== 'development' && useFullscreen()

  const [finishValue, changeFinishValue] = useInput('')

  const inputRef = useRef(null)

  const keyDownHandler = async event => {
    const keyCode = event.which || event.keyCode
    const isEnter = keyCode === 13
    const isEsc = keyCode === 27
    const isCurrent = selectedIndex === fields.length
    const targetIsNotTextArea = !['textarea', 'input'].includes(event.target.nodeName.toLowerCase()) || event.target === inputRef.current
    if (isCurrent && isEnter) {
      await inputRef.current.blur()
      await scanHandler(inputRef.current.value)
    } else if (isCurrent && !isEsc && targetIsNotTextArea) {
      // eslint-disable-next-line no-unused-expressions
      inputRef.current?.focus()
    } else if (isCurrent && isEsc) {
      inputRef.current.blur()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  })

  const confirmTestStop = () => {
    confirmAlert({
      title: t('productionStation.stopTest'),
      buttons: [
        {
          label: t('catalogue.confirmationDialogue.yes'),
          onClick: stopTest
        },
        {
          label: t('catalogue.confirmationDialogue.no'),
          onClick: () => {}
        }
      ]
    })
  }

  // displayed controls condition
  const minutesSinceLastTestRun = moment().diff(moment(StorageService.get(LocalStorageKeys.lastTestRun)), 'm')
  // 60 minutes since last test run or if current assembly is final vacuum controls should be displayed
  const shouldSteeringControlsBeDisplayed = isFinalAssembly || minutesSinceLastTestRun <= 60

  return (
    <Fragment>
      {isTestEnv && <SteamJetGenerator code={obligatoryScans[selectedIndex]?.itemRevision.fullIndex} />}
      <Global styles={styleOverwrites} />
      <Tooltip
        trigger={'manual'}
        size={'big'}
        open={tooltip}
        content={<DayPlanTooltipContent plans={currentStationPlans} />}
      >
        <Flex mx={10} my={6} alignItems='center'>
          <Flex alignItems='center'>
            <span>start:</span>
            <div css={subHeaderTextCss}>{moment(StorageService.get(LocalStorageKeys.loginTime)).format('HH:mm')}</div>
          </Flex>
          <Flex alignItems='center' css={{ marginLeft: 'auto' }}>
            <span>{t('productionStation.estimatedFinishTime')}</span>
            <div css={subHeaderTextCss}>{moment().add(finishEstimationSeconds || 1, 's').format('HH:mm')}</div>
          </Flex>
        </Flex>
        <Box mx={10} my={6}>
          <div css={progressBarCss(progressForDay())} />
        </Box>
        <Flex mx={10} my={6} alignItems='center'>
          <span>stanowisko:</span>
          <span css={subHeaderTextCss}>{currentProductionStation?.number} {currentProductionStation?.name} {currentProductionStation?.subindex}</span>
          <span>zalogowany:</span>
          <span css={subHeaderTextCss}>{userName}</span>
          <div css={timeCss}>
            <span>
              {date}
            </span>
            <span>
              {time}
            </span>
          </div>
        </Flex>
        <Box css={containerCss} onClick={() => { setTooltip(!tooltip) }}>
          <Flex mx={10} mt={10} flexDirection='column' css={plansWrapperCss}>
            <Fragment key={current.id}>
              <Flex css={planCss}>
                <Box
                  css={nameCss(true)}>{current.weeklyPlan?.bom.itemRevision.productionName || current.weeklyPlan?.bom.itemRevision.name}</Box>
                <span css={currentElementCss}>{(current.manufactured || 0)} / {current.quantity}</span>
              </Flex>
              {planDone && <Box px={10} css={planDoneCss}>Wytworzono już wszystko</Box>}
              <Box my={6} css={progressBarCss(currentProgress)} />
            </Fragment>
          </Flex>
          <ImageGallery images={mapFilesToReactImagesComponent(current.weeklyPlan?.bom.itemRevision.files || [])} whiteBackground />
        </Box>
      </Tooltip>
      <Box css={{ position: 'relative' }}>
        <Flex m={10} css={{ maxWidth: 'calc(100vw - 148px)', overflow: 'hidden', flex: '0 0 auto' }}>
          {[
            ...fields,
            <div
              key={'step-' + 'last'}
              css={stepCss(selectedIndex, fields.length)}
            >
              <span css={{ textAlign: 'center' }}>{t('productionStation.scanMainId')}</span>
              <span css={{ textAlign: 'center' }}>{errors.currentlyProduced}</span>
              <input ref={inputRef} css={{ marginTop: '30px', border: 'none', borderBottom: '2px solid black' }} value={finishValue} onChange={changeFinishValue} />
              <div
                css={{
                  height: 200,
                  width: '100%',
                  marginTop: 20,
                  color: 'white',
                  borderRadius: 10,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItem: 'center'
                }}
              >
                <QrCode size={150} value={''} />
              </div>
            </div>
          ]}
        </Flex>
      </Box>
      <Flex m={10} my={8} justifyContent='space-between'>
        <Flex flexDirection='column'>
          {shouldSteeringControlsBeDisplayed && (
            <div
              css={{ cursor: 'pointer', height: '50px', width: '320px', border: '6px solid black', borderRadius: '9px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              onClick={() => setEmergencySteeringModalVisibility(true)}
            >
              {`Stan maszyny: ${machineStatusGetterByProcessName(processName) || '----------'} `}
            </div>
          )}
          <BarcodePrinter />
        </Flex>
        {shouldDisplayRevert &&
          <div>
            <Button
              css={{ width: 200 }}
              onClick={() => rollBackAction(actionIndex - 1)}
            >
              cofnij
            </Button>
          </div>
        }
        <RevertButton
          onClickFinish={getCurrentStationPlans}
          css={{ margin: 'unset', marginLeft: shouldDisplayRevert ? 40 : 'auto', width: 200 }}
          timeForRevert={30}
        />
        {emergencySteeringModalVisibility && (
          <EmergencySteeringModal
            onClose={() => setEmergencySteeringModalVisibility(false)}
          >
            {/* STEAMJET Test Controls */}
            <VacuumTestSteeringBox
              onLaunchTest={launchTest}
              onStopTest={confirmTestStop}
              onGetReport={getReport}
              serialNumber={serialNumber}
              lastTestActionStatus={lastTestActionStatus}
              currentRaport={currentReport}
              processName={processName}
            />
          </EmergencySteeringModal>
        )}
      </Flex>
    </Fragment>
  )
}

export default ProductionStation
