import InternalOrdersList from 'views/InternalOrdersList'
import { connect } from 'react-redux'
import { getInternallyOrderedItemsAsArrayByStatus } from 'selectors/internalOrderedItems'
import { getAllInternallyOrderedItems } from 'ducks/internalOrderedItems'
import orderStatuses from 'constants/orderStatuses'
import * as R from 'ramda'

const mapStateToProps = state => {
  const items = R.pipe(
    R.values,
    R.map((status) => ({ [status]: getInternallyOrderedItemsAsArrayByStatus(state, status) })),
    R.reduce((acc, val) => ({ ...acc, ...val }), {})
  )(orderStatuses)
  return ({
    items
  })
}

const mapDispatchToProps = dispatch => ({
  getAllInternallyOrderedItems: () => dispatch(getAllInternallyOrderedItems())
})

export default connect(mapStateToProps, mapDispatchToProps)(InternalOrdersList)
