/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Flex } from '@rebass/emotion'
import { Field, Form } from 'redux-form'
import theme from 'config/theme'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import { required, numericality } from 'redux-form-validators'
import InputWithIcon from 'components/InputWithIcon'
import { ReactComponent as Report } from 'assets/report.svg'

const buttonCss = css`
  border-radius: 200px;
  padding: 10px;
`

const formCss = css`
  display: grid;
  width: 100%;
  grid-template-columns: 100px 50px 1fr;
`

const Input = ({ input, ...props }) => {
  const hasError = props.meta.touched && props.meta.error
  return <InputWithIcon
    withTooltip={hasError}
    tooltipContent={hasError && props.meta.error}
    iconStyle={{ paddingLeft: 10, path: { fill: theme.colors.error } }}
    hasError={hasError}
    icon={hasError ? Report : null}
    {...props}
    {...input}
  />
}

const ReceiveInternalOrderForm = (props) => {
  const { t } = useTranslation()
  const submit = (values) => {
    props.confirmInternalOrderDelivery(values)
  }
  const quantity = props.initialValues.quantity
  return (
    <Form css={formCss} onSubmit={props.handleSubmit(submit)}>
      <Field
        component={Input}
        name='quantity'
        type='number'
        validate={[
          required({ message: t('errors.form.required') }),
          numericality({ '>': 0, message: t('errors.form.greaterThan', { number: 0 }) }),
          numericality({ '<=': quantity, message: t('errors.form.maxCount', { number: quantity }) }),
          numericality({ message: t('errors.form.mustBeNumber') })
        ]}
      />
      <Flex css={{ paddingLeft: 6, alignSelf: 'center' }}>
        {`/ ${quantity}`}
      </Flex>
      <Field
        type='hidden'
        name='internalOrderItemId'
        component='input'
      />
      <Button css={buttonCss} type='submit'>
        {t('warehouse.orders.pickup')}
      </Button>
    </Form>
  )
}

export default ReceiveInternalOrderForm
