/** @jsx jsx */
import { Global, jsx, CacheProvider } from '@emotion/core'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'emotion-theming'
import { useTranslation, withTranslation } from 'react-i18next'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ReduxStoreConfigurator from 'services/ReduxStoreConfigurator'
import globals from 'config/globals'
import theme from 'config/theme'
import GetSupplyScreenConnector from 'connectors/GetSupplyScreenConnector'
import ReceiveSupplyScreenConnector from 'connectors/ReceiveSupplyScreenConnector'
import MainMenuConnector from 'connectors/MainMenuConnector'
import ConfirmItemDeliveryConnector from 'connectors/ConfirmItemDeliveryConnector'
import Codes from 'views/Codes'
import OnBoardInboundItemConnector from 'connectors/OnBoardInboundItemConnector'
import Locations from 'views/Locations'
import WarehouseStateConnector from 'connectors/WarehouseStateConnector'
import OnBoardItemOnWarehouseConnector from 'connectors/OnBoardItemOnWarehouseConnector'
import SearchItemsInWarehouse from 'views/SearchItemInWarehouse'
import HandOverViewConnector from 'connectors/HandOverViewConnector'
import HandOverFinalizeScreenConnector from 'connectors/HandOverFinalizeScreenConnector'
import RecalculateViewConnector from 'connectors/RecalculateViewConnector'
import RecalculateFinalizeScreenConnector from 'connectors/RecalculateFinalizeScreenConnector'
import LoginConnector from 'connectors/LoginConnector'
import AuthorizedRoutes from 'routerComponents/AuthorizedRoutes'
import Logout from 'views/Logout'
import { Roles } from 'constants/Roles'
import PrivateRoute from 'routerComponents/PrivateRoute'
import Routes from 'constants/Routes'
import WarehouseOrdersListConnector from 'connectors/WarehouseOrdersListConnector'
import LogoutWhenBlacklisted from 'components/LogoutWhenBlacklisted'
import InternalOrdersListConnector from 'connectors/InternalOrdersListConnector'
import InternalOrderCompleteFormConnector from 'connectors/forms/InternalOrderCompleteFormConnector'
import StocktakingIssuesListConnector from 'connectors/StocktakingIssuesListConnector'
import StocktakingIssueFormConnector from 'connectors/forms/StocktakingIssueFormConnector'
import WeeklyPlansConnector from 'connectors/WeeklyPlansConnector'
import WeeklyPlansForemanConnector from 'connectors/WeeklyPlansForemanConnector'
import MainCatalogueViewConnector from 'connectors/MainCatalogueViewConnector'
import InternalSubmissionsListConnector from 'connectors/InternalSubmissionsListConnector'
import InboundItemsListConnector from 'connectors/InboundItemsListConnector'
import StructuredCatalogueViewConnector from 'connectors/StructuredCatalogueViewConnector'
import BomsEditScreenConnector from 'connectors/BomsEditScreenConnector'
import BomsImportsListConnector from 'connectors/BomsImportsListConnector'
import SerialNumberFormatsScreenConnector from 'connectors/SerialNumberFormatsScreenConnector'
import Pusher from 'pusher-js'
import { setPusherClient } from 'react-pusher'
import NotificationsService from 'services/NotificationsService'
import PusherNotificationsConnector from 'connectors/PusherNotificationsConnector'
import ToasterConnector from 'connectors/ToasterConnector'
import ProductionStationChooseConnector from 'connectors/ProductionStationChooseConnector'
import ProductionStationConnector from 'connectors/ProductionStationConnector'
import MoveItemSelectConnector from 'connectors/MoveItemSelectConnector'
import MoveItemOnWarehouseFormConnector from 'connectors/MoveItemOnWarehouseFormConnector'
import ProductionStationsConnector from 'connectors/ProductionStationsConnector'
import AssemblyDetailsScreenConnector from 'connectors/AssemblyDetailsScreenConnector'
import Navbar from 'components/Navbar'
import isZebraBrowser from 'helpers/isZebra'
import { Component, useEffect } from 'react'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import * as R from 'ramda'
import DeviceServiceConnector from 'connectors/DeviceServiceConnector'
import UsersConnector from 'connectors/UsersConnector'
import WorkstationsViewConnector from 'connectors/WorkstationsViewConnector'
import QualityControl from 'views/QualityControl'
import QualityControlDetails from 'views/QualityControlDetails'
import ServiceGetDeviceScreenConnector from 'connectors/ServiceGetDeviceScreenConnector'
import createCache from '@emotion/cache'
import BugReport from 'views/BugReport'
import useOpenState from 'hooks/useOpenState'
import Modal from 'components/Modal'
import { useHotkeys } from 'react-hotkeys-hook'
import StorageService from 'services/StorageService'
import ManageLocationsConnector from 'connectors/ManageLocationsConnector'

const pusherClient = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
  cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  authEndpoint: process.env.REACT_APP_API_URL + '/notifications/auth',
  authorizer: ({ name }) => ({
    authorize: async (socketId, callback) => {
      const channelToken = await NotificationsService.authorize(name, socketId)
      if (channelToken) {
        // eslint-disable-next-line standard/no-callback-literal
        callback(false, JSON.parse(channelToken.request.response))
      } else {
        // eslint-disable-next-line standard/no-callback-literal
        callback(true, null)
      }
    }
  })
})

setPusherClient(pusherClient)

const storeConfigurator = new ReduxStoreConfigurator()
export const store = storeConfigurator.configureStore()

class Boundary extends Component {
  state = {
    error: false
  }
  componentDidCatch (error, info) {
    !R.isEmpty(error) && toast.error(error.message, { containerId: 'statuses' })
  }

  render () {
    return <div onContextMenu={this.props.handleContextMenu}>{this.props.children}</div>
  }
}

const Confirm = ({ confirm, message }) => {
  useEffect(() => {
    confirmAlert({
      message,
      closeOnEscape: false,
      buttons: [
        {
          label: 'Tak',
          onClick: () => confirm(true)
        },
        {
          label: 'Nie',
          onClick: () => confirm(false)
        }
      ]
    })
  }, [message, confirm])
  return null
}

const getUserConfirmation = (message, callback) => {
  ReactDOM.render((
    <div>
      <Confirm message={message} confirm={callback} />
    </div>
  ), document.getElementById('confirmPrompt'))
}

const cache = createCache({
  prefix: false
})

const App = () => {
  const { t } = useTranslation()
  const [isBugReportOpen, openBugReport, closeBugReport] = useOpenState(false)
  useHotkeys('ctrl+shift+l,cmd+shift+l', openBugReport)

  const isBugReportDisabled = StorageService.get('disable-bug-report')

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CacheProvider value={cache}>
          <BrowserRouter getUserConfirmation={getUserConfirmation}>
            <ToasterConnector />
            <Boundary handleContextMenu={openBugReport}>
              <LogoutWhenBlacklisted>
                <Global styles={globals} />
                {isBugReportOpen && !isBugReportDisabled && (
                  <Modal
                    headerTitle={'Report bugów'}
                    onClose={closeBugReport}
                    zIndex={1200}
                  >
                    <BugReport
                      closeBugReport={closeBugReport}
                    />
                  </Modal>
                )}
                <Switch>
                  <Route exact path={Routes.login} component={LoginConnector} />
                  <Route exact path={Routes.logout} component={Logout} />
                  <Route exact path={Routes.codes} component={Codes} />
                  <Route exact path={Routes.locations} component={Locations} />
                  <AuthorizedRoutes allowedRoles={Object.values(Roles)}>
                    {!isZebraBrowser() && <Navbar />}
                    <PusherNotificationsConnector />
                    <Switch>
                      {/* MAIN MENU */}

                      <Route exact path='/' component={MainMenuConnector} />

                      {/* supply orders */}

                      <Route exact path={Routes.supplySearch} component={GetSupplyScreenConnector} />
                      <Route exact path={Routes.supplyView} component={ReceiveSupplyScreenConnector} />
                      <Route exact path={Routes.supplyFinalize} component={ConfirmItemDeliveryConnector} />

                      {/* moving items from inbound zone to warehouse */}

                      <Route exact path={Routes.onBoardingSearch} component={InboundItemsListConnector} />
                      <Route exact path={Routes.onBoardingView} component={OnBoardInboundItemConnector} />
                      <Route
                        exact
                        path={Routes.onBoardingFinalize}
                        component={OnBoardItemOnWarehouseConnector}
                      />

                      {/* acknowledging item submissions to warehouse */}

                      <Route exact path={Routes.internalSubmissionsList} component={InternalSubmissionsListConnector} />
                      <Route exact path={Routes.internalSubmissionView} component={OnBoardInboundItemConnector} />
                      <Route
                        exact
                        path={Routes.internalSubmissionFinalize}
                        component={OnBoardItemOnWarehouseConnector}
                      />

                      {/* moving items on warehouse */}

                      <Route
                        exact
                        path={Routes.moveItemsSearch}
                        component={(props) => <SearchItemsInWarehouse heading={t('warehouse.moveItems')} {...props} target={'/move/select'} />}
                      />
                      <Route exact path={Routes.moveItemOnWarehouse} component={MoveItemSelectConnector} />
                      <Route exact path={Routes.moveItemInventory} component={MoveItemOnWarehouseFormConnector} />

                      {/* handing over items for purpose */}

                      <Route
                        exact
                        path={Routes.handOverSearch}
                        component={(props) => <SearchItemsInWarehouse heading={t('warehouse.handOver')} {...props} target={'/warehouse/hand-over'} />}
                      />
                      <Route exact path={Routes.handOverView} component={HandOverViewConnector} />
                      <Route exact path={Routes.handOverFinalize} component={HandOverFinalizeScreenConnector} />

                      {/* recalculating items count on warehouse */}

                      <Route
                        exact
                        path={Routes.recalculateSearch}
                        component={(props) => <SearchItemsInWarehouse heading={t('warehouse.recalculate')} {...props} target={'/warehouse/recalculate'} />}
                      />
                      <Route exact path={Routes.recalculateView} component={RecalculateViewConnector} />
                      <Route
                        exact
                        path={Routes.recalculateFinalize}
                        component={RecalculateFinalizeScreenConnector}
                      />

                      {/* internal orders views */}

                      <Route
                        exact
                        path={Routes.ordersList}
                        component={InternalOrdersListConnector}
                      />
                      <Route
                        exact
                        path={Routes.orderComplete}
                        component={InternalOrderCompleteFormConnector}
                      />

                      {/* stocktaking on zebra */}

                      <Route
                        exact
                        path={Routes.stocktakingList}
                        component={StocktakingIssuesListConnector}
                      />
                      <Route
                        exact
                        path={Routes.stocktakingComplete}
                        component={StocktakingIssueFormConnector}
                      />

                      {/* warehouse state / orders views / web views */}
                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN]}
                        exact
                        path={Routes.warehouse}
                        component={WarehouseStateConnector}
                      />
                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER]}
                        exact

                        path={Routes.warehouseStocktaking}
                        component={StocktakingIssuesListConnector}
                      />

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.FOREMAN]}
                        exact
                        path={Routes.warehouseOrdersList}
                        component={WarehouseOrdersListConnector}
                      />

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN]}
                        exact
                        path={Routes.manageLocations}
                        component={ManageLocationsConnector}
                      />

                      {/* Catalogue */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.QUALITY_ASSURANCE, Roles.SUPPLY, Roles.TECHNOLOGIST]}
                        exact
                        path={Routes.catalogue}
                        component={MainCatalogueViewConnector}
                      />
                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.QUALITY_ASSURANCE, Roles.SUPPLY, Roles.TECHNOLOGIST]}
                        exact
                        path={Routes.catalogueStructuredView}
                        component={StructuredCatalogueViewConnector}
                      />

                      {/* Catalogue dropdown menu */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.FOREMAN, Roles.PRODUCTION_MANAGER]}
                        exact
                        path={Routes.users}
                        component={UsersConnector}
                      />
                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.FOREMAN, Roles.PRODUCTION_MANAGER]}
                        exact
                        path={Routes.workstations}
                        component={WorkstationsViewConnector}
                      />
                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.TECHNOLOGIST]}
                        exact
                        path={Routes.boms}
                        component={BomsImportsListConnector}
                      />

                      {/* boms edit */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.TECHNOLOGIST]}
                        exact
                        path={Routes.bomsEdit}
                        component={BomsEditScreenConnector}
                      />

                      {/* serial number formats crud */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.TECHNOLOGIST]}
                        exact
                        path={Routes.serialNumberFormats}
                        component={SerialNumberFormatsScreenConnector}
                      />

                      {/* weekly / daily plans */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN]}
                        exact
                        path={Routes.weeklyPlans}
                        component={WeeklyPlansConnector}
                      />
                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN]}
                        exact
                        path={Routes.weeklyPlansForeman}
                        component={WeeklyPlansForemanConnector}
                      />

                      {/* production sites */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.FOREMAN]}
                        exact
                        path={Routes.productionStations}
                        component={ProductionStationsConnector}
                      />

                      {/* Production station for production worker login */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.PRODUCTION_WORKER]}
                        exact
                        path={Routes.productionStationChooseStation}
                        component={ProductionStationChooseConnector}
                      />
                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.PRODUCTION_WORKER]}
                        exact
                        path={Routes.productionStation.root}
                        component={ProductionStationConnector}
                      />

                      {/* Production Service */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.PRODUCTION_WORKER]}
                        exact
                        path={Routes.serviceGetDevice}
                        component={ServiceGetDeviceScreenConnector}
                      />
                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.PRODUCTION_WORKER]}
                        exact
                        path={Routes.productionService}
                        component={DeviceServiceConnector}
                      />

                      {/* Quality Control */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.QUALITY_ASSURANCE]}
                        exact
                        path={Routes.qualityControl}
                        component={QualityControl}
                      />
                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.QUALITY_ASSURANCE]}
                        exact
                        path={Routes.qualityControlDetails}
                        component={QualityControlDetails}
                      />

                      {/* assembled item management */}

                      <PrivateRoute
                        allowedRoles={[Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.PRODUCTION_WORKER]}
                        exact
                        path={Routes.assemblyDetails}
                        component={AssemblyDetailsScreenConnector}
                      />

                      <Route component={() => <div>not found page</div>} />
                    </Switch>
                  </AuthorizedRoutes>
                  <Route component={() => <div>not found page</div>} />
                </Switch>
              </LogoutWhenBlacklisted>
            </Boundary>
          </BrowserRouter>
        </CacheProvider>
      </ThemeProvider>
    </Provider>
  )
}

export default withTranslation()(App)
