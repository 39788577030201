import APIInterceptor from './APIInterceptor'

const interceptor = APIInterceptor

const DiscrepancyService = {
  getDiscrepancyComments: () => {
    return interceptor.get(`/discrepancies/comments`)
  }
}

export default DiscrepancyService
