const orderStatuses = {
  WAITING_FOR_AVAILABILITY: 'waiting for availability',
  PENDING: 'pending',
  RECEIVED: 'received',
  PROCESSING: 'processing',
  DELIVERED: 'delivered',
  NEW: 'new',
  READY: 'ready'
}

export const orderedOrdersStatuses = {
  'waiting for availability': 'warehouse.orders.statuses.waitingForAvailability',
  'pending': 'warehouse.orders.statuses.pending',
  'ready': 'warehouse.orders.statuses.ready',
  'received': 'warehouse.orders.statuses.received'
}

export const handedOverOrders = {
  'pending': 'warehouse.handOvers.statuses.pending',
  'processing': 'warehouse.handOvers.statuses.processing',
  'acknowledged': 'warehouse.handOvers.statuses.acknowledged'
}

export const statusesOrder = {
  [orderStatuses.READY]: 1,
  [orderStatuses.PROCESSING]: 2,
  [orderStatuses.PENDING]: 3,
  [orderStatuses.WAITING_FOR_AVAILABILITY]: 4,
  [orderStatuses.RECEIVED]: 5,
  [orderStatuses.DELIVERED]: 6,
  [orderStatuses.NEW]: 7
}

export default orderStatuses
