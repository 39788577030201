/** @jsx jsx */
import { Global, jsx, css } from '@emotion/core'
import OrderItem from 'components/OrderItem'
import { Box } from '@rebass/emotion'
import { useEffect, useState } from 'react'
import { Field, Form } from 'redux-form'
import theme from 'config/theme'
import { useTranslation } from 'react-i18next'
import Breadcrumb from 'components/Breadcrumb'
import LabelledDivider from 'components/LabelledDivider'
import Input from 'components/Input'
import { inputCss } from 'forms/OnboardItemForm'
import Button from 'components/Button'
import { ReactComponent as CheckmarkIcon } from 'assets/check-mark.svg'
import { ReactComponent as Loader } from 'assets/loader.svg'
import Ink from 'react-ink'
import Routes from 'constants/Routes'
import QrCodeReader from 'components/QrCodeReader'
import { scanParser } from 'helpers/scanParser'
import { getItemFromTheList } from 'helpers/items'
import { path } from 'ramda'

const coloredCanvas = theme => css`
  canvas {
    color: ${theme.colors.darkGrey};
  }
`

const inventoryCss = isSelected => ({
  border: '.5px solid ' + theme.colors.white,
  backgroundColor: isSelected ? theme.colors.white : theme.colors.darkGrey,
  color: !isSelected ? theme.colors.white : theme.colors.darkGrey,
  position: 'relative'
})

const InternalOrderCompleteForm = (props) => {
  const { t } = useTranslation()
  const { item, inventory } = props.location.state
  const [selectedInventory, selectInventory] = useState(inventory)
  const [loading, setLoading] = useState(false)
  const [inputFocused, setInputFocused] = useState(false)

  const handleInventorySelection = inventory => () => {
    selectInventory(inventory)
    props.change('quantity', Math.min(inventory.quantity, item.totalUndeliveredQuantity))
    props.change('inventory_id', inventory.id)
    props.change('inventory_quantity', inventory.quantity)
  }

  const submit = async values => {
    setLoading(true)
    await props.prepareInternalOrderItem(values)
    setLoading(false)
    props.history.replace(Routes.ordersList, {
      recentlyOpenedOrderItemId: props.lastExpandedOrderId
    })
  }

  const handleScanEvent = scanData => {
    const parsedScanData = scanParser(scanData)

    if (parsedScanData.type === 'location') {
      const specificInventory = getItemFromTheList(parsedScanData.data, ['warehouseLocation', 'code'])(path(['inventory'], item))
      specificInventory && handleInventorySelection(specificInventory)()
    }
  }

  useEffect(() => {
    const button = document.getElementById('submitButton')
    inputFocused && setTimeout(() => button.scrollIntoView({ behavior: 'smooth' }), 0)
  }, [inputFocused])

  return (
    <Form onSubmit={props.handleSubmit(submit)}>
      <QrCodeReader onScan={handleScanEvent} invisible />
      <Global styles={coloredCanvas} />
      <Breadcrumb back to={Routes.ordersList}>{t('warehouse.orders.internalOrders')}</Breadcrumb>
      <OrderItem
        key={item.id}
        selected
        item={item}
        renderInventory={
          inventory => {
            const isSelected = selectedInventory.id === inventory.id
            return (
              <Box
                onClick={handleInventorySelection(inventory)}
                as='h4'
                key={inventory.id}
                css={inventoryCss(isSelected)}
                p={3}
              >
                <Ink duration={5000} />
                {inventory.warehouseLocation.code} ({inventory.quantity})
              </Box>
            )
          }
        }
      />
      <LabelledDivider text={t('warehouse.orders.itemCountTaken')} />
      <Box p={5}>
        <Field
          autoFocus
          component={Input}
          css={inputCss}
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          numericKeyboard
          name='quantity'
          type='number'
        />
        <Field
          component='input'
          type='hidden'
          name='inventory_id'
        />
        <Field
          component='input'
          type='hidden'
          name='id'
        />
        <Field
          component='input'
          type='hidden'
          name='inventory_quantity'
        />
      </Box>
      <Box py={3}>
        <Button
          id={'submitButton'}
          withIcon
          icon={loading ? Loader : CheckmarkIcon}
          iconProps={{ fill: theme.colors.white }}
          type='submit'
        >
          {t('warehouse.orders.confirmOrderPrepare')}
        </Button>
      </Box>
    </Form>
  )
}

export default InternalOrderCompleteForm
