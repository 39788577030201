import OnBoardInboundItem from 'views/OnBoardInboundItem'
import { connect } from 'react-redux'
import { searchForItemOnInboundZone } from 'ducks/inboundZone'
import { getSubmissionsAsArray } from 'selectors/internalSubmissions'

const mapStateToProps = state => ({
  currentItem: state.inboundZone.currentItem,
  pendingSubmissions: getSubmissionsAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  searchForItemOnInboundZone: (itemCode) => dispatch(searchForItemOnInboundZone(itemCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardInboundItem)
