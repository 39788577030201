/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { Box } from '@rebass/emotion'
import useInput from 'hooks/useInput'
import APIInterceptor from 'services/APIInterceptor'
import Button from 'components/Button'
import { errorHandler } from 'helpers/errorHandler'
import StorageService, { LocalStorageKeys } from 'services/StorageService'

const BugReport = ({ closeBugReport }) => {
  const [value, onChange] = useInput('')
  const [isLoading, setLoading] = useState(false)
  const handleSubmit = async () => {
    try {
      setLoading(true)
      await APIInterceptor.post('/support/report', {
        message: value,
        url: window.location.href,
        assemblyPostId: StorageService.get(LocalStorageKeys.stationId),
        consoleLog: console.everything,
        localStorage: StorageService.getAll()
      })
      setLoading(false)
      toast.success('pomyślnie wysłano raport', { containerId: 'statuses' })
      closeBugReport()
    } catch (e) {
      errorHandler(e)
      setLoading(false)
    }
  }

  return (
    <Box p={6}>
      <textarea value={value} onChange={onChange} css={{ minWidth: '100%', minHeight: '500px' }} />
      <Button disabled={isLoading} css={{ width: 'auto', margin: 0 }} onClick={handleSubmit}>wyślij</Button>
    </Box>
  )
}

export default BugReport
