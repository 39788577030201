/** @jsx jsx */
import { jsx, css, Global } from '@emotion/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Flex } from '@rebass/emotion'
import theme from 'config/theme'
import moment from 'moment'
import Button from 'components/Button'
import { ReactComponent as Arrow } from 'assets/arrow.svg'
import EditStatus from 'components/EditStatus'
import { useTranslation } from 'react-i18next'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { transformById, transformToArray } from 'helpers/redux'
import StationBox from './StationBox'
import BomItem from './BomItem'
import { iterable } from 'helpers/array'
import * as R from 'ramda'
import { findIdInString } from 'helpers/regexp'
import { ReactComponent as Loader } from 'assets/loader.svg'
import useInterval from 'hooks/useInterval'
import { Prompt } from 'react-router-dom'
import IconBox from 'components/IconBox'
import useInput from 'hooks/useInput'
import { ReactComponent as CancelIcon } from 'assets/cancel.svg'
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'
import { searchBarCss, resetCss, iconDimensions } from 'views/WarehouseState/styles'
import { errorHandler } from 'helpers/errorHandler'

const wrapperCss = css`
  display: grid;
  grid-template-areas: "boms header"
                       "boms stations";
`

const bomsCss = css`
  grid-area: boms;
`

const tooltipStyle = () => css`
  .tippy-popper {
    max-width: unset;
  }
  
  .react-confirm-alert-overlay {
    background: black;
    z-index: 1125;
  }
`

const ProductionStations = ({ plans: propsPlans, ...props }) => {
  const { t } = useTranslation()
  const [state, setState] = useState({})
  const [initialState, setInitialState] = useState({})
  const [availableItems, setAvailableItems] = useState([])
  const [currentWeek, setCurrentWeek] = useState(null)
  const [currentYear, setCurrentYear] = useState(null)
  const [plans, setPlans] = useState([])
  const [isDragging, setDragging] = useState(false)
  const [dailyPlans, setDailyPlans] = useState({})
  const [initialDailyPlans, setInitialDailyPlans] = useState({})
  const [isDraggedFromStationBox, setDraggingFromStationBox] = useState(false)
  const [draggedItem, setDraggedItem] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [filterSearchPhrase, changeFilterSearchPhrase] = useInput('')
  const [filteredPlans, setFilteredPlans] = useState([])

  useEffect(() => {
    const pathIncludesSearchPhrase = path => R.pipe(
      R.pathOr('', path),
      R.toLower,
      R.includes(R.toLower(filterSearchPhrase))
    )

    const filterBySearchPhrase = R.anyPass([
      pathIncludesSearchPhrase(['bom', 'itemRevision', 'fullIndex']),
      pathIncludesSearchPhrase(['bom', 'itemRevision', 'name']),
      pathIncludesSearchPhrase(['bom', 'itemRevision', 'productionName'])
    ])

    const newPlans = R.filter(filterBySearchPhrase, plans)
    setFilteredPlans(newPlans)
  }, [plans, filterSearchPhrase])

  const handleInputIconClick = () => changeFilterSearchPhrase('')

  useEffect(() => {
    setCurrentYear(moment().year())
    setCurrentWeek(moment().week())
    props.getProductionStations()
  }, [])

  useEffect(() => {
    currentWeek && currentYear && props.getWeeklyAndDailyPlansForWeek(currentYear, currentWeek)
  }, [currentWeek, currentYear])

  const hasNotChanged = useMemo(() => {
    return R.equals(dailyPlans, initialDailyPlans) && R.equals(state, initialState)
  }, [dailyPlans, initialDailyPlans, state, initialState])

  useEffect(() => {
    if (propsPlans[currentWeek]) {
      const currentPlans = propsPlans[currentWeek] || []
      setPlans(currentPlans)
      const dailyPlans = currentPlans.reduce((acc, { dailyPlans, bom, id }) => {
        acc.push(dailyPlans.map(dailyPlan => ({ ...dailyPlan, bom, planId: id })))
        return R.filter(item => !R.isEmpty(item), acc).flat()
      }, [])

      const dailyPlansByStationId = dailyPlans.reduce((acc, plan) => {
        const selector = `productionStation-${plan.assemblyPostId}-${plan.day}`
        acc[selector] = { ...acc[selector], [plan.day]: R.sortBy(R.prop('sortOrder'), [...(acc[selector]?.[plan.day] || []), plan]) }
        return acc
      }, {})

      setAvailableItems(currentPlans.map((plan) => {
        return plan.bom.itemRevision.id
      }))

      if (!R.equals(dailyPlansByStationId, initialDailyPlans) && hasNotChanged) {
        setDailyPlans(dailyPlansByStationId)
        setInitialDailyPlans(dailyPlansByStationId)
      }
    }
  }, [propsPlans, currentWeek])

  useInterval(() => {
    !isDragging && props.getWeeklyAndDailyPlansForWeek(currentYear, currentWeek)
  }, 60e3)

  useEffect(() => {
    const mappedItems = iterable(6).map((day, dayIndex) => {
      return props.productionStations.map((station) => {
        const stationSelector = `productionStation-${station.id}-${dayIndex + 1}`
        const bomIds = !R.isNil(dailyPlans[stationSelector])
          ? R.pipe(
            R.map(
              R.map(
                R.pipe(
                  R.path(['bom', 'itemRevision', 'id']),
                  id => id + '-' + stationSelector
                )
              )
            ),
            R.values,
            R.flatten
          )(dailyPlans[stationSelector])
          : []
        return {
          id: `productionStation-${station.id}-${dayIndex + 1}`,
          bomIds
        }
      })
    }).flat()

    if (props.productionStations.length) {
      const initState = {
        columns: {
          'bomsList': { id: 'bomsList', bomIds: [...availableItems] },
          ...transformById(mappedItems)
        },
        columnOrder: ['bomsList', ...mappedItems.map((element) => element.id)]
      }
      setState(initState)
      setInitialState(initState)
    }
  }, [availableItems, props.productionStations, dailyPlans])

  const onDragEnd = result => {
    setDragging(false)
    setDraggingFromStationBox(false)
    setDraggedItem('')

    const { destination, source, draggableId } = result

    const itemId = findIdInString(draggableId, 0)

    const start = state.columns[source.droppableId]
    const finish = state.columns[R.prop('droppableId', destination)]
    if (!finish) {
      return
    }
    const startBomIds = Array.from(start?.bomIds)
    const finishBomIds = Array.from(finish?.bomIds)
    const day = R.takeLast(1, R.prop('droppableId', destination))
    const isTheSamePlace = destination.index === source.index && R.prop('droppableId', destination) === source.droppableId
    const isMovingBetweenStationBoxes =
      source?.droppableId !== 'bomsList' &&
      destination?.droppableId !== 'bomsList'
    const isAlredyPlanned =
      destination?.droppableId !== 'bomsList' &&
      source?.droppableId !== destination?.droppableId &&
      finishBomIds.some(id => findIdInString(id, 0) === findIdInString(draggableId))
    const isOutdated = (day < moment().isoWeekday() && currentWeek === moment().week()) || currentWeek < moment().week()
    const movedPlan = plans.find(plan => plan.bom.itemRevision.id === draggableId)
    const destinationId = findIdInString(destination.droppableId)
    const canBeDropped = (
      R.path(['bom', 'assemblyPosts'], movedPlan) &&
      R.findIndex(post => post.id === destinationId, R.pathOr([], ['bom', 'assemblyPosts'], movedPlan)) >= 0
    ) || R.prop('droppableId', destination) === 'bomsList' || isMovingBetweenStationBoxes
    const removingPlan = R.prop('droppableId', destination) === 'bomsList' && source.droppableId !== 'bomsList'

    if (!destination || isTheSamePlace || isAlredyPlanned || isOutdated || !canBeDropped) {
      return
    }

    if (removingPlan) {
      const newBomsIds = Array.from(start.bomIds)
      newBomsIds.splice(source.index, 1)

      const day = R.takeLast(1, source.droppableId)
      const movedPlan = dailyPlans[source.droppableId]?.[`${day}`].find(plan => plan.bom.itemRevision.id === itemId)

      const newPlans = {
        ...dailyPlans,
        [source.droppableId]: {
          [`${day}`]: dailyPlans[source.droppableId][`${day}`].filter(plan => plan.planId !== movedPlan.planId)
        }
      }

      const newColumn = {
        ...start,
        bomIds: newBomsIds
      }

      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn
        }
      }

      setState(newState)
      setDailyPlans(newPlans)
    }

    if (start === finish) {
      const newBomsIds = Array.from(start.bomIds)
      newBomsIds.splice(source.index, 1)
      newBomsIds.splice(destination.index, 0, draggableId)
      const newColumn = {
        ...start,
        bomIds: newBomsIds
      }
      const newState = {
        ...state,
        columns: {
          ...state.columns,
          [newColumn.id]: newColumn
        }
      }
      const idsOrder = newBomsIds.map(id => findIdInString(id))

      const sortedDailyPlans = R.evolve({
        [newColumn.id]: {
          [day]: R.sortBy((item) => R.indexOf(R.path(['bom', 'itemRevision', 'id'], item), idsOrder))
        }
      }, dailyPlans)
      setDailyPlans(sortedDailyPlans)
      setState(newState)
      return
    }

    source.droppableId !== 'bomsList' && startBomIds.splice(source.index, 1)
    const newStart = {
      ...start,
      bomIds: startBomIds
    }

    finishBomIds.splice(destination.index, 0, source.droppableId === 'bomsList' ? `${draggableId}-${R.prop('droppableId', destination)}` : draggableId)
    const newFinish = {
      ...finish,
      bomIds: finishBomIds
    }
    const newState = {
      ...state,
      columns: {
        ...state.columns,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish
      }
    }

    if (source.droppableId === 'bomsList') {
      const getAssemblyTime = R.pathOr(0, ['bom', 'assemblyTime', 'seconds'])
      const getPlanTimeInSeconds = plan => R.propOr(0, 'quantity', plan) * getAssemblyTime(plan)
      const day = R.takeLast(1, R.prop('droppableId', destination))
      const dailyPlan = dailyPlans[R.prop('droppableId', destination)]?.[day] || []
      const stationId = findIdInString(R.prop('droppableId', destination), 0)

      const productionStationCapacity = props.productionStations.find(station => station.id === stationId).worktime * 60
      const currentlyPlannedSeconds = dailyPlan.reduce((acc, plan) => acc + getPlanTimeInSeconds(plan), 0)
      const productionStationAvilableTime = Math.max(1, productionStationCapacity - currentlyPlannedSeconds)
      const assemblyPost = R.find(R.propEq('id', stationId), props.productionStations)
      const movedPlan = R.find(R.pathEq(['bom', 'itemRevision', 'id'], draggableId), plans)
      const dailyPlansQuantity = R.pipe(
        R.values,
        R.map(R.values),
        R.flatten,
        R.filter(dailyPlan => dailyPlan?.planId === movedPlan?.id),
        R.map(R.prop('quantity')),
        R.reduce(R.add, 0)
      )(dailyPlans)
      const avilableMaxQuantity = movedPlan.quantity - dailyPlansQuantity
      const maxAvailableDayQuantity = Math.max(Math.floor(productionStationAvilableTime / R.pathOr(100, ['bom', 'assemblyTime', 'seconds'], movedPlan)), 1)

      const newDailyPlanBom = {
        assemblyPostId: stationId,
        assemblyPost,
        day,
        quantity: Math.min(avilableMaxQuantity, maxAvailableDayQuantity),
        manufactured: 0,
        acknowledged: 0,
        bom: movedPlan?.bom,
        planId: movedPlan?.id,
        bomWeeklyPlanId: movedPlan?.id,
        shouldOpenModal: true
      }
      const newPlan = {
        [day]: R.insert(destination.index, newDailyPlanBom, dailyPlan)
      }

      setDailyPlans({
        ...dailyPlans,
        [R.prop('droppableId', destination)]: newPlan
      })
      setState(newState)
    }

    if (isMovingBetweenStationBoxes) {
      const destinationStationId = findIdInString(R.propOr('', 'droppableId', destination))
      const movedPlanId = R.find(R.pathEq(['bom', 'itemRevision', 'id'], findIdInString(draggableId, 0)), plans)
      const sourceDay = R.takeLast(1, R.prop('droppableId', source))
      const destinationDay = R.takeLast(1, R.prop('droppableId', destination))
      const planForDay = dailyPlans[source.droppableId][sourceDay]
      const plannedItem = planForDay.find(plan => plan.bom.itemRevision.id === movedPlanId.bom.itemRevision.id)
      const plannedItemIndex = planForDay.findIndex(e => e === plannedItem)
      if (R.findIndex(post => post.id === destinationStationId, R.pathOr([], ['bom', 'assemblyPosts'], plannedItem)) >= 0) {
        const newPlans = R.evolve({
          [source.droppableId]: {
            [sourceDay]: plans => {
              return plans.filter((plan, index) => index !== plannedItemIndex)
            }
          }
        }, dailyPlans)
        const updatedPlan = R.evolve({
          assemblyPostId: R.always(destinationStationId),
          day: R.always(destinationDay),
          shouldOpenModal: R.always(false)
        }, plannedItem)
        const newPlansWithMovedPlan = R.assocPath(
          [destination.droppableId, destinationDay, destination.index],
          updatedPlan,
          newPlans
        )
        setState(newState)
        setDailyPlans(newPlansWithMovedPlan)
      }
    }
  }

  const onDragStart = result => {
    if (result.source.droppableId !== 'bomsList') setDraggingFromStationBox(true)
    setDraggedItem(result.draggableId)
    setDragging(true)
  }

  const nextWeek = () => {
    if (moment().year(currentYear).isoWeeksInYear() === currentWeek) {
      setCurrentWeek(1)
      setCurrentYear(currentYear + 1)
    } else {
      setCurrentWeek(currentWeek + 1)
    }
  }

  const previousWeek = () => {
    if (currentWeek === 1) {
      setCurrentWeek(moment().year(currentYear - 1).isoWeeksInYear())
      setCurrentYear(currentYear - 1)
    } else {
      setCurrentWeek(currentWeek - 1)
    }
  }

  const savePlans = async () => {
    const flattenPlans = transformToArray(dailyPlans).map(el => transformToArray(el)).flat(2)

    const data = flattenPlans.map(plan => {
      return {
        id: plan?.id || null,
        bomWeeklyPlanId: plan.bomWeeklyPlanId,
        assemblyPostId: plan.assemblyPostId,
        day: plan.day,
        quantity: plan.quantity
      }
    })
    try {
      setLoading(true)
      await props.storeDailyPlansForWeek(data, currentYear, currentWeek)
      const response = await props.getWeeklyAndDailyPlansForWeek(currentYear, currentWeek)
      const currentPlans = response || []
      setPlans(currentPlans)
      const dailyPlans = currentPlans.reduce((acc, { dailyPlans, bom, id }) => {
        acc.push(dailyPlans.map(dailyPlan => ({ ...dailyPlan, bom, planId: id })))
        return R.filter(item => !R.isEmpty(item), acc).flat()
      }, [])
      const dailyPlansByStationId = dailyPlans.reduce((acc, plan) => {
        const selector = `productionStation-${plan.assemblyPostId}-${plan.day}`
        acc[selector] = { ...acc[selector], [plan.day]: R.sortBy(R.prop('sortOrder'), [...(acc[selector]?.[plan.day] || []), plan]) }
        return acc
      }, {})
      setLoading(false)
      setDailyPlans(dailyPlansByStationId)
      setInitialDailyPlans(dailyPlansByStationId)
    } catch (error) {
      setLoading(false)
      errorHandler(error)
      try {
        await props.getWeeklyAndDailyPlansForWeek(currentYear, currentWeek)
      } catch (e) {
        errorHandler(e)
      }
      resetChanges()
    }
  }

  const editDailyPlan = useCallback(newPlan => {
    const planId = `productionStation-${newPlan.assemblyPostId}-${newPlan.day}`
    const planIndex = dailyPlans[planId][`${newPlan.day}`].findIndex(el => el.bom.itemRevision.id === newPlan.bom.itemRevision.id)
    const edittedPlans = {
      ...dailyPlans,
      [planId]: {
        ...dailyPlans[planId],
        [`${newPlan.day}`]: R.update(planIndex, newPlan, dailyPlans[planId][newPlan.day])
      }
    }

    setDailyPlans(edittedPlans)
  }, [setDailyPlans, dailyPlans])

  const resetChanges = () => {
    setDailyPlans(initialDailyPlans)
    setState(initialState)
  }

  useEffect(() => {
    if (!hasNotChanged) {
      window.onbeforeunload = () => true
    }
    return () => {
      window.onbeforeunload = null
    }
  }, [hasNotChanged])

  const [highlightedElement, selectHighlightedComponent] = useState('')

  const disableHighlight = () => selectHighlightedComponent('')

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Prompt
        when={!hasNotChanged}
        message={'Masz niezapisane zmiany. Wyjść mimo to? (zmiany zostaną skasowane)'}
      />
      <Global styles={tooltipStyle} />
      <Box p={10} pt={0} css={wrapperCss} onClick={disableHighlight}>
        <Box css={{
          position: 'sticky',
          top: 70,
          zIndex: 500,
          boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
          background: theme.colors.white,
          gridArea: 'header',
          '&:after': {
            content: "''",
            height: '100%',
            width: 10,
            background: 'white',
            position: 'absolute',
            right: -10,
            top: 0
          },
          '&:before': {
            content: "''",
            height: '100%',
            width: 10,
            background: 'white',
            position: 'absolute',
            left: -10,
            top: 0
          }
        }}
        >
          <Flex
            alignItems='center'
            p={6}
            pb={0}
            width={1}
          >
            <Flex>
              <Flex alignItems={'center'} css={{ width: 20 }}>
                <Arrow
                  css={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
                  fill={hasNotChanged ? theme.colors.darkGrey : theme.colors.grey}
                  onClick={hasNotChanged ? previousWeek : () => {
                    if (window.confirm('Masz niezapisane zmiany. Zmienić tydzień mimo to? (zmiany zostaną skasowane)')) {
                      resetChanges()
                      previousWeek()
                    }
                  }}
                />
              </Flex>
              <Flex justifyContent={'center'} flexDirection={'column'} alignItems={'center'} css={{ margin: '0 30px' }}>
                <h3>{`tydzień ${currentWeek}`}</h3>
              </Flex>
              <Flex alignItems={'center'} css={{ width: 20 }}>
                <Arrow
                  css={{ cursor: 'pointer' }}
                  fill={hasNotChanged ? theme.colors.darkGrey : theme.colors.grey}
                  onClick={hasNotChanged ? nextWeek : () => {
                    if (window.confirm('Masz niezapisane zmiany. Zmienić tydzień mimo to? (zmiany zostaną skasowane)')) {
                      resetChanges()
                      nextWeek()
                    }
                  }}
                />
              </Flex>
            </Flex>
            <Button
              outline
              onClick={resetChanges}
              color={theme.colors.red}
              disabled={hasNotChanged}
              css={{ padding: 8, width: 100, margin: 'unset', marginLeft: 'auto' }}
            >
              {t('weeklyPlansForeman.reset')}
            </Button>
            <Button
              color={theme.colors.navyBlue}
              onClick={savePlans}
              disabled={(hasNotChanged) || isLoading}
              css={{ padding: 10, width: 120, margin: '0 100px 0 30px', position: 'relative' }}
            >
              {isLoading &&
              <IconBox css={{ background: 'transparent', color: 'white', position: 'absolute', top: 5, left: 0 }}>
                <Loader />
              </IconBox>
              }
              {t('weeklyPlansForeman.save')}
            </Button>
            <EditStatus
              status={hasNotChanged ? 'accepted' : 'edited'}
              acceptedText={t('weeklyPlansForeman.status.accepted')}
              editedText={t('weeklyPlansForeman.status.edited')}
            />
          </Flex>
          <Flex width={1} css={{ textAlign: 'center', padding: 10, minWidth: 800, fontSize: 12 }}>
            <Box width={1 / 7}>pn</Box>
            <Box width={1 / 7}>wt</Box>
            <Box width={1 / 7}>śr</Box>
            <Box width={1 / 7}>czw</Box>
            <Box width={1 / 7}>pt</Box>
            <Box width={1 / 7}>sb</Box>
            <Box width={1 / 7}>stanowisko</Box>
          </Flex>
        </Box>
        <Box id={'availableBoms'} css={bomsCss}>
          <Droppable droppableId={'bomsList'}>
            {(provided) => {
              return <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                css={{
                  margin: '10px',
                  display: 'block',
                  minWidth: 250,
                  maxHeight: 'calc(100vh - 110px)',
                  overflowY: 'scroll',
                  position: 'sticky',
                  top: 80,
                  background: 'white',
                  boxShadow: isDragging ? `inset 0 0 10px ${theme.colors.successGreen}` : 'unset',
                  transition: 'all .3s ease-in-out'
                }}
              >
                <Box>
                  <input value={filterSearchPhrase} onChange={changeFilterSearchPhrase} css={searchBarCss} />
                  <div css={resetCss} onClick={handleInputIconClick}>
                    {filterSearchPhrase
                      ? <CancelIcon {...iconDimensions} />
                      : <SearchIcon {...iconDimensions} />
                    }
                  </div>
                </Box>
                {state.columns?.bomsList.bomIds.length
                  ? state.columns?.bomsList.bomIds.map((bomId, index) => {
                    const bom = filteredPlans.find(plan => plan.bom.itemRevision.id === bomId)
                    const dailyPlansQuantity = R.pipe(
                      R.values,
                      R.map(R.values),
                      R.flatten,
                      R.filter(dailyPlan => dailyPlan?.planId === bom?.id),
                      R.map(R.prop('quantity')),
                      R.reduce(R.add, 0)
                    )(dailyPlans)
                    return bom ? (
                      <BomItem
                        key={`listItemKey-${index}`}
                        index={index}
                        onDoubleClick={() => selectHighlightedComponent(bomId)}
                        highlightedElement={highlightedElement}
                        bomId={bomId}
                        bom={bom}
                        dailyPlansQuantity={dailyPlansQuantity}
                        list={'bomsList'}
                      />
                    )
                      : null
                  })
                  : <div css={{ textAlign: 'center' }}>Uzupełnij plan tygodniowy</div>
                }
                {provided.placeholder}
              </div>
            }}
          </Droppable>
        </Box>
        <Flex pt={3} id={'productionStations'} flexWrap={'wrap'} justifyContent={'center'} css={{ gridArea: 'stations' }}>
          <StationBox
            productionStations={props.productionStations}
            highlightedElement={highlightedElement}
            setServiceRightOnStation={props.setServiceRightOnStation}
            currentWeek={currentWeek}
            state={state}
            draggedItem={draggedItem}
            plans={plans}
            isDragging={isDragging}
            isDraggedFromStationBox={isDraggedFromStationBox}
            dailyPlans={dailyPlans}
            editDailyPlan={editDailyPlan}
          />
        </Flex>
      </Box>
      <div id='confirmPrompt' />
    </DragDropContext>
  )
}

export default ProductionStations
