import SerialNumberService from 'services/SerialNumberService'
import { transformById } from 'helpers/redux'
import * as R from 'ramda'
import { errorHandler } from 'helpers/errorHandler'

const GET_SERIAL_NUMBER_FORMATS = Symbol('GET_SERIAL_NUMBER_FORMATS')
const GET_SERIAL_NUMBER_FORMATS_SUCCESS = Symbol('GET_SERIAL_NUMBER_FORMATS_SUCCESS')
const GET_SERIAL_NUMBER_FORMATS_FAILED = Symbol('GET_SERIAL_NUMBER_FORMATS_FAILED')

const getSerialNumberFormatsRequest = () => ({ type: GET_SERIAL_NUMBER_FORMATS })
const getSerialNumberFormatsSuccess = (data) => ({ type: GET_SERIAL_NUMBER_FORMATS_SUCCESS, data })
const getSerialNumberFormatsFailed = () => ({ type: GET_SERIAL_NUMBER_FORMATS_FAILED })

export const getSerialNumberFormats = () => {
  return async dispatch => {
    dispatch(getSerialNumberFormatsRequest())
    try {
      const { data } = await SerialNumberService.getSerialNumberFormats()
      dispatch(getSerialNumberFormatsSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      errorHandler(e)
      dispatch(getSerialNumberFormatsFailed(e))
      return Promise.reject(e)
    }
  }
}

export const getSerialNumberFormatsIfNeeded = () => {
  return async (dispatch, getState) => {
    const serialNumberFormats = getState().serialNumberFormats.entries
    const isLoading = getState().serialNumberFormats.isLoading
    if (R.isEmpty(serialNumberFormats) && !isLoading) {
      return dispatch(getSerialNumberFormats())
    }
  }
}

const CREATE_SERIAL_NUMBER_FORMAT = Symbol('CREATE_SERIAL_NUMBER_FORMAT')
const CREATE_SERIAL_NUMBER_FORMAT_SUCCESS = Symbol('CREATE_SERIAL_NUMBER_FORMAT_SUCCESS')
const CREATE_SERIAL_NUMBER_FORMAT_FAILED = Symbol('CREATE_SERIAL_NUMBER_FORMAT_FAILED')

const createSerialNumberFormatRequest = () => ({ type: CREATE_SERIAL_NUMBER_FORMAT })
const createSerialNumberFormatSuccess = (data) => ({ type: CREATE_SERIAL_NUMBER_FORMAT_SUCCESS, data })
const createSerialNumberFormatFailed = () => ({ type: CREATE_SERIAL_NUMBER_FORMAT_FAILED })

export const createSerialNumberFormat = (payload) => {
  return async dispatch => {
    dispatch(createSerialNumberFormatRequest())
    try {
      const { data } = await SerialNumberService.createSerialNumberFormat(payload)
      dispatch(createSerialNumberFormatSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      errorHandler(e)
      dispatch(createSerialNumberFormatFailed(e))
      return Promise.reject(e)
    }
  }
}

const UPDATE_SERIAL_NUMBER_FORMAT = Symbol('UPDATE_SERIAL_NUMBER_FORMAT')
const UPDATE_SERIAL_NUMBER_FORMAT_SUCCESS = Symbol('UPDATE_SERIAL_NUMBER_FORMAT_SUCCESS')
const UPDATE_SERIAL_NUMBER_FORMAT_FAILED = Symbol('UPDATE_SERIAL_NUMBER_FORMAT_FAILED')

const updateSerialNumberFormatRequest = () => ({ type: UPDATE_SERIAL_NUMBER_FORMAT })
const updateSerialNumberFormatSuccess = (data) => ({ type: UPDATE_SERIAL_NUMBER_FORMAT_SUCCESS, data })
const updateSerialNumberFormatFailed = () => ({ type: UPDATE_SERIAL_NUMBER_FORMAT_FAILED })

export const updateSerialNumberFormat = (payload) => {
  return async dispatch => {
    dispatch(updateSerialNumberFormatRequest())
    try {
      const { data } = await SerialNumberService.updateSerialNumberFormat(payload)
      dispatch(updateSerialNumberFormatSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      errorHandler(e)
      dispatch(updateSerialNumberFormatFailed(e))
      return Promise.reject(e)
    }
  }
}

const DELETE_SERIAL_NUMBER_FORMAT = Symbol('DELETE_SERIAL_NUMBER_FORMAT')
const DELETE_SERIAL_NUMBER_FORMAT_SUCCESS = Symbol('DELETE_SERIAL_NUMBER_FORMAT_SUCCESS')
const DELETE_SERIAL_NUMBER_FORMAT_FAILED = Symbol('DELETE_SERIAL_NUMBER_FORMAT_FAILED')

const deleteSerialNumberFormatRequest = () => ({ type: DELETE_SERIAL_NUMBER_FORMAT })
const deleteSerialNumberFormatSuccess = (serialNumberFormatId) => ({ type: DELETE_SERIAL_NUMBER_FORMAT_SUCCESS, serialNumberFormatId })
const deleteSerialNumberFormatFailed = () => ({ type: DELETE_SERIAL_NUMBER_FORMAT_FAILED })

export const deleteSerialNumberFormat = (serialNumberFormatId) => {
  return async dispatch => {
    dispatch(deleteSerialNumberFormatRequest())
    try {
      await SerialNumberService.deleteSerialNumberFormat(serialNumberFormatId)
      dispatch(deleteSerialNumberFormatSuccess(serialNumberFormatId))
      return Promise.resolve()
    } catch (e) {
      errorHandler(e)
      dispatch(deleteSerialNumberFormatFailed(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  entries: {},
  isLoading: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_SERIAL_NUMBER_FORMATS:
      return {
        ...state,
        isLoading: true
      }
    case GET_SERIAL_NUMBER_FORMATS_FAILED:
      return {
        ...state,
        isLoading: false
      }
    case GET_SERIAL_NUMBER_FORMATS_SUCCESS:
      return {
        ...state,
        entries: transformById(action.data),
        isLoading: false
      }
    case UPDATE_SERIAL_NUMBER_FORMAT_SUCCESS:
    case CREATE_SERIAL_NUMBER_FORMAT_SUCCESS:
      return R.evolve({
        entries: R.assoc(action.data.id, action.data)
      }, state)
    case DELETE_SERIAL_NUMBER_FORMAT_SUCCESS:
      return R.evolve({
        entries: R.dissoc(action.serialNumberFormatId)
      }, state)
    default:
      return state
  }
}
