import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip as ReactTippy } from 'react-tippy'

import 'react-tippy/dist/tippy.css'

const Tooltip = ({ size, trigger, children, content, withLink, linkHref, linkName, position, followCursor, className,
  arrow, open, hideOnClick, onRequestClose, interactive, disabled, zIndex = 5, style, unmountHTMLWhenHide, noFlip, delay }) => {
  const tooltipContent = (
    <div>
      <div>{content}</div>
      { withLink && <a href={linkHref}>{linkName}</a> }
    </div>
  )

  const shouldFlip = noFlip
    ? { preventOverflow: { enabled: false }, flip: { enabled: false }, hide: { enabled: false } }
    : {}

  return (
    <ReactTippy
      className={className}
      style={style}
      open={trigger === 'manual' ? open : undefined}
      arrow={arrow}
      disabled={disabled}
      html={tooltipContent}
      interactive={interactive || Boolean(onRequestClose)}
      followCursor={followCursor}
      unmountHTMLWhenHide={unmountHTMLWhenHide}
      position={position}
      size={size}
      trigger={trigger}
      onRequestClose={onRequestClose}
      hideOnClick={hideOnClick}
      delay={delay}
      // eslint-disable-next-line react/jsx-boolean-value
      useContext={true}
      theme='light'
      popperOptions={{
        modifiers: {
          addZIndex: {
            enabled: true,
            order: 810,
            fn: data => ({
              ...data,
              styles: {
                ...data.styles,
                zIndex
              }
            })
          },
          ...shouldFlip
        }
      }}
    >
      {children}
    </ReactTippy>
  )
}

Tooltip.defaultProps = {
  position: 'bottom',
  followCursor: false,
  arrow: true,
  hideOnClick: true,
  delay: 0,
  noFlip: false
}

Tooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.any,
  followCursor: PropTypes.bool,
  linkHref: PropTypes.string,
  linkName: PropTypes.string,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  className: PropTypes.string,
  trigger: PropTypes.oneOf(['mouseenter', 'focus', 'click', 'manual']),
  withLink: PropTypes.bool,
  arrow: PropTypes.bool,
  noFlip: PropTypes.bool,
  hideOnClick: PropTypes.any,
  onRequestClose: PropTypes.func,
  zIndex: PropTypes.number
}

export default Tooltip
