import store from 'store'

export const LocalStorageKeys = {
  token: 'enbio-app-token',
  user: 'enbio-app-user-data',
  stationId: 'enbio-app-station-id',
  loginTime: 'enbio-app-login-time',
  assemblyTime: 'enbio-app-assembly-time',
  serviceTime: 'enbio-app-service-time',
  lastKnownDeviceStatus: 'enbio-app--production-station-last-known-device-status',
  lastProcessName: 'enbio-app--production-station-last-process-name',
  currentlyProcessedUnit: 'enbio-app--production-station-currently-processed-unit',
  currentReport: 'enbio-app--production-station-current-report',
  idsForCurrentItem: 'enbio-app--production-station-ids-for-current-item',
  selectedIndex: 'enbio-app--production-station-selected-index',
  actions: 'enbio-app--production-station-production-station-actions',
  actionIndex: 'enbio-app--production-station-production-station-action-index',
  lastTestRun: 'enbio-app--production-station-production-station-last-test-run',
  isBlueSectionActive: 'enbio-app--catalogue-blue-section-active',
  isOrangeSectionActive: 'enbio-app--catalogue-orange-section-active',
  isGraySectionActive: 'enbio-app--catalogue-gray-section-active'
}

const storageInterface = store

const StorageService = {
  get: (key, defaultValue = null) => storageInterface.get(key, defaultValue),
  set: (key, value) => storageInterface.set(key, value),
  remove: (key) => storageInterface.remove(key),
  getAll: () => {
    let temp = {}
    storageInterface.each((value, key) => {
      temp[key] = value
    })
    return temp
  },
  clearAll: () => storageInterface.clearAll()
}

export default StorageService
