import React, { useEffect } from 'react'
import QrCode from 'qrcode.react'

const Codes = () => {
  useEffect(() => {
    window.documentReadyForReact = true
  }, [])
  return (
    <div>
      {[
        'EA0155',
        '1-8-13026A36',
        'AR1006',
        '1-8-25094B4',
        '1-8-51188A1',
        'ST01',
        '1-8-42988A1',
        '1-8-43768D2',
        '1-8-51668A4',
        '1-8-25159B1',
        '1-8-52050A2',
        'OB1004',
        '1-8-13123A31',
        'OB1001',
        'WC1001',
        'KO1005',
        '1-8-51915A1',
        '1-8-14309D5',
        '1-8-12929A24',
        '1-8-37844F3',
        '1-8-14713B1',
        '1-8-37858F2',
        '1-8-37846F5',
        '1-8-25361A4',
        '1-8-14797A9',
        '1-8-51288A1',
        '1-8-14874A13',
        'OB1006',
        '1-8-51673A2',
        '1-8-51698A4',
        '1-8-14869A12',
        '1-8-47023A3',
        '1-8-51676A3',
        '1-8-13162A19',
        '1-8-14686B4',
        '1-8-37844E6',
        '1-8-25080A12',
        '1-8-51123A2',
        'US0001',
        '1-8-37857F2',
        '1-8-44835A2',
        '1-8-30104A2',
        '1-8-30108A2',
        '1-8-51672A4',
        'OB1002',
        'AR1001',
        '1-8-43768C2',
        '1-8-25400D13',
        '1-8-37866E2',
        'OB1003',
        '1-8-14763B5',
        '1-8-42992A1',
        '1-8-28762A11',
        '1-8-51084A1',
        '1-8-14584B1',
        '1-8-14591A14',
        '1-8-14735B1',
        '1-8-14877A10',
        '1-8-37858E14',
        'MP0217',
        '1-8-14750B1',
        '1-8-28761A19',
        'WC1003',
        '1-8-14800B1',
        '1-8-27920B2',
        '1-8-37846G3',
        'MP0211',
        '1-8-51134A2',
        'AR0352',
        'MP0219',
        'KO1006',
        'KO1010',
        '1-8-26367A6',
        'EA1001',
        '1-8-14761B1',
        '1-8-42730A15',
        '1-8-51194A1',
        '1-8-13123A29',
        '1-8-14875A17'
      ].map(
        code => <div
          style={{ marginBottom: '300px', display: 'flex', padding: '25px', borderBottom: '.5px solid grey' }}>
          {code}
          <QrCode size={200} style={{ marginLeft: 'auto' }} value={code} />
        </div>
      )

      }
    </div>
  )
}

export default Codes
