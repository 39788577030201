/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useState } from 'react'
import Tooltip from 'components/Tooltip'
import { connect } from 'react-redux'
import Button from 'components/Button'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import { cellCss } from 'views/Catalogue/styles'
import { updateBomFields } from 'ducks/bom'
import theme from 'config/theme'
import { extractMinutesFromTime, getValueInSeconds } from 'helpers/assemblyTime'
import useInput from 'hooks/useInput'
import * as R from 'ramda'

const buttonCss = css`
  max-width: 150px;
  margin-top: 20px;
`

const inputCss = (hasError) => ({
  textAlignLast: 'center',
  textAlign: 'center',
  border: `${hasError ? 4 : 2}px solid ${hasError ? theme.colors.error : 'rgb(238, 238, 238)'}`,
  backgroundColor: theme.colors.white,
  WebkitAppearance: 'none',
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px'
})

const EditAssemblyTime = ({ children, ...props }) => {
  const { t } = useTranslation()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [assemblyTime, changeAssemblyTime] = useInput(extractMinutesFromTime(props.item.assemblyTime || ''))
  const [error, setError] = useState(false)

  const closeTooltip = () => {
    setTooltipOpen(false)
  }

  const save = () => {
    const matchAssemblyTime = R.match(/(^[0-9]{1,2}:[0-9]{2}$|^[0-9]+[,.][0-9]{1,2}$|^[0-9]+$)/g, assemblyTime)

    if (!matchAssemblyTime.length) {
      return setError(true)
    }

    confirmAlert({
      title: <div css={{ marginBottom: 20 }}>{t('catalogue.confirmationDialogue.confirmHeader')}</div>,
      message: <div>
        {t('catalogue.confirmationDialogue.from')}
        <br /><br />
        <b>{children || 'n/a'}</b>
        <br /><br />
        {t('catalogue.confirmationDialogue.to')}
        <br /><br />
        <b>{assemblyTime}</b>
      </div>,
      buttons: [
        {
          label: t('catalogue.confirmationDialogue.yes'),
          onClick: async () => {
            const assemblyTimeValue = getValueInSeconds(assemblyTime)
            await props.updateBomFields({ bomId: props.item.bomId, assemblyTime: assemblyTimeValue }, props.item.id)
            closeTooltip()
          }
        },
        {
          label: t('catalogue.confirmationDialogue.no'),
          onClick: closeTooltip
        }
      ]
    })
  }

  const editingContent = <Fragment>
    <div css={{ marginBottom: 15 }}>
      {t('catalogue.editAssemblyTime.label')}
    </div>
    <input
      value={assemblyTime}
      onChange={changeAssemblyTime}
      onFocus={() => setError(false)}
      name='assemblyTime'
      type='text'
      css={inputCss(error)}
    />
    <Button disabled={error} css={buttonCss} onClick={save}>{t('catalogue.confirmationDialogue.save')}</Button>
  </Fragment>

  return (
    <Tooltip
      style={{ zIndex: 5 }}
      content={editingContent}
      arrow
      hideOnClick={false}
      open={tooltipOpen}
      trigger='manual'
      onRequestClose={closeTooltip}
      css={cellCss}
    >
      <div
        css={css`
          cursor: pointer;
          width: 100%;
          height: 100%;
        `}
        className={props.className}
        onClick={() => setTooltipOpen(!tooltipOpen)}
      >
        {children}
      </div>
    </Tooltip>
  )
}

const mapDispatchToProps = dispatch => ({
  updateBomFields: R.compose(dispatch, updateBomFields)
})

export default connect(null, mapDispatchToProps)(EditAssemblyTime)
