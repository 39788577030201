import APIInterceptor from './APIInterceptor'
import StorageService, { LocalStorageKeys } from './StorageService'
import qs from 'query-string'

const ProductionStationsService = {
  checkPartsReceived: bomDailyPlanId => APIInterceptor.get(`/assembly/check_parts_received/${bomDailyPlanId}`),
  loginToProductionStation: (stationId) => APIInterceptor.post('/assembly_posts/login', { assembly_post_id: stationId }),
  getProductionStations: (query) => APIInterceptor.get(`/assembly_posts?${qs.stringify(query)}`),
  getDailyPlans: date => APIInterceptor.get(`/daily_plans/day/${date}`),
  getWeeklyAndDailyPlansForWeek: (year, week) => APIInterceptor.get(`/daily_plans/week/${year}/${week}`),
  storeDailyPlansForWeek: (data, year, week) => APIInterceptor.post('/daily_plans', { data, year, week }),
  getCurrentStationPlans: () => {
    const currentStationId = StorageService.get(LocalStorageKeys.stationId)
    if (currentStationId) {
      return APIInterceptor.get(`/daily_plans/assembly_post/${currentStationId}`)
    } else {
      return Promise.reject(new Error('Could not find station'))
    }
  },
  markItemAsAssembled: (data) => APIInterceptor.post('/assembly', data),
  getZplQrCode: (uuids) => APIInterceptor.post(`/qr/zpl`, { uuids }, { headers: { Authorization: null } }),
  checkForSerialNumberValidity: ({ itemCode, serialNumber }) => APIInterceptor.get(`/assembly/check_bom_part/${itemCode}/${serialNumber}`),
  sendReport: ({ type = 'first_run', results }) => APIInterceptor.post(`/qa/assembly/tests`, { type, results }),
  setServiceRightOnStation: ({ id, value }) => APIInterceptor.patch(`assembly_posts/${id}/service`, { isServiceAllowed: value })
}

export default ProductionStationsService
