import PusherNotifications from 'components/PusherNotifications'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getUnreadNotifications, markNotificationAsRead } from 'ducks/notifications'
import { getUserId } from 'selectors/user'

const mapStateToProps = state => ({
  userId: getUserId(state)
})

const mapDispatchToProps = dispatch => ({
  getUnreadNotifications: () => dispatch(getUnreadNotifications()),
  markNotificationAsRead: compose(dispatch, markNotificationAsRead)
})

export default connect(mapStateToProps, mapDispatchToProps)(PusherNotifications)
