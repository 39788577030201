import LocationsService from 'services/LocataionsService'
import { transformById } from 'helpers/redux'
import { errorHandler } from 'helpers/errorHandler'

const GET_LOCATIONS_LIST_REQUEST = Symbol('GET_LOCATIONS_LIST_REQUEST')
const GET_LOCATIONS_LIST_SUCCESS = Symbol('GET_LOCATIONS_LIST_SUCCESS')
const GET_LOCATIONS_LIST_FAILED = Symbol('GET_LOCATIONS_LIST_FAILED')

const getLocationsListRequest = () => ({ type: GET_LOCATIONS_LIST_REQUEST })
const getLocationsListSuccess = data => ({ type: GET_LOCATIONS_LIST_SUCCESS, data })
const getLocationsListFailed = () => ({ type: GET_LOCATIONS_LIST_FAILED })

export const getLocationsList = () => {
  return async dispatch => {
    dispatch(getLocationsListRequest())
    try {
      const { data } = await LocationsService.getLocationsList()
      dispatch(getLocationsListSuccess(data))
    } catch (err) {
      dispatch(getLocationsListFailed(err))
      console.error(err)
    }
  }
}

const CREATE_LOCATION_REQUEST = Symbol('CREATE_LOCATION_REQUEST')
const CREATE_LOCATION_SUCCESS = Symbol('CREATE_LOCATION_SUCCESS')
const CREATE_LOCATION_FAILED = Symbol('CREATE_LOCATION_FAILED')

const createLocationRequest = () => ({ type: CREATE_LOCATION_REQUEST })
const createLocationSuccess = data => ({ type: CREATE_LOCATION_SUCCESS, data })
const createLocationFailed = () => ({ type: CREATE_LOCATION_FAILED })

export const createLocation = (payload) => async dispatch => {
  dispatch(createLocationRequest())
  try {
    const { data } = await LocationsService.createLocation(payload)
    dispatch(createLocationSuccess(data))
    return Promise.resolve(data)
  } catch (err) {
    console.error(err)
    errorHandler(err)
    dispatch(createLocationFailed(err))
    return Promise.reject(err)
  }
}

const DELETE_LOCATION_REQUEST = Symbol('DELETE_LOCATION_REQUEST')
const DELETE_LOCATION_SUCCESS = Symbol('DELETE_LOCATION_SUCCESS')
const DELETE_LOCATION_FAILED = Symbol('DELETE_LOCATION_FAILED')

const deleteLocationRequest = () => ({ type: DELETE_LOCATION_REQUEST })
const deleteLocationSuccess = data => ({ type: DELETE_LOCATION_SUCCESS, data })
const deleteLocationFailed = () => ({ type: DELETE_LOCATION_FAILED })

export const deleteLocation = (payload) => async dispatch => {
  dispatch(deleteLocationRequest())
  try {
    const { data } = await LocationsService.deleteLocation(payload)
    await dispatch(deleteLocationSuccess(data))
    dispatch(getLocationsList())
    return Promise.resolve(data)
  } catch (err) {
    console.error(err)
    errorHandler(err)
    dispatch(deleteLocationFailed(err))
    return Promise.reject(err)
  }
}

const initState = {
  entries: [],
  isLoading: false
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_LOCATIONS_LIST_REQUEST:
      return {
        ...state,
        isLoading: true
      }
    case GET_LOCATIONS_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        entries: transformById(action.data)
      }
    case GET_LOCATIONS_LIST_FAILED:
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
