/** @jsx jsx */
import { jsx } from '@emotion/core'
import ReactSelect from 'react-select'
import { dissoc } from 'ramda'
import theme from 'config/theme'
import { errorCss } from 'components/Input'

const disableBoxShadow = {
  boxShadow: 'none',
  '&:focus': {
    boxShadow: 'none'
  }
}

export const makeControlStyles = provided => ({
  ...dissoc('&:hover', provided),
  border: '2px solid black',
  borderRadius: 0,
  boxShadow: 'none',
  '&:focus': {
    border: '2px solid black'
  },
  ...disableBoxShadow
})

export const makeSeparatorStyles = () => ({ display: 'none' })

export const makeDropdownStyles = (provided, state) => ({
  ...provided,
  display: state.hasValue ? 'none' : 'block',
  color: theme.colors.black
})

export const makeClearIndicatorStyles = provided => ({
  ...provided,
  color: theme.colors.black
})

export const makeMenuStyles = (provided, state) => ({
  ...provided,
  borderRadius: 0,
  maxHeight: 280,
  ...disableBoxShadow
})

export const makeOptionStyles = (provided, state) => ({
  ...provided,
  backgroundColor: state.isSelected ? theme.colors.darkGrey : theme.colors.white,
  padding: 12,
  ':active': {
    backgroundColor: theme.colors.grey,
    color: theme.colors.white
  }
})

const Select = (props) => {
  const { meta: { touched, error }, className } = props

  return (
    <div className={className}>
      {touched && error && <div css={errorCss}>{error}</div>}
      <ReactSelect
        isSearchable={false}
        {...dissoc('onBlur', props.input)}
        {...props}
        onChange={props.input.onChange}
        placeholder={props.placeholder || ''}
        isClearable
        styles={{
          control: makeControlStyles,
          indicatorSeparator: makeSeparatorStyles,
          dropdownIndicator: makeDropdownStyles,
          clearIndicator: makeClearIndicatorStyles,
          option: makeOptionStyles
        }}
      />
    </div>
  )
}

export default Select
