import ApiInterceptor from './APIInterceptor'

const InternalOrdersService = {
  createInternalOrder: (data) => ApiInterceptor.post('/internal_orders', data),
  getPersonalOrders: () => ApiInterceptor.get('/internal_orders/mine'),
  getAllInternallyOrderedItems: () => ApiInterceptor.get('internal_orders/items'),
  prepareInternalOrderItem: ({ id, ...rest }) => {
    return ApiInterceptor.patch(`internal_orders/items/${id}/prepare2`, rest)
  },
  confirmInternalOrderDelivery: ({ internalOrderItemId, quantity }) => {
    return ApiInterceptor.patch(`internal_orders/items/${internalOrderItemId}/pickup`, { quantity })
  },
  deleteOrderedItem: (internalOrderItemId) => {
    return ApiInterceptor.delete(`/internal_orders/items/${internalOrderItemId}`)
  }
}

export default InternalOrdersService
