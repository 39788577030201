/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment } from 'react'
import theme from 'config/theme'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'
import useOpenState from 'hooks/useOpenState'
import Modal from 'components/Modal'
import EditSerialNumberFormatFormConnector from 'connectors/forms/EditSerialNumberFormatFormConnector'

const actionsCss = css`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  & > button:not(:first-child) {
    margin-left: ${theme.space[5]}px;
  }
`

const buttonCss = css`
  padding: ${theme.space[5]}px;
  width: unset;
`

const SerialNumberFormatRow = ({ serialNumberFormat, deleteSerialNumberFormat, updateSerialNumberFormat }) => {
  const { t } = useTranslation()
  const [editModalOpened, openEditModal, closeEditModal] = useOpenState()
  const handleUpate = async (values) => {
    await updateSerialNumberFormat({ ...values, serialNumberFormatId: serialNumberFormat.id })
  }

  return (
    <Fragment>
      {editModalOpened && (
        <Modal
          headerTitle={t('serialNumberFormats.addNewFormat')}
          onClose={closeEditModal}
        >
          <EditSerialNumberFormatFormConnector
            onSubmit={handleUpate}
            initialValues={{ name: serialNumberFormat.name, format: serialNumberFormat.format }}
            close={closeEditModal}
          />
        </Modal>
      )}
      <div>{serialNumberFormat.name}</div>
      <div><code>{serialNumberFormat.format}</code></div>
      <div>{serialNumberFormat.itemRevisions?.map((itemRevision) => (
        <div key={itemRevision.id + serialNumberFormat.id}>
          {itemRevision.fullIndex} {itemRevision.item.name}
        </div>
      ))}</div>
      <div css={actionsCss}>
        <Button css={buttonCss} color={theme.colors.lightBlue} onClick={openEditModal}>{t('serialNumberFormats.edit')}</Button>
        {!serialNumberFormat.itemRevisions?.length && (
          <Button css={buttonCss} onClick={deleteSerialNumberFormat} color={theme.colors.error}>{t('serialNumberFormats.delete')}</Button>
        )}
      </div>
    </Fragment>
  )
}

export default SerialNumberFormatRow
