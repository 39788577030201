import OnBoardItemOnWarehouse from 'views/OnBoardItemOnWarehouse'
import { connect } from 'react-redux'
import { moveItemInInventory } from 'ducks/inboundZone'
import { acknowledgeItemSubmission, listAllPendingSubmissions } from 'ducks/itemsSubmissions'
import { getSubmissionsAsArray } from 'selectors/internalSubmissions'

const mapStateToProps = (state, ownProps) => ({
  currentItem: ownProps.location.state.item || state.inboundZone.currentItem,
  pendingSubmissions: getSubmissionsAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  moveItemInInventory: ({ inventoryId, quantity, destinationCode, itemId }) =>
    dispatch(moveItemInInventory({ inventoryId, quantity, destinationCode, itemId })),
  acknowledgeItemSubmission: ({ itemCode, quantity, destinationCode }) =>
    dispatch(acknowledgeItemSubmission({ itemCode, quantity, destinationCode })),
  listAllPendingSubmissions: () => dispatch(listAllPendingSubmissions())
})

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardItemOnWarehouse)
