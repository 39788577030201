import APIInterceptor from './APIInterceptor'

const ProductionServiceService = {
  getDeviceForService: (serialNumber) => APIInterceptor.get(`/service/${serialNumber}`),
  startDeviceService: (serialNumber) => APIInterceptor.post(`/service/${serialNumber}/start`),
  finishDeviceService: (serialNumber, payload) => APIInterceptor.post(`/service/${serialNumber}/finish`, payload),
  serviceDevice: (assemblyId, payload) => APIInterceptor.post(`/service/${assemblyId}/component`, payload)
}

export default ProductionServiceService
