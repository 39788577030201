import { find, pathEq } from 'ramda'
import { itemsOnTopOfTheList } from 'constants/weeklyPlans'
import md5 from 'md5'
import bomImportsActionTypes from 'constants/bomImportsActionTypes'
import { randexp } from 'randexp'

export const getItemFromTheList = (arg, path = []) => find(
  pathEq(path, arg)
)

export const getItemsOnTopOfTheList = (targetArray) => {
  let fixedArray = [...targetArray]
  let firstItemsIndexes = []
  const shifter = ind => {
    ind >= 0 && fixedArray.unshift(fixedArray.splice(ind, 1)[0])
  }
  fixedArray.length && itemsOnTopOfTheList.forEach(code => firstItemsIndexes.push(fixedArray.indexOf(fixedArray.find(e => e['code'] === code))))
  firstItemsIndexes.sort((a, b) => a > b ? 1 : -1).forEach(shifter)
  return fixedArray
}

export const generateNewIndex = () => randexp(/1-0-\d{5}A1/)

export const generateNewItem = (index, name, quantity) => {
  const newId = md5(index + name)
  return ({
    title: {
      index,
      name,
      defaultVendor: null,
      quantity
    },
    id: newId,
    expanded: false,
    originalParentId: null,
    children: [],
    original: {
      name,
      quantity,
      index,
      id: newId,
      vendorName: null,
      originalParentId: null,
      defaultAction: null,
      processingDetails: {
        action: {
          type: bomImportsActionTypes.CREATE,
          id: newId,
          name,
          index
        },
        status: 'keep',
        sortOrder: 1,
        stage: 1,
        quantity: 1,
        isNew: true,
        isFinalAssemblyMmi: false
      }
    }
  })
}
