/** @jsx jsx */
import { jsx } from '@emotion/core'

import Modal from 'components/Modal'

const EmergencySteeringModal = ({ children, onClose }) => (
  <Modal headerTitle={`Panel do testów`} onClose={onClose} closeOnOutsideClick zIndex={85}>
    <div>
      <div style={{ marginTop: 20 }}>
        {children}
      </div>
    </div>
  </Modal>
)

export default EmergencySteeringModal
