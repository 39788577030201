/** @jsx jsx */
import { jsx } from '@emotion/core'
import { orderCss } from 'components/OrderDetails'
import theme from 'config/theme'
import { ReactComponent as Cancel } from 'assets/cancel.svg'
import IconBox, { iconCss } from 'components/IconBox'
import Container from 'components/Container'
import { useTranslation } from 'react-i18next'

const OutsideOrder = (props) => {
  const { t } = useTranslation()
  const onClick = () => props.setOutsideOrder(false)
  return (
    <div onClick={onClick} css={{ ...orderCss, color: theme.colors.black }}>
      <Container>
        <h3>
          {t('supply.outsideOrderExpanded')}
        </h3>
      </Container>
      <Container css={{ marginLeft: 'auto' }}>
        <IconBox size={40} color={theme.colors.white} border={theme.colors.black}>
          <Cancel fill={theme.colors.black} css={iconCss} />
        </IconBox>
      </Container>
    </div>
  )
}

export default OutsideOrder
