/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Box, Flex } from '@rebass/emotion'
import theme from 'config/theme'
import Button from 'components/Button'
import useInput from 'hooks/useInput'
import { useEffect, useRef } from 'react'
import useKeyHandler from 'hooks/useKeyHandler'
import getSerialNumberFromUrl from 'helpers/getSerialNumberFromUrl'
import Routes from 'constants/Routes'
import SteamJetGenerator from 'components/SteamJetGenerator'

const ServiceGetDeviceScreen = ({ isServiceAllowed, history, getProductionStations }) => {
  const [value, onChange] = useInput()
  const inputRef = useRef(null)

  useEffect(() => {
    getProductionStations()
  }, [])

  const scanHandler = (scannedCode) => {
    const serialNumberForSteamJet = getSerialNumberFromUrl(scannedCode)
    onChange('')
    history.push(Routes.productionService.replace(':serialNumber', serialNumberForSteamJet || scannedCode))
  }

  const handleClick = () => scanHandler(value)

  useKeyHandler(scanHandler, inputRef)

  if (!isServiceAllowed) {
    return <Flex justifyContent={'center'} mt={10}><h2>To stanowisko produkcyjne nie jest uprawnione do korzystania z serwisu</h2></Flex>
  }
  return (
    <Box p={8}>
      {process.env.NODE_ENV === 'development' && <SteamJetGenerator code={'1-8-43435C3'} />}
      <div css={inputWrapperCss}>
        <h3>Zeskanuj lub wpisz numer seryjny:</h3>
        <input
          ref={inputRef}
          css={{ padding: '10px 5px', border: `2px solid ${theme.colors.darkGrey}`, borderRadius: '7.5px' }}
          value={value}
          onChange={onChange}
          type='text'
          name='handOverQuantityInput'
        />
        <Button css={actionButtonCss} onClick={handleClick}>Szukaj</Button>
      </div>
    </Box>
  )
}

const inputWrapperCss = css`
  display: flex;
  padding: 20px;
  align-items: center;

  & > * {
    margin: 5px;
  }
`

const actionButtonCss = css`
  width: unset;
  margin: unset;
`

export default ServiceGetDeviceScreen
