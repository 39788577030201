/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@rebass/emotion'
import { useEffect } from 'react'
import stocktakingStatuses from 'constants/stocktakingStatuses'
import Routes from 'constants/Routes'
import LabelledDivider from 'components/LabelledDivider'
import Breadcrumb from 'components/Breadcrumb'
import { checkmarkCss, orderItemWrapperCss } from 'components/OrderItem'
import { ReactComponent as CheckmarkInBox } from 'assets/check-mark-in-box.svg'

const StocktakingIssuesListZebra = (props) => {
  const { t } = useTranslation()
  useEffect(() => {
    props.getStocktakingIssues()
  }, [])

  const handleClick = (stocktakingIssueId) => () => props.history.push({
    pathname: Routes.stocktakingComplete.replace(':stocktakingIssueId', stocktakingIssueId)
  })

  const itemsWithoutCancelled = props.stocktakingIssues.filter(item => item.status !== stocktakingStatuses.CANCELLED)
  return (
    <div>
      <Breadcrumb>{t('warehouse.stocktaking.stocktakingList')}</Breadcrumb>
      <LabelledDivider text={t('warehouse.stocktaking.stocktakingCommands')} />
      {itemsWithoutCancelled.length
        ? itemsWithoutCancelled.map(item => {
          const isReady = item.status === stocktakingStatuses.FINISHED
          const getWrapperStyles = orderItemWrapperCss(false, false, isReady)
          return <Box key={item.id} p={6} onClick={handleClick(item.id)} css={{ ...getWrapperStyles, '&:after': { ...getWrapperStyles['&:after'], bottom: '2px' } }}>
            <Flex my={6} mt={30}>
              <h3>{item.itemRevision.fullIndex}</h3>
            </Flex>
            <Box my={6}>
              <div>{item.itemRevision.name}</div>
            </Box>
            {isReady && <CheckmarkInBox css={checkmarkCss} />}
          </Box>
        })
        : <Box p={6} as='h3'>{t('warehouse.stocktaking.noStocktakingCommands')}</Box>
      }
    </div>
  )
}

export default StocktakingIssuesListZebra
