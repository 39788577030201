const stocktakingStatuses = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  FINISHED: 'finished',
  CANCELLED: 'cancelled'
}

export const stocktakingOrder = {
  [stocktakingStatuses.PROCESSING]: 1,
  [stocktakingStatuses.PENDING]: 2,
  [stocktakingStatuses.FINISHED]: 3
}

export default stocktakingStatuses
