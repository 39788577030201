import * as R from 'ramda'
import { iterable } from './array'

export const handleFloatNumber = (value = 0) => {
  return value % 1 !== 0 ? Number(value.toFixed(2)) : value
}

const reduceIndexed = R.addIndex(R.reduce)

export const times100 = R.multiply(100)

export const getListOfOnesPositionsInBitwiseNumber = (number = '') => R.pipe(
  Number,
  num => R.map(item => (num >> item) % 2, iterable(32)),
  reduceIndexed((indexes, nextBit, index) => {
    if (nextBit) {
      indexes.push(index + 1)
    }
    return indexes
  }, [])
)(number)

export const isFloat = value => {
  return !isNaN(value) && value.toString().indexOf('.') !== -1
}

export const flippedGt = R.flip(R.gt)
