/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Box, Flex } from '@rebass/emotion'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import theme from 'config/theme'
import * as R from 'ramda'
import { Fragment } from 'react'
import { decodeHTMLEntities, toCamelCase } from 'helpers/string'
import { ReactComponent as InProgressIcon } from 'assets/clock-thin.svg'
import { ReactComponent as AbandonedIcon } from 'assets/x-mark-circle-thin.svg'
import { ReactComponent as RepairedIcon } from 'assets/check-mark-circle-thin.svg'
import { ReactComponent as RepairIcon } from 'assets/tools.svg'
import { ReactComponent as ReplaceIcon } from 'assets/replace.svg'

const serviceStatusesIcons = {
  inProgress: <InProgressIcon fill={'orange'} height='100%' width='36' />,
  fixed: <RepairedIcon fill={'green'} height='100%' width='36' />,
  abandoned: <AbandonedIcon fill={'red'} height='100%' width='36' />
}

const serviceInstanceCss = css`
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr 1fr;
  grid-template-areas: "dateHeader componentHeader partHeader typeHeader personHeader statusHeader"
                       "dateData componentName partName type person status"
                       "dateData componentIndex partIndex type person status"
                       "comment comment comment comment comment comment";
  grid-gap: 2px;
  background-color: ${theme.colors.lightGrey};
`

const headerCss = css`
  color: ${theme.colors.lightGrey};
  font-weight: bold;
`

const centerCss = css`
  align-items: center;
  justify-content: center;
`

const smallerFontCss = css`
  font-size: 14px;
`

const ServiceBox = ({ gridArea, ...props }) => (
  <Flex
    p={2}
    css={{
      backgroundColor: theme.colors.white,
      gridArea: gridArea
    }}
    {...props}
  />
)

export const DeviceServicesView = ({ device }) => {
  const { t } = useTranslation()
  const getIconByStatus = status => status.value === 'repaired'
    ? <RepairIcon height='100%' width='36' />
    : <ReplaceIcon height='100%' width='36' />

  return device ? (
    <Flex flexDirection='column' width={1}>
      {device?.services.map(service => {
        return (
          <Fragment key={service.id}>
            {service.subservices.map(subservice => {
              const subServiceItemRevision = R.path(['assembly', 'bomStage', 'bom', 'itemRevision'], subservice)
              return subservice.serviceReplacements.map((replacement) => {
                const replacementItemRevision = R.path(['bomPart', 'itemRevision'], replacement)
                return (
                  <Box css={serviceInstanceCss} key={replacement.id} mb={20} p={'2px'}>
                    <ServiceBox gridArea='dateHeader' css={headerCss}><em>{t('service.date')}</em></ServiceBox>
                    <ServiceBox gridArea='componentHeader' css={headerCss}><em>{t('service.servicedAssembly')}</em></ServiceBox>
                    <ServiceBox gridArea='partHeader' css={headerCss}><em>{t('service.servicedPart')}</em></ServiceBox>
                    <ServiceBox gridArea='typeHeader' css={headerCss}><em>{t('service.type')}</em></ServiceBox>
                    <ServiceBox gridArea='personHeader' css={headerCss}><em>{t('service.person')}</em></ServiceBox>
                    <ServiceBox gridArea='statusHeader' css={headerCss}><em>{t('service.status')}</em></ServiceBox>
                    <ServiceBox gridArea='dateData' css={centerCss}>
                      {service.status.value !== 'in progress' ? moment(service.finishedAt).format('YYYY-MM-DD') : ''}
                    </ServiceBox>
                    <ServiceBox gridArea='componentName'>{subServiceItemRevision?.item.name}</ServiceBox>
                    <ServiceBox gridArea='componentIndex' css={smallerFontCss}>{subServiceItemRevision?.fullIndex}</ServiceBox>
                    <ServiceBox gridArea='partName'>{replacementItemRevision?.item.name}</ServiceBox>
                    <ServiceBox gridArea='type' css={centerCss}>{getIconByStatus(replacement.status)}</ServiceBox>
                    <ServiceBox gridArea='partIndex' css={smallerFontCss}>{replacementItemRevision.fullIndex}</ServiceBox>
                    <ServiceBox gridArea='person' css={centerCss}>{subservice.handledBy.fullName}</ServiceBox>
                    <ServiceBox gridArea='status' css={centerCss}>
                      {serviceStatusesIcons[toCamelCase(service.status.value)]}
                    </ServiceBox>
                    <ServiceBox gridArea='comment' css={smallerFontCss} p={6}>{decodeHTMLEntities(replacement.comment)}</ServiceBox>
                  </Box>
                )
              })
            })}
            {service.serviceReplacements.map(replacement => {
              const replacementItemRevision = R.path(['bomPart', 'itemRevision'], replacement)
              return (
                <Box css={serviceInstanceCss} key={replacement.id} mb={20} p={'2px'}>
                  <ServiceBox gridArea='dateHeader' css={headerCss}><em>{t('service.date')}</em></ServiceBox>
                  <ServiceBox gridArea='componentHeader' css={headerCss}><em>{t('service.servicedAssembly')}</em></ServiceBox>
                  <ServiceBox gridArea='partHeader' css={headerCss}><em>{t('service.servicedPart')}</em></ServiceBox>
                  <ServiceBox gridArea='typeHeader' css={headerCss}><em>{t('service.type')}</em></ServiceBox>
                  <ServiceBox gridArea='personHeader' css={headerCss}><em>{t('service.person')}</em></ServiceBox>
                  <ServiceBox gridArea='statusHeader' css={headerCss}><em>{t('service.status')}</em></ServiceBox>
                  <ServiceBox gridArea='dateData' css={centerCss}>
                    {service.status.value !== 'in progress' ? moment(service.finishedAt).format('YYYY-MM-DD') : '-'}
                  </ServiceBox>
                  <ServiceBox gridArea='componentName'>-</ServiceBox>
                  <ServiceBox gridArea='componentIndex' css={smallerFontCss}>-</ServiceBox>
                  <ServiceBox gridArea='partName'>{replacementItemRevision?.item.name}</ServiceBox>
                  <ServiceBox gridArea='type' css={centerCss}>{getIconByStatus(replacement.status)}</ServiceBox>
                  <ServiceBox gridArea='partIndex' css={smallerFontCss}>{replacementItemRevision.fullIndex}</ServiceBox>
                  <ServiceBox gridArea='person' css={centerCss}>{service.handledBy.fullName}</ServiceBox>
                  <ServiceBox gridArea='status' css={centerCss}>
                    {serviceStatusesIcons[toCamelCase(service.status.value)]}
                  </ServiceBox>
                  <ServiceBox gridArea='comment' css={smallerFontCss} p={6}>{decodeHTMLEntities(replacement.comment)}</ServiceBox>
                </Box>
              )
            })
            }
          </Fragment>
        )
      })}
    </Flex>
  ) : null
}

export default DeviceServicesView
