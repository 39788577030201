import DeviceService from 'views/DeviceService'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getDeviceForService, serviceDevice, startService, finishService } from 'ducks/productionService'
import { getDeviceForServiceBySerialNumber, getCurrentService } from 'selectors/productionService'
import { isCurrentStationServiceAllowed } from 'selectors/productionStations'
import { getProductionStations } from 'ducks/productionStations'

const mapStateToProps = (state, ownProps) => ({
  isServiceAllowed: isCurrentStationServiceAllowed(state),
  device: getDeviceForServiceBySerialNumber(state, ownProps.match.params.serialNumber),
  currentService: getCurrentService(state)
})

const mapDispatchToProps = dispatch => ({
  getDeviceForService: compose(dispatch, getDeviceForService),
  serviceDevice: compose(dispatch, serviceDevice),
  startDeviceService: compose(dispatch, startService),
  finishDeviceService: compose(dispatch, finishService),
  getProductionStations: compose(dispatch, getProductionStations)
})

export default connect(mapStateToProps, mapDispatchToProps)(DeviceService)
