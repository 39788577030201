import { createSelector } from 'reselect'
import { transformToArray } from 'helpers/redux'
import * as R from 'ramda'

const getBomsImports = state => state.bomsImports.entries

export const getBomsImportsAsArray = createSelector(
  getBomsImports,
  transformToArray
)

const propOrObj = R.propOr({})
const propOrArr = R.propOr([])
const mapIndexed = R.addIndex(R.map)

const mapTreeToSortableTree = (item, depth = 0, originalParentId, path = []) => R.applySpec({
  title: item => ({
    index: R.propOr('brak indeksu', 'index', item),
    name: R.propOr('brak nazwy', 'name', item),
    defaultVendor: R.propOr('brak dostawcy', 'vendorName', item),
    quantity: R.propOr('brak danych', 'quantity', item)
  }),
  expanded: R.always(true),
  originalParentId: R.always(originalParentId),
  id: R.prop('id'),
  componentPath: R.always(depth === 0 ? [] : [...path, item.id]),
  children: R.pipe(
    propOrObj('parts'),
    transformToArray,
    R.sortBy(R.path(['processingDetails', 'sortOrder'])),
    mapIndexed((part) => mapTreeToSortableTree(part, depth + 1, item.id, depth === 0 ? [] : [...path, item.id]))
  ),
  original: R.identity
})(item)

const constructTree = parsedInput => R.pipe(
  propOrObj('mainElement'),
  R.assoc('parts', propOrArr('parts', parsedInput)),
  mainElement => mapTreeToSortableTree(mainElement, 0, mainElement.id)
)(parsedInput)

export const getBomImportDetailsById = createSelector(
  getBomsImports,
  (state, bomImportId) => bomImportId,
  (bomImports, bomImportId) => R.pipe(
    propOrObj(bomImportId),
    propOrObj('parsedInput'),
    constructTree
  )(bomImports)
)
