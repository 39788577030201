import RecalculateFinalizeScreen from 'views/RecalculateFinalizeScreen'
import { connect } from 'react-redux'
import { recalculateInventoryQuantity } from 'ducks/warehouseState'
import { getInventoryById } from 'selectors/warehouseInventories'
import { getCurrentItem } from 'selectors/warehouseState'

const mapStateToProps = (state, ownProps) => ({
  currentItem: getCurrentItem(state),
  inventory: getInventoryById(state, ownProps.location.state.inventoryId)
})

const mapDispatchToProps = dispatch => ({
  recalculateInventoryQuantity: ({ quantity, inventoryId }) => {
    return dispatch(recalculateInventoryQuantity({ quantity, inventoryId }))
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(RecalculateFinalizeScreen)
