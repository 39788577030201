/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import { useCallback, useEffect, useState } from 'react'
import { cellCss, inlineInputCss, errorStyles } from 'views/Catalogue/styles'
import Routes from 'constants/Routes'
import 'react-confirm-alert/src/react-confirm-alert.css'

const TableCellEdit = (props) => {
  const [editing, setEditing] = useState(false)
  const [text, setText] = useState(props.children)

  useEffect(() => {
    setText(props.children)
  }, [props.children])

  const handleEditStart = useCallback(() => {
    setEditing(true)
  }, [])

  const [shouldFlash, setShouldFlash] = useState(false)
  const isProductionManagerScreen = window.location.pathname === Routes.weeklyPlans

  const handleFocusChange = async () => {
    if (isCurrentValueAPositiveNumber && (isProductionManagerScreen || isValid || currentValue === 0)) {
      await props.onSave(text)
    } else {
      setShouldFlash(true)
      setTimeout(() => {
        setShouldFlash(false)
      }, 500)
    }
    handleInputBlur()
  }

  const handleInputBlur = () => {
    setText(props.children)
    setEditing(false)
  }
  const handleInputChange = useCallback((e) => setText(e.target.value), [])

  const currentValue = Number(text)
  const originalValue = Number(props.children)
  const isValid = (props.maxAvailable + originalValue) - currentValue >= 0
  const isCurrentValueAPositiveNumber = currentValue >= 0

  const handleKeyDown = async event => {
    const keyCode = event.which || event.keyCode
    const isEnter = keyCode === 13
    const isEsc = keyCode === 27
    switch (true) {
      case isEnter && props.validate && isCurrentValueAPositiveNumber && (isValid || currentValue === 0):
        await props.onSave(text, event.shiftKey)
        return handleInputBlur()
      case isEnter && !props.validate && isCurrentValueAPositiveNumber:
        await props.onSave(text, event.shiftKey)
        return handleInputBlur()
      case isEnter && props.validate:
        return handleFocusChange()
      case isEsc:
        return handleInputBlur()
      default:
        return null
    }
  }

  return editing
    ? <div
      css={css`
        ${cellCss};
        padding: 0 5px;
        display: flex;
      `}>
      <input
        value={text}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        autoFocus
        type='text'
        onBlur={handleFocusChange}
        css={inlineInputCss}
      />
    </div>
    : <div
      style={props.style || errorStyles(shouldFlash)}
      css={{ transition: 'all 0.5s ease-in-out' }}
      className={props.className}
      onClick={handleEditStart}
    >
      {text}
    </div>
}

export default TableCellEdit

TableCellEdit.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  editItem: PropTypes.func,
  item: PropTypes.object,
  maxAvailable: PropTypes.number,
  maxWidth: PropTypes.number,
  onSave: PropTypes.func,
  validate: PropTypes.any
}
