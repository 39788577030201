/** @jsx jsx */
import { jsx } from '@emotion/core'
import * as PropTypes from 'prop-types'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import { connect } from 'react-redux'
import { revertEvents, resetEvents } from 'ducks/events'
import { useTimer } from 'use-timer'
import { withRouter } from 'react-router-dom'
import theme from 'config/theme'

const RevertButton = ({ revertEvents, events, onFinish, resetEvents, onClickFinish, className, timeForRevert }) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const endTime = useMemo(() => 0, [])
  const { time, start, reset } = useTimer({
    initialTime: timeForRevert,
    timerType: 'DECREMENTAL',
    endTime
  })

  const handleFinish = () => {
    resetEvents()
    reset()
    onFinish()
  }

  useEffect(() => {
    reset()
    events.length && start()
  }, [events])

  useEffect(() => {
    time === endTime && handleFinish()
  }, [time])

  const handleClick = useCallback(async () => {
    setLoading(true)
    await revertEvents(events)
    resetEvents()
    reset()
    onClickFinish()
    setLoading(false)
  }, [events, revertEvents, resetEvents])

  return (
    <Fragment>
      {(time > endTime && !!events.length) &&
        <Button
          disabled={loading}
          onClick={handleClick}
          className={className}
          css={{ backgroundColor: theme.colors.white, color: theme.colors.black }}
        >
          <h2>{t('common.revert')} {(time)}s</h2>
        </Button>
      }
    </Fragment>
  )
}

const mapStateToProps = state => ({
  events: state.events.events
})

const mapDispatchToProps = dispatch => ({
  revertEvents: events => dispatch(revertEvents(events)),
  resetEvents: () => dispatch(resetEvents())
})

RevertButton.propTypes = {
  events: PropTypes.any.isRequired,
  onClickFinish: PropTypes.func,
  onFinish: PropTypes.func,
  resetEvents: PropTypes.func.isRequired,
  revertEvents: PropTypes.func.isRequired,
  timeForRevert: PropTypes.number
}

RevertButton.defaultProps = {
  onClickFinish: () => {},
  onFinish: () => {},
  timeForRevert: 5
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RevertButton))
