/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useState } from 'react'
import Tooltip from 'components/Tooltip'
import { connect } from 'react-redux'
import { transformVendorsToOptionsArray } from 'selectors/itemsConfig'
import theme from 'config/theme'
import Button from 'components/Button'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import { editItemRevision } from 'ducks/warehouseState'
import { cellCss } from 'views/Catalogue/styles'

const tooltipContentCss = css`
  padding: 10px;
  text-align: left;
`

const selectCss = css`
  min-width: 300px;
  max-width: 350px;
  cursor: pointer;
  border: none;
  background: ${theme.colors.white};
`

const buttonCss = css`
  max-width: 100px;
  margin-top: 20px;
`

const EditVendor = ({ children, ...props }) => {
  const { t } = useTranslation()
  const [editing, setEditing] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const [selected, setSelected] = useState(props.item.defaultVendorId || '')
  const vendor = props.item.defaultVendor

  const closeTooltip = () => {
    setTooltipOpen(false)
    setEditing(false)
  }

  const notEditingContent = <Fragment>
    {vendor?.name || 'n/a'}
    <br />
    {vendor?.postalCode}&nbsp;{vendor?.city}
  </Fragment>
  const save = () => {
    confirmAlert({
      title: <div css={{ marginBottom: 20 }}>{t('catalogue.confirmationDialogue.confirmHeader')}</div>,
      message: <div>{t('catalogue.confirmationDialogue.editVendor')}</div>,
      buttons: [
        {
          label: t('catalogue.confirmationDialogue.yes'),
          onClick: async () => {
            await props.editItemRevision({ field: 'defaultVendorId', value: selected, revisionId: props.item.id })
            closeTooltip()
          }
        },
        {
          label: t('catalogue.confirmationDialogue.no'),
          onClick: closeTooltip
        }
      ]
    })
  }

  const editingContent = <Fragment>
    <select id={props.item.itemId} css={selectCss} onChange={e => setSelected(e.target.value)} value={selected}>
      {props.vendors.map((vendor, index) =>
        <option value={vendor.value} key={vendor.id + index.toString()}>
          {vendor.label}
        </option>
      )}
    </select>
    <Button css={buttonCss} onClick={save}>{t('catalogue.confirmationDialogue.save')}</Button>
  </Fragment>

  const renderContent = () => {
    return <div onClick={() => setEditing(true)} css={tooltipContentCss}>
      {editing ? editingContent : notEditingContent}
    </div>
  }

  return (
    <Tooltip
      style={{ zIndex: 5 }}
      content={renderContent()}
      arrow
      hideOnClick={false}
      open={tooltipOpen}
      trigger='manual'
      onRequestClose={closeTooltip}
      css={cellCss}
    >
      <div
        css={css`
          cursor: pointer;
          width: 100%;
          height: 100%;
        `}
        className={props.className}
        onClick={() => setTooltipOpen(!tooltipOpen)}
      >
        {children}
      </div>
    </Tooltip>
  )
}

const mapStateToProps = state => ({
  vendors: transformVendorsToOptionsArray(state)
})

const mapDispatchToProps = dispatch => ({
  editItemRevision: values => dispatch(editItemRevision(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditVendor)
