/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { Flex } from '@rebass/emotion'
import useInput from 'hooks/useInput'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import Button from 'components/Button'
import theme from 'config/theme'
import { extractMinutesFromTime, getValueInSeconds } from 'helpers/assemblyTime'
import { stopPropagation } from '../helpers/htmlEvents'
import Checkbox from './Checkbox'

const inputCss = (hasError) => ({
  textAlignLast: 'center',
  textAlign: 'center',
  border: `${hasError ? 4 : 2}px solid ${hasError ? theme.colors.error : 'rgb(238, 238, 238)'}`,
  backgroundColor: theme.colors.white,
  WebkitAppearance: 'none',
  borderBottomLeftRadius: '0px',
  borderBottomRightRadius: '0px',
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px'
})

const NodeForm = ({ node, currentPath, handleChangeNode, handleClose }) => {
  const { t } = useTranslation()

  const defaultQuantity = R.pathOr('', ['original', 'processingDetails', 'quantity'], node)
  const defaultStage = R.path(['original', 'processingDetails', 'stage'], node)
  const defaultAssemblyTime = R.pipe(
    R.pathOr('0', ['original', 'processingDetails', 'assemblyTime']),
    extractMinutesFromTime
  )(node)
  const defaultFinalAssemblyMmi = R.pathOr(true, ['original', 'processingDetails', 'finalAssemblyMmi'], node)
  const isFirstNode = Number(node.original.depth) === 0
  const hasChildren = node.children.length > 0
  const [quantity, changeQuantity] = useInput(defaultQuantity)
  const [stage, changeStage] = useInput(defaultStage)
  const [assemblyTime, changeAssemblyTime] = useInput(defaultAssemblyTime)
  const [finalAssemblyMmi, changeFinalAssemblyMmi] = useInput(defaultFinalAssemblyMmi)
  const [errors, setErrors] = useState({})

  const handleChange = () => {
    let validation = {}
    if (!isFirstNode && (Number(quantity) <= 0 || isNaN(Number(quantity)))) {
      validation.quantity = true
    }

    const matchAssemblyTime = R.match(/(^[0-9]{1,2}:[0-9]{2}$|^[0-9]+[,.][0-9]{1,2}$|^[0-9]+$)/g, assemblyTime)

    if (!matchAssemblyTime.length) {
      validation.assemblyTime = true
    }

    if (!R.isEmpty(validation)) {
      setErrors(validation)
    } else {
      const assemblyTimeValue = getValueInSeconds(assemblyTime)
      handleChangeNode(node, currentPath)(quantity, stage, assemblyTimeValue, isFirstNode ? finalAssemblyMmi : false)
      setErrors({})
      handleClose()
    }
  }

  const currentAssemblyTimeInSeconds = getValueInSeconds(assemblyTime)

  return (
    <Flex flexDirection='column'>
      <div css={{ display: 'grid', gridTemplateColumns: 'max-content max-content', gridGap: 12, padding: 12, justifyContent: 'center' }}>
        {!isFirstNode && (
          <Fragment>
            <span css={{ verticalAlign: 'middle' }}>{t('bomImports.amount')}:&nbsp;</span>
            <input
              css={inputCss(errors?.quantity)}
              type='number'
              value={quantity}
              min={1}
              onChange={changeQuantity}
            />
            <span css={{ verticalAlign: 'middle' }}>{t('bomImports.stage')}:&nbsp;</span>
            <select
              onChange={changeStage}
              defaultValue={stage}
              css={inputCss(false)}
            >
              <option value='1'>etap 1</option>
              <option value='2'>etap 2</option>
            </select>
          </Fragment>
        )}
        {hasChildren && (
          <Fragment>
            <span css={{ verticalAlign: 'middle' }}>
              {t('bomImports.assemblyTime')}:&nbsp;
            </span>
            <input
              value={assemblyTime}
              onChange={changeAssemblyTime}
              name='assemblyTime'
              type='text'
              css={inputCss(errors?.assemblyTime)}
            />
            <span>w sekundach:</span>
            <span>{currentAssemblyTimeInSeconds}</span>
          </Fragment>
        )}
        {isFirstNode && (
          <Fragment>
            <span css={{ verticalAlign: 'middle' }}>{t('bomImports.isFinalAssembly')}:&nbsp;</span>
            <Checkbox
              checked={finalAssemblyMmi}
              type='checkbox'
              onClick={stopPropagation}
              onChange={() => changeFinalAssemblyMmi(!finalAssemblyMmi)}
            />
          </Fragment>
        )}
      </div>
      <Button onClick={handleChange}>
        {t('bomImports.saveAndClose')}
      </Button>
    </Flex>
  )
}

export default NodeForm

NodeForm.propTypes = {
  handleChangeNode: PropTypes.func.isRequired,
  node: PropTypes.any.isRequired
}
