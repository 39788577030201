/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Box, Flex } from '@rebass/emotion'
import moment from 'moment'
import { Droppable } from 'react-beautiful-dnd'
import theme from 'config/theme'
import BomItem from './BomItem'
import { iterable } from 'helpers/array'
import * as R from 'ramda'
import { findIdInString } from 'helpers/regexp'
import { Fragment, useCallback } from 'react'
import Tooltip from 'components/Tooltip'
import { times100 } from 'helpers/number'
import Toggle from 'components/Toggle'
import { useTranslation } from 'react-i18next'

const mapIndexed = R.addIndex(R.map)
export const getAssemblyTime = R.pathOr(0, ['bom', 'assemblyTime', 'seconds'])
const getPlanTimeInSeconds = plan => R.propOr(0, 'quantity', plan) * getAssemblyTime(plan)
const getRelatedProgressTime = (plan, relevantRelation) => ((R.propOr(0, 'manufactured', plan) * getAssemblyTime(plan)) / relevantRelation) * 100

const getDurationsWithOffset = (tasksDurationList = [], DAY_LIMIT) => {
  let durationSum = R.sum(tasksDurationList) > DAY_LIMIT ? R.sum(tasksDurationList) : DAY_LIMIT
  let outList = []
  let limitCounter = 0
  for (let taskDuration in tasksDurationList) {
    const taskDurNumber = tasksDurationList[taskDuration]
    let normal
    let overflow
    let offset
    if (limitCounter < DAY_LIMIT && DAY_LIMIT < limitCounter + taskDurNumber) {
      let overflowDur = limitCounter + taskDurNumber - DAY_LIMIT
      let normalDur = taskDurNumber - overflowDur
      normal = (normalDur / durationSum)
      overflow = (overflowDur / durationSum)
    } else if (limitCounter < DAY_LIMIT) {
      normal = (taskDurNumber / durationSum)
      overflow = 0
    } else {
      normal = 0
      overflow = (taskDurNumber / durationSum)
    }
    offset = (limitCounter / durationSum)
    limitCounter += taskDurNumber
    outList.push({ offset: times100(offset), normal: Math.max(overflow > 0 ? 0 : 1, times100(normal)), overflow: times100(overflow) })
  }

  return outList
}

const StationBox = ({ editDailyPlan, highlightedElement, ...props }) => {
  const { t } = useTranslation()
  const editPlan = useCallback((dailyPlan, quantity) => {
    const newPlan = { ...dailyPlan, quantity }
    return editDailyPlan(newPlan)
  }, [editDailyPlan])

  return (
    <Fragment>
      {props.productionStations.map((station, index) => {
        const loggedIn = station.login?.user
          ? `${station.login.user.firstname} ${station.login.user.lastname}`
          : ''
        const onServiceStatusChange = (value) => props.setServiceRightOnStation({ id: station.id, value })
        return (
          <Flex
            width={1}
            key={station.id + index}
            css={{
              borderRadius: '5px',
              boxShadow: `1px 1px 3px ${theme.colors.dustyGray}`,
              padding: 5,
              marginBottom: 10
            }}
          >
            {iterable(6).map(R.add(1)).map(dayIndex => {
              const ownBomIds = props.state.columns?.[`productionStation-${station.id}-${dayIndex}`]?.bomIds
              const dailyPlan = props?.dailyPlans?.[`productionStation-${station.id}-${dayIndex}`]
              const alreadyPlanned = ownBomIds?.some(id => findIdInString(id, 0) === findIdInString(props.draggedItem)) && !R.includes(`productionStation-${station.id}-${dayIndex}`, props.draggedItem)
              const draggedPlan = props.plans.find(plan => plan.bom.itemRevision.id === findIdInString(props.draggedItem, 0))
              const canBeProduced = R.path(['bom', 'assemblyPosts'], draggedPlan) && R.findIndex(post => post.id === station.id, R.pathOr([], ['bom', 'assemblyPosts'], draggedPlan)) >= 0
              const isOutdated = (dayIndex < moment().isoWeekday() && props.currentWeek === moment().week()) || props.currentWeek < moment().week()
              return (
                <Box
                  key={station.id + index + dayIndex}
                  width={1 / 7}
                  css={{
                    boxShadow: `3px 0 5px -5px ${theme.colors.lightGrey}`
                  }}
                >
                  <Droppable droppableId={`productionStation-${station.id}-${dayIndex}`}>
                    {(provided, snapshot) => {
                      return (
                        <Flex
                          ref={provided.innerRef}
                          flexDirection={'column'}
                          {...provided.droppableProps}
                          css={{
                            height: '100%',
                            minHeight: 60,
                            borderRadius: '5px',
                            alignItems: 'center',
                            boxShadow: props.isDragging
                              ? `inset 0 0 10px ${(isOutdated || R.not(canBeProduced) || alreadyPlanned)
                                ? theme.colors.error
                                : theme.colors.successGreen}`
                              : 'unset',
                            background: snapshot.isDraggingOver
                              ? (isOutdated || R.not(canBeProduced) || alreadyPlanned)
                                ? theme.colors.error
                                : theme.colors.lightGreen
                              : 'unset',
                            transition: 'all .3s ease-in-out'
                          }}
                        >
                          {ownBomIds?.map((bomId, index, all) => {
                            const originId = findIdInString(bomId, 0)
                            const dailyPlanItem = dailyPlan?.[`${dayIndex}`].find(plan => plan?.bom.itemRevision.id === originId)
                            const dailyPlanQuantity = dailyPlanItem?.quantity || null
                            const bom = props.plans.find(plan => plan?.bom?.itemRevision.id === originId)
                            const times = R.map(mapIndexed((plan, planIndex, plans) => {
                              const assemblyPostWorkTimeInSeconds = plan.assemblyPost.worktime * 60
                              const plannedWorkTime = plans.reduce((acc, nextPlan) => acc + getPlanTimeInSeconds(nextPlan), 0)
                              const relevantTimeRelation = Math.max(plannedWorkTime, assemblyPostWorkTimeInSeconds)
                              const planTimes = plans.map(getPlanTimeInSeconds)
                              const durations = getDurationsWithOffset(planTimes, assemblyPostWorkTimeInSeconds)
                              const planBars = durations[planIndex]
                              const progressTime = Math.max(getRelatedProgressTime(plan, relevantTimeRelation), 0)
                              return {
                                progressTime,
                                planTime: planBars.normal,
                                offset: planBars.offset,
                                tooMuch: planBars.overflow
                              }
                            }), dailyPlan || {})

                            const finishTimes = R.map(mapIndexed((plan, planIndex, plans) => {
                              const planDuration = (R.propOr(0, 'quantity', plan) - R.propOr(0, 'manufactured', plan)) * getAssemblyTime(plan)
                              const offset = R.pipe(
                                R.slice(0, planIndex),
                                R.reduce((prev, current) => {
                                  return prev + ((current.quantity - current.manufactured) * current?.bom?.assemblyTime?.seconds)
                                }, 0)
                              )(plans)
                              const isToday = (dayIndex === moment().day() && props.currentWeek === moment().week())
                              const planFinishTime = isToday
                                ? moment().add(offset + planDuration, 'seconds')
                                : moment().hour(7).minutes(0).seconds(0).add(Math.max(offset + planDuration, 0), 'seconds')
                              return {
                                planFinishTime: planFinishTime.isAfter(moment().endOf('day'))
                                  ? planFinishTime.format('LLL')
                                  : planFinishTime.format('HH:mm'),
                                offset
                              }
                            }), dailyPlan || {})

                            const currentQuantity = R.pipe(
                              R.values,
                              R.map(R.values),
                              R.flatten,
                              R.filter(dailyPlan => dailyPlan?.planId === bom?.id),
                              R.map(R.prop('quantity')),
                              R.reduce(R.add, 0)
                            )(props.dailyPlans)
                            const maxQuantity = Math.max(0, bom?.quantity - currentQuantity + dailyPlanQuantity)
                            return (
                              <BomItem
                                key={`listItemKey-2-${index}`}
                                index={index}
                                times={R.pathOr({}, [dayIndex, index], times)}
                                finishTimes={R.pathOr({}, [dayIndex, index], finishTimes)}
                                dayIndex={dayIndex}
                                bomId={bomId}
                                highlightedElement={highlightedElement}
                                bom={bom}
                                currentWeek={props.currentWeek}
                                singlePlanQuantity={dailyPlanQuantity}
                                dailyPlan={dailyPlanItem}
                                editPlan={editPlan}
                                maxQuantity={maxQuantity}
                              />
                            )
                          })}
                          {provided.placeholder}
                        </Flex>
                      )
                    }}
                  </Droppable>
                </Box>
              )
            })
            }
            <Flex
              key={station.id + index + 'stationName'}
              width={1 / 7}
              css={{
                fontSize: 16
              }}
              alignItems='center'
              justifyContent='center'
              flexDirection='column'
            >
              <span>{station.number} {station.name}</span>
              <Tooltip
                css={{ margin: '8px 0' }}
                content={
                  <Box p={4}>
                    zalogowany od: {moment(station.login?.loggedInAt).format('LLL')}
                  </Box>
                }
                disabled={!station.login}
              >
                <span css={{ color: theme.colors.grey }}>{loggedIn}</span>
              </Tooltip>
              <Toggle
                label={t('productionStation.service')}
                width={85}
                height={18}
                onChange={onServiceStatusChange}
                value={station.isServiceAllowed}
                isLoading={station.isLoading}
              />
            </Flex>
          </Flex>
        )
      })
      }
    </Fragment>
  )
}

export default StationBox
