import { createSelector } from 'reselect'
import { transformToArray } from 'helpers/redux'
import { identity } from 'ramda'

const getWarehouseState = state => state.warehouseState.entries
const getWarehouseStateAllItems = state => state.warehouseState.allItemEntries
const getWarehouseStateCurrentItem = state => state.warehouseState.currentItem
const getCancelledInternalOrders = state => state.warehouseState.cancelledInternalOrders

export const allItemsSelector = createSelector(
  getWarehouseStateAllItems,
  identity
)

export const getWarehouseStateAsArray = createSelector(
  getWarehouseState,
  warehouseState => transformToArray(warehouseState)
)

export const getWarehouseStateAllItemsAsArray = createSelector(
  getWarehouseStateAllItems,
  warehouseState => transformToArray(warehouseState)
)

export const getCurrentItem = createSelector(
  getWarehouseStateCurrentItem,
  identity
)

export const selectAllCancelledInternalOrders = createSelector(
  getCancelledInternalOrders,
  identity
)
