import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import searchInput from './searchInput'
import ordersForItem from './ordersForItem'
import inboundZone from './inboundZone'
import warehouseState from './warehouseState'
import warehouseInventories from './warehouseInventories'
import discrepancyComments from './discrepancyComments'
import handOverPurposes from './handOverPurposes'
import events from './events'
import internalOrders from './internalOrders'
import internalOrderedItems from './internalOrderedItems'
import user from './user'
import stocktaking from './stocktaking'
import itemsSubmissions from './itemsSubmissions'
import bom from './bom'
import weeklyPlans from './weeklyPlans'
import itemsConfig from './itemsConfig'
import notifications from './notifications'
import productionStations from './productionStations'
import productionService from './productionService'
import users from './users'
import workstations from './workstations'
import bomsImports from './bomsImports'
import serialNumberFormats from './serialNumberFormats'
import locations from './locations'
import colors from './colors'

const appReducer = combineReducers({
  form: formReducer,
  searchInput,
  ordersForItem,
  inboundZone,
  warehouseState,
  warehouseInventories,
  discrepancyComments,
  handOverPurposes,
  events,
  internalOrders,
  internalOrderedItems,
  user,
  stocktaking,
  itemsSubmissions,
  itemsConfig,
  bom,
  weeklyPlans,
  notifications,
  productionStations,
  productionService,
  users,
  workstations,
  bomsImports,
  serialNumberFormats,
  locations,
  colors
})

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer
