/** @jsx jsx */
import { Fragment, useEffect, useState } from 'react'
import { jsx } from '@emotion/core'
import { connect } from 'react-redux'
import { deleteColorVersion, getColorsList, updateColorsList, updateColorVersion } from 'ducks/colors'
import Modal from 'components/Modal'
import { isNotNilOrEmpty } from 'helpers/ramda'
import Toggle from 'components/Toggle'
import { transformToArray } from 'helpers/redux'
import Button from 'components/Button'
import { editItemRevision } from 'ducks/warehouseState'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import AddColorVariantFormConnector from 'connectors/forms/AddColorVariantFormConnector'
import {
  ModalButtonsWrapper,
  StyledCell,
  StyledRow,
  StyledTableHeader,
  StyledTrashIcon
} from 'views/Catalogue/ColorsModalStyles'
import { toast } from 'react-toastify'
import { errorHandler } from 'helpers/errorHandler'
import { noop } from 'helpers/productionStation'

const ColorsModal = ({
  getWarehouseState,
  getColorsList,
  addNewColorVersion,
  updateColorVersion,
  removeColorVersion,
  hasColorVariants,
  itemRevisionId,
  colors,
  onClose,
  updateColors,
  updateItemRevision,
  isProduct }) => {
  const { t } = useTranslation()
  const [colorsArray, setColorsArray] = useState([])
  const [colorsList, setColorsList] = useState({})
  const [hasColors, setHasColors] = useState(hasColorVariants)
  const [isAddFormVisible, setAddFormVisibility] = useState(false)

  useEffect(() => {
    getColorsList(itemRevisionId)
  }, [])

  useEffect(() => {
    setColorsList(colors)
  }, [colors])

  useEffect(() => {
    setColorsArray(transformToArray(colorsList))
  }, [colorsList])

  const handleChangeStatus = colorId => () => {
    setColorsList({
      ...colorsList,
      [colorId]: {
        ...colorsList[colorId],
        isActive: !colorsList[colorId].isActive
      }
    })
  }

  const saveChanges = async () => {
    if (isProduct) {
      try {
        await updateColors(itemRevisionId, colorsArray)
        getWarehouseState()
      } catch (err) {
        console.error(err)
      }
      onClose()
    } else {
      try {
        await updateItemRevision('hasColorVersions', hasColors, itemRevisionId)
        toast.success(t('colors.alerts.itemRevisionUpdated'), { containerId: 'statuses', autoClose: 15e3 })
        onClose()
      } catch (err) {
        console.error(err)
      }
    }
  }

  const handleDeleteColorVersion = (colorId, colorName) => () => {
    confirmAlert({
      title: t('colors.alerts.deleteColor', { colorName }),
      buttons: [
        {
          label: t('catalogue.confirmationDialogue.yes'),
          onClick: async () => {
            try {
              await removeColorVersion(colorId)
              getColorsList(itemRevisionId)
            } catch (err) {
              errorHandler(err)
              console.error(err)
            }
          }
        },
        {
          label: t('catalogue.confirmationDialogue.no'),
          onClick: noop()
        }
      ]
    })
  }

  const handleCloseModal = () => {
    if (colorsList !== colors) {
      confirmAlert({
        title: t('colors.alerts.hasChanges'),
        buttons: [
          {
            label: t('catalogue.confirmationDialogue.yes'),
            onClick: onClose
          },
          {
            label: t('catalogue.confirmationDialogue.no'),
            onClick: noop()
          }
        ]
      })
    } else {
      onClose()
    }
  }

  const openAddForm = () => setAddFormVisibility(true)
  const closeAddForm = () => setAddFormVisibility(false)

  return (
    <Modal headerTitle={t('colors.modal.title')} onClose={handleCloseModal} zIndex={21}>
      {
        isProduct
          ? <Fragment>
            <table css={{ width: '100%' }}>
              <tbody>
                <StyledRow css={{ marginBottom: 20 }}>
                  <StyledTableHeader>{t('colors.form.name')}</StyledTableHeader>
                  <StyledTableHeader>{t('colors.form.code')}</StyledTableHeader>
                  <StyledTableHeader>{t('colors.modal.status')}</StyledTableHeader>
                  <StyledTableHeader>{t('colors.modal.actions')}</StyledTableHeader>
                </StyledRow>
                {isNotNilOrEmpty(colorsArray) && colorsArray.map((color, index) => {
                  return (
                    <StyledRow key={`color-${index}`}>
                      <StyledCell>{color.name}</StyledCell>
                      <StyledCell>{color.code}</StyledCell>
                      <StyledCell>
                        <Toggle
                          onChange={handleChangeStatus(color.id)}
                          value={color.isActive}
                        />
                      </StyledCell>
                      <StyledCell>
                        <StyledTrashIcon onClick={handleDeleteColorVersion(color.id, color.name)} />
                      </StyledCell>
                    </StyledRow>
                  )
                })}
              </tbody>
            </table>
            {
              isAddFormVisible && <AddColorVariantFormConnector revisionId={itemRevisionId} resetForm={closeAddForm} />
            }
            <ModalButtonsWrapper>
              <div css={{ marginTop: 30 }}>
                <Button secondary onClick={openAddForm}>{t('colors.form.addColor')}</Button>
              </div>
              <div css={{ marginTop: 30 }}>
                <Button onClick={saveChanges}>{t('colors.form.save')}</Button>
              </div>
            </ModalButtonsWrapper>
          </Fragment>
          : <div>
            <p style={{ textAlign: 'center', margin: '30px 0' }}>{t('colors.modal.hasOtherVersions')}</p>
            <div css={{ margin: '30px 0', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
              <span style={{ marginRight: 20 }}>{t('catalogue.confirmationDialogue.no')}</span>
              <Toggle
                onChange={() => setHasColors(!hasColors)}
                value={hasColors}
              />
              <span style={{ marginLeft: 20 }}>{t('catalogue.confirmationDialogue.yes')}</span>
            </div>
            <div css={{ marginTop: 30 }}>
              <Button onClick={saveChanges}>{t('colors.form.save')}</Button>
            </div>
          </div>
      }
    </Modal>
  )
}

const mapStateToProps = state => ({
  colors: state.colors.entries
})

const mapDispatchToProps = dispatch => ({
  getColorsList: revisionId => dispatch(getColorsList(revisionId)),
  updateColors: (revisionId, newList) => dispatch(updateColorsList(revisionId, newList)),
  updateItemRevision: (field, value, revisionId) => dispatch(editItemRevision({ field, value, revisionId })),
  removeColorVersion: id => dispatch(deleteColorVersion(id)),
  updateColorVersion: (id, name, code) => dispatch(updateColorVersion(id, name, code))
})

export default connect(mapStateToProps, mapDispatchToProps)(ColorsModal)
