/** @jsx jsx */
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { jsx } from '@emotion/core'
import { Box, Flex } from '@rebass/emotion'
import Breadcrumb from 'components/Breadcrumb'
import LabelledDivider from 'components/LabelledDivider'
import QrCodeReader from 'components/QrCodeReader'
import { scanParser } from 'helpers/scanParser'
import { propEq } from 'ramda'

const InternalSubmissionsList = (props) => {
  const { t } = useTranslation()

  useEffect(() => {
    props.listAllPendingSubmissions()
  }, [])

  const redirectToItemView = item => {
    props.history.push({
      pathname: `/internal-submission-view/item/${item.fullIndex}`,
      state: { item: item }
    })
  }

  const onScan = scanData => {
    const parsedScan = scanParser(scanData)
    const isItemScan = parsedScan.type === 'item'
    const item = isItemScan ? props.pendingSubmissions.find(propEq('fullIndex', parsedScan.data)) : null
    item && redirectToItemView(item)
  }

  const generateList = submissionsArray => {
    return submissionsArray.map((item, index) => {
      return <div key={index} onClick={() => redirectToItemView(item)}>
        <Flex my={6} justifyContent='space-between'>
          <h3>{item.fullIndex}</h3>
          <h3>{item['totalNotAcknowledgedWarehouseSubmissionQuantity']}</h3>
        </Flex>
        <Box my={6} css={{ borderBottom: '1px solid black', paddingBottom: 20 }}>
          <div>{item.name}</div>
        </Box>
      </div>
    })
  }

  return (
    <div>
      <QrCodeReader onScan={onScan} invisible />
      <Breadcrumb>{t('warehouse.internalSubmissionsList')}</Breadcrumb>
      <Box p={6}>
        <LabelledDivider text={t('warehouse.declarations')} noMargin />
        {generateList(props.pendingSubmissions)}
      </Box>
    </div>
  )
}

export default InternalSubmissionsList
