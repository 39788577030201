/** @jsx jsx */
import { jsx } from '@emotion/core'
import Input, { errorCss } from 'components/Input'
import QrCodeReader from 'components/QrCodeReader'

const ScanInput = ({ onScan, ...props }) => {
  const { meta: { touched, error }, input: { value } } = props
  return (
    <div>
      <Input {...props} type={'hidden'} />
      {touched && error && <div css={{ ...errorCss, marginLeft: 12 }}>{error}</div>}
      {value
        ? <div css={{ textAlign: 'center', fontSize: 20, fontWeight: 'bold' }}>
          <QrCodeReader onScan={onScan} invisible />
          {value}
        </div>
        : <QrCodeReader onScan={onScan} noHeight />}
    </div>
  )
}

export default ScanInput
