import { useEffect } from 'react'

const useKeyHandler = (scanHandler, inputRef, omitInputs) => {
  const keyDownHandler = async event => {
    if (inputRef.current !== null) {
      const keyCode = event.which || event.keyCode
      const isEnter = keyCode === 13
      const isEsc = keyCode === 27
      const targetIsNotTextArea = !['textarea', omitInputs && 'input'].includes(event.target.nodeName.toLowerCase()) || event.target === inputRef.current
      if (isEnter && targetIsNotTextArea) {
        await inputRef.current.blur()
        scanHandler(inputRef.current.value)
      } else if (!isEsc && targetIsNotTextArea) {
        // eslint-disable-next-line no-unused-expressions
        inputRef.current?.focus()
      } else if (isEsc && targetIsNotTextArea) {
        inputRef.current.blur()
      }
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  })
}

export default useKeyHandler
