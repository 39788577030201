import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { includesInArray } from 'helpers/array'
import Routes from 'constants/Routes'

const AuthorizedRoutes = ({ authenticated, roles, children, allowedRoles, ...rest }) => {
  return (
    <Route {...rest} render={props => {
      switch (true) {
        case authenticated && includesInArray(allowedRoles, roles):
          return children
        case authenticated && !includesInArray(allowedRoles, roles):
          return <Redirect to={{
            pathname: Routes.logout,
            state: { from: props.location }
          }} />
        default:
          return (
            <Redirect to={{
              pathname: Routes.login,
              state: { from: props.location }
            }} />
          )
      }
    }} />
  )
}

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
  roles: state.user.roles || []
})

export default withRouter(connect(mapStateToProps)(AuthorizedRoutes))
