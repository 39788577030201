/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useRef, useState } from 'react'
import { stepCss } from 'views/ProductionStation/styles'
import { iterable } from 'helpers/array'
import Button from 'components/Button'
import { ReactComponent as Arrow } from 'assets/arrow.svg'
import Keyboard from 'react-simple-keyboard'
import * as R from 'ramda'
import ImageGallery from 'components/ImageGallery'
import { moveCursorToEnd } from 'helpers/htmlEvents'
import { mapFilesToReactImagesComponent } from 'helpers/files'

const PartBox = ({ part, index: partIndex, errors, selectedIndex, scanHandler, currentValue }) => {
  const [values, setValues] = useState(currentValue)
  const [focusedInput, setFocusedInput] = useState(part.id + 0)
  const [keyboardVisible, setKeyboardVisible] = useState(false)

  const inputRef = useRef(null)
  const keyboardRef = useRef(null)

  const clearCurrentInputValue = () => {
    // eslint-disable-next-line
    inputRef.current?.setInput('')
  }

  const handleValidateAndSend = async (value, onSuccessCallback) => {
    try {
      await scanHandler(value || values, clearCurrentInputValue, onSuccessCallback)
    } catch (e) {
      setValues(currentValue)
      console.error(e)
    }
  }

  useEffect(() => {
    currentValue && setValues(currentValue)
  }, [currentValue])

  useEffect(() => {
    selectedIndex !== partIndex && setKeyboardVisible(false)
  }, [partIndex, selectedIndex])

  const setCurrentValueFromEvent = useCallback(index => async (event) => {
    const value = event.target.value
    // eslint-disable-next-line
    keyboardRef.current?.setInput(value)
    const newValues = index >= 1
      ? R.insert(index, value, values)
      : R.insert(index, value, [])
    await setValues(newValues)
  }, [setValues, values, keyboardRef])

  const onChange = value => setValues([value])

  const handleFocus = index => () => {
    setFocusedInput(part.id + index)
    setKeyboardVisible(true)
    moveCursorToEnd(inputRef.current)
  }

  const keyDownHandler = event => {
    const keyCode = event.which || event.keyCode
    const isEnter = keyCode === 13
    const isEsc = keyCode === 27
    const isCurrent = selectedIndex === partIndex
    const targetIsNotTextArea = !['textarea', 'input'].includes(event.target.nodeName.toLowerCase()) || event.target === inputRef.current
    if (isCurrent && isEnter) {
      const onSuccess = () => inputRef.current.blur()
      handleValidateAndSend([inputRef.current.value], onSuccess)
    } else if (isCurrent && !isEsc && targetIsNotTextArea) {
      // eslint-disable-next-line no-unused-expressions
      inputRef.current?.focus()
    } else if (isCurrent && isEsc) {
      inputRef.current.blur()
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
    }
  })

  return (
    <div
      css={stepCss(selectedIndex, partIndex)}
    >
      <span css={{ textAlign: 'center' }}>{part.itemRevision.fullIndex}</span>
      <span css={{ textAlign: 'center' }}>{part.itemRevision.productionName || part.itemRevision.name}</span>
      <span css={{ textAlign: 'center', color: 'red', transition: 'color 1s linear' }}>{errors[part.id]}</span>
      {iterable(part.quantity).map(index => {
        return (
          <input
            ref={inputRef}
            key={part.itemRevision.itemId + '-input-' + index}
            value={values[index] || ''}
            name={part.id + index}
            onFocus={handleFocus(index)}
            onChange={setCurrentValueFromEvent(index)}
            autoComplete={'off'}
            css={{ marginTop: 30, border: 'none', borderBottom: '2px solid black' }}
          />
        )
      })
      }
      <Button
        css={{ margin: '20px 0' }}
        onClick={() => handleValidateAndSend(values)}
      >
        <Arrow fill='#fff' />
      </Button>
      {keyboardVisible && (
        <Keyboard
          keyboardRef={el => (keyboardRef.current = el)}
          onChange={onChange}
          inputName={focusedInput}
          disableButtonHold
          layout={{
            default: [
              '1 2 3',
              '4 5 6',
              '7 8 9',
              '{bksp} 0'
            ]
          }}
          display={{
            '{bksp}': 'usuń'
          }}
        />
      )}
      <ImageGallery images={mapFilesToReactImagesComponent(part.itemRevision.files)} />
    </div>
  )
}

export default PartBox
