import { css } from '@emotion/core'
import theme from 'config/theme'
import cursor from 'assets/cursor.svg'

export const gridCss = (columnsCount = 30) => css`
  display: grid;
  font-size: 14px;
  grid-template-columns: 150px 300px 300px 150px 200px repeat(${columnsCount - 4}, 70px);
  position: relative;
  grid-auto-rows: max-content;
  margin: 0 ${theme.space[8]}px;
  
  button {
    background-color: transparent;
  }
`

export const catalogueWrapperCss = (columnsCount) => css`
  cursor: url(${cursor}), auto;
  position: relative;
  
  > div > div > div {
    ${gridCss(columnsCount)};
  }
`

export const headerCss = css`
  position: sticky;
  top: 70px;
  display: flex;
  align-items: flex-end;
  font-size: 10px;
  padding: 9px 6px;
  background-color: ${theme.colors.white};
  z-index: 10;
`

export const sortableCss = css`
  ${headerCss};
  cursor: pointer;
`

export const cellCss = css`
  box-shadow: inset 0 1px 2px rgba(0, 0, 0,0.2);
  padding: 12px 6px;
  justify-self: stretch;
  align-self: stretch;
`

export const nameCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const normalCellCss = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`

const arrowCss = css`
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  width: 5px;
  height: 5px;
  border: 2px solid ${theme.colors.darkGrey};
  border-top: none;
  border-left: none;
`

export const arrowUpCss = css`
  &:after {
    ${arrowCss};
    transform: rotate(45deg);
    top: unset;
  }
`

export const arrowDownCss = css`
  &:after {
    ${arrowCss};
    transform: rotate(-135deg);
    top: unset;
  }
`

export const openCss = css`
  overflow: unset;
  &:after {
    ${arrowCss};
    right: unset;
    top: 5px;
    left: -25px;
    transform: rotate(-45deg);
  }
`

export const openedCss = css`
  overflow: unset;
  &:after {
    ${arrowCss};
    right: unset;
    top: 3px;
    left: -25px;
    transform: rotate(45deg);
  }
`

const getOpacityByDepth = (depth, reversed, index) => reversed
  ? 1 - ((depth + 1) * 0.2) + (0.2 * index)
  : 1 - (depth * 0.2)

export const depthCss = (isLast, depth) => css`
  &:after {
    position: absolute;
    left: -10px;
    top: -12px;
    content: '';
    display: block;
    border-left: 1px solid rgba(0, 0, 0, ${getOpacityByDepth(depth)});
    height: ${isLast ? 20 : 40}px;
    border-bottom: ${isLast ? `1px solid rgba(0, 0, 0, ${getOpacityByDepth(depth)})` : 'none'};
    width: 7px;
  }
  
  &:before {
    position: absolute;
    left: -9px;
    top: -12px;
    content: '';
    display: block;
    height: 20px;
    border-bottom: ${isLast ? 'none' : `1px solid rgba(0, 0, 0, ${getOpacityByDepth(depth)})`};
    width: 6px;
  }
`

export const lineCss = (index, depth) => {
  return css`
  position: absolute;
  left: ${(-(index * 20) - 10)}px;
  top: -12px;
  content: '';
  display: block;
  border-left: 1px solid rgba(0, 0, 0, ${getOpacityByDepth(depth, true, index + 1)});
  height: 40px;
  width: 7px;
`
}

export const inlineInputCss = css`
  border: 1px solid ${theme.colors.lightGrey};
  padding: 5px;
  min-width: 20px;
  width: 100%;
`

export const lastCellCss = headerEnd => css`
  ${headerCss};
  grid-column: ${headerEnd} / -1;
  background-color: ${theme.colors.white};
`
export const errorStyles = shouldFlash => shouldFlash ? {
  cursor: 'pointer',
  boxShadow: `inset ${theme.colors.red} 0px 0px 4px 3px`
} : { cursor: 'pointer' }
