import WarehouseService from 'services/WarehouseService'
import { transformById } from 'helpers/redux'
import { receiveEvents } from 'ducks/events'
import { dissoc } from 'ramda'

const SUBMIT_ITEMS_FOR_WAREHOUSE = Symbol('SUBMIT_ITEMS_FOR_WAREHOUSE_REQUEST')
const SUBMIT_ITEMS_FOR_WAREHOUSE_SUCCESS = Symbol('SUBMIT_ITEMS_FOR_WAREHOUSE_SUCCESS')
const SUBMIT_ITEMS_FOR_WAREHOUSE_FAILURE = Symbol('SUBMIT_ITEMS_FOR_WAREHOUSE_FAILURE')

const LIST_ITEM_SUBMISSIONS = Symbol('LIST_ITEM_SUBMISSIONS')
const LIST_ITEM_SUBMISSIONS_SUCCESS = Symbol('LIST_ITEM_SUBMISSIONS_SUCCESS')
const LIST_ITEM_SUBMISSIONS_FAILURE = Symbol('LIST_ITEM_SUBMISSIONS_FAILURE')

const LIST_ALL_PENDING_SUBMISSIONS_REQUEST = Symbol('LIST_ALL_PENDING_SUBMISSIONS_REQUEST')
const LIST_ALL_PENDING_SUBMISSIONS_SUCCESS = Symbol('LIST_ALL_PENDING_SUBMISSIONS_SUCCESS')
const LIST_ALL_PENDING_SUBMISSIONS_FAILED = Symbol('LIST_ALL_PENDING_SUBMISSIONS_FAILED')

const ACKNOWLEDGE_ITEM_SUBMISSION_REQUEST = Symbol('ACKNOWLEDGE_ITEM_SUBMISSION_REQUEST')
const ACKNOWLEDGE_ITEM_SUBMISSION_SUCCESS = Symbol('ACKNOWLEDGE_ITEM_SUBMISSION_SUCCESS')
const ACKNOWLEDGE_ITEM_SUBMISSION_FAILED = Symbol('ACKNOWLEDGE_ITEM_SUBMISSION_FAILED')

const DELETE_SUBMITTED_ITEM_REQUEST = Symbol('DELETE_SUBMITTED_ITEM_REQUEST')
const DELETE_SUBMITTED_ITEM_SUCCESS = Symbol('DELETE_SUBMITTED_ITEM_SUCCESS')
const DELETE_SUBMITTED_ITEM_FAILED = Symbol('DELETE_SUBMITTED_ITEM_FAILED')

const deleteSubmittedItemRequest = () => ({ type: DELETE_SUBMITTED_ITEM_REQUEST })
const deleteSubmittedItemSuccess = handOverId => ({ type: DELETE_SUBMITTED_ITEM_SUCCESS, handOverId })
const deleteSubmittedItemFailed = () => ({ type: DELETE_SUBMITTED_ITEM_FAILED })

export const deleteSubmittedItem = handOverId => {
  return async dispatch => {
    dispatch(deleteSubmittedItemRequest())
    try {
      await WarehouseService.deletePendingSubmittedItem(handOverId)
      await dispatch(deleteSubmittedItemSuccess(handOverId))
      return Promise.resolve()
    } catch (e) {
      dispatch(deleteSubmittedItemFailed(e))
      return Promise.reject(e)
    }
  }
}

const submitItemsForWarehouseRequest = () => ({ type: SUBMIT_ITEMS_FOR_WAREHOUSE })
const submitItemsForWarehouseSuccess = data => ({ type: SUBMIT_ITEMS_FOR_WAREHOUSE_SUCCESS, data })
const submitItemsForWaregouseFailure = () => ({ type: SUBMIT_ITEMS_FOR_WAREHOUSE_FAILURE })

export const submitItemsForWarehouse = values => {
  return async dispatch => {
    dispatch(submitItemsForWarehouseRequest())
    try {
      const { data } = await WarehouseService.submitItemsForWarehouse(values)
      dispatch(submitItemsForWarehouseSuccess(data))
    } catch (err) {
      dispatch(submitItemsForWaregouseFailure(err))
    }
  }
}

const listItemSubmissionsRequest = () => ({ type: LIST_ITEM_SUBMISSIONS })
const listItemSubmissionsSuccess = (data) => ({ type: LIST_ITEM_SUBMISSIONS_SUCCESS, data })
const listItemSubmissionsFailure = () => ({ type: LIST_ITEM_SUBMISSIONS_FAILURE })

export const listItemSubmissions = () => {
  return async dispatch => {
    dispatch(listItemSubmissionsRequest())
    try {
      const { data } = await WarehouseService.listItemSubmissions()
      dispatch(listItemSubmissionsSuccess(data))
    } catch (err) {
      dispatch(listItemSubmissionsFailure(err))
    }
  }
}

const listAllPendingSubmissionsRequest = () => ({ type: LIST_ALL_PENDING_SUBMISSIONS_REQUEST })
const listAllPendingSubmissionsSuccess = (data) => ({ type: LIST_ALL_PENDING_SUBMISSIONS_SUCCESS, data })
const listAllPendingSubmissionsFailed = () => ({ type: LIST_ALL_PENDING_SUBMISSIONS_FAILED })

export const listAllPendingSubmissions = () => {
  return async dispatch => {
    dispatch(listAllPendingSubmissionsRequest())
    try {
      const { data } = await WarehouseService.listAllPendingSubmissions()
      dispatch(listAllPendingSubmissionsSuccess(data))
    } catch (e) {
      dispatch(listAllPendingSubmissionsFailed(e))
    }
  }
}

const acknowledgeItemSubmissionRequest = () => ({ type: ACKNOWLEDGE_ITEM_SUBMISSION_REQUEST })
const acknowledgeItemSubmissionSuccess = (data) => ({ type: ACKNOWLEDGE_ITEM_SUBMISSION_SUCCESS, data })
const acknowledgeItemSubmissionFailed = () => ({ type: ACKNOWLEDGE_ITEM_SUBMISSION_FAILED })

export const acknowledgeItemSubmission = ({ itemCode, quantity, destinationCode }) => {
  return async dispatch => {
    dispatch(acknowledgeItemSubmissionRequest())
    try {
      const { data, meta } = await WarehouseService.acknowledgeItemSubmission({ itemCode, quantity, destinationCode })
      dispatch(receiveEvents(meta?.eventIds))
      dispatch(acknowledgeItemSubmissionSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(acknowledgeItemSubmissionFailed())
      return Promise.reject(e)
    }
  }
}

const initState = {
  entries: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case LIST_ALL_PENDING_SUBMISSIONS_SUCCESS:
    case LIST_ITEM_SUBMISSIONS_SUCCESS:
      return {
        ...state,
        entries: transformById(action.data)
      }
    case ACKNOWLEDGE_ITEM_SUBMISSION_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.data.id]: {
            ...state.entries[action.data.id],
            ...action.data
          }
        }
      }
    case SUBMIT_ITEMS_FOR_WAREHOUSE_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          [action.data.id]: action.data
        }
      }
    case DELETE_SUBMITTED_ITEM_SUCCESS:
      return {
        ...state,
        entries: dissoc(action.handOverId, state.entries)
      }
    default:
      return state
  }
}
