import { useEffect, useState } from 'react'
import { sumBy } from 'helpers/array'

const useSumedItems = (itemsToSum, dependencyList = []) => {
  const [summedItems, setSummedItems] = useState({})
  const keys = ['productionMoq', 'moq', 'minimumQuantity', 'price.float', 'transportPrice.float', 'customsPrice.float']
  useEffect(() => {
    const sums = sumBy(keys, itemsToSum)
    setSummedItems(sums)
  }, dependencyList)

  return [summedItems, keys]
}

export default useSumedItems
