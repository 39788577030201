/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useEffect, useRef, useState } from 'react'
import 'react-table/react-table.css'
import { Box, Flex } from '@rebass/emotion'
import Checkbox from 'components/Checkbox'
import * as styles from './styles'
import * as JsSearch from 'js-search'
import { iterable, removeDuplicatesInArrayById } from 'helpers/array'
import { ReactComponent as CancelIcon } from 'assets/cancel.svg'
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'
import WarehouseItemRow from 'views/WarehouseState/WarehouseItemRow'
import { stopPropagation } from 'helpers/htmlEvents'
import { useTranslation } from 'react-i18next'
import RefreshButton from 'components/RefreshButton'
import ReactToPrint from 'react-to-print'
import ReactList from 'react-list'
import { PrintButton, PrintSelectedItems } from 'components/PrintComponents'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { ReactComponent as ListIcon } from 'assets/list.svg'
import theme from 'config/theme'
import Button from 'components/Button'
import { Roles } from 'constants/Roles'
import * as R from 'ramda'
import Select from 'react-select'

let allItemsIndex

const WarehouseState = (props) => {
  const { t } = useTranslation()
  const [selectedRows, selectRows] = useState([])
  const [expandedRow, expandRow] = useState({})
  const [searchPhrase, setPhrase] = useState('')
  const [currentItems, setCurrentItems] = useState([])
  const [loading, setLoading] = useState(false)

  const refreshList = async () => {
    try {
      setLoading(true)
      await Promise.all([
        props.getWarehouseState(),
        props.getWarehouseStateWithBlankItems()
      ])
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    allItemsIndex = new JsSearch.Search('id')
    allItemsIndex.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    allItemsIndex.addIndex(['item', 'sanitizedName'])
    allItemsIndex.addIndex('fullIndex')
    iterable(30).forEach(el => allItemsIndex.addIndex(['inventory', el, 'warehouseLocation', 'code']))
    allItemsIndex.addDocuments(props.warehouseStateAllItems)
    setCurrentItems(allItemsIndex.search(searchPhrase))
  }, [props.warehouseStateAllItems])

  useEffect(() => {
    searchPhrase.length >= 3 && setCurrentItems(allItemsIndex.search(searchPhrase))
  }, [searchPhrase])

  useEffect(() => {
    refreshList()
  }, [])

  const handleSelectRow = (id) => selectRows([...selectedRows, id])
  const handleDeSelectRow = (id) => selectRows(selectedRows.filter(item => item !== id))

  const handleCheckChange = (e) => {
    const ID = e.target.value
    !selectedRows.includes(ID) ? handleSelectRow(ID) : handleDeSelectRow(ID)
  }

  const handleToggleAll = () => selectedRows.length
    ? selectRows([])
    : selectRows(props.warehouseState.map(item => item.id))

  const isRowAboveExpanded = (index) => index === expandedRow.index - 1

  const [element, setElement] = useState(null)
  const handleSelectSingleRow = (row) => () => {
    expandRow(row)
    setTimeout(() => { setElement(document.getElementById(row.id)) }, 0)
    props.resetError()
  }

  useEffect(() => {
    element && element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [element])

  const isExpandedRow = (item) => expandedRow.id === item.id
  const handleSearch = (e) => setPhrase(e.target.value)
  const handleInputIconClick = () => searchPhrase && setPhrase('')

  const initialSelectedFiltersState = { column: null, bookstand: null, shelf: null }

  const [selectedFilters, setSelectedFilters] = useState(initialSelectedFiltersState)

  const getWarehouseStateWithFilters = (items = []) => items.filter(e => {
    return e.inventory.length > 0 ? e.inventory.some(location => {
      let code = location.warehouseLocation.code.split('-')
      let first = selectedFilters['column'] !== null ? code[0] === selectedFilters['column'] : true
      let sec = selectedFilters['bookstand'] !== null ? parseInt(code[1]) === selectedFilters['bookstand'] : true
      let thrd = selectedFilters['shelf'] !== null ? parseInt(code[2]) === selectedFilters['shelf'] : true
      return first && sec && thrd
    }) : R.equals(selectedFilters, initialSelectedFiltersState)
  })

  useEffect(() => { prepareFilters() }, [props.warehouseState])
  const [filtersObject, setFiltersObject] = useState({})
  const noFilterOption = field => [{ label: t('warehouse.filters.noFilter'), value: { value: null, name: field } }]

  const compareByString = (a, b) => a.localeCompare(b)
  const prepareFilterObjectForFilter = name => e => ({ value: { value: e, name }, label: e })
  const getInventoryColumn = e => e.split('-')[0]

  const filterOutNils = e => !isNaN(e)

  const prepareFilters = () => {
    let setOfFilters = R.uniq(props.warehouseState?.map(e => e.inventory?.reduce((acc, i) => [...acc, i.warehouseLocation.code], [])[0])).filter((a) => !R.isNil(a))
    const filtersObject = {
      column: noFilterOption('column')
        .concat(
          R.pipe(
            R.map(getInventoryColumn),
            R.sort(compareByString),
            R.map(prepareFilterObjectForFilter('column')),
            R.uniq
          )(setOfFilters)
        ),
      bookstand: noFilterOption('bookstand')
        .concat(
          R.uniq(setOfFilters.map(e => parseInt(e.split('-')[1], 10)))
            .sort((a, b) => a > b ? 1 : -1)
            .filter(filterOutNils)
            .map(prepareFilterObjectForFilter('bookstand'))),
      shelf: noFilterOption('shelf')
        .concat(
          R.uniq(setOfFilters.map(e => parseInt(e.split('-')[2], 10)))
            .sort((a, b) => a > b ? 1 : -1)
            .filter(filterOutNils)
            .map(prepareFilterObjectForFilter('shelf')))
    }
    setFiltersObject(filtersObject)
  }

  const handleFilterSelect = ({ value: { value, name } }) => setSelectedFilters({ ...selectedFilters, [name]: value })

  const handleFilterReset = () => setSelectedFilters(initialSelectedFiltersState)

  const componentRef = useRef()

  const selectedItems = props.warehouseStateAllItems.filter(e => selectedRows.includes(e.id))

  const itemsToRender = searchPhrase.length >= 3
    ? removeDuplicatesInArrayById([ ...selectedItems, ...getWarehouseStateWithFilters(currentItems) ])
    : removeDuplicatesInArrayById([ ...getWarehouseStateWithFilters(props.warehouseState), ...selectedItems ])

  const renderItem = (index, key) => {
    const item = itemsToRender[index]
    return (
      item.id &&
      <WarehouseItemRow
        key={key}
        item={item}
        error={props.error}
        resetError={props.resetError}
        index={index}
        selectedRows={selectedRows}
        handleCheckChange={handleCheckChange}
        isRowAboveExpanded={isRowAboveExpanded}
        isExpanded={isExpandedRow(item)}
        handleSelectSingleRow={handleSelectSingleRow}
        userRole={props.userRole}
        issueStocktakingForItem={props.issueStocktakingForItem}
      />
    )
  }

  const [issueStocktakingButtonColor, setButtonColor] = useState(theme.colors.black)
  const isAdminOrProductionManager = props.userRole === Roles.ADMIN || props.userRole === Roles.PRODUCTION_MANAGER

  useEffect(() => {
    const fadeColor = setTimeout(() => setButtonColor(theme.colors.black), 2000)
    return () => {
      clearTimeout(fadeColor)
    }
  }, [issueStocktakingButtonColor])

  const bulkStocktaking = async () => {
    const { warehouseState, registerMultipleIssueStocktaking } = props
    const rows = warehouseState.filter(e => selectedRows.includes(e.id)).map(e => e.fullIndex)
    if (rows.length) {
      props.resetError()
      try {
        setLoading(true)
        await registerMultipleIssueStocktaking(rows)
        setLoading(false)
        setButtonColor(theme.colors.successGreen)
      } catch (e) {
        setLoading(false)
        setButtonColor(theme.colors.error)
      }
    }
  }

  const selectedFilterGetter = parameter => filtersObject[parameter]?.find(e => e.value?.value === selectedFilters[parameter])

  return (
    <div>
      <div style={{ display: 'none' }}>
        <PrintSelectedItems selectedRows={selectedRows} warehouseState={props.warehouseState} ref={componentRef} />
      </div>
      <Box m={10}>
        <Flex width={1} flexDirection='column' flexWrap='nowrap'>
          <Flex width={1} css={styles.searchWrapperCss}>
            <Box width={3 / 8} py={10} px={8}>
              <input value={searchPhrase} onChange={handleSearch} css={styles.searchBarCss} />
              <div css={styles.resetCss} onClick={handleInputIconClick}>
                {searchPhrase
                  ? <CancelIcon {...styles.iconDimensions} />
                  : <SearchIcon {...styles.iconDimensions} />
                }
              </div>
            </Box>
            <Flex width={2 / 8}>
              <RefreshButton refreshFunction={refreshList} isLoading={loading} />
            </Flex>
            <Flex width={1 / 4}>
              <ReactToPrint trigger={() => <PrintButton>{t('weeklyPlansForeman.print')}</PrintButton>} content={() => componentRef.current} />
            </Flex>
            {isAdminOrProductionManager &&
            <Fragment>
              <Flex width={1 / 3}>
                <Button
                  color={issueStocktakingButtonColor}
                  disabled={loading}
                  withIcon
                  icon={loading ? Loader : ListIcon} iconProps={{ fill: theme.colors.white }}
                  onClick={bulkStocktaking}>
                  {t('warehouse.stocktaking.issueStocktaking')}
                </Button>
              </Flex>
            </Fragment>
            }
            <Flex width={3 / 8} p={10} alignItems='center' />
          </Flex>
          <Box css={styles.filterBoxCss}>
            {props.warehouseState && Object.keys(initialSelectedFiltersState).map(option => (
              <Box css={styles.selectOptionsCss} key={option}>
                <label>{t(`warehouse.filters.${option}`)}</label>
                <Select defaultValue={noFilterOption(option)} options={filtersObject[option]} value={selectedFilterGetter(option)} onChange={handleFilterSelect} />
              </Box>
            ))}
            <Box width={300}><Button disabled={loading} onClick={handleFilterReset}>{t('warehouse.filters.resetFilters')}</Button></Box>
          </Box>
          {(searchPhrase.length >= 3 ? currentItems : props.warehouseState).length > 0 &&
            <Flex p={6} width={2 / 3}>
              <Flex css={styles.selectAllCss}>
                <Checkbox
                  checked={selectedRows.length}
                  type='checkbox'
                  onClick={stopPropagation}
                  onChange={handleToggleAll}
                />
              </Flex>
            </Flex>
          }
          <ReactList
            itemRenderer={renderItem}
            length={itemsToRender.length}
            type='simple'
            useTranslate3d
          />
        </Flex>
      </Box>
    </div>
  )
}

export default WarehouseState
