import APIInterceptor from './APIInterceptor'

const interceptor = APIInterceptor

const LocationsService = {
  getLocationsList: () => {
    return interceptor.get(`/warehouse/locations`)
  },

  createLocation: ({ name, type, parentId }) => {
    return interceptor.post('/warehouse/locations', { name, type, parentId })
  },

  deleteLocation: ({ id }) => {
    return interceptor.delete(`/warehouse/locations/${id}`)
  }
}

export default LocationsService
