/** @jsx jsx */
import { jsx } from '@emotion/core'
import IconBox from 'components/IconBox'
import theme from 'config/theme'
import * as PropTypes from 'prop-types'
import Ink from 'react-ink'
import { ReactComponent as Loader } from 'assets/loader.svg'

const defaultAnimationTime = 500

const animatedBackground = `background-colr ${defaultAnimationTime}ms linear`

const commonStyles = {
  width: '70%',
  color: 'white',
  border: 'none',
  borderRadius: '10px',
  display: 'flex',
  position: 'relative',
  flexFlow: 'row nowrap',
  padding: '15px',
  margin: 'auto',
  alignItems: 'center',
  boxShadow: '0 0 0.25em rgba(0,0,0,0.5)',
  transition: animatedBackground
}

const buttonCss = (color = theme.colors.black) => ({
  backgroundColor: color,
  ...commonStyles
})

const disabledButtonCss = (disabledButtonColor = theme.colors.darkGrey) => ({
  backgroundColor: disabledButtonColor,
  opacity: '.5',
  cursor: 'default',
  ...commonStyles
})

const outlineButtonCss = (color = theme.colors.black) => ({
  ...commonStyles,
  backgroundColor: 'transparent',
  border: `4px solid ${color}`,
  color: color,
  boxShadow: 'none'
})

const iconContainerCss = (color = theme.colors.black) => ({
  marginRight: 10,
  backgroundColor: color,
  transition: animatedBackground
})

const buttonTextCss = props => ({
  textAlign: 'center',
  width: '100%',
  textTransform: props.omitTextTransform ? 'unset' : 'lowercase',
  fontWeight: 700
})

const getButtonStyles = (props) => {
  switch (true) {
    case props.disabled && props.outline:
      return { ...disabledButtonCss(props.color), ...outlineButtonCss(props.color) }
    case props.disabled:
      return disabledButtonCss(props.color)
    case props.outline:
      return outlineButtonCss(props.color)
    default:
      return buttonCss(props.color)
  }
}

const Button = (props) => {
  const Icon = props.icon
  const iconProps = props.iconProps || {}
  return (
    <button
      type={props.type}
      css={getButtonStyles(props)}
      id={props.id}
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled}
    >
      {!props.disabled && <Ink />}
      {props.withIcon && !props.isLoading && (
        <IconBox css={iconContainerCss(props.color)}>
          <Icon {...iconProps} />
        </IconBox>
      )}
      {props.isLoading && (
        <IconBox css={iconContainerCss(props.color)}>
          <Loader />
        </IconBox>
      )}
      <span css={buttonTextCss(props)}>{props.children}</span>
    </button>
  )
}

Button.defaultProps = {
  type: 'button',
  color: theme.colors.black,
  isLoading: false
}

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.any,
  iconProps: PropTypes.object,
  onClick: PropTypes.func,
  type: PropTypes.string,
  withIcon: PropTypes.bool,
  color: PropTypes.string,
  omitTextTransform: PropTypes.any,
  isLoading: PropTypes.bool
}

export default Button
