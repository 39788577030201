import InboundZoneService from 'services/InboundZoneService'
import { dissoc, pathOr } from 'ramda'
import { receiveEvents } from 'ducks/events'
import WarehouseService from 'services/WarehouseService'
import { transformById } from 'helpers/redux'

const SEARCH_FOR_ITEM_ON_INBOUND_ZONE = Symbol('SEARCH_FOR_ITEM_ON_INBOUND_ZONE')
const SEARCH_FOR_ITEM_ON_INBOUND_ZONE_SUCCESS = Symbol('SEARCH_FOR_ITEM_ON_INBOUND_ZONE_SUCCESS')
const SEARCH_FOR_ITEM_ON_INBOUND_ZONE_FAILED = Symbol('SEARCH_FOR_ITEM_ON_INBOUND_ZONE_FAILED')

const MOVE_ITEM_IN_INVENTORY = Symbol('MOVE_ITEM_IN_INVENTORY')
const MOVE_ITEM_IN_INVENTORY_SUCCESS = Symbol('MOVE_ITEM_IN_INVENTORY_SUCCESS')
const MOVE_ITEM_IN_INVENTORY_FAILED = Symbol('MOVE_ITEM_IN_INVENTORY_FAILED')

const requestSearchForItem = () => ({ type: SEARCH_FOR_ITEM_ON_INBOUND_ZONE })
const searchForItemSuccess = (data) => ({ type: SEARCH_FOR_ITEM_ON_INBOUND_ZONE_SUCCESS, data })
const searchForItemFailed = (error) => ({ type: SEARCH_FOR_ITEM_ON_INBOUND_ZONE_FAILED, error })

const takeError = pathOr('No error message', ['response', 'data', 'message'])

export const searchForItemOnInboundZone = (itemCode) => {
  return async dispatch => {
    dispatch(requestSearchForItem())
    try {
      const { data } = await InboundZoneService.searchForItemOnInboundZone(itemCode)
      dispatch(searchForItemSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(searchForItemFailed(takeError(e)))
      console.error(takeError(e))
      return Promise.reject(takeError(e))
    }
  }
}

const requestMoveItemInInventory = () => ({ type: MOVE_ITEM_IN_INVENTORY })
const moveItemInInventorySuccess = (data, itemId) => ({ type: MOVE_ITEM_IN_INVENTORY_SUCCESS, data, itemId })
const moveItemInInventoryFailed = (error) => ({ type: MOVE_ITEM_IN_INVENTORY_FAILED, error })

export const moveItemInInventory = ({ inventoryId, quantity, destinationCode, itemId }) => {
  return async dispatch => {
    dispatch(requestMoveItemInInventory())
    try {
      const { data, meta } = await InboundZoneService.moveItemInInventory({ inventoryId, quantity, destinationCode })
      dispatch(moveItemInInventorySuccess(data, itemId))
      dispatch(receiveEvents(meta?.eventIds))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(moveItemInInventoryFailed(e))
      console.error(takeError(e))
      return Promise.reject(takeError(e))
    }
  }
}

const GET_INBOUND_ITEMS = Symbol('GET_INBOUND_ITEMS')
const GET_INBOUND_ITEMS_SUCCESS = Symbol('GET_INBOUND_ITEMS_SUCCESS')
const GET_INBOUND_ITEMS_FAILED = Symbol('GET_INBOUND_ITEMS_FAILED')

const requestGetInboundItems = () => ({ type: GET_INBOUND_ITEMS })
const getInboundItemsSuccess = data => ({ type: GET_INBOUND_ITEMS_SUCCESS, data })
const getInboundItemsFailed = () => ({ type: GET_INBOUND_ITEMS_FAILED })

export const getInboundItems = () => {
  return async dispatch => {
    dispatch(requestGetInboundItems())
    try {
      const { data } = await WarehouseService.getWarehouseState({ location_code: 'INBOUND' })
      dispatch(getInboundItemsSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(getInboundItemsFailed(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  warehouseInboundLocation: {},
  currentItem: {},
  entries: {},
  error: null
}

export default (state = initState, action) => {
  switch (action.type) {
    case SEARCH_FOR_ITEM_ON_INBOUND_ZONE:
      return {
        ...state,
        error: null,
        currentItem: {},
        warehouseInboundLocation: {}
      }
    case SEARCH_FOR_ITEM_ON_INBOUND_ZONE_SUCCESS:
      return {
        ...state,
        currentItem: action.data.itemRevision,
        warehouseInboundLocation: dissoc('item', action.data)
      }
    case SEARCH_FOR_ITEM_ON_INBOUND_ZONE_FAILED:
      return {
        ...state,
        currentItem: {},
        warehouseInboundLocation: {},
        error: action.error
      }
    case MOVE_ITEM_IN_INVENTORY_SUCCESS:
      return {
        ...state,
        entries: dissoc(action.itemId, state.entries)
      }
    case GET_INBOUND_ITEMS_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          ...transformById(action.data)
        }
      }
    default:
      return state
  }
}
