import Users from 'views/Users/Users'
import { connect } from 'react-redux'
import { getUsers } from 'ducks/users'
import { selectUsersAsArray, selectWasFetchedBefore } from 'selectors/users'
import { getUserRoles } from 'selectors/user'

const mapStateToProps = state => ({
  users: selectUsersAsArray(state),
  wasFetched: selectWasFetchedBefore(state),
  userRoles: getUserRoles(state)
})

const mapDispatchToProps = dispatch => ({
  getUsers: () => dispatch(getUsers())
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
