import EventsService from 'services/EventsService'

const RECEIVE_EVENTS = Symbol('RECEIVE_EVENTS')

export const receiveEvents = (events) => ({ type: RECEIVE_EVENTS, events })

const REVERT_EVENTS = Symbol('REVERT_EVENTS')
const REVERT_EVENTS_SUCCESS = Symbol('REVERT_EVENTS_SUCCESS')
const REVERT_EVENTS_FAILED = Symbol('REVERT_EVENTS_FAILED')

const requestrevertEvents = () => ({ type: REVERT_EVENTS })
const revertEventsSuccess = (data) => ({ type: REVERT_EVENTS_SUCCESS, data })
const revertEventsFailed = () => ({ type: REVERT_EVENTS_FAILED })

export const revertEvents = (events) => {
  return async dispatch => {
    dispatch(requestrevertEvents())
    try {
      const { data } = await EventsService.revertEvents(events)
      return dispatch(revertEventsSuccess(data))
    } catch (e) {
      console.error(e)
      return dispatch(revertEventsFailed(e))
    }
  }
}

const RESET_EVENTS = Symbol('RESET_EVENTS')

export const resetEvents = () => ({ type: RESET_EVENTS })

const initState = {
  events: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case REVERT_EVENTS_SUCCESS:
      return {
        ...state,
        events: []
      }
    case RECEIVE_EVENTS:
      return !action.events
        ? { ...state }
        : { ...state, events: action.events
        }
    case RESET_EVENTS:
      return { ...state, events: [] }
    default:
      return state
  }
}
