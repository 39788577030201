import axios from 'axios'
import mitt from 'mitt'
import applyConverters from 'axios-case-converter'
import StorageService from './StorageService'
import { LocalStorageKeys } from 'services/StorageService'
import { includes } from 'ramda'

const events = mitt()
const retrieveData = successRes => {
  successRes?.headers?.authorization && StorageService.set(LocalStorageKeys.token, successRes?.headers?.authorization)
  const response = ({
    ...successRes,
    data: successRes?.data?.data || successRes?.data,
    meta: successRes?.data?.meta,
    assemblyPosts: successRes?.data?.assemblyPosts,
    newBomIds: successRes?.data?.newBomIds
  })
  return response
}

const authorizationError = 'authorizationError'

const receiveError = error => {
  error.response?.headers?.authorization && StorageService.set(LocalStorageKeys.token, error.response.headers.authorization)
  if (
    includes(error?.response?.data?.message, [
      'Token blacklisted.',
      'The token has been blacklisted',
      'Token expired.',
      'This action is unauthorized.',
      'Token missing.'
    ])
  ) {
    events.emit(authorizationError, { err: error })
  }
  return Promise.reject(error)
}

class APIInterceptor {
  constructor (skipConverters) {
    this.baseURL = process.env.REACT_APP_API_URL

    const settings = {
      baseURL: this.baseURL,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }

    this.api = skipConverters ? axios.create(settings) : applyConverters(axios.create(settings))
    this.api.interceptors.response.use(
      retrieveData,
      receiveError
    )
  }

  async get (url, config) {
    return this.api.get(url, await this.mergeConfig(config))
  }

  async post (url, data, config) {
    return this.api.post(url, data, await this.mergeConfig(config))
  }

  async put (url, data, config) {
    return this.api.put(url, data, await this.mergeConfig(config))
  }

  async patch (url, data, config) {
    return this.api.patch(url, data, await this.mergeConfig(config))
  }

  async delete (url, params) {
    return this.api.delete(url, await this.mergeConfig({ params }))
  }

  registerResponseInterceptor (success, error) {
    this.api.interceptors.response.use(success, error)
  }

  async mergeConfig (config = {}) {
    const token = await StorageService.get(LocalStorageKeys.token)
    return Object.assign({}, config, {
      headers: {
        Authorization: token,
        ...config.headers
      }
    })
  }
}

class APIInterceptorFactory {
  constructor (baseUrl, skipConverters) {
    this.baseURL = baseUrl || process.env.REACT_APP_API_URL

    const settings = {
      baseURL: this.baseURL
    }

    this.api = skipConverters ? axios.create(settings) : applyConverters(axios.create(settings))
    this.api.interceptors.response.use(
      retrieveData,
      receiveError
    )
  }

  async get (url, config) {
    return this.api.get(url, await this.mergeConfig(config))
  }

  async post (url, data, config) {
    return this.api.post(url, data, await this.mergeConfig(config))
  }

  async put (url, data, config) {
    return this.api.put(url, data, await this.mergeConfig(config))
  }

  async patch (url, data, config) {
    return this.api.patch(url, data, await this.mergeConfig(config))
  }

  async delete (url, params) {
    return this.api.delete(url, await this.mergeConfig({ params }))
  }

  registerResponseInterceptor (success, error) {
    this.api.interceptors.response.use(success, error)
  }

  async mergeConfig (config = {}) {
    return Object.assign({}, config, {
      headers: {
        ...config.headers
      },
      withCredentials: false
    })
  }
}

export { APIInterceptor, events, authorizationError, APIInterceptorFactory }
export default new APIInterceptor()
