/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Flex } from '@rebass/emotion'
import enbioLogo from 'assets/enbio-logo.png'
import { useTranslation } from 'react-i18next'
import theme from 'config/theme'
import { useEffect, useRef, useState } from 'react'
import { toCamelCase } from 'helpers/string'
import { ReactComponent as Loader } from 'assets/loader.svg'
import isZebraBrowser from 'helpers/isZebra'
import Button from 'components/Button'
import * as R from 'ramda'
import { errorHandler } from 'helpers/errorHandler'

const inputCss = css`
  height: 40px;
  text-align: center;
  font-size: 22px;
  color: ${theme.colors.lightBlue};
  border: 2px solid ${theme.colors.darkGrey};
  box-shadow: 2px 2px 5px ${theme.colors.lightGrey};
  width: 30px;
  margin-right: 10px;
 -webkit-text-security: disc;
 
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
`

const LoginForm = (props) => {
  const { t } = useTranslation()
  const [error, setError] = useState(null)
  const [pin, setPin] = useState('')

  const nextInput = async event => {
    const allInputs = document.querySelectorAll('.loginInput')
    const targetInputNumber = Number(event.target.dataset.number)
    const value = event.target.value
    const nextInput = allInputs[targetInputNumber + 1]
    await setPin(pin + value)

    if (nextInput) {
      // eslint-disable-next-line no-unused-expressions
      nextInput?.focus()
      nextInput.setAttribute('placeholder', '')
    }
  }

  const [visible, setVisible] = useState(true)

  const submit = async credentials => {
    setError(null)
    const allInputs = document.querySelectorAll('.loginInput')
    const { history } = props
    try {
      setVisible(false)
      await props.login({ pin: credentials })
      history.push('/')
    } catch (error) {
      setPin('')
      setVisible(true)
      errorHandler(error)
      setError(toCamelCase(R.pathOr('', ['response', 'data', 'message'], error)))
      allInputs.forEach(e => { e.value = '' })
      // eslint-disable-next-line no-unused-expressions
      allInputs[0]?.focus()
    }
  }

  const loginAs = as => () => {
    submit(as)
  }

  useEffect(() => {
    pin.length === 4 && submit(pin)
  }, [pin])

  const listenToKeys = (event) => {
    event.persist()
    let pressed = {}
    let nonUserPressedKeys = []
    event.target.addEventListener('keydown', e => {
      e.preventDefault()
      if (pressed[e.which]) return
      pressed[e.which] = e.timeStamp
    })

    event.target.addEventListener('keyup', e => {
      if (!pressed[e.which]) return
      let duration = (e.timeStamp - pressed[e.which]) / 1000
      pressed[e.which] = 0
      if (duration < 0.01 && !isZebraBrowser()) {
        nonUserPressedKeys.push(e.key)
      } else {
        event.target.value = e.key
        return /[0-9]/.test(e.key) && nextInput(e)
      }
      const rfid = nonUserPressedKeys.map(e => parseInt(e))

      if (isNaN(rfid[rfid.length - 1])) {
        rfid.splice(-1, 1)
        submit(rfid.join(''))
      }
    })
  }

  const firstInputRef = useRef(null)

  useEffect(() => {
    const listenToKeyboard = event => {
      const targetIsNotTextArea = !['textarea'].includes(event.target.nodeName.toLowerCase())
      if (!pin && firstInputRef.current && targetIsNotTextArea) {
        // eslint-disable-next-line no-unused-expressions
        firstInputRef.current?.focus()
      }
    }
    document.addEventListener('keydown', listenToKeyboard)

    return () => {
      document.removeEventListener('keydown', listenToKeyboard)
    }
  }, [pin])

  return (
    <Flex flexDirection={'column'} style={{ height: '100vh', padding: 30 }} alignItems={'center'} justifyContent={'center'}>
      <img src={enbioLogo} alt='logo' css={{ maxHeight: '30px', marginBottom: '50px' }} />
      <h2 style={{ marginBottom: '50px', color: theme.colors.darkGrey, textAlign: 'center' }}>{t('common.loginInfo')}</h2>
      {visible
        ? <Flex flexDirection='column' alignItems='center' css={{ height: 50 }}>
          <Flex>
            <input autoFocus onFocus={listenToKeys} className='loginInput' type='number' maxLength={1} data-number={0} css={inputCss} inputMode='numeric' ref={firstInputRef} />
            <input onFocus={listenToKeys} className='loginInput' type='number' maxLength={1} data-number={1} css={inputCss} />
            <input onFocus={listenToKeys} className='loginInput' type='number' maxLength={1} data-number={2} css={inputCss} />
            <input onFocus={listenToKeys} className='loginInput' type='number' maxLength={1} data-number={3} css={inputCss} />
          </Flex>
          {error ? <div style={{ color: theme.colors.error, marginTop: 20 }}>{t(`common.${error}`)}</div> : null}
        </Flex>
        : <div css={{ height: 50 }}><Loader stroke={theme.colors.darkGrey} style={{ width: 60, height: 60 }} /></div>
      }
      {process.env.NODE_ENV === 'development' &&
        <Flex>
          <Button css={{ width: 'auto', margin: 10 }} onClick={loginAs('2222')}>Brygadzista</Button>
          <Button css={{ width: 'auto', margin: 10 }} onClick={loginAs('0000')}>Admin</Button>
          <Button css={{ width: 'auto', margin: 10 }} onClick={loginAs('6666')}>Pracownik</Button>
          <Button css={{ width: 'auto', margin: 10 }} onClick={loginAs('4444')}>Kierownik</Button>
          <Button css={{ width: 'auto', margin: 10 }} onClick={loginAs('3333')}>QA</Button>
          <Button css={{ width: 'auto', margin: 10 }} onClick={loginAs('8888')}>TECHNOLOGIST</Button>
        </Flex>
      }
      <div css={{ position: 'absolute', right: 5, bottom: 5, fontSize: 12 }}>Wersja: 1.0.0</div>
    </Flex>
  )
}

export default LoginForm
