import WorkstationBomsForm from 'forms/WorkstationBomsForm'
import { connect } from 'react-redux'
import { getAllBoms } from 'ducks/bom'
import { compose } from 'redux'
import { getAllBomsAsOptions } from 'selectors/bom'
import { reduxForm } from 'redux-form'
import { toast } from 'react-toastify'
import { errorHandler } from 'helpers/errorHandler'
import { withTranslation } from 'react-i18next'
import { getWorkstations, setBomsForWorkstationById } from 'ducks/workstations'
import * as R from 'ramda'

const formName = 'WorkstationBomsForm'

const Form = reduxForm({
  form: formName,
  onSubmit: async (values, dispatch, ownProps) => {
    try {
      const payload = R.map(R.prop('value'), R.prop('boms', values))
      await dispatch(setBomsForWorkstationById(ownProps.station.id, payload))
      dispatch(getWorkstations())
      ownProps.closeModal()
      return Promise.resolve()
    } catch (e) {
      toast.error(ownProps.t('workstationEdit.editBomsFail'), { containerId: 'statuses', autoClose: 15e3 })
      errorHandler(e)
      return Promise.reject(e)
    }
  }
})(WorkstationBomsForm)

const mapStateToProps = state => ({
  allBomsAsOptions: getAllBomsAsOptions(state)
})

const mapDispatchToProps = dispatch => ({
  getAllBoms: compose(dispatch, getAllBoms)
})

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Form)
