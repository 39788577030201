import { connect } from 'react-redux'
import ReceiveSupplyScreen from 'views/ReceiveSupplyScreen'
import { getOrdersForItem } from 'ducks/ordersForItem'
import { getCurrentItem, getOrdersForItemAsArray } from 'selectors/ordersForItem'

const mapStateToProps = (state, ownProps) => ({
  orders: getOrdersForItemAsArray(state, ownProps.match.params.itemCode),
  currentItem: getCurrentItem(state),
  error: state.ordersForItem.error
})

const mapDispatchToProps = dispatch => ({
  getOrdersForItem: itemCode => dispatch(getOrdersForItem(itemCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveSupplyScreen)
