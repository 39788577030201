import { createSelector } from 'reselect'
import { propOr } from 'ramda'

const getInventories = state => state.warehouseInventories.entries

export const getInventoryById = createSelector(
  getInventories,
  (_, locationId) => locationId,
  (inventories, inventoryId) => propOr({}, inventoryId, inventories)
)
