/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState, useEffect } from 'react'
import Breadcrumb from 'components/Breadcrumb'
import { useTranslation } from 'react-i18next'
import StatusBox from 'components/StatusBox'
import Container from 'components/Container'
import LabelledDivider from 'components/LabelledDivider'
import Divider from 'components/Divider'
import QrCodeReader from 'components/QrCodeReader'
import Routes from 'constants/Routes'
import { scanParser } from 'helpers/scanParser'
import { isNil, propEq } from 'ramda'

const OnBoardItemOnWarehouse = (props) => {
  const { t } = useTranslation()
  const { sourceLocationId, quantity, destinationCode, itemCode, isMoveItem } = props.location.state
  const [status, setStatus] = useState(t('common.sending'))
  const [isLoading, setLoading] = useState(true)
  const isInternalSubmission = props.match.path === Routes.internalSubmissionFinalize

  const moveItemInInventory = async () => {
    try {
      setLoading(true)
      isInternalSubmission
        ? await props.acknowledgeItemSubmission({ itemCode, quantity, destinationCode })
        : await props.moveItemInInventory({ inventoryId: sourceLocationId, quantity, destinationCode, itemId: props.currentItem.id })
      isInternalSubmission && props.listAllPendingSubmissions()
      setStatus(t('warehouse.locationSaved'))
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setStatus(t('common.somethingWentWrong'))
    }
  }

  useEffect(() => {
    moveItemInInventory()
  }, [])

  const getRedirectPath = itemCode => {
    switch (true) {
      case isInternalSubmission:
        return `/internal-submission-view/item/${itemCode}`
      case isMoveItem:
        return `/move/select/${itemCode}`
      default:
        return `/on-boarding/item/${itemCode}`
    }
  }

  const redirectToItemView = (item) => {
    props.history.replace({
      pathname: getRedirectPath(item.fullIndex),
      state: isInternalSubmission ? { item: item } : null
    })
  }

  const onScan = scanData => {
    const parsedScan = scanParser(scanData)
    const isItemScan = parsedScan.type === 'item'
    const scannedItem = isItemScan ? props.pendingSubmissions.find(propEq('code', parsedScan.data)) : null
    !isNil(scannedItem) && redirectToItemView(scannedItem)
  }

  const item = props.currentItem

  const handleFinish = () => {
    switch (true) {
      case isInternalSubmission:
        return props.history.replace(Routes.internalSubmissionsList)
      case isMoveItem:
        return props.history.replace(Routes.moveItemsSearch)
      default:
        return props.history.replace(Routes.onBoardingSearch)
    }
  }

  const handleClickFinish = () => {
    return isInternalSubmission
      ? props.history.replace({ pathname: getRedirectPath(props.location.state.itemCode), state: { item: item } })
      : props.history.replace({ pathname: getRedirectPath(props.location.state.itemCode) })
  }

  return (
    <div>
      <Breadcrumb>{isInternalSubmission ? t('warehouse.internalSubmissionsList') : t('warehouse.onboard')}</Breadcrumb>
      <StatusBox
        withRevertButton
        isLoading={isLoading}
        onFinish={handleFinish}
        onClickFinish={handleClickFinish}
      >
        {status}
      </StatusBox>
      <Container flex>
        <h3>#{item.fullIndex}</h3>
        <h3 css={{ marginLeft: 'auto' }}>{quantity}</h3>
      </Container>
      <Container flex>
        <div>{item.name}</div>
      </Container>
      <Container>
        <LabelledDivider text={t('common.location')} noMargin />
        <h3 css={{ textAlign: 'center', padding: 30 }}>{destinationCode}</h3>
        <Divider noMargin medium />
      </Container>
      <QrCodeReader
        onScan={onScan}
        invisible
      />
    </div>
  )
}

export default OnBoardItemOnWarehouse
