/** @jsx jsx */
import { jsx } from '@emotion/core'
import theme from 'config/theme'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@rebass/emotion'
import LabelledDivider from 'components/LabelledDivider'
import * as PropTypes from 'prop-types'
import orderStatuses from 'constants/orderStatuses'
import { ReactComponent as CheckmarkInBox } from 'assets/check-mark-in-box.svg'
import { useCallback, useMemo } from 'react'

const itemNameCss = (isSelected, isReady) => ({
  color: isSelected ? theme.colors.white : 'inherit',
  fontWeight: 'bold',
  wordBreak: 'break-all',
  paddingRight: isReady ? '50px' : 'none'
})

const getColorsForWrapper = (isSelected, isReady) => {
  switch (true) {
    case isSelected:
      return {
        backgroundColor: theme.colors.darkGrey,
        color: theme.colors.white
      }
    case isReady:
      return {
        color: theme.colors.lightGrey
      }
    default: return {}
  }
}

export const orderItemWrapperCss = (isSelected, isRowAbove, isReady) => ({
  ...getColorsForWrapper(isSelected, isReady),
  position: 'relative',
  '&:after': {
    content: isRowAbove || isSelected ? null : '""',
    borderBottom: '1px solid ' + theme.colors.darkGrey,
    width: '90%',
    height: 1,
    position: 'absolute',
    left: '5%',
    bottom: isReady && 0
  }
})

const inventoriesWrapperCss = {
  display: 'grid',
  gridTemplateColumns: '2fr 2fr',
  gridColumnGap: '5px',
  gridRowGap: '5px'
}

export const checkmarkCss = {
  position: 'absolute',
  top: 63,
  right: theme.space[6]
}

const OrderItem = props => {
  const { t } = useTranslation()
  const isReady = useMemo(() => props.status === orderStatuses.READY, [props.status])
  const handleClick = useCallback(() => {
    !isReady && props.onClick()
  }, [isReady, props.onClick])

  const getRounded = num => Math.round(num * 100) / 100

  const { item } = props
  return <Box p={6} id={item.id} onClick={handleClick} css={orderItemWrapperCss(props.selected, props.rowAbove, isReady)}>
    <Flex my={6} mt={30}>
      <h3>{item.itemRevision?.fullIndex}</h3>
      <h3 css={{ marginLeft: 'auto' }}>{getRounded(item.deliveredQuantity)} / {getRounded(item.quantity)}</h3>
    </Flex>
    <Box my={6}>
      <div css={itemNameCss(props.selected, isReady)}>{item.itemRevision?.name}</div>
    </Box>
    {props.selected && <LabelledDivider text={t('common.locations')} noMargin color={theme.colors.white} />}
    {!isReady
      ? <Box my={6} css={inventoriesWrapperCss}>
        {item?.itemRevision?.inventory?.map(props.renderInventory)}
      </Box>
      : <CheckmarkInBox css={checkmarkCss} />
    }
  </Box>
}

OrderItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  renderInventory: PropTypes.func,
  rowAbove: PropTypes.bool,
  selected: PropTypes.bool,
  text: PropTypes.func
}

OrderItem.defaultProps = {
  onClick: () => {}
}

export default OrderItem
