import InternalOrderCompleteForm from 'forms/InternalOrderCompleteForm'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import i18n from 'i18next'
import { prepareInternalOrderItem } from 'ducks/internalOrderedItems'

const formName = 'CompleteInternalOrder'

let Form = reduxForm({
  form: formName,
  validate: (values, ownProps) => {
    const errors = {}
    const requiredFields = ['quantity']
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = i18n.t('common.fieldRequired')
      }
    })

    if (values['quantity'] && Number(values['quantity']) <= 0) {
      errors['quantity'] = i18n.t('common.numberMustBePositive')
    }

    const undeliveredQuantity = ownProps.location?.state?.item?.undeliveredQuantity
    const inventoryQuantity = ownProps.values.inventory_quantity

    if (values['quantity'] && (Number(values['quantity']) > inventoryQuantity)) {
      errors['quantity'] = i18n.t('warehouse.orders.notEnoughInLocation')
    }

    if (values['quantity'] && (Number(values['quantity']) > undeliveredQuantity)) {
      errors['quantity'] = i18n.t('warehouse.orders.maximumOrderRequired', { undeliveredQuantity })
    }

    return errors
  }
})(InternalOrderCompleteForm)

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    quantity: Math.min(ownProps.location?.state?.inventory.quantity, ownProps.location?.state?.item.totalUndeliveredQuantity),
    inventory_id: ownProps.location?.state?.inventory?.id,
    inventory_quantity: ownProps.location?.state?.inventory?.quantity,
    id: ownProps.location?.state?.item?.id
  },
  lastExpandedOrderId: ownProps.location?.state?.expandedOrderId
})

const mapDispatchToProps = dispatch => ({
  prepareInternalOrderItem: values => dispatch(prepareInternalOrderItem(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(Form)
