/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@rebass/emotion'
import { merge } from 'ramda'
import Ink from 'react-ink'
import moment from 'moment'
import { ReactComponent as Report } from 'assets/report.svg'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import theme from 'config/theme'
import { iconCss } from 'components/IconBox'
import Breadcrumb from 'components/Breadcrumb'
import LabelledDivider from 'components/LabelledDivider'
import StocktakingForm from 'forms/StocktakingForm'
import { sortLocationsByCode } from 'helpers/array'

const iconDimension = theme.space[11] + '%'

const wrapperCss = css`
  display: grid;
  color: ${theme.colors.white};
  background-color: ${theme.colors.darkGrey};
  grid-gap: 20px;
  grid-auto-rows: max-content;
`
const inventoryCss = css`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 3fr 2fr 1fr ;
`

const doneCheckmarkBoxCss = css`
  grid-column: 3 / 4 ;
  text-align: center;
`

const selectedInventoryCss = css`
  background-color: ${theme.colors.white};
  color: ${theme.colors.darkGrey};
  border: none;
  align-self: center;
  justify-self: center;
  width: 100%;
  padding: ${theme.space[4]}px;
  position: relative;
`

const reportButtonCss = css`
  ${selectedInventoryCss};
  display: flex;
`

const StocktakingIssueForm = (props) => {
  const { t } = useTranslation()
  const [selectedInventory, selectInventory] = useState(null)
  const [isFormOpen, openForm] = useState(false)
  useEffect(() => {
    !props.stocktakingIssue && props.history.goBack()
  })

  const handleSelectInventory = inventory => () => {
    openForm(false)
    selectInventory(inventory)
  }

  const handleOpenForm = async () => {
    await openForm(true)
  }

  const validate = values => {
    let errors = {}
    if (!values['quantity']) {
      errors['quantity'] = t('common.fieldRequired')
    }

    if (isNaN(Number(values['quantity']))) {
      errors['quantity'] = t('common.mustBeNumber')
    }

    return errors
  }

  const defaultValues = {
    quantity: selectedInventory?.quantity,
    inventoryId: selectedInventory?.id,
    stocktakingIssueId: props.stocktakingIssue?.id
  }

  const markAsDone = () => {
    props.registerInventoryStocktaking(defaultValues)
    selectInventory(null)
  }

  const handleSubmit = async (values, { setSubmitting }) => {
    await props.registerInventoryStocktaking(merge(defaultValues, values))
    openForm(false)
    selectInventory(null)
    setSubmitting(false)
  }

  return (
    <div>
      <Breadcrumb back>{t('warehouse.stocktaking.stocktaking')}</Breadcrumb>
      <Box p={6} css={wrapperCss}>
        <h3>{props.stocktakingIssue?.itemRevision.fullIndex}</h3>
        <h3>{props.stocktakingIssue?.itemRevision.name}</h3>
        <LabelledDivider text={t('common.locations')} noMargin color={theme.colors.white} />
        {sortLocationsByCode(props.stocktakingIssue?.itemRevision.inventory.filter(inv => inv.warehouseLocation.code !== 'INBOUND')).map(inventory => {
          const isSelected = selectedInventory?.id === inventory.id
          const isDone = moment(inventory.stocktakenAt).isAfter(props.stocktakingIssue.createdAt)
          return <Box css={inventoryCss} key={inventory.id}>
            <Box css={css`${isSelected && selectedInventoryCss}; `} onClick={handleSelectInventory(isDone ? null : inventory)}>
              {inventory.warehouseLocation.code} ({inventory.quantity})
            </Box>
            {isSelected &&
            <Fragment>
              <button onClick={handleOpenForm} css={reportButtonCss}>
                <Ink />
                <Report fill={theme.colors.darkGrey} width={17} height={17} />&nbsp;&nbsp;{t('common.change')}
              </button>
              <button css={selectedInventoryCss} onClick={markAsDone}>
                <Ink />
                <Checkmark css={{ width: iconDimension, height: iconDimension }} fill={theme.colors.darkGrey} />
              </button>
            </Fragment>
            }
            {isDone && <Box css={doneCheckmarkBoxCss}>
              <Checkmark css={iconCss} fill={theme.colors.white} />
            </Box>
            }
          </Box>
        })
        }
      </Box>
      {isFormOpen &&
        <StocktakingForm
          selectedInventory={selectedInventory}
          validate={validate}
          onSubmit={handleSubmit}
        />
      }
    </div>
  )
}

export default StocktakingIssueForm
