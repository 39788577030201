import { useCallback, useState } from 'react'
import * as R from 'ramda'

const useInput = (initialState = '', transformFunction = R.identity, valueKey = 'value') => {
  const [value, setValue] = useState(initialState)

  const setValueFromEvent = useCallback(eventOrValue => {
    eventOrValue.target
      ? setValue(transformFunction(eventOrValue.target[valueKey]))
      : setValue(transformFunction(eventOrValue))
  }, [setValue, valueKey])

  return [value, setValueFromEvent, setValue]
}

export default useInput
