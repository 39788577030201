import NewBomsForm from 'forms/NewBomsForm'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { getProductionStationsAsOptionsArray } from 'selectors/productionStations'
import { getProductionStations } from 'ducks/productionStations'
import { getSerialNumberFormatsIfNeeded } from 'ducks/serialNumberFormats'
import { getSerialNumberFormatsAsOptionsArray } from 'selectors/serialNumberFormats'
import { editItemRevision } from 'ducks/warehouseState'
import { updateBomFields } from 'ducks/bom'
import { compose } from 'redux'

const mapStateToProps = state => ({
  productionStations: getProductionStationsAsOptionsArray(state),
  serialNumberFormats: getSerialNumberFormatsAsOptionsArray(state)
})

const mapDispatchToProps = dispatch => ({
  getSerialNumberFormatsIfNeeded: () => dispatch(getSerialNumberFormatsIfNeeded()),
  getProductionStations: () => dispatch(getProductionStations()),
  editItemRevision: (values) => dispatch(editItemRevision(values)),
  updateBomFields: (values) => dispatch(updateBomFields(values))
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(NewBomsForm)
