import * as R from 'ramda'

const matchItem = R.match(/(?=.*-){2}^L/)

const makeItemResponse = R.pipe(
  item => R.assoc('data', item, {}),
  R.assoc('type', 'item')
)

const makeLocationResponse = R.pipe(
  R.drop(1),
  location => R.assoc('data', location, {}),
  R.assoc('type', 'location')
)

export const scanParser = (scan = {}) => R.pipe(
  R.propOr('', 'data'),
  R.ifElse(
    data => R.isEmpty(matchItem(data)),
    makeItemResponse,
    makeLocationResponse
  )
)(scan)
