/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Field, Form } from 'redux-form'
import theme from 'config/theme'
import Input from 'components/Input'
import Button from 'components/Button'
import { useTranslation } from 'react-i18next'

const SerialNumberFormatForm = ({ handleSubmit }) => {
  const { t } = useTranslation()
  return (
    <Form
      css={{
        padding: theme.space[6],
        display: 'grid',
        gridTemplateColumns: '1fr 3fr',
        alignItems: 'center',
        gridGap: 10
      }}
      onSubmit={handleSubmit}
    >
      <span>{t('serialNumberFormats.name')}:</span>
      <Field
        type='text'
        name='name'
        css={{ padding: 10, width: '100%' }}
        component={Input}
      />
      <span>{t('serialNumberFormats.format')}:</span>
      <Field
        type='text'
        name='format'
        css={{ padding: 10, width: '100%' }}
        component={Input}
      />
      <Button type='submit'>{t('serialNumberFormats.save')}</Button>
    </Form>
  )
}

export default SerialNumberFormatForm
