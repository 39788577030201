import i18n from 'i18next'
import * as R from 'ramda'

const validateSerialNumberForm = (values) => {
  const errors = {}
  const requiredFields = ['name', 'format']

  requiredFields.forEach(field => {
    if (!values[field] || R.isEmpty(values[field])) {
      errors[field] = i18n.t('serialNumberFormats.fieldRequired', { field: i18n.t('serialNumberFormats.' + field) })
    }
  })

  if (values.format) {
    try {
      // eslint-disable-next-line no-new
      new RegExp(values.format)
    } catch (e) {
      errors.format = i18n.t('serialNumberFormats.invalidFormat') + ' ' + values.format
    }
  }

  return errors
}

export default validateSerialNumberForm
