import { useEffect } from 'react'

const useFullscreen = () => {
  useEffect(() => {
    const ownDocument = document.documentElement
    !document.fullscreenElement && document.fullscreenEnabled && ownDocument.requestFullscreen()

    return () => {
      document.fullscreenElement && document.exitFullscreen()
    }
  }, [])
}

export default useFullscreen
