import ProductionStationChoose from 'views/ProductionStationChoose'
import { connect } from 'react-redux'
import { getProductionStationsAsOptionsArray } from 'selectors/productionStations'
import { getProductionStations, loginToProductionStation } from 'ducks/productionStations'
import { compose } from 'redux'

const mapStateToProps = state => ({
  productionStations: getProductionStationsAsOptionsArray(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getProductionStations: compose(dispatch, getProductionStations),
  loginToProductionStation: stationId => dispatch(loginToProductionStation(stationId))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductionStationChoose)
