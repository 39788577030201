import { connect } from 'react-redux'
import { formValueSelector, getFormMeta, reduxForm } from 'redux-form'
import i18n from 'i18next'
import MoveItemOnWarehouseForm from 'forms/MoveItemOnWarehouseForm'
import { getCurrentItem } from 'selectors/warehouseState'

const formName = 'MoveItemOnWarehouseForm'

const valuesSelector = formValueSelector(formName)

const Form = reduxForm({
  form: formName,
  validate: (values, ownProps) => {
    const errors = {}
    const requiredFields = ['quantity', 'destinationCode']
    requiredFields.forEach(field => {
      if (!values[field]) {
        errors[field] = i18n.t('common.fieldRequired')
      }
    })

    if (values['quantity'] && !(Number(values['quantity']) > 0)) {
      errors['quantity'] = i18n.t('common.numberMustBePositive')
    }

    const sourceLocationQuantity = ownProps.location.state.sourceInventory.quantity

    if (Number(values['quantity']) > sourceLocationQuantity) {
      errors['quantity'] = i18n.t('errors.form.maxCount', { number: sourceLocationQuantity })
    }

    return errors
  }
})(MoveItemOnWarehouseForm)

const mapStateToProps = (state, ownProps) => ({
  destinationCodeValue: valuesSelector(state, 'destinationCode'),
  currentItem: getCurrentItem(state),
  activeFields: getFormMeta(formName)(state),
  initialValues: {
    quantity: ownProps.location.state?.sourceInventory?.quantity
  }
})

export default connect(mapStateToProps)(Form)
