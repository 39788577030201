import { createSelector } from 'reselect'
import { pathOr } from 'ramda'

const getUserDetails = state => state.user.details
export const getUserRoles = pathOr([], ['user', 'roles'])

export const getUserId = createSelector(
  getUserDetails,
  user => user.id || ''
)

export const getUserName = createSelector(
  getUserDetails,
  user => user.name || ''
)
