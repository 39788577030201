import APIInterceptor from './APIInterceptor'

const interceptor = APIInterceptor

const InboundZoneService = {
  searchForItemOnInboundZone: (itemCode) => {
    return interceptor.get(`/warehouse/inbound/searchByItem?code=${itemCode}`)
  },

  moveItemInInventory: ({ inventoryId, quantity, destinationCode }) => {
    return interceptor.post(`/warehouse/inventory/${inventoryId}/move`, { quantity, destinationCode })
  }
}

export default InboundZoneService
