import BomsImportsService from 'services/BomsImportsService'
import * as R from 'ramda'
import { transformById } from 'helpers/redux'

const GET_BOMS_IMPORTS = Symbol('GET_BOMS_IMPORTS')
const GET_BOMS_IMPORTS_SUCCESS = Symbol('GET_BOMS_IMPORTS_SUCCESS')
const GET_BOMS_IMPORTS_FAILED = Symbol('GET_BOMS_IMPORTS_FAILED')

const getBomsImportsRequest = () => ({ type: GET_BOMS_IMPORTS })
const getBomsImportsSuccess = (data) => ({ type: GET_BOMS_IMPORTS_SUCCESS, data })
const getBomsImportsFailed = () => ({ type: GET_BOMS_IMPORTS_FAILED })

export const getBomsImports = () => {
  return async dispatch => {
    dispatch(getBomsImportsRequest())
    try {
      const { data } = await BomsImportsService.getBomsImports()
      return dispatch(getBomsImportsSuccess(data))
    } catch (e) {
      return dispatch(getBomsImportsFailed(e))
    }
  }
}

const GET_BOM_IMPORT_DETAILS = Symbol('GET_BOM_IMPORT_DETAILS')
const GET_BOM_IMPORT_DETAILS_SUCCESS = Symbol('GET_BOM_IMPORT_DETAILS_SUCCESS')
const GET_BOM_IMPORT_DETAILS_FAILED = Symbol('GET_BOM_IMPORT_DETAILS_FAILED')

const getBomImportDetailsRequest = () => ({ type: GET_BOM_IMPORT_DETAILS })
const getBomImportDetailsSuccess = (bomImportId, data) => ({ type: GET_BOM_IMPORT_DETAILS_SUCCESS, data, bomImportId })
const getBomImportDetailsFailed = () => ({ type: GET_BOM_IMPORT_DETAILS_FAILED })

export const getBomImportDetails = (bomImportId) => {
  return async dispatch => {
    dispatch(getBomImportDetailsRequest())
    try {
      const { data } = await BomsImportsService.getBomImportDetails(bomImportId)
      return dispatch(getBomImportDetailsSuccess(bomImportId, data))
    } catch (e) {
      return dispatch(getBomImportDetailsFailed(e))
    }
  }
}

const UPLOAD_BOM_IMPORT = Symbol('UPLOAD_BOM_IMPORT')
const UPLOAD_BOM_IMPORT_SUCCESS = Symbol('UPLOAD_BOM_IMPORT_SUCCESS')
const UPLOAD_BOM_IMPORT_FAILED = Symbol('UPLOAD_BOM_IMPORT_FAILED')

const uploadBomImportRequest = () => ({ type: UPLOAD_BOM_IMPORT })
const uploadBomImportSuccess = (data) => ({ type: UPLOAD_BOM_IMPORT_SUCCESS, data })
const uploadBomImportFailed = () => ({ type: UPLOAD_BOM_IMPORT_FAILED })

export const uploadBomImport = (file) => {
  return async dispatch => {
    dispatch(uploadBomImportRequest())
    try {
      const { data } = await BomsImportsService.uploadBomImport(file)
      dispatch(uploadBomImportSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(uploadBomImportFailed(e))
      return Promise.reject(e)
    }
  }
}

const SAVE_BOM_IMPORT = Symbol('SAVE_BOM_IMPORT')
const SAVE_BOM_IMPORT_SUCCESS = Symbol('SAVE_BOM_IMPORT_SUCCESS')
const SAVE_BOM_IMPORT_FAILED = Symbol('SAVE_BOM_IMPORT_FAILED')

const saveBomImportRequest = () => ({ type: SAVE_BOM_IMPORT })
const saveBomImportSuccess = (bomImportId, data) => ({ type: SAVE_BOM_IMPORT_SUCCESS, data, bomImportId })
const saveBomImportFailed = () => ({ type: SAVE_BOM_IMPORT_FAILED })

export const saveBomImport = (bomImportId, parts) => {
  return async dispatch => {
    dispatch(saveBomImportRequest())
    try {
      const { data } = await BomsImportsService.saveBomImport(bomImportId, parts)
      dispatch(saveBomImportSuccess(bomImportId, data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(saveBomImportFailed(e))
      return Promise.reject(e)
    }
  }
}

const GENERATE_BOM_FROM_IMPORT = Symbol('GENERATE_BOM_FROM_IMPORT')
export const GENERATE_BOM_FROM_IMPORT_SUCCESS = Symbol('GENERATE_BOM_FROM_IMPORT_SUCCESS')
const GENERATE_BOM_FROM_IMPORT_FAILED = Symbol('GENERATE_BOM_FROM_IMPORT_FAILED')

const generateBomFromImportRequest = () => ({ type: GENERATE_BOM_FROM_IMPORT })
const generateBomFromImportSuccess = (bomImportId, data, assemblyPosts) => ({ type: GENERATE_BOM_FROM_IMPORT_SUCCESS, data, bomImportId, assemblyPosts })
const generateBomFromImportFailed = () => ({ type: GENERATE_BOM_FROM_IMPORT_FAILED })

export const generateBomFromImport = (bomImportId) => {
  return async dispatch => {
    dispatch(generateBomFromImportRequest())
    try {
      const { data, assemblyPosts, newBomIds } = await BomsImportsService.generateBomFromImport(bomImportId)
      dispatch(generateBomFromImportSuccess(bomImportId, data, assemblyPosts, newBomIds))
      return Promise.resolve({ newBomIds, data })
    } catch (e) {
      dispatch(generateBomFromImportFailed(e))
      return Promise.reject(e)
    }
  }
}

const DELETE_BOM_IMPORT = Symbol('DELETE_BOM_IMPORT')
const DELETE_BOM_IMPORT_SUCCESS = Symbol('DELETE_BOM_IMPORT_SUCCESS')
const DELETE_BOM_IMPORT_FAILED = Symbol('DELETE_BOM_IMPORT_FAILED')

const deleteBomImportByIdRequest = () => ({ type: DELETE_BOM_IMPORT })
const deleteBomImportByIdSuccess = (bomImportId) => ({ type: DELETE_BOM_IMPORT_SUCCESS, bomImportId })
const deleteBomImportByIdFailed = () => ({ type: DELETE_BOM_IMPORT_FAILED })

export const deleteBomImportById = (bomImportId) => {
  return async dispatch => {
    dispatch(deleteBomImportByIdRequest())
    try {
      await BomsImportsService.deleteBomImportById(bomImportId)
      dispatch(deleteBomImportByIdSuccess(bomImportId))
      return Promise.resolve()
    } catch (e) {
      dispatch(deleteBomImportByIdFailed(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  entries: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_BOMS_IMPORTS_SUCCESS:
      return R.evolve({
        entries: R.mergeLeft(transformById(action.data))
      }, state)
    case GENERATE_BOM_FROM_IMPORT_SUCCESS:
    case SAVE_BOM_IMPORT_SUCCESS:
    case GET_BOM_IMPORT_DETAILS_SUCCESS:
      return R.evolve({
        entries: R.assoc(action.bomImportId, action.data)
      }, state)
    case UPLOAD_BOM_IMPORT_SUCCESS:
      return R.evolve({
        entries: R.assoc(action.data.id, action.data)
      }, state)
    case DELETE_BOM_IMPORT_SUCCESS:
      return R.evolve({
        entries: R.reject(R.propEq('id', action.bomImportId))
      }, state)
    default:
      return state
  }
}
