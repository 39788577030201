/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useState } from 'react'
import { Flex } from '@rebass/emotion'
import theme from 'config/theme'
import Button from 'components/Button'
import useOpenState from 'hooks/useOpenState'
import Modal from 'components/Modal'
import useInput from 'hooks/useInput'
import * as R from 'ramda'
import { generateNewIndex, generateNewItem } from 'helpers/items'
import { useTranslation } from 'react-i18next'

const inputsContainerCss = css({
  display: 'grid',
  gridTemplateColumns: 'max-content 1fr',
  gridGap: '12px'
})

const modalCss = css({
  display: 'flex',
  flexDirection: 'column'
})

const inputCss = css({
  borderColor: theme.colors.grey,
  borderWidth: 2
})

const errorInputCss = css({
  borderColor: theme.colors.error,
  borderWidth: 2
})

const doesNotStartsWith = R.complement(R.startsWith)

const convertToNumber = R.pipe(
  R.replace(/[^0-9]+/g, ''),
  Number,
  R.ifElse(
    R.gt(R.__, 0),
    R.identity,
    R.always(1)
  )
)

const AddItemModal = ({ onAddItem }) => {
  const { t } = useTranslation()
  const [isModalOpen, openModal, closeModal] = useOpenState(false)
  const [index, changeIndex] = useInput(generateNewIndex(), R.toUpper)
  const [name, changeName] = useInput('')
  const [quantity, changeQuantity] = useInput('', convertToNumber)
  const [errors, setErrors] = useState({})

  const resetFields = () => {
    changeIndex(generateNewIndex())
    changeName('')
    changeQuantity('')
  }

  const constructItem = () => {
    if (R.isEmpty(errors)) {
      const newItem = generateNewItem(index, name, quantity)
      onAddItem(newItem)
      resetFields()
      closeModal()
    }
  }

  const onIndexBlur = () => {
    if (doesNotStartsWith('1-0-', index) && !errors.index) {
      setErrors({
        ...errors,
        index: t('bomImports.addItem.errors.invalidIndex')
      })
    } else if (R.startsWith('1-0-', index) && errors.index) {
      setErrors(R.dissoc('index', errors))
    }
  }

  const onNameBlur = () => {
    if (!name.length && !errors.name) {
      setErrors({
        ...errors,
        name: t('bomImports.addItem.errors.invalidName')
      })
    } else if (name.length && errors.name) {
      setErrors(R.dissoc('name', errors))
    }
  }

  const onQuantityBlur = () => {
    if (!quantity && !errors.quantity) {
      setErrors({
        ...errors,
        quantity: t('bomImports.addItem.errors.invalidQuantity')
      })
    } else if (quantity && errors.quantity) {
      setErrors(R.dissoc('quantity', errors))
    }
  }

  const handleClose = () => {
    resetFields()
    closeModal()
    setErrors({})
  }

  return (
    <Fragment>
      <Button color='white' css={{ width: '95%', marginBottom: 12 }} onClick={openModal}>
        <h1 css={{ color: theme.colors.successGreen }}>+</h1>
      </Button>
      {isModalOpen && (
        <Modal
          headerTitle={t('bomImports.addItem.addNew')}
          onClose={handleClose}
          styles={modalCss}
        >
          <Flex flexDirection='column' css={{ flex: 1 }}>
            <div css={inputsContainerCss}>
              <label htmlFor='index'>
                {t('bomImports.addItem.index')}
              </label>
              <input
                type='text'
                name='index'
                value={index}
                onChange={changeIndex}
                onBlur={onIndexBlur}
                css={errors.index ? errorInputCss : inputCss}
              />
              <label htmlFor='name'>
                {t('bomImports.addItem.name')}
              </label>
              <input
                type='text'
                name='name'
                value={name}
                onChange={changeName}
                onBlur={onNameBlur}
                css={errors.name ? errorInputCss : inputCss}
              />
              <label htmlFor='quantity'>
                {t('bomImports.addItem.quantity')}
              </label>
              <input
                type='number'
                name='quantity'
                min='1'
                value={quantity}
                onChange={changeQuantity}
                onBlur={onQuantityBlur}
                css={errors.quantity ? errorInputCss : inputCss}
              />
            </div>
            <Button disabled={!R.isEmpty(errors)} onClick={constructItem} css={{ margin: 'auto auto 0 auto' }}>
              {t('bomImports.addItem.create')}
            </Button>
          </Flex>
        </Modal>
      )}
    </Fragment>
  )
}

export default AddItemModal
