/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { includesInArray } from 'helpers/array'
import { Roles } from 'constants/Roles'
import { normalCellCss } from 'views/Catalogue/styles'
import RenderCell from 'views/Catalogue/RenderCell'
import BomLink from 'components/BomLink'
import { transformById } from 'helpers/redux'
import Tooltip from 'components/Tooltip'

const bomColumn = (item, index, userRoles, items, historyPush) => {
  const firstBom = item.partOf?.[0]
  return includesInArray([Roles.ADMIN, Roles.FOREMAN, Roles.PRODUCTION_MANAGER, Roles.SUPPLY], userRoles)
    ? [
      <RenderCell key={'partOf' + index} css={normalCellCss}>
        {firstBom &&
        <Tooltip
          content={item.partOf?.map((part, i) =>
            <BomLink
              itemId={item.id}
              partOf={part}
              key={i + '_part_' + part + '_of_' + item.fullIndex}
              items={items ? transformById(items) : {}}
              historyPush={historyPush}
            />
          )}
          position={'bottom'}
          interactive
        >
          <BomLink partOf={firstBom} itemId={item.id} items={items ? transformById(items) : {}} historyPush={historyPush} />
        </Tooltip>
        }
      </RenderCell>
    ] : []
}

const deliveryColumns = (item, index, userRoles, showDelivery) => {
  const canEdit = includesInArray([Roles.ADMIN, Roles.SUPPLY], userRoles)
  if (includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.SUPPLY], userRoles)) {
    return showDelivery
      ? [
        <RenderCell item={item} key={'defaultVendor' + index} css={normalCellCss} editable={canEdit} fieldName={'defaultVendorId'}>
          {item.defaultVendor?.name}
        </RenderCell>,
        <RenderCell item={item} key={'productionMoq' + index} css={normalCellCss} editable={canEdit} fieldName='productionMoq'>
          {item.productionMoq}
        </RenderCell>,
        <RenderCell item={item} key={'moq' + index} css={normalCellCss} editable={canEdit} fieldName='moq'>
          {item.moq}
        </RenderCell>,
        <RenderCell item={item} key={'minQuantity' + index} css={normalCellCss} editable={canEdit} fieldName='minimumQuantity'>
          {item.minimumQuantity}
        </RenderCell>,
        <RenderCell item={item} key={'leadTime' + index} css={normalCellCss} editable={canEdit} fieldName='leadTime'>
          {item.leadTime}
        </RenderCell>,
        <RenderCell item={item} key={'leadTimeMargin' + index} css={normalCellCss} editable={canEdit} fieldName='leadTimeMargin'>
          {item.leadTimeMargin}
        </RenderCell>,
        <RenderCell item={item} key={'averageLeadTime' + index} css={normalCellCss}>
          {item.averageLeadTime}
        </RenderCell>,
        <RenderCell item={item} key={'packingQuantity' + index} css={normalCellCss} editable={canEdit} fieldName='packingQuantity'>
          {item.packingQuantity}
        </RenderCell>,
        <RenderCell item={item} key={'itemsPerUnit' + index} css={normalCellCss} editable={canEdit} fieldName='itemsPerUnit'>
          {item.itemsPerUnit}
        </RenderCell>,
        <RenderCell item={item} key={'preferredWarehouseLocationId' + index} css={normalCellCss}>
          {item.preferredWarehouseLocationId}
        </RenderCell>,
        <RenderCell item={item} key={'price' + index} css={normalCellCss} editable={canEdit} fieldName='price'>
          {item.price?.float?.toString()} {item.price?.currency}
        </RenderCell>,
        <RenderCell item={item} key={'paymentConditions' + index} css={normalCellCss} editable={canEdit} fieldName='paymentConditions'>
          {item.paymentConditions}
        </RenderCell>,
        <RenderCell item={item} key={'transportPrice' + index} css={normalCellCss} editable={canEdit} fieldName='transportPrice'>
          {item.transportPrice?.float?.toString()} {item.transportPrice?.currency}
        </RenderCell>,
        <RenderCell item={item} key={'customsPrice' + index} css={normalCellCss} editable={canEdit} fieldName='customsPrice'>
          {item.customsPrice?.float?.toString()} {item.customsPrice?.currency}
        </RenderCell>,
        <RenderCell item={item} key={'deliveryConditions' + index} css={normalCellCss}>
          {item.deliveryConditions}
        </RenderCell>
      ] : [
        <RenderCell item={item} key={'empty-cell-delivery'} css={normalCellCss} />
      ]
  }
}

const qualityAssuranceColumns = (item, index, userRoles, showQa) => {
  const canEdit = includesInArray([Roles.ADMIN, Roles.QUALITY_ASSURANCE, Roles.PRODUCTION_MANAGER], userRoles)
  if (includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.QUALITY_ASSURANCE, Roles.SUPPLY, Roles.FOREMAN], userRoles)) {
    return showQa
      ? [
        <RenderCell item={item} key={'contract' + index} css={normalCellCss}>
          {item.contract}
        </RenderCell>,
        <RenderCell item={item} key={'qualityBook' + index} css={normalCellCss}>
          {item.qualityBook}
        </RenderCell>,
        <RenderCell item={item} key={'instruction' + index} css={normalCellCss}>
          {item.instruction}
        </RenderCell>,
        <RenderCell item={item} key={'entryControl' + index} css={normalCellCss} editable={canEdit} fieldName='entryControl'>
          {item.entryControl}
        </RenderCell>,
        <RenderCell item={item} key={'currentComplaintsLevel' + index} css={normalCellCss}>
          {item.currentComplaintsLevel}
        </RenderCell>,
        <RenderCell item={item} key={'productControl' + index} css={normalCellCss} editable={canEdit} fieldName='productControl'>
          {item.productControl}
        </RenderCell>
      ] : [
        <RenderCell item={item} key={'empty-cell-qa'} css={normalCellCss} />
      ]
  }
}

const productionColumns = (item, index, userRoles, openColorModal, openAlternativesModal, openFilesModal, showProduction) => {
  const canEdit = includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER], userRoles)
  const isItemProduct = item.group === 'Produkt'
  const getColorsCellValue = () => {
    if (isItemProduct) {
      return item.activeColorVersionsCount
    } else {
      return item.hasColorVersions
        ? 'tak'
        : '---'
    }
  }

  const customCellWrapperStyles = css`display: flex; justify-content: center; width: 100%;`
  const customCellButtonStyles = css`border: 0px transparent;`

  if (includesInArray([Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.SUPPLY], userRoles)) {
    console.log({ showProduction })
    return showProduction
      ? [
        <RenderCell item={item} key={'currentNokLevel' + index} css={normalCellCss}>
          {item.currentNokLevel}
        </RenderCell>,
        <RenderCell item={item} key={'assemblyPost' + index} css={normalCellCss} editable={canEdit} fieldName='assemblyPost'>
          {item.assemblyPost}
        </RenderCell>,
        <RenderCell item={item} key={'assemblyTime' + index} css={normalCellCss} editable={canEdit} fieldName='assemblyTime'>
          {item.assemblyTime}
        </RenderCell>,
        <RenderCell item={item} key={'currentAssemblyEfficiency' + index} css={normalCellCss}>
          {item.currentAssemblyEfficiency}
        </RenderCell>,
        <RenderCell item={item} key={'pieceworkRate' + index} css={normalCellCss} editable={canEdit} fieldName='pieceworkRate'>
          {item.pieceworkRate?.float}
        </RenderCell>,
        <RenderCell item={item} key={'pieceworkRateNok' + index} css={normalCellCss} editable={canEdit} fieldName='pieceworkRateNok'>
          {item.pieceworkRateNok?.float}
        </RenderCell>,
        <RenderCell item={item} key={'serialNumberFormat' + index} css={normalCellCss} editable={includesInArray([Roles.ADMIN], userRoles)} fieldName='serialNumberFormat'>
          {item.serialNumberFormat?.name}
        </RenderCell>,
        <RenderCell>
          <div css={customCellWrapperStyles}>
            <button css={customCellButtonStyles} onClick={openAlternativesModal}>{item?.alternatives?.length}</button>
          </div>
        </RenderCell>,
        <RenderCell>
          <div css={customCellWrapperStyles}>
            <button css={customCellButtonStyles} onClick={openColorModal}>{getColorsCellValue()}
            </button>
          </div>
        </RenderCell>,
        <RenderCell>
          <div css={customCellWrapperStyles}>
            <button css={customCellButtonStyles} onClick={openFilesModal}>
              {item?.files?.length}
            </button>
          </div>
        </RenderCell>
      ]
      : [
        <RenderCell item={item} key={'empty-cell-production'} css={normalCellCss} />
      ]
  } else {
    return []
  }
}

export const getColumns = ({ item = {}, index, userRoles, items, historyPush, openColorModal, openAlternativesModal, openFilesModal, showDelivery = true, showQa = true, showProduction = true }) =>
  bomColumn(item, index, userRoles, items, historyPush)
    .concat(deliveryColumns(item, index, userRoles, showDelivery))
    .concat(qualityAssuranceColumns(item, index, userRoles, showQa))
    .concat(productionColumns(item, index, userRoles, openColorModal, openAlternativesModal, openFilesModal, showProduction))
