/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import theme from 'config/theme'
import Container from 'components/Container'
import { useTranslation } from 'react-i18next'
import { Fragment } from 'react'
import * as R from 'ramda'

const textCss = {
  fontSize: theme.space[5],
  margin: '0'
}

const ItemDescription = (props) => {
  const { t } = useTranslation()
  const { item } = props
  // APICHANGES ? FIND USAGE IN APP ITEM.ITEM.DEFAULTVENDOR
  return (
    <div
      css={{ backgroundColor: theme.colors.darkGrey, display: 'flex', color: theme.colors.white, padding: '10px 0' }}>
      <Container>
        <h3 css={{ marginBottom: 15 }}>
          {props.error ? t(props.error) : `#${R.propOr('', 'fullIndex', item)}`}
        </h3>
        {!props.error && <Fragment>
          <p css={textCss}>
            {R.pathOr('', ['name'], item)}
          </p>
          {item?.item?.defaultVendor &&
          <p css={{ ...textCss, marginTop: 15 }}>
            {`${item?.item?.defaultVendor?.name}`}
          </p>
          }
        </Fragment>}
      </Container>
    </div>
  )
}

ItemDescription.propTypes = {
  item: PropTypes.object.isRequired
}

export default ItemDescription
