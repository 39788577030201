import { createSelector } from 'reselect'
import { transformToArray, transformById } from 'helpers/redux'
import * as R from 'ramda'
import moment from 'moment'
import StorageService, { LocalStorageKeys } from 'services/StorageService'
import { checkIsServiceAllowedByStationId } from 'helpers/isServiceAllowed'

export const getProductionStations = state => state.productionStations.entries
const getDailyPlans = state => state.productionStations.dailyPlans
const getWeeklyAndDailyPlans = state => state.productionStations.plans
const getStationPlan = state => state.productionStations.currentStationPlans

export const getProductionStationsAsArray = createSelector(
  getProductionStations,
  transformToArray
)

export const getDailyPlansAsArray = createSelector(
  getDailyPlans,
  transformToArray
)

export const getProductionStationsAsOptionsArray = createSelector(
  getProductionStations,
  stations => {
    const stationsArray = transformToArray(stations)

    return stationsArray.reduce((allStations, nextStation) => {
      const mappedStation = nextStation.subPosts?.length
        ? nextStation.subPosts.map(post => ({
          value: post.id,
          label: post.number
            ? `${post.number} ${post.name} ${post.subindex}`
            : `${post.name} ${post.subindex}`
        }))
        : [{ value: nextStation.id, label: nextStation.number ? nextStation.number + ' ' + nextStation.name : nextStation.name }]
      return [...allStations, ...mappedStation]
    }, [])
  }
)

export const getProductionStationsWithSubstations = createSelector(
  getProductionStations,
  R.pipe(
    transformToArray,
    R.reduce(
      (allStations, nextStation) => {
        const mappedStation = nextStation.subPosts?.length
          ? nextStation.subPosts
          : [nextStation]
        return [...allStations, ...mappedStation]
      }, []),
    transformById
  )
)

export const getWeeklyAndDailyPlansForWeekAsArray = createSelector(
  getWeeklyAndDailyPlans,
  R.map(transformToArray)
)

export const getCurrentStationPlans = createSelector(
  getStationPlan,
  R.pipe(
    R.filter(
      R.propEq('day', String(moment().day()))
    ),
    transformToArray,
    R.sortBy(R.prop('sortOrder'))
  )
)

export const isCurrentStationServiceAllowed = createSelector(
  state => state.user.roles,
  getProductionStationsAsArray,
  (userRoles, productionStations) => {
    const stationId = StorageService.get(LocalStorageKeys.stationId)
    const substations = R.flatten(R.map(R.propOr([], 'subPosts'), productionStations))
    const productionStationsWithSubstations = R.concat(substations, productionStations)

    return checkIsServiceAllowedByStationId(productionStationsWithSubstations, stationId)
  }
)
