/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@rebass/emotion'
import theme from 'config/theme'
import Button from 'components/Button'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { ReactComponent as Order } from 'assets/order.svg'
import moment from 'moment'

const HandOverModal = ({ row, submitItemsForWarehouse, onClose, getBomItemsQuantities, getDailyBomItems }) => {
  const [handOverQuantity, setHandOverQuantity] = useState(1)

  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const handleConfirm = async () => {
    submitItemsForWarehouse({
      code: row.original.fullIndex,
      quantity: handOverQuantity
    })
    setLoading(true)
    await getBomItemsQuantities()
    await getDailyBomItems(moment().week())
    setLoading(false)
    onClose()
  }

  return (
    <Box css={{ backgroundColor: theme.colors.white, boxShadow: `1px 1px 15px ${theme.colors.darkGrey}`, position: 'relative', padding: theme.space[8] }}>
      <h2>{t('weeklyPlansForeman.modal.handOverModalTitle')}</h2>
      <div onClick={onClose} style={{ position: 'absolute', top: 10, right: 10, fontSize: 24, cursor: 'pointer' }}>X</div>
      <Box my={4}>
        <Box mb={3} style={{ fontWeight: 700 }}>{row.original.name}</Box>
        <Flex mt={2}>
          <Box>
            <label style={{ fontWeight: 700 }} htmlFor='handOverQuantityInput'>{t('weeklyPlansForeman.modal.quantity')}</label>
            <input
              style={{ padding: '10px 5px', border: `2px solid ${theme.colors.darkGrey}`, borderRadius: '7.5px' }}
              value={handOverQuantity}
              min={1}
              onChange={e => setHandOverQuantity(e.target.value)}
              type='number'
              name='handOverQuantityInput'
            />
          </Box>
        </Flex>
      </Box>
      <Flex justifyContent={'flex-end'} my={3}>
        <Box width={1 / 2}>
          <Button
            withIcon
            color={theme.colors.lightBlue}
            icon={loading ? Loader : Order}
            disabled={loading}
            iconProps={{
              style: {
                fill: theme.colors.white
              }
            }}
            onClick={handleConfirm}
          >
            {t('weeklyPlansForeman.modal.confirm')}
          </Button>
        </Box>
      </Flex>
    </Box>
  )
}

export default HandOverModal
