/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useEffect, useState, Fragment, useCallback, memo, useMemo } from 'react'
import Breadcrumb from 'components/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@rebass/emotion'
import LabelledDivider from 'components/LabelledDivider'
import theme from 'config/theme'
import Routes from 'constants/Routes'
import OrderItem from 'components/OrderItem'
import QrCodeReader from 'components/QrCodeReader'
import { getItemFromTheList } from 'helpers/items'
import { scanParser } from 'helpers/scanParser'
import orderStatuses from 'constants/orderStatuses'
import { sortLocationsByCode } from 'helpers/array'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import * as R from 'ramda'

const locationCss = {
  border: '.5px solid ' + theme.colors.white
}

const componentAgregatorCss = css`
  width: 100%;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  margin-bottom: 15px;
`

const componentNameTextCss = {
  fontWeight: 'bold',
  fontSize: `${20}px`,
  textTransform: 'uppercase',
  minWidth: 'fit-content',
  wordBreak: 'break-word'
}

const Item = withRouter(
  memo(
    ({ onItemClick, item, isSelected, status, history, index, rowAboveSelected, expandedOrderId }) => {
      const handleLocationSelect = useCallback((inventory) => (e) => {
        e.stopPropagation()
        history.replace({
          pathname: Routes.orderComplete,
          state: { inventory, item: item, expandedOrderId }
        })
      }, [item])

      const handleItemClick = useCallback(() => onItemClick(R.assoc('index', index, item)), [item])

      return (
        <OrderItem
          onClick={handleItemClick}
          selected={isSelected}
          rowAbove={rowAboveSelected}
          status={status}
          item={item}
          dailyDemand={item.dueDates}
          renderInventory={
            inventory =>
              <Box
                onClick={isSelected ? handleLocationSelect(inventory) : undefined}
                as='h4'
                key={inventory.id}
                css={isSelected && locationCss}
                p={3}
              >
                {inventory.warehouseLocation.code} ({inventory.quantity})
              </Box>
          }
        />
      )
    })
)

const ItemsList = memo(({ components, expandedItemsDate, expandedOrderStatus, status, itemsDate, selectedOrderItem, onItemClick, expandedOrderId, handleOrderExpanded }) => {
  const getItemsWithSortedLocations = items => items.map(item => ({
    ...item,
    inventory: sortLocationsByCode(item.itemRevision.inventory)
  }))

  // this is function which sorts componentsArray by id's of assembly parts to which they are needed
  // as i needed to keep some kind of order in displaying groups of parts. Can be improved in future
  const sortingFnByIds = (a, b) => a.component.id >= b.component.id ? 1 : -1
  const getSortedComponents = componentsArray => componentsArray.sort(sortingFnByIds)
  const componentsSortedByMainPartIds = getSortedComponents(components)

  return componentsSortedByMainPartIds.map(({ component, parts }, componentIndex) => {
    const componentName = component.name
    const componentId = component.id
    const partsArray = Object.values(parts)
    const componentPartsWithSortedLocations = getItemsWithSortedLocations(partsArray)
    const isGrouppedOrderExpanded = expandedOrderId === component.id && (expandedItemsDate === 'all' || itemsDate === expandedItemsDate) && expandedOrderStatus === status

    return (
      <Flex css={{ width: '100%', flexDirection: 'column' }} key={componentName + componentId}>
        <Flex css={{ width: '100%', flexDirection: 'column' }} onClick={() => handleOrderExpanded({ id: component.id, date: itemsDate, orderStatus: status })}>
          <LabelledDivider thickness={'thick'} text={'#' + (componentIndex + 1)} />
          <LabelledDivider thickness={'thick'} textCss={componentNameTextCss} color={'transparent'} text={componentName} />
        </Flex>
        { isGrouppedOrderExpanded && componentPartsWithSortedLocations.map((item, index) => (
          <Item
            key={item.id}
            expandedOrderId={expandedOrderId}
            isSelected={selectedOrderItem?.id === item.id}
            rowAboveSelected={selectedOrderItem?.index - 1 === index}
            item={item}
            index={index}
            status={status}
            onItemClick={onItemClick}
          />
        ))
        }
      </Flex>
    )
  })
})

const MainComponentsSectionList = memo((props) => {
  const { items, expandedItemsDate, expandedOrderStatus, selectedOrderItem, onItemClick, expandedOrderId, handleOrderExpanded, redirectOnScanFn, enableScan } = props

  const itemsWithPath = useMemo(() => Object.entries(items).reduce((newItems, [date, items]) => {
    items.forEach(({ component, parts }, indexInDate) => {
      Object.entries(parts).forEach(([partId, part]) => {
        newItems.push(R.pipe(
          R.assoc('pathToItem', [date, indexInDate, partId]),
          R.assoc('parentComponent', component),
          R.assoc('inventory', sortLocationsByCode(part.itemRevision.inventory))
        )(part))
      })
    })
    return newItems
  }, []), [items])

  const handleScanEvent = async scanData => {
    const parsedScan = scanParser(scanData)
    if (parsedScan.type === 'item') {
      const scannedItem = itemsWithPath.find(R.pathEq(['itemRevision', 'fullIndex'], parsedScan.data))

      if (scannedItem && (selectedOrderItem?.id !== scannedItem?.id)) {
        const orderToExpand = R.path(['parentComponent', 'id'], scannedItem)
        if (orderToExpand !== expandedOrderId) {
          await handleOrderExpanded({ id: orderToExpand, date: 'all', orderStatus: orderStatuses.PENDING }) // date all as scan should expand all items, expected to expand and to scroll into is item with pending status
        }
        await onItemClick(scannedItem)
        const element = document.getElementById(scannedItem.id)
        element && element.scrollIntoView({ behavior: 'smooth', block: 'center' })
      }
    }

    if (parsedScan.type === 'location' && !R.isNil(selectedOrderItem)) {
      const specificInventory = getItemFromTheList(parsedScan.data, ['warehouseLocation', 'code'])(R.prop('inventory', selectedOrderItem))
      specificInventory && redirectOnScanFn({
        pathname: Routes.orderComplete,
        state: { inventory: specificInventory, item: selectedOrderItem }
      })
    }
  }

  return (
    <Fragment>
      {enableScan && <QrCodeReader onScan={handleScanEvent} invisible />}
      <Flex css={componentAgregatorCss}>
        {Object.entries(items).map(([date, items]) => {
          const dateMoment = moment(date.split('_').join('-'))
          const currentDate = moment()
          const isNextWeek = dateMoment.week() === currentDate.week() + 1

          return (
            <Fragment key={date + items.reduce(R.add(R.path(['component', 'id'])), '')}>
              <LabelledDivider
                thickness={'thick'}
                text={isNextWeek ? dateMoment.format('DD.MM') : dateMoment.format('dddd')}
              />
              <ItemsList
                expandedOrderStatus={expandedOrderStatus}
                components={items}
                itemsDate={date}
                expandedItemsDate={expandedItemsDate}
                status={props.status}
                selectedOrderItem={selectedOrderItem}
                onItemClick={onItemClick}
                expandedOrderId={expandedOrderId}
                handleOrderExpanded={handleOrderExpanded}
              />
            </Fragment>
          )
        })}
      </Flex>
    </Fragment>
  )
})

const DateSectionList = memo((props) => {
  const { expandedOrderId, expandedOrderStatus, expandedItemsDate, pending, ready, selectedOrderItem, onItemClick, handleOrderExpanded, redirectOnScanFn } = props
  return (
    <Fragment>
      <MainComponentsSectionList
        expandedOrderStatus={expandedOrderStatus}
        expandedItemsDate={expandedItemsDate}
        status={orderStatuses.PENDING}
        items={pending}
        selectedOrderItem={selectedOrderItem}
        onItemClick={onItemClick}
        handleOrderExpanded={handleOrderExpanded}
        expandedOrderId={expandedOrderId}
        redirectOnScanFn={redirectOnScanFn}
        enableScan
      />
      <LabelledDivider thickness={'thick'} text='Zebrane zamówienia' />
      <MainComponentsSectionList
        expandedOrderStatus={expandedOrderStatus}
        expandedItemsDate={expandedItemsDate}
        status={orderStatuses.READY}
        items={ready}
        selectedOrderItem={selectedOrderItem}
        onItemClick={onItemClick}
        handleOrderExpanded={handleOrderExpanded}
        expandedOrderId={expandedOrderId}
      />
    </Fragment>
  )
})

const InternalOrdersList = (props) => {
  const { t } = useTranslation()
  const [selectedOrderItem, selectOrderItem] = useState(null)
  const [expandedOrderId, setExpandedOrderId] = useState(props.location.state?.recentlyOpenedOrderItemId || '')
  const [expandedItemsDate, setExpandedItemsDate] = useState('')
  const [expandedOrderStatus, setExpandedOrderStatus] = useState('')

  const handleOrderItemSelection = useCallback(orderItem => {
    selectOrderItem(orderItem)
  }, [])

  useEffect(() => {
    props.getAllInternallyOrderedItems()
  }, [])

  const handleOrderExpanded = ({ id, date, orderStatus }) => {
    if (expandedOrderStatus !== orderStatus) {
      setExpandedOrderStatus(orderStatus)
      setExpandedOrderId(id)
      setExpandedItemsDate(date)
    } else {
      if (expandedItemsDate === date) {
        if (expandedOrderId === id) {
          setExpandedItemsDate('')
          setExpandedOrderId('')
          setExpandedOrderStatus('')
        } else {
          setExpandedOrderId(id)
        }
      } else if (expandedItemsDate !== date) {
        // contains 'all' date ( case when item is scanned )
        setExpandedItemsDate(date)
        setExpandedOrderId(id)
      }
    }
  }

  const windowLocationReplaceHandler = ({ pathname, state = {} }) => {
    props.history.replace({
      pathname,
      state
    })
  }

  return (
    <div>
      <Breadcrumb css={{ marginBottom: 30 }}>{t('warehouse.orders.internalOrders')}</Breadcrumb>
      <DateSectionList
        expandedOrderStatus={expandedOrderStatus}
        expandedItemsDate={expandedItemsDate}
        pending={props.items.pending}
        ready={props.items.ready}
        selectedOrderItem={selectedOrderItem}
        onItemClick={handleOrderItemSelection}
        expandedOrderId={expandedOrderId}
        handleOrderExpanded={handleOrderExpanded}
        redirectOnScanFn={windowLocationReplaceHandler}
      />
    </div>
  )
}

export default InternalOrdersList
