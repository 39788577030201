import StocktakingIssueForm from 'forms/StocktakingIssueForm'
import { connect } from 'react-redux'
import { registerInventoryStocktaking } from 'ducks/stocktaking'
import { getStocktakingIssueById } from 'selectors/stocktakingIssues'

const mapStateToProps = (state, ownProps) => ({
  stocktakingIssue: getStocktakingIssueById(state, ownProps.match.params.stocktakingIssueId)
})

const mapDispatchToProps = dispatch => ({
  registerInventoryStocktaking: (values) => dispatch(registerInventoryStocktaking(values))
})

export default connect(mapStateToProps, mapDispatchToProps)(StocktakingIssueForm)
