/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Breadcrumb from 'components/Breadcrumb'
import ItemDescription from 'components/ItemDescription'
import LabelledDivider from 'components/LabelledDivider'
import InventoryTabs from 'components/InventoryTabs'
import LoadingOverlay from 'components/LoadingOverlay'

const MoveItemSelectView = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const fetchData = useCallback(async () => {
    setLoading(true)
    await props.getInventoryStateForItem(props.match.params.itemCode)
    setLoading(false)
  }, [props.match.params.itemCode])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const onInventoryTap = (sourceInventory) => {
    props.history.push(`/move/${props.match.params.itemCode}`, { sourceInventory })
  }

  const inventories = props.currentItem?.inventory || []

  return (
    <div>
      <Breadcrumb>{t('warehouse.moveItems')}</Breadcrumb>
      {loading && <LoadingOverlay />}
      <ItemDescription item={props.currentItem} />
      <LabelledDivider text={t('common.locations')} />
      <InventoryTabs
        inventories={inventories}
        currentItem={props.currentItem}
        onInventoryTap={onInventoryTap}
        isMoveItemScreen
      />
    </div>
  )
}
export default MoveItemSelectView
