/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState } from 'react'
import { Box, Flex } from '@rebass/emotion'
import { useTranslation } from 'react-i18next'
import RefreshButton from 'components/RefreshButton'
import StocktakingItem from 'components/StocktakingItem'

const StocktakingIssuesList = (props) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const refreshList = async () => {
    try {
      setLoading(true)
      await props.getStocktakingIssues()
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  useEffect(() => {
    refreshList()
  }, [])

  return (
    <Box m={10}>
      <Flex width={1}>
        <Box width={3 / 8} p={10} style={{ fontWeight: 700 }}>{t('warehouse.stocktaking.stocktakingList')}</Box>
        <Flex width={2 / 8}><RefreshButton refreshFunction={refreshList} isLoading={loading} /></Flex>
      </Flex>
      <Flex width={1} flexDirection='column' flexWrap='nowrap'>
        {props.stocktakingIssues.map(stocktakingIssue => {
          return <StocktakingItem
            key={stocktakingIssue.id}
            delete={props.deleteStocktakingOrder}
            stocktakingIssue={stocktakingIssue}
          />
        })}
      </Flex>
    </Box>
  )
}

export default StocktakingIssuesList
