/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect } from 'react'
import { Field, Form } from 'redux-form'
import { Flex, Box } from '@rebass/emotion'
import Select from 'react-select'
import Button from 'components/Button'
import theme from 'config/theme'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'

export const renderMultiSelectField = ({ options, input }) => {
  return (
    <Select
      options={options}
      isMulti
      {...input}
      closeMenuOnSelect={false}
      css={{ maxWidth: 800 }}
      onBlur={() => input.onBlur(input.value)}
    />
  )
}

const WorkstationBomsForm = ({ allBomsAsOptions, getAllBoms, initialValues, bomsValues, submitting, t, submit }) => {
  useEffect(() => {
    getAllBoms()
  }, [])

  return (
    <Form css={{ flex: 1, display: 'flex', flexFlow: 'column nowrap', minHeight: 'inherit' }}>
      <Flex>
        <Box flex={1}>
          <Field
            name={'boms'}
            component={renderMultiSelectField}
            options={allBomsAsOptions}
          />
        </Box>
      </Flex>
      <Flex justifyContent={'space-between'} flexDirection={'row'} flexWrap={'nowrap'} pt={8} mt={'auto'}>
        <div css={{ flexGrow: 3 }}>
          <Button
            color={theme.colors.navyBlue}
            onClick={submit}
            disabled={submitting}
            withIcon
            icon={submitting ? Loader : Checkmark}
            iconProps={{ fill: theme.colors.white }}
          >
            {t(`workstationEdit.save`)}
          </Button>
        </div>
      </Flex>
    </Form>
  )
}

export default WorkstationBomsForm
