/** @jsx jsx */
import * as PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import { useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Container from 'components/Container'
import Breadcrumb from 'components/Breadcrumb'
import LabelledDivider from 'components/LabelledDivider'
import theme from 'config/theme'
import { ReactComponent as ScanIcon } from 'assets/scan-icon.svg'
import { scanParser } from 'helpers/scanParser'
import scriptLoader from 'react-async-script-loader'

const scanIconCss = (noHeight) => ({
  display: 'flex',
  height: !noHeight && `calc(100vh - 100px)`,
  alignItems: 'center',
  justifyContent: 'center'
})

export const textCss = {
  color: theme.colors.grey,
  display: 'flex',
  margin: '10px 0',
  flexFlow: 'row nowrap',
  fontWeight: 'bold'
}

function QrCodeReader (props) {
  const { t } = useTranslation()
  const scanReceived = (params) => {
    if (props.onScan) {
      return props.onScan(params)
    }
    const scan = scanParser(params)
    if (scan.type === 'item' && props.destination) {
      return props.history[props.method](`${props.destination}/${scan.data}`)
    }
  }

  const { onScan } = props

  useEffect(() => {
    window.EB && window.EB.Barcode.enable({ allDecoders: true }, scanReceived)
    return () => {
      window.EB && window.EB.Barcode.disable(() => {})
    }
  }, [window.EB, onScan])
  return (
    <div css={{ display: props.invisible && 'none' }}>
      {props.breadCrumbText &&
      <Fragment>
        <Breadcrumb>{props.breadCrumbText}</Breadcrumb>
        <LabelledDivider text={t('common.reading')} />
      </Fragment>
      }
      <Container css={scanIconCss(props.noHeight)}>
        <div>
          <h3 css={textCss}><ScanIcon width='20px' height='20px' css={{ marginRight: '10px' }} />[{t('supply.scanCode')}]</h3>
        </div>
      </Container>
    </div>
  )
}

export default scriptLoader('/ebapi-modules.js')(withRouter(QrCodeReader))

QrCodeReader.propTypes = {
  breadCrumbText: PropTypes.string,
  destination: PropTypes.string,
  history: PropTypes.any,
  invisible: PropTypes.bool,
  method: PropTypes.oneOf([
    'push', // Use when you want to get back to screen you are using it there
    'replace' // Use when you dont want to allow to get back on screen you are using
  ]),
  noHeight: PropTypes.bool,
  onScan: PropTypes.func
}

QrCodeReader.defaultProps = {
  method: 'push'
}
