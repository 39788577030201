import ProductionStation from 'views/ProductionStation/ProductionStation'
import { connect } from 'react-redux'
import { compose } from 'redux'
import {
  loginToProductionStation,
  getCurrentStationPlans as fetchCurrentStationPlans,
  markItemAsAssembled,
  getProductionStations as fetchStations
} from 'ducks/productionStations'
import { getCurrentStationPlans, getProductionStationsWithSubstations } from 'selectors/productionStations'
import StorageService, { LocalStorageKeys } from 'services/StorageService'
import { getUserName } from 'selectors/user'

const mapStateToProps = state => {
  return ({
    currentStationPlans: getCurrentStationPlans(state),
    currentProductionStation: getProductionStationsWithSubstations(state)[StorageService.get(LocalStorageKeys.stationId)],
    userName: getUserName(state)
  })
}

const mapDispatchToProps = dispatch => ({
  loginToProductionStation: compose(dispatch, loginToProductionStation),
  getCurrentStationPlans: compose(dispatch, fetchCurrentStationPlans),
  markItemAsAssembled: compose(dispatch, markItemAsAssembled),
  getProductionStations: compose(dispatch, fetchStations)
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductionStation)
