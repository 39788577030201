/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import theme from 'config/theme'
import IconBox from 'components/IconBox'
import { ReactComponent as Cancel } from 'assets/cancel.svg'

export const modalContainerStyles = (zIndex) => css`
  background-color: rgba(255, 255, 255, 0.78);
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${zIndex};
`

export const modalWrapperStyles = css`
  background-color: ${theme.colors.white};
  min-width: 800px;
  max-width: 90%;
  min-height: 400px;
  border-radius: 4px;
  box-shadow: 0 2px 7px 1px rgba(0, 0, 0, 0.13);
  padding: 40px;
  position: relative;
`

export const modalHeaderStyles = css`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 13px;
  color: ${theme.colors.black};
  font-size: 1.3em;
  margin-right: 80px;
`

export const closeIconWrapperStyles = css`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`

const noop = () => {}

const Modal = ({ children, onClose, headerTitle, styles, zIndex, closeOnOutsideClick }) => {
  const closeModalOnEsc = event => event.key === 'Escape' ? onClose() : noop()

  useEffect(() => {
    document.addEventListener('keydown', closeModalOnEsc)
    return () => document.removeEventListener('keydown', closeModalOnEsc)
  }, [])

  const modalStyles = css`
    ${modalWrapperStyles};
    ${styles};
  `

  return (
    <div
      css={modalContainerStyles(zIndex)}
      onMouseDown={closeOnOutsideClick ? onClose : () => {}}
    >
      <div css={modalStyles} onClick={(e) => { e.stopPropagation() }} onMouseDown={(e) => { e.stopPropagation() }}>
        <div css={closeIconWrapperStyles} onClick={onClose}>
          <IconBox size={40} color={theme.colors.white} border={theme.colors.black} cursor='pointer'>
            <Cancel fill={theme.colors.black} css={{ width: '50%', height: '50%' }} />
          </IconBox>
        </div>
        <div css={modalHeaderStyles}>
          <span>
            <b>{headerTitle}</b>
          </span>{}
        </div>
        {children}
      </div>
    </div>
  )
}

const modalRoot = document.querySelector('#modal-root')

const PortalModal = (props = {}) => ReactDOM.createPortal(<Modal {...props} />, modalRoot)

PortalModal.propTypes = {
  children: PropTypes.any.isRequired,
  headerTitle: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  styles: PropTypes.any,
  zIndex: PropTypes.number,
  closeOnOutsideClick: PropTypes.bool
}

export default PortalModal

PortalModal.defaultProps = {
  zIndex: 100,
  closeOnOutsideClick: true
}
