import qs from 'query-string'
import APIInterceptor from 'services/APIInterceptor'

const interceptor = APIInterceptor

const BomService = {
  getBom: (bomCode) => {
    return interceptor.get(`/items/revision/${bomCode}?${qs.stringify({ bom: 1 })}`)
  },
  getAllBoms: () => interceptor.get(`/boms`),
  updateBom: ({ bomId, assemblyPostIds, listOrder, allowBulkAssembly, assemblyTime }) => interceptor.patch(`/boms/${bomId}`, {
    assemblyPostIds,
    listOrder,
    allowBulkAssembly,
    assemblyTime
  })
}

export default BomService
