import Routes from 'constants/Routes'
import { Roles } from 'constants/Roles'

export const navbarItems = (t) => [
  {
    name: t('catalogue.catalogue'),
    path: Routes.catalogue,
    roles: [Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.QUALITY_ASSURANCE, Roles.SUPPLY]
  },
  {
    name: t('warehouse.warehouse'),
    path: Routes.warehouse,
    roles: [Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN]
  },
  {
    name: t('warehouse.orders.internalOrdersList'),
    path: Routes.warehouseOrdersList,
    roles: [Roles.ADMIN, Roles.FOREMAN]
  },
  {
    name: t('warehouse.stocktaking.stocktakingList'),
    path: Routes.stocktakingList,
    roles: [Roles.ADMIN, Roles.PRODUCTION_MANAGER]
  },
  {
    name: t('warehouse.weeklyPlans'),
    path: Routes.weeklyPlans,
    roles: [Roles.ADMIN, Roles.PRODUCTION_MANAGER]
  },
  {
    name: t('warehouse.weeklyPlansForeman'),
    path: Routes.weeklyPlansForeman,
    roles: [Roles.ADMIN, Roles.FOREMAN]
  },
  {
    name: t('productionStations.productionStations'),
    path: Routes.productionStations,
    roles: [Roles.ADMIN, Roles.FOREMAN]
  },
  {
    name: t('productionStations.assemblyDetails'),
    path: Routes.assemblyDetails.replace('/:serialNumber', ''),
    roles: [Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN, Roles.PRODUCTION_WORKER]
  },
  {
    name: t('productionStation.production'),
    path: Routes.mainMenu,
    roles: [Roles.PRODUCTION_WORKER]
  },
  {
    name: t('productionStation.service'),
    path: Routes.serviceGetDevice,
    roles: [Roles.PRODUCTION_WORKER]
  },
  {
    name: t('qualityControl.qualityControl'),
    path: Routes.qualityControl,
    roles: [Roles.QUALITY_ASSURANCE]
  },
  {
    name: t('navbar.dropDownMenu.boms'),
    path: Routes.boms,
    roles: [Roles.TECHNOLOGIST]
  },
  {
    name: t('warehouse.reports'),
    isReportSection: true,
    roles: [Roles.ADMIN, Roles.PRODUCTION_MANAGER, Roles.FOREMAN]
  }
]
