/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Fragment, useEffect, useState } from 'react'
import {
  depthCss, lineCss,
  nameCss,
  normalCellCss,
  openCss,
  openedCss
} from 'views/Catalogue/styles'
import theme from 'config/theme'
import { getColumns } from 'views/Catalogue/rowColumns'
import { connect } from 'react-redux'
import RenderCell from 'views/Catalogue/RenderCell'
import { iterable } from 'helpers/array'
import join from 'ramda/src/join'
import { getUserRoles } from 'selectors/user'
import { withRouter } from 'react-router-dom'

export const getBom = (depth, findPartId, contextId, index, arrayLength, collapsed, quantity) => (bom) => {
  return <Fragment key={bom.id}>
    <ConnectedBomRow
      findPartId={findPartId}
      depth={depth}
      bom={bom}
      requiredQuantity={quantity}
      collapsed={collapsed}
      isLast={index === arrayLength - 1}
      selfIndex={index}
      contextId={contextId}
    />
  </Fragment>
}

const BomRow = ({ bom, depth, findPartId, contextId, isLast, selfIndex, userRoles, collapsed, requiredQuantity, history }) => {
  const isInsideParts = part => part.id === contextId || part?.bom?.parts?.findIndex(isInsideParts) >= 0
  const isContext = bom.id === contextId || bom.bom?.parts?.findIndex(isInsideParts) >= 0
  const isPartLookedFor = bom.id === findPartId
  const [isOpen, setOpen] = useState(depth === 0 || isContext)
  const [isSearchedElement, setIsSearchedElement] = useState(false)
  const { id, fullIndex, bom: activeBom } = bom

  const hasParts = activeBom?.parts?.length > 0

  useEffect(() => {
    collapsed && setOpen(true)
    !collapsed && setOpen(depth === 0 || isContext)
  }, [collapsed])

  useEffect(() => {
    isPartLookedFor && setIsSearchedElement(true)
    const element = document.getElementById(findPartId)
    isPartLookedFor && element && element.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }, [])

  const columns = getColumns({
    item: bom,
    index: selfIndex + id,
    userRoles,
    historyPush: history.push
  }).slice(1)

  return (
    <Fragment>
      {[
        <RenderCell key={'fullIndex' + id}
          css={css`
          ${normalCellCss};
          overflow: visible;
          padding-left: ${(depth * 20 + 10)}px;
          cursor: ${hasParts && 'pointer'};
        `}
          onClick={() => setOpen(!isOpen)}
          id={id}
        >
          <div
            css={css`
            ${normalCellCss};
            overflow: visible;
            ${depth > 0 && depthCss(isLast, depth)};
            text-decoration: ${isSearchedElement && 'underline'};
            color: ${isSearchedElement && theme.colors.lightBlue};
          `}
          >
            {iterable(Math.max(depth - 1, 0)).map(index => (
              <span key={join('-', [(depth), 'line', id, 'index', index])} css={lineCss(index + 1, depth)} />
            ))}
            <span
              css={css`
                ${hasParts && (!isOpen ? openCss : openedCss)};
              `}
            />
            {fullIndex}
          </div>
        </RenderCell>,
        <RenderCell
          key={'name' + id}
          css={css`
            ${nameCss};
            padding-left: ${depth * 20}px;
          `}
        >
          {bom.productionName || bom.name}
        </RenderCell>,
        <RenderCell
          key={'requiredQuantity' + id}
          css={css`
            ${normalCellCss};
            padding-left: ${depth * 20}px;
          `}
        >
          {requiredQuantity}
        </RenderCell>,
        // hiding last 3 columns (photos, alternatives, colors) @slowik
        ...columns.slice(0, columns.length - 3)
      ]}
      {isOpen && activeBom?.parts?.map((part, index, array) => getBom(depth + 1, findPartId, contextId, index, array.length, collapsed, part.quantity)(part.itemRevision))}
    </Fragment>
  )
}

const mapStateToProps = state => ({
  userRoles: getUserRoles(state)
})

const ConnectedBomRow = withRouter(connect(mapStateToProps)(BomRow))
