/** @jsx jsx */
import { Fragment } from 'react'
import { jsx } from '@emotion/core'
import { Link } from 'react-router-dom'
import Container from 'components/Container'
import theme from 'config/theme'
import { ReactComponent as ArrowIcon } from 'assets/arrow.svg'
import IconBox, { iconCss } from 'components/IconBox'
import Divider from 'components/Divider'
import { capitalize } from 'helpers/string'

const spanCss = {
  color: theme.colors.darkGrey,
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'bold'
}

const Arrow = () => (
  <IconBox css={{ marginLeft: 'auto' }}>
    <ArrowIcon fill='white' css={iconCss} />
  </IconBox>
)

const MenuLink = (props) => {
  const { text, to } = props
  return (
    <Fragment>
      <Link to={to}>
        <Divider />
        <Container noMargin flex>
          <span css={spanCss}>{capitalize(text)}</span>
          <Arrow />
        </Container>
      </Link>
    </Fragment>
  )
}

export default MenuLink
