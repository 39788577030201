/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { Fragment, useEffect, useState } from 'react'
import { Box, Flex } from '@rebass/emotion'
import * as styles from 'views/WarehouseState/styles'
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'
import Routes from 'constants/Routes'
import {
  gridCss,
  headerCss
} from 'views/Catalogue/styles'
import { useTranslation } from 'react-i18next'
import { getBom } from 'views/Catalogue/BomRow'
import { getHeaders } from 'views/Catalogue/headerColumns'
import Button from 'components/Button'
import useSumedItems from 'hooks/useSummedItems'
import * as R from 'ramda'
import theme from 'config/theme'

const StructuredCatalogueView = (props) => {
  const { t } = useTranslation()
  const [collapsed, uncollapseAll] = useState(false)
  useEffect(() => {
    props.location.state?.parentCode && props.getBomIfNeeded(props.location.state.parentCode)
  }, [])

  const headers = getHeaders({ userRoles: props.userRoles, t }).slice(1)

  const extractItemsFromBom = (bomsList, nextBom) => {
    const moreItems = nextBom.bom ? nextBom.bom.parts.map(part => part.itemRevision).reduce(extractItemsFromBom, []) : []
    return R.uniqBy(R.prop('id'), [...bomsList, nextBom, ...moreItems])
  }

  const itemsToRender = props.boms.reduce(extractItemsFromBom, [])

  const [summedItems, keys] = useSumedItems(itemsToRender, [itemsToRender.length])

  const sumCells = keys.map(key => {
    const correspondingColumnIndex = R.findIndex(
      R.propEq('key', key.split('.')[0] + 'Header'),
      headers
    )
    const shouldDisplayOnColumn = correspondingColumnIndex + 4
    return correspondingColumnIndex >= 0
      ? <div
        key={key + 'SUM'}
        css={{
          gridColumnStart: shouldDisplayOnColumn,
          gridRow: '2 / 3',
          padding: 5,
          position: 'sticky',
          top: 51,
          backgroundColor: theme.colors.white,
          zIndex: 5
        }}
      >
        {R.propOr(0, key, summedItems)}
      </div>
      : null
  })

  return (
    <div>
      <Fragment>
        <Flex width={1} css={styles.searchWrapperCss}>
          <Box width={3 / 8} py={10} px={8}>
            <input onFocus={() => props.history.push(Routes.catalogue)} css={styles.searchBarCss} />
            <div css={styles.resetCss}>
              <SearchIcon {...styles.iconDimensions} />
            </div>
          </Box>
          <Box width={3 / 8} p={10}>
            <Button onClick={() => uncollapseAll(!collapsed)}>{t('catalogue.uncollapseAll')}</Button>
          </Box>
        </Flex>
        <div
          css={css`
            ${gridCss()};
            // -4 is for hiding last 3 columns (photos, alternatives, colors) @slowik
            grid-template-columns: min-content 300px 150px 200px repeat(${headers.length - 4}, 70px);
          `}
        >
          <Fragment>
            {[
              <div key={'codeHeader'} css={headerCss}>{t('catalogue.code')}</div>,
              <div key={'nameHeader'} css={headerCss}>{t('catalogue.name')}</div>,
              <div key={'requiredQuantityHeader'} css={headerCss}>{t('catalogue.requiredQuantity')}</div>,
              // hiding last 3 columns (photos, alternatives, colors) @slowik
              ...headers.slice(0, headers.length - 3),
              <div
                key={'itemsCount'}
                css={{
                  gridColumnStart: 1,
                  gridRow: '2 / 3',
                  padding: 5,
                  position: 'sticky',
                  top: 51,
                  backgroundColor: theme.colors.white,
                  zIndex: 6
                }}
              >
                Łącznie: {itemsToRender.length}
              </div>,
              <div
                key={'whiteBackgroundForSumRow'}
                css={{
                  gridColumn: '1 / -1',
                  gridRow: '2 / 3',
                  position: 'sticky',
                  top: 51,
                  backgroundColor: theme.colors.white,
                  zIndex: 5
                }}
              />,
              ...sumCells
            ]}
            {/* <div css={spacerCss} /> */}
            {props.boms.map((bom, index, array) => getBom(0, props.location.state.itemId, props.location.state.bomId, index, array.length, collapsed)(bom))}
          </Fragment>
        </div>
      </Fragment>
    </div>
  )
}

export default StructuredCatalogueView
