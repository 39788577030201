/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { useState } from 'react'

const highlightedBorder = keyframes`
  0% { 
    box-shadow: 0 0 4px 1px rgba(0,0,0,0.5);
  }
  50% { 
    box-shadow: 0 0 12px 1px rgba(0,0,0,0.5);
  }
  100% { 
    box-shadow: 0 0 4px 1px rgba(0,0,0,0.5);
  }
`

const CustomInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  
  &:after {
    content: " ";
    width: 10px;  
    height: 10px;
    border: 5px solid red;
  } 
`

const CustomLabel = styled.label`
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  line-height: 1;
`

const CustomInputReplacement = styled.span`
  position: relative;
  display: inline-block;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  border: 3px solid ${({ isChecked, theme }) => isChecked ? theme.colors.darkGrey : theme.colors.grey};
  background-color: ${({ isChecked, theme }) => isChecked ? theme.colors.darkGrey : theme.colors.white};
  animation: ${({ focused }) => focused ? highlightedBorder : 'none'} 1s ease infinite;
  
  &:hover {
    border: 3px solid ${({ theme }) => theme.colors.darkGrey};
  }
  
  &:after {
    content: "";
    position: absolute;
    display: ${({ isChecked }) => isChecked ? 'block' : 'none'};
    left: 4px;
    top: 0;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`

const Checkbox = (props) => {
  const [focused, setFocused] = useState(false)
  return (
    <CustomLabel onClick={props.onClick}>
      <CustomInput type='checkbox' {...props} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} />
      <CustomInputReplacement isChecked={props.checked} focused={focused} />
    </CustomLabel>
  )
}

export default Checkbox
