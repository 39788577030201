/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { toast } from 'react-toastify'
import moment from 'moment'
import isZebraBrowser from 'helpers/isZebra'
import theme from 'config/theme'

const dateCss = css`
  font-size: 14px;
  text-align: ${isZebraBrowser() ? 'left' : 'center'};
  color: ${theme.colors.lightBlue};
`

const messageCss = css`
  font-size: 24px;
`

export const showNotification = (data, onClose) => {
  const date = data.createdAt || data.data.created_at

  const handleOnClose = () => {
    clearInterval(updateInterval)
    onClose(data.id)
  }

  const toastNotification = toast(<div>
    <p css={dateCss}>{moment(date).fromNow()}</p>
    <p css={messageCss}>{data.message}</p>
  </div>, {
    onClose: handleOnClose
  })

  const updateInterval = setInterval(() => {
    toast.update(toastNotification, {
      render: (
        <div>
          <p css={dateCss}>{moment(date).fromNow()}</p>
          <p css={messageCss}>{data.message}</p>
        </div>
      ),
      containerId: 'notifications'
    })
  }, 15000)
}
