/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Box, Flex } from '@rebass/emotion'
import * as styles from './styles'
import Checkbox from 'components/Checkbox'
import theme from 'config/theme'
import { stopPropagation } from 'helpers/htmlEvents'
import InternalOrderFormConnector from 'connectors/forms/InternalOrderFormConnector'
import Button from 'components/Button'
import { ReactComponent as ListIcon } from 'assets/list.svg'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { useTranslation } from 'react-i18next'
import { Fragment, useEffect, useState } from 'react'
import { sortLocationsByCode } from 'helpers/array'
import QrCode from 'qrcode.react'
import { Roles } from 'constants/Roles'

const KeyCapture = (props) => {
  const [keyDown, setKeyDown] = useState(false)
  useEffect(() => {
    const keyDownHandler = event => {
      event.altKey && setKeyDown(true)
    }
    const keyUpHandler = () => {
      setKeyDown(false)
    }
    window.addEventListener('keydown', keyDownHandler)
    window.addEventListener('keyup', keyUpHandler)
    return () => {
      window.removeEventListener('keydown', keyDownHandler)
      window.removeEventListener('keyup', keyUpHandler)
    }
  }, [])
  useEffect(() => {
    keyDown && props.onKeysHold()
    !keyDown && props.onKeysRelease()
  }, [keyDown])
  return null
}

const WarehouseItemRow = ({ isExpanded, item, index, handleSelectSingleRow, handleCheckChange, isRowAboveExpanded,
  selectedRows, userRole, issueStocktakingForItem, error, resetError }) => {
  const { t } = useTranslation()
  const getWidth = (part) => isExpanded ? (2 / 3) * (part / 12) : part / 12
  const itemCount = item.inventory?.reduce((all, next) => all + next.quantity, 0) || 0
  const [loading, setLoading] = useState(false)
  const [issueStocktakingButtonColor, setButtonColor] = useState(theme.colors.grey)
  const [displayQR, setDisplayQR] = useState(false)
  const getNotSelectedStyles = (index) =>
    isRowAboveExpanded(index)
      ? { ...styles.rowWrapperCss, ...styles.rowAboveSelectedCss }
      : styles.rowWrapperCss

  const isAdmin = userRole === Roles.ADMIN
  const isProductionManager = userRole === Roles.PRODUCTION_MANAGER
  const isForeman = userRole === Roles.FOREMAN

  const issueStocktaking = async () => {
    resetError()
    try {
      setLoading(true)
      await issueStocktakingForItem(item?.fullIndex)
      setLoading(false)
      setButtonColor(theme.colors.successGreen)
    } catch (e) {
      setLoading(false)
      setButtonColor(theme.colors.error)
    }
  }

  return (
    (
      <Flex
        width={isExpanded ? 1 : 2 / 3}
        p={6}
        onClick={handleSelectSingleRow({ ...item, index })}
        css={isExpanded ? styles.selectedRowCss : getNotSelectedStyles(index)}
      >
        <Flex width={getWidth(1)}>
          <Checkbox
            checked={selectedRows.includes(item.id)}
            type='checkbox'
            value={item.id}
            onClick={stopPropagation}
            onChange={handleCheckChange}
          />
        </Flex>
        <Flex width={getWidth(3)} as='h3'>{item.fullIndex}</Flex>
        <Flex width={getWidth(6)} as='p' css={styles.nameCss}>{item.name}</Flex>
        <Flex width={getWidth(2)} as='h3'>{itemCount}</Flex>
        {isExpanded &&
        <Flex width={1 / 3} pl={11}>
          <Flex width={1} justifyContent='space-between' css={{ position: 'relative' }}>
            <h1>{item.fullIndex}</h1>
            <h1>{itemCount}</h1>
            <Box css={styles.restContentCss}>
              <span>
                {item.name}
              </span>
              <div id={item.id} css={{ marginTop: '40px' }}>{sortLocationsByCode(item?.inventory).map(inv =>
                <h2 key={inv.id} css={{ color: theme.colors.grey, marginBottom: '11px' }}>
                  {inv.warehouseLocation?.code} ({inv.quantity})
                </h2>)}
              </div>
              {(isForeman || isAdmin) &&
                <InternalOrderFormConnector
                  unit={item.unit}
                  availableQuantity={item.availableQuantity}
                  initialValues={{ quantity: 1, code: item.fullIndex, available: item.availableQuantity }}
                />
              }
              {(isProductionManager || isAdmin) &&
              <Fragment>
                {error && <p css={styles.errorMsgCss}>{t(`errors.stocktaking.${error}`)}</p>}
                <Button
                  css={{ marginTop: 30 }}
                  color={issueStocktakingButtonColor}
                  disabled={loading}
                  withIcon icon={loading ? Loader : ListIcon}
                  iconProps={{ fill: theme.colors.white }}
                  onClick={issueStocktaking}
                >
                  {t('warehouse.stocktaking.issueStocktaking')}
                </Button>
              </Fragment>
              }
              <KeyCapture onKeysHold={() => setDisplayQR(true)} onKeysRelease={() => setDisplayQR(false)} />
              {displayQR &&
                <Flex p={6}>
                  <QrCode size={200} style={{ margin: 'auto' }} value={item.fullIndex} />
                </Flex>
              }
            </Box>
          </Flex>
        </Flex>
        }
      </Flex>
    )
  )
}

export default WarehouseItemRow
