/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useState } from 'react'
import Breadcrumb from 'components/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@rebass/emotion'
import LabelledDivider from 'components/LabelledDivider'
import QrCodeReader from 'components/QrCodeReader'
import { scanParser } from 'helpers/scanParser'
import { find, propEq } from 'ramda'

const InboundItemsList = (props) => {
  const { t } = useTranslation()
  const [scanData, setScanData] = useState({})

  useEffect(() => {
    props.getInboundItems()
  }, [])

  const redirectToOnboardingView = item => () => {
    props.history.push({
      pathname: `/on-boarding/item/${item.fullIndex}`,
      state: { item: item }
    })
  }

  const getItemCount = useCallback((item) => item.inventory?.reduce((all, next) => all + next.quantity, 0), [])

  const afterScan = () => {
    if (scanData.type === 'item') {
      const scannedItem = find(propEq('fullIndex', scanData.data), props.items)
      scannedItem && props.history.replace(`/on-boarding/item/${scanData.data}`)
    }
  }

  useEffect(() => {
    afterScan()
  }, [scanData])

  const handleScan = scan => {
    setScanData(scanParser(scan))
  }

  return (
    <div>
      <Breadcrumb>{t('warehouse.onboard')}</Breadcrumb>
      <QrCodeReader invisible onScan={handleScan} />
      <Box p={6}>
        <LabelledDivider text={t('warehouse.onboarded')} noMargin />
        {props.items.map((item, index) => {
          return <div key={index} onClick={redirectToOnboardingView(item)}>
            <Flex my={6} justifyContent='space-between'>
              <h3>{item.fullIndex}</h3>
              <h3>{getItemCount(item)}</h3>
            </Flex>
            <Box my={6} css={{ borderBottom: '1px solid black', paddingBottom: 20 }}>
              <div>{item.name}</div>
            </Box>
          </div>
        })}
      </Box>
    </div>
  )
}

export default InboundItemsList
