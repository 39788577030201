import styled from '@emotion/styled'
import theme from 'config/theme'
import { ReactComponent as TrashIcon } from 'assets/trash-can.svg'
import Button from 'components/Button'

export const LocationCodeBox = styled('p')`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 20px;
  border-radius: 4px;
  margin-bottom: 10px;
  border: 1px solid ${theme.colors.darkGrey};
  background-color: ${props => props.isActive ? theme.colors.darkGrey : theme.colors.white};
  color: ${props => props.isActive ? theme.colors.white : theme.colors.darkGrey};
  
  &:hover {
    cursor: pointer;
    color: ${theme.colors.white};
    background-color: ${theme.colors.darkGrey};
  }
`

export const LocationTypeHeader = styled('p')`
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
`

export const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 48px
`

export const StyledIcon = styled(TrashIcon)`
  width: 15px;
  margin-left: 5px;
  
  &:hover {
    cursor: pointer;
    fill: ${theme.colors.error};
  }
`

export const LocationsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  padding-top: 50px;

  & > div {
    width: 200px;
  }
`

export const AddButton = styled(Button)`
  width: 100%;
  margin-bottom: 30px;
`

export const DeleteModalContent = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-top: 30px;
  
  .modal-buttons {
    display: flex;
    margin: 30px 0;
    
    & > * {
      margin: 0 5px;
    }
    
    canvas {
      margin: 0 10px;
    }
  }
`
