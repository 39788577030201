/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Box } from '@rebass/emotion'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as PropTypes from 'prop-types'
import theme from 'config/theme'
import LabelledDivider from 'components/LabelledDivider'
import Button from 'components/Button'
import { inputCss } from 'forms/OnboardItemForm'
import { ReactComponent as Loader } from 'assets/loader.svg'
import { ReactComponent as Checkmark } from 'assets/check-mark.svg'
import { useEffect } from 'react'
import useBoolean from 'react-use/lib/useBoolean'

const formWrapperCss = css`
  display: grid;
  grid-gap: 20px;
`
const StocktakingForm = (props) => {
  const { t } = useTranslation()
  const [focused, setFocused] = useBoolean(true)

  useEffect(() => {
    const element = document.getElementById('stocktaking-form')
    element && element.scrollIntoView({ behavior: 'smooth' })
  }, [focused])
  return <Box id='stocktaking-form' p={6} css={formWrapperCss}>
    <LabelledDivider noMargin text={t('warehouse.stocktaking.indicatedQuantity')} />
    <Box as='h2'>{props.selectedInventory?.warehouseLocation.code} ({props.selectedInventory?.quantity})</Box>
    <Formik
      initialValues={{ quantity: props.selectedInventory?.quantity || '' }}
      validate={props.validate}
      onSubmit={props.onSubmit}
    >{
        ({ isSubmitting }) => (
          <Form>
            <ErrorMessage name='quantity' component='div' />
            <Field
              type='text'
              name='quantity'
              pattern='[0-9]*'
              inputMode='numeric'
              css={css`${inputCss}; margin-bottom: 20px`}
              onFocus={() => setFocused(true)}
              onBlur={() => setFocused(false)}
            />
            <Button disabled={isSubmitting} type='submit' withIcon icon={isSubmitting ? Loader : Checkmark}
              iconProps={{ fill: theme.colors.white }}>
              {t('warehouse.stocktaking.confirmQuantityChange')}
            </Button>
          </Form>
        )
      }
    </Formik>
  </Box>
}

StocktakingForm.propTypes = {
  selectedInventory: PropTypes.any,
  validate: PropTypes.func,
  onSubmit: PropTypes.func
}

export default StocktakingForm
