import { createSelector } from 'reselect'
import { transformToArray } from 'helpers/redux'
import * as R from 'ramda'

const getBoms = state => state.bom.entries
const getAllBoms = state => state.bom.allBomsEntries

export const getBomsAsArray = createSelector(
  getBoms,
  transformToArray
)

export const getAllBomsAsOptions = createSelector(
  getAllBoms,
  R.pipe(
    transformToArray,
    R.map(R.applySpec({
      label: item => R.path(['itemRevision', 'fullIndex'], item) + ' ' + R.path(['itemRevision', 'name'], item),
      value: R.prop('id')
    }))
  )
)
