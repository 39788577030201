import * as R from 'ramda'

export const getValueInSeconds = (value = '') => {
  switch (true) {
    case R.test(/(^[0-9]{1,2}:[0-9]{2}$)/g, value):
      return R.pipe(
        R.split(':'),
        R.map(Number),
        values => (values[0] * 60) + values[1]
      )(value)
    case R.test(/(^[0-9]+\.[0-9]{1,2}$)/g, value):
      return R.pipe(
        R.split('.'),
        R.map(Number),
        values => Math.round((values[0] * 60) + (values[1] < 10 ? values[1] * 6 : values[1] * 0.6))
      )(value)
    case R.test(/(^[0-9]+,[0-9]{1,2}$)/g, value):
      return R.pipe(
        R.split(','),
        R.map(Number),
        values => Math.round((values[0] * 60) + (values[1] < 10 ? values[1] * 6 : values[1] * 0.6))
      )(value)
    case R.test(/(^[0-9]+$)/g, value):
      return R.pipe(
        Number,
        R.multiply(60)
      )(value)
    case R.test(/(^[0-9]{2}:[0-9]{2}:[0-9]{2}$)/g, value):
      return R.pipe(
        R.split(':'),
        R.map(Number),
        values => (values[0] * 3600) + (values[1] * 60) + values[2]
      )(value)
    default:
      return 'błędne dane'
  }
}

const minutesFromSeconds = R.pipe(
  Number,
  R.divide(R.__, 60),
  R.multiply(100),
  Math.round,
  R.divide(R.__, 100),
  String
)

export const extractMinutesFromTime = R.ifElse(
  R.includes(':'),
  R.pipe(
    getValueInSeconds,
    minutesFromSeconds
  ),
  minutesFromSeconds
)
