import InboundItemsList from 'views/InboundItemsList'
import { connect } from 'react-redux'
import { getInboundItemsAsArray } from 'selectors/inboundZone'
import { getInboundItems } from 'ducks/inboundZone'

const mapStateToProps = state => ({
  items: getInboundItemsAsArray(state)
})

const mapDispatchToProps = dispatch => ({
  getInboundItems: () => dispatch(getInboundItems())
})

export default connect(mapStateToProps, mapDispatchToProps)(InboundItemsList)
