import SerialNumberFormatForm from 'forms/SerialNumberFormatForm'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import validateSerialNumberForm from 'forms/validators/validateSerialNumberForm'

const formName = 'CreateSerialNumberForm'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate: validateSerialNumberForm,
  onSubmitSuccess: (_, __, props) => props.close()
})(SerialNumberFormatForm)

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(Form)
