import { generateBomFromImport, getBomImportDetails, saveBomImport } from 'ducks/bomsImports'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { getBomImportDetailsById } from 'selectors/bomsImports'
import BomsEditScreen from 'views/BomsEditScreen'

const mapStateToProps = (state, ownProps) => ({
  bomImport: getBomImportDetailsById(state, ownProps.match.params.bomImportId)
})

const mapDispatchToProps = dispatch => ({
  getBomImportDetails: compose(dispatch, getBomImportDetails),
  saveBomImport: compose(dispatch, saveBomImport),
  generateBomFromImport: compose(dispatch, generateBomFromImport)
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(BomsEditScreen)
