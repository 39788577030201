/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import { Link, withRouter } from 'react-router-dom'
import home from 'assets/home.svg'
import Container from 'components/Container'
import { ReactComponent as Arrow } from 'assets/arrow.svg'
import IconBox, { iconCss } from 'components/IconBox'
import theme from 'config/theme'
import { capitalize } from 'helpers/string'

const Breadcrumb = (props) => {
  const getTitle = typeof props.children === 'string' ? capitalize(props.children) : props.children
  const handleOnBackClick = !props.to ? props.history.goBack : () => props.history.replace(props.to)
  const GoBackContainer = (containerProps) => <div onClick={handleOnBackClick}>{containerProps.children}</div>
  const GoHomeContainer = (linkProps) => <Link {...linkProps} to='/' />
  const IconContainer = props.back ? GoBackContainer : GoHomeContainer
  return (
    <div className={props.className}>
      <Container flex centerItems>
        <IconContainer>
          <IconBox size={40}>
            {props.back ? <Arrow fill={theme.colors.white} css={{ transform: 'rotate(180deg)', ...iconCss }} /> : <img src={home} alt='home' css={iconCss} />}
          </IconBox>
        </IconContainer>
        <Arrow fill={theme.colors.black} css={{ width: 15, height: 15, margin: '0 10px' }} />
        <span css={{ fontSize: 12, fontWeight: 'bold' }}>{getTitle}</span>
      </Container>
    </div>
  )
}

export default withRouter(Breadcrumb)

Breadcrumb.propTypes = {
  back: PropTypes.bool,
  to: PropTypes.any
}
