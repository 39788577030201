import APIInterceptor from 'services/APIInterceptor'

const interceptor = APIInterceptor

const EventsService = {
  revertEvents: (events) => {
    return interceptor.post('/events/rollback', { event_ids: events })
  }
}

export default EventsService
