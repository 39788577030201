export default {

  // zebra

  ordersList: '/warehouse/orders-hand-over',
  orderComplete: '/warehouse/orders-hand-over/complete',
  recalculateFinalize: '/warehouse/recalculate/:itemCode/finalize',
  recalculateView: '/warehouse/recalculate/:itemCode',
  recalculateSearch: '/warehouse/recalculate',
  handOverFinalize: '/warehouse/hand-over/:itemCode/finalize',
  handOverView: '/warehouse/hand-over/:itemCode',
  handOverSearch: '/warehouse/hand-over/search',
  onBoardingFinalize: '/on-boarding/confirm',
  onBoardingView: '/on-boarding/item/:itemCode',
  onBoardingSearch: '/on-boarding',
  moveItemsSearch: '/move/search',
  moveItemOnWarehouse: '/move/select/:itemCode',
  moveItemInventory: '/move/:itemCode',
  supplyFinalize: '/supply/confirm/:itemCode',
  supplyView: '/supply/item/:itemCode',
  supplySearch: '/supply',
  stocktakingList: '/stocktaking/list',
  stocktakingComplete: '/stocktaking/complete/:stocktakingIssueId',
  internalSubmissionsList: '/internal-submissions-list',
  internalSubmissionView: '/internal-submission-view/item/:itemCode',
  internalSubmissionFinalize: '/internal-submission-view/confirm',

  // admin and foreman

  warehouseOrdersList: '/warehouse/orders/list',
  warehouse: '/warehouse',
  warehouseStocktaking: '/warehouse/stocktaking',
  weeklyPlans: '/weekly-plans',
  weeklyPlansForeman: '/weekly-plans-foreman',
  catalogue: '/catalogue',
  catalogueStructuredView: '/catalogue/structured',
  productionStations: '/production-stations',
  assemblyDetails: '/assembly-details/:serialNumber?',
  users: '/users',
  workstations: '/workstations',
  boms: '/boms',
  serialNumberFormats: '/serial-number-formats',
  bomsEdit: '/boms/edit/:bomImportId',
  manageLocations: '/warehouse/manage-locations',

  // Production station

  productionStationChooseStation: '/production/station',
  productionStation: {
    root: '/production/station/:stationId',
    base: '/production/station/'
  },
  serviceGetDevice: '/service',
  productionService: '/service/:serialNumber',

  // Quality control

  qualityControl: '/quality',
  qualityControlDetails: '/quality/:serialNumber',

  // common

  login: '/login',
  logout: '/logout',
  mainMenu: '/',
  codes: '/codes',
  locations: '/locations'
}
