import WorkstationsView from 'views/Workstations/WorkstationsView'
import { connect } from 'react-redux'
import { getWorkstations } from 'ducks/workstations'
import { selectWereWorkstationsFeched, selectWorkstationsAsArray } from 'selectors/workstations'
import { getUserRoles } from 'selectors/user'

const mapStateToProps = state => ({
  workstations: selectWorkstationsAsArray(state),
  wasFetched: selectWereWorkstationsFeched(state),
  userRoles: getUserRoles(state)
})

const mapDispatchToProps = dispatch => ({
  getWorkstations: () => dispatch(getWorkstations())
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkstationsView)
