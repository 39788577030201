import { css } from '@emotion/core'

export const NoImagesNotieCss = css`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const ModalContentWrapperCss = css`
  width: 100%;
  height: 100%;
  height: 500px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const imagePreviewCss = css`
  display: flex;
  max-width: 400px;
  margin: 10px
`

export const removeImageCss = css`
  position: relative;
  top: 15px;
  right: 35px;
  cursor: pointer;
`

export const imageCss = css`
  max-width: 100%;
  height: auto;
`

export const inputCss = css`
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
`

export const buttonHolderStyles = css`
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 100%; 
  height: 80px;
  margin-bottom: 10px;
`

export const inputHolderCss = css`
  width: 100%;
  height: auto;
  cursor: pointer;
`

export const imagesPreviewWrapperCss = css`height: auto`
