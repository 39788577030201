import { css } from '@emotion/core'
import theme from 'config/theme'

export const nameCss = isFirst => css`
  font-weight: bold;
  font-size: ${isFirst ? '42px' : '24px'};
`

export const quantityCss = isFirst => css`
  ${nameCss(isFirst)};
  margin-left: auto;
`

export const timeCss = css`
  display: flex;
  flex-flow: column nowrap;
  font-weight: bold;
  font-size: 24px;
  margin-left: auto;
  align-items: center;
`

export const planCss = css`
  position: relative;
`

export const dayCss = css`
  ${nameCss()};
  position: absolute;
  left: 0;
`

export const containerCss = css`
  display: grid;
  grid-template-columns: 60% auto;
`

export const plansWrapperCss = css`
  position: relative;
`

export const currentElementCss = css`
  margin-left: auto;
  font-weight: bold;
  font-size: 48px;
  margin-bottom: 10px;
`

export const progressCss = css`
   grid-row-start: 1;
   grid-column-start: 2;
`
export const progressBarCss = (progress) => css`
  width: 100%;
  height: 10px;
  align-self: center;
  position: relative;
  background-color: ${theme.colors.barelyGrey};
  
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${theme.colors.successGreen};
    width: ${progress}%;
    min-height: 10px;
  }
`

export const dividerCss = css`
  margin: 64px 0 44px;
  width: 100%;
  position: relative;
  left: 0;
  height: 10px;
  background-color: ${theme.colors.darkGrey};
`

export const qrCss = css`
  margin-top: 20px;
`

export const rhythmCss = css`
  margin-top: 25px;
  font-size: 24px;
  font-weight: bold;
  color: ${theme.colors.grey};
`

export const buttonCss = css`
  min-height: 100px;
  max-width: 20%;
`

export const planDoneCss = css`
  ${nameCss(true)};
  color: ${theme.colors.successGreen};
`

export const subHeaderTextCss = css`
  font-weight: bold;
  font-size: 24px;
  margin-right: 15px;
  margin-left: 15px;
`

const coverCss = css`
  content: "";
  width: 105%;
  height: 105%;
  background: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: -5px;
  left: -5px;
`

export const stepCss = (selectedIndex, index) => css`
  min-width: 400px;
  max-width: 400px;
  width: 400px;
  min-height: 300px;
  margin-right: calc(50% - 636px);
  max-height: 100%;
  border: 2px solid black;
  padding: 10px;
  transition: left ease .3s;
  border-radius: 10px;
  font-size: 24px;
  font-weight: bold;
  display: flex;
  flex: 1 1 100%;
  flex-flow: column nowrap;
  align-items: center;
  position: relative;
  left: calc((50% - 212px) - (${selectedIndex * 424}px + ${selectedIndex} * (50% - 636px)));
  &:after {
    ${selectedIndex !== index && coverCss}
  };
`

export const producedItemImageCss = (imageUrl) => css`
  background: url('${imageUrl}') no-repeat center;
  background-size: contain;
`
