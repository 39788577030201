import React from 'react'
import { Field, Form } from 'redux-form'
import Button from 'components/Button'
import Input from 'components/Input'
import styled from '@emotion/styled'
import { t } from 'i18next'

const AddColorVariantFormWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  
  & > div {
    margin: 10px 10px 10px 20px;
    
    & > input {
      height: 40px;
    }
  }
`

const AddColorVariantForm = props => {
  return (
    <Form onSubmit={props.handleSubmit}>
      <AddColorVariantFormWrapper>
        <Field
          component={Input}
          name='name'
          label={t('colors.form.name')}
          type='text'
        />
        <Field
          component={Input}
          name='code'
          label={t('colors.form.code')}
          type='text'
        />
        <div />
        <Button type='submit'>{t('colors.form.add')}</Button>
      </AddColorVariantFormWrapper>
    </Form>
  )
}

export default AddColorVariantForm
