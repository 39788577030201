/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Flex } from '@rebass/emotion'

const DayPlanTooltipContent = ({ plans }) => {
  return (
    <div>
      {plans.map(plan => {
        return (
          <Flex m={3} key={plan.id}>
            <div css={{ marginRight: 50, whiteSpace: 'nowrap' }}>{plan.weeklyPlan.bom.itemRevision.productionName || plan.weeklyPlan.bom.itemRevision.item.name}</div>
            <div css={{ width: 'auto', marginLeft: 'auto', whiteSpace: 'nowrap' }}>{plan.manufactured} / {plan.quantity}</div>
          </Flex>
        )
      })}
    </div>
  )
}

export default DayPlanTooltipContent
