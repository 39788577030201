/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Component } from 'react'
import enbioLogo from 'assets/enbio-logo.png'
import { ReactComponent as PrinterIcon } from 'assets/printer.svg'
import Button from 'components/Button'

const tableToPrintCss = css`
  width: 100%;
  padding: 20px 0px;
  border-spacing: 0;
  margin-top: 20px;

  td {
    border: 1px solid gray;
    padding: 15px 5px;
    vertical-align: middle;
  }

  th {
    font-size: 18px;
    padding: 15px 5px;
    border: 1px solid gray;
    background-color: #f2f2f2;
    vertical-align: middle;
  }
  
  tr {
    font-size: 16px;
  }
`

class PrintSelectedItems extends Component {
  render () {
    const { selectedRows, warehouseState } = this.props
    const rowsToPrint = warehouseState.filter(e => selectedRows.includes(e.id))
    return (
      <div style={{ padding: '20px' }}>
        <img src={enbioLogo} alt='logo' css={{ maxHeight: '20px', paddingTop: '10px' }} />
        <table css={tableToPrintCss}>
          <tbody>
            <tr>
              <th>Index</th>
              <th>Nazwa produktu</th>
              <th>Lokalizacje (liczba)</th>
              <th>Liczba ogółem</th>
            </tr>
            {rowsToPrint.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.fullIndex}</td>
                  <td>{item.name}</td>
                  <td>
                    {item.inventory.map((element, index) => (
                      <p key={index}>{`${element.warehouseLocation.code} (${element.quantity})`}</p>
                    ))}
                  </td>
                  <td width={100} style={{ textAlign: 'center' }}>
                    {item.inventory.length > 1
                      ? item.inventory.reduce((acc, next) => acc + next.quantity, 0)
                      : item.inventory[0].quantity
                    }
                  </td>
                </tr>
              )
            }
            )}
          </tbody>
        </table>
      </div>
    )
  }
}

class PrintButton extends Component {
  render () {
    return (
      <Button
        iconProps={{ style: { fill: 'white', width: 20, height: 20 } }}
        withIcon
        icon={PrinterIcon}
        {...this.props}
      >
        {this.props.children}
      </Button>
    )
  }
}

export {
  PrintSelectedItems,
  PrintButton
}
