/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'
import { ReactComponent as CancelIcon } from 'assets/cancel.svg'

const iconDimensions = {
  width: 20,
  height: 20
}

const DropdownIndicator = (props) => {
  const { getStyles, innerProps } = props
  const clearSearch = () => {
    props.selectProps.onInputChange('', { action: 'input-change' })
    props.selectProps.onFocus()
  }

  const onClick = props.selectProps.inputValue
    ? clearSearch
    : () => {}
  return (
    <div css={getStyles('dropdownIndicator', props)} {...innerProps} onTouchEnd={onClick}>
      {props.selectProps.inputValue
        ? <CancelIcon {...iconDimensions} />
        : <SearchIcon {...iconDimensions} />
      }
    </div>
  )
}

export default DropdownIndicator
