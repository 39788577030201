import * as R from 'ramda'
import { UPDATE_BOM_FIELDS_SUCCESS } from 'ducks/bom'
import { receiveEvents } from 'ducks/events'
import { transformById } from 'helpers/redux'
import WarehouseService from 'services/WarehouseService'
import FileUploadService from 'services/FileUploadService'
import { toast } from 'react-toastify'

const GET_WAREHOUSE_STATE = Symbol('GET_WAREHOUSE_STATE')
const GET_WAREHOUSE_STATE_SUCCESS = Symbol('GET_WAREHOUSE_STATE_SUCCESS')
const GET_WAREHOUSE_STATE_FAILED = Symbol('GET_WAREHOUSE_STATE_FAILED')

const GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS = Symbol('GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS')
const GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS_SUCCESS = Symbol('GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS_SUCCESS')
const GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS_FAILED = Symbol('GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS_FAILED')

const GET_INVENTORY_STATE_FOR_ITEM = Symbol('GET_INVENTORY_STATE_FOR_ITEM')
export const GET_INVENTORY_STATE_FOR_ITEM_SUCCESS = Symbol('GET_INVENTORY_STATE_FOR_ITEM_SUCCESS')
const GET_INVENTORY_STATE_FOR_ITEM_FAILED = Symbol('GET_INVENTORY_STATE_FOR_ITEM_FAILED')

const HAND_OVER_ITEM_FROM_INVENTORY = Symbol('HAND_OVER_ITEM_FROM_INVENTORY')
export const HAND_OVER_ITEM_FROM_INVENTORY_SUCCESS = Symbol('HAND_OVER_ITEM_FROM_INVENTORY_SUCCESS')
const HAND_OVER_ITEM_FROM_INVENTORY_FAILED = Symbol('HAND_OVER_ITEM_FROM_INVENTORY_FAILED')

const EDIT_ITEM_REVISION = Symbol('EDIT_ITEM_REVISION')
const EDIT_ITEM_REVISION_SUCCESS = Symbol('EDIT_ITEM_REVISION_SUCCESS')
const EDIT_ITEM_REVISION_FAILED = Symbol('EDIT_ITEM_REVISION_FAILED')

const UPLOAD_IMAGE_TO_ITEM = Symbol('UPLOAD_IMAGE_TO_ITEM')
const UPLOAD_IMAGE_TO_ITEM_SUCCESS = Symbol('UPLOAD_IMAGE_TO_ITEM_SUCCESS')
const UPLOAD_IMAGE_TO_ITEM_FAILED = Symbol('UPLOAD_IMAGE_TO_ITEM_FAILED')

const DELETE_IMAGE_FROM_ITEM = Symbol('DELETE_IMAGE_FROM_ITEM')
const DELETE_IMAGE_FROM_ITEM_SUCCESS = Symbol('DELETE_IMAGE_FROM_ITEM_SUCCESS')
const DELETE_IMAGE_FROM_ITEM_FAILURE = Symbol('DELETE_IMAGE_FROM_ITEM_FAILED')

const GET_CANCELLED_INTERNAL_ORDERS = Symbol('GET_CANCELLED_INTERNAL_ORDERS')
const GET_CANCELLED_INTERNAL_ORDERS_SUCCESS = Symbol('GET_CANCELLED_INTERNAL_ORDERS_SUCCESS')
const GET_CANCELLED_INTERNAL_ORDERS_FAILURE = Symbol('GET_CANCELLED_INTERNAL_ORDERS_FAILURE')

const requestGetWarehouseState = () => ({ type: GET_WAREHOUSE_STATE })
const getWarehouseStateFailed = () => ({ type: GET_WAREHOUSE_STATE_FAILED })
const getWarehouseStateSuccess = (data) => ({ type: GET_WAREHOUSE_STATE_SUCCESS, data })

const requestGetWarehouseStateWithBlankItems = () => ({ type: GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS })
const getWarehouseStateWithBlankItemsFailed = () => ({ type: GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS_FAILED })
const getWarehouseStateWithBlankItemsSuccess = (data) => ({ type: GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS_SUCCESS, data })

const requestGetInventoryStateForItem = () => ({ type: GET_INVENTORY_STATE_FOR_ITEM })
const getInventoryStateForItemFailed = () => ({ type: GET_INVENTORY_STATE_FOR_ITEM_FAILED })
const getInventoryStateForItemSuccess = (data) => ({ type: GET_INVENTORY_STATE_FOR_ITEM_SUCCESS, data })

const requestHandOverItemFromInventory = () => ({ type: HAND_OVER_ITEM_FROM_INVENTORY })
const handOverItemFromInventoryFailed = () => ({ type: HAND_OVER_ITEM_FROM_INVENTORY_FAILED })
const handOverItemFromInventorySuccess = (data) => {
  return ({ type: HAND_OVER_ITEM_FROM_INVENTORY_SUCCESS, inventory: R.omit('item', data) })
}

const requestUploadImageToItem = () => ({ type: UPLOAD_IMAGE_TO_ITEM })
const uploadingImageToItemSuccess = (data) => ({ type: UPLOAD_IMAGE_TO_ITEM_SUCCESS, data })
const uploadingImageToItemFailed = () => ({ type: UPLOAD_IMAGE_TO_ITEM_FAILED })

const requestRemoveImageFromItem = () => ({ type: DELETE_IMAGE_FROM_ITEM })
const removingImageFromItemSuccess = (data) => ({ type: DELETE_IMAGE_FROM_ITEM_SUCCESS, data })
const removingImageFromItemFailed = () => ({ type: DELETE_IMAGE_FROM_ITEM_FAILURE })

const requestGetCancelledInternalOrders = () => ({ type: GET_CANCELLED_INTERNAL_ORDERS })
const getCancelledInternalOrdersSuccess = (data) => ({ type: GET_CANCELLED_INTERNAL_ORDERS_SUCCESS, data })
const getCancelledInternalOrdersFailure = () => ({ type: GET_CANCELLED_INTERNAL_ORDERS_FAILURE })

export const getCancelledInternalOrders = () => {
  return async dispatch => {
    dispatch(requestGetCancelledInternalOrders())
    try {
      const { data } = await WarehouseService.getCancelledInternalOrders()
      dispatch(getCancelledInternalOrdersSuccess(data))
    } catch (error) {
      console.log('getCancelledInternalOrders', { error })
      dispatch(getCancelledInternalOrdersFailure())
    }
  }
}

export const addImageToWarehouseStateItem = ({ revisionId, file }) => {
  return async dispatch => {
    dispatch(requestUploadImageToItem())
    try {
      const { data } = await FileUploadService.uploadFile(revisionId, file)
      const unifiedData = R.omit(['fileable'], data)
      dispatch(uploadingImageToItemSuccess({ unifiedData, revisionId }))
      return Promise.resolve(unifiedData)
    } catch (error) {
      dispatch(uploadingImageToItemFailed())
      return Promise.reject(error)
    }
  }
}

export const removeImageFromWarehouseStateItem = ({ revisionId, fileId }) => {
  return async dispatch => {
    dispatch(requestRemoveImageFromItem())
    try {
      await FileUploadService.removeFile(revisionId, fileId)
      dispatch(removingImageFromItemSuccess({ revisionId, fileId }))
      return Promise.resolve()
    } catch (error) {
      dispatch(removingImageFromItemFailed())
      return Promise.reject(error)
    }
  }
}

export const getWarehouseState = (query) => {
  return async dispatch => {
    dispatch(requestGetWarehouseState())
    try {
      const { data } = await WarehouseService.getWarehouseState(query)
      dispatch(getWarehouseStateSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(getWarehouseStateFailed(e))
      return Promise.reject(e)
    }
  }
}

export const getWarehouseStateWithBlankItems = (include) => {
  return async dispatch => {
    dispatch(requestGetWarehouseStateWithBlankItems())
    try {
      const { data } = await WarehouseService.getWarehouseStateWithBlankItems(include)
      dispatch(getWarehouseStateWithBlankItemsSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(getWarehouseStateWithBlankItemsFailed(e))
      return Promise.reject(e)
    }
  }
}

export const getInventoryStateForItem = (itemCode) => {
  return async dispatch => {
    dispatch(requestGetInventoryStateForItem())
    try {
      const { data } = await WarehouseService.getInventoryStateForItem(itemCode)
      return dispatch(getInventoryStateForItemSuccess(data))
    } catch (e) {
      return dispatch(getInventoryStateForItemFailed(e))
    }
  }
}

export const handOverItemFromInventory = ({ quantity, inventoryId, purpose }) => {
  return async dispatch => {
    dispatch(requestHandOverItemFromInventory())
    try {
      const { data, meta } = await WarehouseService.handOverItemFromInventory({ quantity, inventoryId, purpose })
      dispatch(handOverItemFromInventorySuccess(data))
      dispatch(receiveEvents(meta?.eventIds))
      return Promise.resolve(data)
    } catch (e) {
      console.error(e)
      dispatch(handOverItemFromInventoryFailed())
      return Promise.reject(e)
    }
  }
}

const RECALCULATE_INVENTORY_QUANTITY = Symbol('RECALCULATE_INVENTORY_QUANTITY')
export const RECALCULATE_INVENTORY_QUANTITY_SUCCESS = Symbol('RECALCULATE_INVENTORY_QUANTITY_SUCCESS')
const RECALCULATE_INVENTORY_QUANTITY_FAILED = Symbol('RECALCULATE_INVENTORY_QUANTITY_FAILED')

const requestRecalculateInventoryQuantity = () => ({ type: RECALCULATE_INVENTORY_QUANTITY })
const recalculateInventoryQuantityFailed = () => ({ type: RECALCULATE_INVENTORY_QUANTITY_FAILED })
const recalculateInventoryQuantitySuccess = (data) => {
  return ({ type: RECALCULATE_INVENTORY_QUANTITY_SUCCESS, inventory: R.omit('item', data) })
}

export const recalculateInventoryQuantity = ({ quantity, inventoryId }) => {
  return async dispatch => {
    dispatch(requestRecalculateInventoryQuantity())
    try {
      const { data, meta } = await WarehouseService.recalculateInventoryQuantity({ quantity, inventoryId })
      dispatch(recalculateInventoryQuantitySuccess(data))
      dispatch(receiveEvents(meta?.eventIds))
      return Promise.resolve(data)
    } catch (e) {
      console.error(e)
      dispatch(recalculateInventoryQuantityFailed())
      return Promise.reject(e)
    }
  }
}

const requestEditItemRevision = () => ({ type: EDIT_ITEM_REVISION })
const editItemRevisionFailed = () => ({ type: EDIT_ITEM_REVISION_FAILED })
const editItemRevisionSuccess = (data, field, revisionId) => ({ type: EDIT_ITEM_REVISION_SUCCESS, data, field, revisionId })

export const editItemRevision = ({ field, value, revisionId: itemRevisionId }) => {
  return async dispatch => {
    dispatch(requestEditItemRevision())
    try {
      const { data } = await WarehouseService.editItemRevision({ itemRevisionId, field, value })
      dispatch(editItemRevisionSuccess(data, field, itemRevisionId))
      return Promise.resolve(data)
    } catch (e) {
      console.error(e)
      dispatch(editItemRevisionFailed(e))
      return Promise.reject(e)
    }
  }
}

const ADD_ITEM_REVISION_ALTERNATIVE = Symbol('ADD_ITEM_REVISION_ALTERNATIVE')
const ADD_ITEM_REVISION_ALTERNATIVE_SUCCESS = Symbol('ADD_ITEM_REVISION_ALTERNATIVE_SUCCESS')
const ADD_ITEM_REVISION_ALTERNATIVE_FAILED = Symbol('ADD_ITEM_REVISION_ALTERNATIVE_FAILED')

const requestAddItemRevisionAlternative = () => ({ type: ADD_ITEM_REVISION_ALTERNATIVE })
const addItemRevisionAlternativeFailed = () => ({ type: ADD_ITEM_REVISION_ALTERNATIVE_FAILED })
const addItemRevisionAlternativeSuccess = (data, itemRevisionId) => ({ type: ADD_ITEM_REVISION_ALTERNATIVE_SUCCESS, data, itemRevisionId })

export const addItemRevisionAlternative = ({ alternativeId, itemRevisionId }) => {
  return async dispatch => {
    dispatch(requestAddItemRevisionAlternative())
    toast.info('rozpoczęto dodawanie zamiennika', { containerId: 'statuses', autoClose: 8e3 })
    try {
      const { data } = await WarehouseService.addItemRevisionAlternative({ itemRevisionId, alternativeId })
      dispatch(addItemRevisionAlternativeSuccess(data, itemRevisionId))
      return Promise.resolve(data)
    } catch (e) {
      console.error(e)
      dispatch(addItemRevisionAlternativeFailed(e))
      return Promise.reject(e)
    }
  }
}

const REMOVE_ITEM_REVISION_ALTERNATIVE = Symbol('REMOVE_ITEM_REVISION_ALTERNATIVE')
const REMOVE_ITEM_REVISION_ALTERNATIVE_SUCCESS = Symbol('REMOVE_ITEM_REVISION_ALTERNATIVE_SUCCESS')
const REMOVE_ITEM_REVISION_ALTERNATIVE_FAILED = Symbol('REMOVE_ITEM_REVISION_ALTERNATIVE_FAILED')

const requestRemoveItemRevisionAlternative = () => ({ type: REMOVE_ITEM_REVISION_ALTERNATIVE })
const removeItemRevisionAlternativeFailed = () => ({ type: REMOVE_ITEM_REVISION_ALTERNATIVE_FAILED })
const removeItemRevisionAlternativeSuccess = (data, field, revisionId) => ({ type: REMOVE_ITEM_REVISION_ALTERNATIVE_SUCCESS, data, field, revisionId })

export const removeItemRevisionAlternative = ({ alternativeId, itemRevisionId }) => {
  return async dispatch => {
    dispatch(requestRemoveItemRevisionAlternative())
    toast.info('rozpoczęto usuwanie zamiennika', { containerId: 'statuses', autoClose: 8e3 })
    try {
      const { data } = await WarehouseService.removeItemRevisionAlternative({ itemRevisionId, alternativeId })
      dispatch(removeItemRevisionAlternativeSuccess(data, itemRevisionId))
      return Promise.resolve(data)
    } catch (e) {
      console.error(e)
      dispatch(removeItemRevisionAlternativeFailed(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  currentItem: {},
  entries: {},
  allItemEntries: {},
  cancelledInternalOrders: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_WAREHOUSE_STATE_SUCCESS:
      return {
        ...state,
        entries: {
          ...state.entries,
          ...transformById(R.map(el => R.assoc('availableQuantity', state.entries[el.id]?.availableQuantity, el), action.data))
        }
      }
    case GET_WAREHOUSE_STATE_WITH_BLANK_ITEMS_SUCCESS:
      return {
        ...state,
        allItemEntries: {
          ...state.entries,
          ...transformById(R.map(el => R.assoc('availableQuantity', state.entries[el.id]?.availableQuantity, el), action.data))
        }
      }
    case GET_INVENTORY_STATE_FOR_ITEM_SUCCESS:
      return {
        ...state,
        currentItem: action.data,
        entries: {
          ...state.entries,
          [action.data.id]: action.data
        }
      }
    case EDIT_ITEM_REVISION_SUCCESS:
      return R.evolve({
        allItemEntries: R.assoc(action.data.id, action.data)
      }, state)
    case UPDATE_BOM_FIELDS_SUCCESS: {
      return R.evolve({
        allItemEntries: {
          [action.itemId]: R.assoc('assemblyTime', R.path(['data', 'assemblyTime', 'formatted'], action))
        }
      }, state)
    }
    case ADD_ITEM_REVISION_ALTERNATIVE_SUCCESS: {
      toast.success('poprawnie dodano zamiennik', { containerId: 'statuses', autoClose: 8e3 })
      return R.evolve({
        allItemEntries: R.assoc(action.data.id, action.data)
      }, state)
    }
    case REMOVE_ITEM_REVISION_ALTERNATIVE_SUCCESS: {
      toast.success('poprawnie usunięto zamiennik', { containerId: 'statuses', autoClose: 8e3 })
      return R.evolve({
        allItemEntries: R.assoc(action.data.id, action.data)
      }, state)
    }
    case UPLOAD_IMAGE_TO_ITEM_SUCCESS: {
      const currentRevision = state.allItemEntries[action.data.revisionId]
      // as in case of uploading the same image api returns ID which already exists
      // we need to check if we can push unifiedData to store
      const isNewImageIdUnique = !currentRevision.files.some(image => image.id === action.data.unifiedData.id)
      const pushedFilesArray = isNewImageIdUnique ? [...currentRevision.files, action.data.unifiedData] : currentRevision.files
      // as all action takes place in store, and all of it was successfull, toast message will be sent from here
      !isNewImageIdUnique && toast.error('wgrywane zdjęcie już było dodane', { containerId: 'statuses', autoClose: 8e3 })
      return {
        ...state,
        allItemEntries: {
          ...state.allItemEntries,
          [action.data.revisionId]: {
            ...currentRevision,
            files: pushedFilesArray
          }
        }
      }
    }
    case DELETE_IMAGE_FROM_ITEM_SUCCESS: {
      const currentRevision = state.allItemEntries[action.data.revisionId]
      return {
        ...state,
        allItemEntries: {
          ...state.allItemEntries,
          [action.data.revisionId]: {
            ...currentRevision,
            files: currentRevision.files.filter(file => file.id !== action.data.fileId)
          }
        }
      }
    }
    case GET_CANCELLED_INTERNAL_ORDERS_SUCCESS: {
      return {
        ...state,
        cancelledInternalOrders: action.data
      }
    }
    default:
      return state
  }
}
