import React from 'react'
import { Box, Flex } from '@rebass/emotion'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

const translateObjectLabels = element => {
  const { t } = useTranslation()
  return { value: element[0], label: t(element[1]) }
}

export const noFilterTranslated = () => {
  const { t } = useTranslation()
  return { value: null, label: t('warehouse.orders.statuses.noFilter') }
}

const toOptions = (optionsObject) => [ noFilterTranslated(), ...Object.entries(optionsObject).map(translateObjectLabels) ]

export const FiltersBox = ({ optionsObj, value, onChange }) => {
  return (
    <Box width={300}>
      <Select options={toOptions(optionsObj)} value={value} onChange={onChange} />
    </Box>
  )
}

export const filteredOrders = (ordersArr, ordersFilter) => {
  return ordersArr
    .filter(item => ordersFilter.value === null || item.status === ordersFilter.value)
}

export const filteredHandOvers = (handOversArr, handedOverFilter) => handOversArr.filter(handOver => handedOverFilter.value === null || handOver.status === handedOverFilter.value)

export const NoItemsToShow = () => {
  const { t } = useTranslation()
  return (
    <Flex style={{ justifyContent: 'center', fontSize: '2em' }} >
      <p>{t('warehouse.orders.NoItemsToShow')}</p>
    </Flex>
  )
}
