import styled from '@emotion/styled'
import theme from 'config/theme'
import { ReactComponent as TrashIcon } from 'assets/trash-can.svg'
import { ReactComponent as EditIcon } from 'assets/edit.svg'

export const StyledCell = styled('td')`
    text-align: center;
    
    & > div {
      display: inline-block;
      margin: 0 auto;
    }
  `
export const StyledRow = styled('tr')`
    line-height: 50px;
  `

export const StyledTableHeader = styled('th')`
    font-size: 1.1rem;
  `

export const StyledTrashIcon = styled(TrashIcon)`
  width: 25px;
  
  &:hover {
    cursor: pointer;
    fill: ${theme.colors.error};
  }
`

export const StyledEditIcon = styled(EditIcon)`
  width: 25px;
  margin-right: 10px;
  
  &:hover {
    cursor: pointer;
    fill: ${theme.colors.error};
  }
`

export const ModalButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  
  & > div {
    width: 200px;
  }
`
