import React, { useEffect, useState, Fragment } from 'react'
import Breadcrumb from 'components/Breadcrumb'
import { useTranslation } from 'react-i18next'
import Container from 'components/Container'
import ItemDescription from 'components/ItemDescription'
import { withRouter } from 'react-router-dom'
import Divider from 'components/Divider'
import LabelledDivider from 'components/LabelledDivider'
import OnboardItemFormConnector from 'connectors/forms/OnboardItemFormConnector'
import QrCodeReader from 'components/QrCodeReader'
import Routes from 'constants/Routes'
import LoadingOverlay from 'components/LoadingOverlay'

const OnBoardInboundItem = ({ match: { params: { itemCode }, path }, location, searchForItemOnInboundZone, currentItem }) => {
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const isInternalSubmission = path === Routes.internalSubmissionView
  const searchItem = async () => {
    try {
      setError('')
      setLoading(true)
      await searchForItemOnInboundZone(itemCode)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      setError(e)
    }
  }

  useEffect(() => {
    !isInternalSubmission && searchItem()
  }, [itemCode])

  return (
    <div>
      <Breadcrumb>{isInternalSubmission ? t('warehouse.internalSubmissionsList') : t('warehouse.onboard')}</Breadcrumb>
      {isLoading && <LoadingOverlay />}
      {!isLoading && !error &&
      <Fragment>
        <Divider noMargin thin />
        <ItemDescription item={isInternalSubmission ? location?.state?.item : currentItem} />
        <Divider noMargin thin />
        <LabelledDivider text={t('warehouse.itemCount')} />
        <OnboardItemFormConnector item={isInternalSubmission ? location?.state?.item : currentItem} isInternalSubmission={isInternalSubmission} />
      </Fragment>
      }
      <Container>
        &nbsp;
      </Container>
      {error &&
      <Container>
        {!isInternalSubmission && <QrCodeReader method='replace' destination='/on-boarding/item' invisible />}
        <h3>{t(error)}</h3>
      </Container>
      }
    </div>
  )
}

export default withRouter(OnBoardInboundItem)
