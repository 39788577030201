/** @jsx jsx */
import { jsx } from '@emotion/core'
import { Fragment, useEffect } from 'react'
import PusherComponent from 'react-pusher'
import { showNotification } from 'helpers/notifiacations'

const PusherNotifications = ({ userId, getUnreadNotifications, markNotificationAsRead }) => {
  useEffect(() => {
    getUnreadNotifications()
  }, [])

  const handleShowNotification = data => showNotification(data, markNotificationAsRead)
  return (
    <Fragment>
      {userId &&
      <PusherComponent
        channel={`private-App.Models.User.${userId}`}
        event='Illuminate\Notifications\Events\BroadcastNotificationCreated'
        onUpdate={handleShowNotification}
      />
      }
    </Fragment>
  )
}

export default PusherNotifications
