/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ReactTable from 'react-table'
import { Box, Flex } from '@rebass/emotion'
import ProgressBar from 'components/ProgressBar'
import theme from 'config/theme'
import moment from 'moment'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import * as R from 'ramda'
import Tooltip from 'components/Tooltip'
import * as styles from './styles'
import TableCellEdit from 'components/TableCellEdit'
import Button from 'components/Button'
import { transformById } from 'helpers/redux'
import { getGeneralInfoColumns } from 'views/WeeklyPlans/commonColumns'
import { useTranslation } from 'react-i18next'
import EditStatus from 'components/EditStatus'
import { iterable } from 'helpers/array'
import { handleFloatNumber } from 'helpers/number'
import { ReactComponent as Arrow } from 'assets/arrow.svg'

const isCurrentWeek = (week, year, today) => moment().week(week).year(year).isSame(today, 'week')
const isThisYearFuturePlan = (week, year, today) => moment().week(week).year(year).isSameOrAfter(today, 'week')

const generateWeeksColumns = (lookForChanges, visibleWeeks, today) => {
  return visibleWeeks.map(({ week, year }) => {
    const isCurrent = isCurrentWeek(week, year, today)
    const weekIndex = week - 1
    return ({
      Header: () => <span id={`W${week}`}>{week}</span>,
      id: 'week' + week,
      maxWidth: 75,
      headerStyle: {
        backgroundColor: isCurrent ? 'rgba(72, 146, 199, .4)' : theme.colors.transparent
      },
      accessor: item => R.not(R.isEmpty(item.weeklyPlans)) ? item.weeklyPlans[year]?.[weekIndex]?.quantity : '',
      style: {
        textAlign: 'center',
        padding: 0
      },
      Cell: row => {
        if (isThisYearFuturePlan(week, year, today)) {
          const onSave = (value, shouldForceUpdate) => row.original.updatePlan(weekIndex, value, shouldForceUpdate, year)
          return row.original.hasChildren
            ? (
              <TableCellEdit
                style={isCurrent
                  ? {
                    borderRight: `4px solid ${theme.colors.lightBlue}`,
                    borderLeft: `4px solid ${theme.colors.lightBlue}`
                  }
                  : {}
                }
                css={styles.weekCell(row, year, weekIndex)}
                editable={row.parts}
                lookForChanges={lookForChanges}
                onSave={onSave}
              >
                {handleFloatNumber(row.original?.weeklyPlans[year]?.[weekIndex]?.quantity)}
              </TableCellEdit>
            )
            : <div
              style={isCurrent ? {
                borderRight: `4px solid ${theme.colors.lightBlue}`,
                borderLeft: `4px solid ${theme.colors.lightBlue}`
              } : {}}
              css={styles.weekCell(row, year, weekIndex)}>
              {handleFloatNumber(row.original?.weeklyPlans[year]?.[weekIndex]?.quantity)}
            </div>
        } else {
          return <Tooltip
            trigger={'click'}
            content={<div style={{ padding: 5 }}>
              <p style={{ fontSize: '1.2em', fontWeight: 700, padding: '10px 0' }}>
                Wykonano:
              </p>
              <p style={{ fontSize: '1.2em', fontWeight: 700, color: theme.colors.dustyGray }}>
                {row.original.weeklyPlans &&
                `${handleFloatNumber(row.original.weeklyPlans[year]?.[weekIndex]?.manufactured)} / ${handleFloatNumber(row.original.weeklyPlans[year]?.[weekIndex]?.quantity)}`}
              </p>
            </div>}
          >
            <div css={styles.weekCellDisabled}>{handleFloatNumber(row.value)}</div>
          </Tooltip>
        }
      }
    })
  })
}

const columns = (t, renderIndexCell, setVisibleIds, lookForChanges, visibleWeeks, today) => [
  {
    columns: getGeneralInfoColumns(t, setVisibleIds, renderIndexCell)
  },
  {
    columns: generateWeeksColumns(lookForChanges, visibleWeeks, today)
  },
  {
    style: { backgroundColor: theme.colors.successGreen },
    columns: [
      {
        Header: 'wykonano',
        id: 'manufactured',
        accessor: d => d.weeklyPlans ? d.weeklyPlans[moment().year()][moment().format('W') - 1]?.manufactured : '',
        minWidth: 105,
        headerStyle: { borderLeft: `3px solid ${theme.colors.black}` },
        style: { textAlign: 'center', padding: 0, borderLeft: `3px solid ${theme.colors.black}` },
        Cell: row => {
          const hasChildren = row.original.hasChildren
          const currentPlanQuantity = row.original.weeklyPlans ? row.original.weeklyPlans[moment().year()][moment().format('W') - 1]?.quantity : 0
          return <div css={styles.commonCell(row.original.depth)}>
            {hasChildren && (row.value > currentPlanQuantity
              ? <p>{row.value} (+<span style={{ color: theme.colors.orange }}>{row.value - currentPlanQuantity}</span>)
              </p>
              : row.value)}
          </div>
        }
      },
      {
        Header: 'postęp',
        accessor: 'progress',
        minWidth: 200,
        headerStyle: { borderLeft: `1px solid ${theme.colors.black}`, borderRight: `1px solid ${theme.colors.black}` },
        style: {
          padding: 0,
          height: 32,
          display: 'flex',
          alignItems: 'center',
          borderLeft: `1px solid ${theme.colors.black}`,
          borderRight: `1px solid ${theme.colors.black}`
        },
        Cell: props => {
          const currentPlan = props.original.weeklyPlans ? props.original.weeklyPlans[moment().year()][moment().week() - 1] : null
          const shouldDisplayProgressBar = props.original.hasChildren
          const manufactured = currentPlan ? currentPlan.manufactured : 0
          const plan = currentPlan ? currentPlan.quantity : 0

          if (shouldDisplayProgressBar) {
            return <ProgressBar current={manufactured} max={plan} depth={props.original.depth} />
          } else {
            return <div css={styles.commonCell(props.original.depth)} />
          }
        }
      },
      {
        Header: 'plan',
        id: 'plan',
        minWidth: 105,
        accessor: d => d.weeklyPlans ? d.weeklyPlans[moment().year()][moment().format('W') - 1]?.quantity : '',
        headerStyle: { borderRight: `3px solid ${theme.colors.black}` },
        style: { textAlign: 'center', padding: 0, borderRight: `3px solid ${theme.colors.black}` },
        Cell: row => {
          const hasChildren = row.original.hasChildren
          return <div css={styles.commonCell(row.original.depth)}>{hasChildren && row.value}</div>
        }
      }
    ]
  }
]

const generateTreeLines = (depth, isLast) => {
  const emptyArray = Array.apply(0, Array(depth))
  return emptyArray.map((lineNumber, index, allLines) => {
    return <span key={index} css={styles.treeLines(depth, index, isLast && (index + 1) === allLines.length)} />
  })
}

const WeeklyPlans = (props) => {
  const { t } = useTranslation()
  const [allPlans, setPlans] = useState([])
  const today = useMemo(() => moment(), [])
  const currentYear = useMemo(() => today.year(), [today])
  const currentWeek = useMemo(() => today.week(), [today])

  const alreadyFetchedYears = useMemo(() => {
    const years = props.bomItems.reduce((all, next) => {
      all.push(Object.keys(next.weeklyPlans))
      return all
    }, [])
    return R.pipe(R.flatten, R.uniq)(years)
  }, [props.bomItems])

  const [visibleWeeks, setVisibleWeeks] = useState([])
  const [weeksOffset, changeWeeksOffset] = useState(0)

  useEffect(() => {
    const newVisibleWeeks = iterable(10).map(e => {
      const today = moment().add(weeksOffset + e, 'weeks')
      return ({
        week: today.week(),
        year: today.weekYear()
      })
    })
    setVisibleWeeks(newVisibleWeeks)
  }, [weeksOffset])

  useEffect(() => {
    if (visibleWeeks.length && !props.fetchingYears.length) {
      const firstVisibleNotFetched = R.not(alreadyFetchedYears.includes(String(visibleWeeks[0].year)))
      const lastVisibleNotFetched = R.not(alreadyFetchedYears.includes(String(visibleWeeks[9].year)))
      firstVisibleNotFetched && getBomItemsAndHandleThem(visibleWeeks[0].year)
      lastVisibleNotFetched && getBomItemsAndHandleThem(visibleWeeks[9].year)
    }
  }, [visibleWeeks, alreadyFetchedYears, props.fetchingYears])

  const [visibleIds, setVisible] = useState([])

  const isVisible = id => visibleIds.includes(id)

  useEffect(() => {
    setPlans(props.bomItems)
  }, [props.bomItems])

  useEffect(() => {
    if (!allPlans.length && props.bomItems.length) {
      setVisible(R.uniq([props.bomItems[0]?.id]))
    }
  }, [props.bomItems, allPlans])

  const updateAllPlans = (parentPath, weeklyPlanIndex, newValue, shouldForceUpdate, year) => {
    const combinedPath = [...parentPath, 'weeklyPlans', year, weeklyPlanIndex, 'quantity']
    const newPlans = R.assocPath(combinedPath, Number(newValue), allPlans)
    setPlans(shouldForceUpdate ? R.assocPath(R.append('shouldForceUpdate', combinedPath.slice(0, -1)), shouldForceUpdate, newPlans) : newPlans)
  }

  useEffect(() => {
    lookForChanges()
  }, [allPlans, props.bomItems])

  const itemsEvolve = (parent) => {
    const currentWeekIndex = currentWeek - 1
    const transformFunction = R.map(item => {
      const weeklyPlansToMap = R.has('weeklyPlans', item) ? item.weeklyPlans : parent.weeklyPlans
      const weeklyPlansForChild = R.mapObjIndexed((yearPlans, year) => {
        return yearPlans.map((plan, index, allPlans) => {
          const calculatedQuantity = (parent.weeklyPlans[year][index]?.quantity * item.quantity)
          const correctQuantity = plan.id !== parent.weeklyPlans[year][index]?.id && R.has('quantity', plan) && !parent.weeklyPlans[year][index].shouldForceUpdate
            ? plan.quantity
            : calculatedQuantity
          const calculatedPlan = R.assoc(
            'quantity',
            correctQuantity,
            plan
          )
          const plansForConsecutiveWeeks = allPlans.slice(currentWeekIndex, index + 1).reduce((sum, currPlan) => (
            plan.id !== parent.weeklyPlans[year][index].id || item.weeklyPlans
              ? currPlan.quantity
              : (currPlan.quantity * item.quantity)
          ) + sum, 0)
          const planWithCanProduce = R.assoc(
            'canProduce',
            props.bomItemsQuantities[item.id]?.quantity - plansForConsecutiveWeeks >= 0,
            calculatedPlan
          )
          return R.assoc('shouldForceUpdate', parent.weeklyPlans[year][index].shouldForceUpdate, planWithCanProduce)
        })
      }, weeklyPlansToMap)
      const itemWithWeeklyPlan = R.assoc(
        'weeklyPlans',
        weeklyPlansForChild,
        item
      )
      return R.evolve(itemsEvolve(itemWithWeeklyPlan), itemWithWeeklyPlan)
    })

    return ({ parts: transformFunction })
  }

  const prepareData = (data, depth = 0) => data.reduce((all, next, parentIndex) => {
    const nextItems = R.propOr([], 'parts', R.evolve(itemsEvolve(next), next))
    all.push({
      ...R.evolve(itemsEvolve(next), next),
      depth: depth,
      hasChildren: !R.isEmpty(nextItems),
      warehouseQuantity: props.bomItemsQuantities[next.id]?.quantity,
      updatePlan: (i, newValue, shouldForceUpdate, year) => {
        updateAllPlans([parentIndex], i, newValue, shouldForceUpdate, year)
      }
    })
    const flatten = (flatDepth, parentPath = [parentIndex, 'parts']) => (item, index, allItems) => {
      const nextItems = R.propOr([], 'parts', R.evolve(itemsEvolve(item), item))
      const hasChildren = !R.isEmpty(nextItems)
      const path = [...parentPath, index]
      all.push({
        ...item,
        depth: flatDepth,
        hasChildren,
        warehouseQuantity: props.bomItemsQuantities[item.id]?.quantity,
        isLast: (index + 1) === allItems.length,
        pathToThisItem: path,
        parentId: next.id,
        updatePlan: (i, newValue, shouldForceUpdate, year) => {
          updateAllPlans(path, i, newValue, shouldForceUpdate, year)
        }
      })

      item.parts && isVisible(next.id + item.id) && nextItems.map(flatten(flatDepth + 1, [...path, 'parts']))
    }
    isVisible(next.id) && nextItems.map(flatten(depth + 1))
    return all
  }, [])

  const setVisibleIds = (id) => {
    const newVisibleIds = id.length === 36 && R.find(id => R.equals(R.length(id), 36), visibleIds)
      ? visibleIds.filter(id => R.not(R.equals(R.length(id), 36)))
      : visibleIds
    return visibleIds.includes(id)
      ? setVisible(newVisibleIds.filter(visibleId => visibleId !== id))
      : setVisible([...newVisibleIds, id])
  }

  const renderIndexCell = useCallback((row) => {
    const id = row.original.parentId ? row.original.parentId + row.original.id : row.original.id
    const isItemVisible = R.includes(id, visibleIds)
    return (
      <div
        onClick={() => setVisibleIds(id)}
        css={styles.expanderCell(isItemVisible, row.original.depth, row.original.hasChildren)}
      >
        {generateTreeLines(row.original.depth, row.original.isLast)}
        <div css={styles.indexCell(row.original.depth, row.original.hasChildren)}>
          {row.value}
        </div>
      </div>
    )
  }, [visibleIds])

  const [isLoading, setLoading] = useState(false)
  const [data, setData] = useState({ oldData: {}, newData: [] })
  const [hasChanges, setHasChanges] = useState(false)

  useEffect(() => {
    setHasChanges(!!data.newData.length)
  }, [data])

  const getBomItemsAndHandleThem = async (year) => {
    try {
      setLoading(true)
      await props.getBomItems(year)
      await props.getBomItemsQuantities()
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const lookForChanges = () => {
    const getPlansFromItemIfHasChilds = R.pipe(
      R.ifElse(
        R.has('parts'),
        R.propOr({}, 'weeklyPlans'),
        R.always({})
      ),
      R.values,
      R.flatten,
      R.map(R.pick(['id', 'quantity', 'year']))
    )

    const mapItems = R.map(item => [...getPlansFromItemIfHasChilds(item), mapItems(item.parts || [])])
    const getData = data => R.flatten(mapItems(prepareData(data)))
    const oldData = transformById(getData(props.bomItems))
    const newData = getData(allPlans).filter(plan => plan.quantity !== oldData[plan.id]?.quantity)

    setData({ oldData: oldData, newData: newData })
  }

  const saveChanges = async () => {
    const changedYears = data.newData.reduce((years, nextData) => {
      years.push(nextData.year)
      return R.uniq(years)
    }, [])
    try {
      setLoading(true)
      await props.updateWeeklyPlans(data.newData)
      await Promise.all(changedYears.map(getBomItemsAndHandleThem))
      setLoading(false)
    } catch (e) {
      setLoading(false)
    }
  }

  const getWeekDate = () => {
    const fromDate = today.startOf('isoWeek').format('DD-MM-YYYY')
    const toDate = today.endOf('isoWeek').format('DD-MM-YYYY')

    return {
      from: fromDate,
      to: toDate
    }
  }

  const foldAllUnfolded = () => {
    setVisible([])
  }

  useEffect(() => {
    let refreshInterval = setInterval(() => {
      !hasChanges && !isLoading && getBomItemsAndHandleThem(currentYear)
    }, 60e3)
    return () => clearInterval(refreshInterval)
  }, [hasChanges, isLoading])

  const getWeeksForTableHeader = () => {
    return visibleWeeks.map((visibleWeek, i) => {
      const isAnotherYear = visibleWeek.year !== currentYear
      return (
        <div key={'visibleWeek' + i} css={styles.headerCellCss}>
          <span>{visibleWeek.week}</span>
          {isAnotherYear && <span css={{ fontSize: 10 }}> / {visibleWeek.year}</span>}
        </div>
      )
    })
  }

  const resetPlans = () => {
    setPlans(props.bomItems)
    lookForChanges()
  }

  const incrementOffset = () => changeWeeksOffset(weeksOffset + 1)
  const decrementOffset = () => changeWeeksOffset(weeksOffset - 1)

  return (
    <Box mx={10}>
      <Flex justifyContent={'space-between'} alignItems={'center'}>
        <Flex flexDirection={'column'} mb={3} mt={6}>
          <h1 css={{ fontSize: 35 }}>{t('warehouse.weeklyPlans')}</h1>
          <h3 css={{ fontSize: 20 }}>{`${t('weeklyPlans.currentWeek')} ${today.format('W')}`}</h3>
          <h3 css={{ fontSize: 20 }}>{`${getWeekDate().from} - ${getWeekDate().to}`}</h3>
        </Flex>
        <Flex css={{ paddingRight: 30 }} alignItems={'center'}>
          <Box css={{ width: 600, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box width={1 / 4}>
              <Button
                style={{ height: '100%' }}
                color={theme.colors.navyBlue}
                iconProps={{ fill: theme.colors.white }}
                onClick={foldAllUnfolded}
              >
                {t('weeklyPlans.fold')}
              </Button>
            </Box>
            <Box width={1 / 4}>
              <Button
                css={{ padding: 11 }}
                outline
                color={theme.colors.red}
                onClick={resetPlans}
                disabled={isLoading || !hasChanges}
              >
                {t('weeklyPlansForeman.reset')}
              </Button>
            </Box>
            <Box width={1 / 4}>
              <Button
                color={theme.colors.navyBlue}
                disabled={isLoading || !hasChanges}
                primary
                iconProps={{ fill: theme.colors.white }}
                onClick={saveChanges}
              >
                {t('weeklyPlansForeman.save')}
              </Button>
            </Box>
          </Box>
          <EditStatus
            status={!hasChanges ? 'accepted' : 'edited'}
            acceptedText={t('weeklyPlansForeman.status.accepted')}
            editedText={t('weeklyPlansForeman.status.edited')}
          />
        </Flex>
      </Flex>
      <div css={styles.header}>
        <div />
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <span onClick={decrementOffset} css={{ paddingLeft: 15, cursor: 'pointer' }}>
            {<Arrow fill={theme.colors.black} style={{ transform: 'rotate(180deg)' }} />}
          </span>
          <span>{t('weeklyPlans.warehouse|plan')}</span>
          <span onClick={incrementOffset} css={{ paddingRight: 15, cursor: 'pointer' }}>
            {<Arrow fill={theme.colors.black} />}
          </span>
        </Flex>
        <div>{t('weeklyPlans.workProgress')}</div>
      </div>
      <div css={styles.headerDetails}>
        <div css={styles.headerCellCss}>indeks</div>
        <div css={styles.headerCellCss}>nazwa</div>
        <div
          css={styles.headerCellCss}
          style={{
            borderRight: `3px solid ${theme.colors.darkGrey}`,
            borderLeft: `3px solid ${theme.colors.darkGrey}`,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          magazyn
        </div>
        {getWeeksForTableHeader()}
        <div
          css={styles.headerCellCss}
          style={{
            borderLeft: `3px solid ${theme.colors.darkGrey}`,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          wykonano
        </div>
        <div css={styles.headerCellCss}>postęp</div>
        <div
          css={styles.headerCellCss}
          style={{
            borderRight: `3px solid ${theme.colors.darkGrey}`,
            borderTopWidth: 0,
            borderBottomWidth: 0
          }}
        >
          plan
        </div>
      </div>
      <ReactTable
        data={prepareData(allPlans)}
        columns={columns(t, renderIndexCell, setVisibleIds, lookForChanges, visibleWeeks, today)}
        showPagination={false}
        minRows={17}
        className={'-highlight -striped'}
        sortable={false}
        resizable={false}
        filterable={false}
        defaultPageSize={200}
        NoDataComponent={() => null}
        style={{ width: '1790px' }}
        {...styles.tableProps}
      />
      <Flex justifyContent={'center'} alignItems={'center'} my={5}>
        <Flex flexDirection={'column'}>
          <p css={{ fontSize: 30 }}><strong>{t('weeklyPlans.legend.submittingChanges')}</strong></p>
          <p><strong>Enter: </strong>{t('weeklyPlans.legend.recalculatesBOM')}</p>
          <p><strong>Shift + Enter: </strong>{t('weeklyPlans.legend.recalculatesBOMwithElements')}</p>
        </Flex>
      </Flex>
    </Box>
  )
}

export default WeeklyPlans
