import styled from '@emotion/styled'

const Divider = styled('div')(
  ({ theme, ...props }) => {
    const getThickness = () => {
      switch (true) {
        case props.thick:
          return 6
        case props.thin:
          return 1
        case props.medium:
          return 2
        default:
          return 3
      }
    }
    return ({
      width: '100%',
      height: getThickness(),
      margin: !props.noMargin && `${theme.space[6]}px ${theme.space[0]}px`,
      backgroundColor: props.color || theme.colors.darkGrey
    })
  }
)

export default Divider
