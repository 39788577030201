import InternalOrdersService from 'services/InternalOrdersService'
import { transformById, itemRemover } from 'helpers/redux'

const CREATE_INTERNAL_ORDER = Symbol('CREATE_INTERNAL_ORDER')
const CREATE_INTERNAL_ORDER_SUCCESS = Symbol('CREATE_INTERNAL_ORDER_SUCCESS')
const CREATE_INTERNAL_ORDER_FAILED = Symbol('CREATE_INTERNAL_ORDER_FAILED')

const requestCreateInternalOrder = () => ({ type: CREATE_INTERNAL_ORDER })
const createInternalOrderSuccess = (data) => ({ type: CREATE_INTERNAL_ORDER_SUCCESS, data })
const createInternalOrderFailed = () => ({ type: CREATE_INTERNAL_ORDER_FAILED })

export const createInternalOrder = (values) => {
  return async dispatch => {
    dispatch(requestCreateInternalOrder())
    try {
      const { data } = await InternalOrdersService.createInternalOrder(values)
      dispatch(createInternalOrderSuccess(data))
      return Promise.resolve(data)
    } catch (e) {
      dispatch(createInternalOrderFailed(e))
      return Promise.reject(e)
    }
  }
}

const GET_PERSONAL_ORDERS = Symbol('GET_PERSONAL_ORDERS')
const GET_PERSONAL_ORDERS_SUCCESS = Symbol('GET_PERSONAL_ORDERS_SUCCESS')
const GET_PERSONAL_ORDERS_FAILED = Symbol('GET_PERSONAL_ORDERS_FAILED')

const requestGetPersonalOrders = () => ({ type: GET_PERSONAL_ORDERS })
const getPersonalOrdersSuccess = (data) => ({ type: GET_PERSONAL_ORDERS_SUCCESS, data })
const getPersonalOrdersFailed = () => ({ type: GET_PERSONAL_ORDERS_FAILED })

export const getPersonalOrders = () => {
  return async dispatch => {
    dispatch(requestGetPersonalOrders())
    try {
      const { data } = await InternalOrdersService.getPersonalOrders()
      return dispatch(getPersonalOrdersSuccess(data))
    } catch (e) {
      return dispatch(getPersonalOrdersFailed(e))
    }
  }
}

const CONFIRM_INTERNAL_ORDER_DELIVERY = Symbol('CONFIRM_INTERNAL_ORDER_DELIVERY')
const CONFIRM_INTERNAL_ORDER_DELIVERY_SUCCESS = Symbol('CONFIRM_INTERNAL_ORDER_DELIVERY_SUCCESS')
const CONFIRM_INTERNAL_ORDER_DELIVERY_FAILED = Symbol('CONFIRM_INTERNAL_ORDER_DELIVERY_FAILED')

const requestConfirmInternalOrderDelivery = () => ({ type: CONFIRM_INTERNAL_ORDER_DELIVERY })
const confirmInternalOrderDeliverySuccess = (data) => ({ type: CONFIRM_INTERNAL_ORDER_DELIVERY_SUCCESS, data })
const confirmInternalOrderDeliveryFailed = () => ({ type: CONFIRM_INTERNAL_ORDER_DELIVERY_FAILED })

export const confirmInternalOrderDelivery = (values) => {
  return async dispatch => {
    dispatch(requestConfirmInternalOrderDelivery())
    try {
      const { data } = await InternalOrdersService.confirmInternalOrderDelivery(values)
      return dispatch(confirmInternalOrderDeliverySuccess(data))
    } catch (e) {
      return dispatch(confirmInternalOrderDeliveryFailed(e))
    }
  }
}

const DELETE_INTERNAL_ORDER_REQUEST = Symbol('DELETE_INTERNAL_ORDER_REQUEST')
const DELETE_INTERNAL_ORDER_SUCCESS = Symbol('DELETE_INTERNAL_ORDER_SUCCESS')
const DELETE_INTERNAL_ORDER_FAILED = Symbol('DELETE_INTERNAL_ORDER_FAILED')

const requestDeleteInternalOrder = () => ({ type: DELETE_INTERNAL_ORDER_REQUEST })
const deleteInternalOrderSuccess = (orderId, itemId) => ({ type: DELETE_INTERNAL_ORDER_SUCCESS, orderId, itemId })
const deleteInternalOrderFailed = () => ({ type: DELETE_INTERNAL_ORDER_FAILED })

export const deleteInternalOrder = (itemId, orderId) => {
  return async dispatch => {
    dispatch(requestDeleteInternalOrder())
    try {
      await InternalOrdersService.deleteOrderedItem(itemId)
      dispatch(deleteInternalOrderSuccess(orderId, itemId))
      return Promise.resolve()
    } catch (e) {
      dispatch(deleteInternalOrderFailed(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  entries: {}
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_PERSONAL_ORDERS_SUCCESS:
      return {
        ...state,
        entries: transformById(action.data)
      }
    case CONFIRM_INTERNAL_ORDER_DELIVERY_SUCCESS:
      const orderId = action.data?.internalOrderItem?.internalOrderId
      return {
        ...state,
        entries: {
          ...state.entries,
          [orderId]: {
            ...state.entries[orderId],
            ...action.data?.internalOrderItem?.order,
            items: [...state.entries[orderId].items.filter(item => item.id !== action.data?.internalOrderItem.id), action.data?.internalOrderItem]
          }
        }
      }
    case DELETE_INTERNAL_ORDER_SUCCESS:
      return {
        ...state,
        entries: itemRemover(state.entries, action.orderId, action.itemId)
      }
    default:
      return state
  }
}
