import WeeklyPlansService from 'services/WeeklyPlans'
import { transformById, transformToArray } from 'helpers/redux'
import * as R from 'ramda'

const GET_BOM_ITEMS_REQUEST = Symbol('GET_BOM_ITEMS_REQUEST')
const GET_BOM_ITEMS_SUCCESS = Symbol('GET_BOM_ITEMS_SUCCESS')
const GET_BOM_ITEMS_FAILED = Symbol('GET_BOM_ITEMS_FAILED')

const getBomItemsRequest = (year) => ({ type: GET_BOM_ITEMS_REQUEST, year })
const getBomItemsSuccess = (data, year) => ({ type: GET_BOM_ITEMS_SUCCESS, data, year })
const getBomItemsFailed = () => ({ type: GET_BOM_ITEMS_FAILED })

export const getBomItems = (year) => {
  return async dispatch => {
    dispatch(getBomItemsRequest(year))
    try {
      const { data } = await WeeklyPlansService.getBomItems(year)
      dispatch(getBomItemsSuccess(data, year))
    } catch (e) {
      dispatch(getBomItemsFailed(e))
    }
  }
}

const GET_DAILY_BOM_ITEMS_REQUEST = Symbol('GET_DAILY_BOM_ITEMS_REQUEST')
const GET_DAILY_BOM_ITEMS_SUCCESS = Symbol('GET_DAILY_BOM_ITEMS_SUCCESS')
const GET_DAILY_BOM_ITEMS_FAILED = Symbol('GET_DAILY_BOM_ITEMS_FAILED')

const getDailyBomItemsRequest = () => ({ type: GET_DAILY_BOM_ITEMS_REQUEST })
const getDailyBomItemsSuccess = (data, week) => ({ type: GET_DAILY_BOM_ITEMS_SUCCESS, data, week })
const getDailyBomItemsFailed = () => ({ type: GET_DAILY_BOM_ITEMS_FAILED })

export const getDailyBomItems = (week) => {
  return async dispatch => {
    dispatch(getDailyBomItemsRequest())
    try {
      const { data } = await WeeklyPlansService.getDailyBomItems(week)
      dispatch(getDailyBomItemsSuccess(data, week))
    } catch (e) {
      dispatch(getDailyBomItemsFailed(e))
    }
  }
}

const GET_BOM_ITEMS_QUANTITIES_REQUEST = Symbol('GET_BOM_ITEMS_QUANTITIES_REQUEST')
const GET_BOM_ITEMS_QUANTITIES_SUCCESS = Symbol('GET_BOM_ITEMS_QUANTITIES_SUCCESS')
const GET_BOM_ITEMS_QUANTITIES_FAILED = Symbol('GET_BOM_ITEMS_QUANTITIES_FAILED')

const getBomItemsQuantitiesRequest = () => ({ type: GET_BOM_ITEMS_QUANTITIES_REQUEST })
const getBomItemsQuantitiesSuccess = (data) => ({ type: GET_BOM_ITEMS_QUANTITIES_SUCCESS, data })
const getBomItemsQuantitiesFailed = () => ({ type: GET_BOM_ITEMS_QUANTITIES_FAILED })

export const getBomItemsQuantities = () => {
  return async dispatch => {
    dispatch(getBomItemsQuantitiesRequest())
    try {
      const { data } = await WeeklyPlansService.getBomItemsQuantities()
      dispatch(getBomItemsQuantitiesSuccess(data))
    } catch (e) {
      dispatch(getBomItemsQuantitiesFailed(e))
    }
  }
}

const UPDATE_WEEKLY_PLANS_REQUEST = Symbol('UPDATE_WEEKLY_PLANS_REQUEST')
const UPDATE_WEEKLY_PLANS_SUCCESS = Symbol('UPDATE_WEEKLY_PLANS_SUCCESS')
const UPDATE_WEEKLY_PLANS_FAILED = Symbol('UPDATE_WEEKLY_PLANS_FAILED')

const updateWeeklyPlansRequest = () => ({ type: UPDATE_WEEKLY_PLANS_REQUEST })
const updateWeeklyPlansSuccess = (data) => ({ type: UPDATE_WEEKLY_PLANS_SUCCESS, data })
const updateWeeklyPlansFailed = () => ({ type: UPDATE_WEEKLY_PLANS_FAILED })

export const updateWeeklyPlans = data => {
  return async dispatch => {
    dispatch(updateWeeklyPlansRequest())
    try {
      const { data: apiResponse } = await WeeklyPlansService.updateWeeklyPlans(data)
      dispatch(updateWeeklyPlansSuccess(apiResponse))
      return Promise.resolve(apiResponse)
    } catch (e) {
      dispatch(updateWeeklyPlansFailed(e))
      return Promise.reject(e)
    }
  }
}

const UPDATE_DAILY_PLANS_REQUEST = Symbol('UPDATE_DAILY_PLANS_REQUEST')
const UPDATE_DAILY_PLANS_SUCCESS = Symbol('UPDATE_DAILY_PLANS_SUCCESS')
const UPDATE_DAILY_PLANS_FAILED = Symbol('UPDATE_DAILY_PLANS_FAILED')

const updateDailyPlansRequest = () => ({ type: UPDATE_DAILY_PLANS_REQUEST })
const updateDailyPlansSuccess = (data, week) => ({ type: UPDATE_DAILY_PLANS_SUCCESS, data, week })
const updateDailyPlansFailed = () => ({ type: UPDATE_DAILY_PLANS_FAILED })

export const updateDailyPlans = (data, week) => {
  return async dispatch => {
    dispatch(updateDailyPlansRequest())
    try {
      const { data: apiResponse } = await WeeklyPlansService.updateDailyPlans(data)
      dispatch(updateDailyPlansSuccess(apiResponse, week))
      return Promise.resolve(apiResponse)
    } catch (e) {
      dispatch(updateDailyPlansFailed(e))
      return Promise.reject(e)
    }
  }
}

const EXECUTE_WEEKLY_PLAN_MATERIAL_ORDER_REQUEST = Symbol('EXECUTE_WEEKLY_PLAN_MATERIAL_ORDER_REQUEST')
const EXECUTE_WEEKLY_PLAN_MATERIAL_ORDER_SUCCESS = Symbol('EXECUTE_WEEKLY_PLAN_MATERIAL_ORDER_SUCCESS')
const EXECUTE_WEEKLY_PLAN_MATERIAL_ORDER_FAILED = Symbol('EXECUTE_WEEKLY_PLAN_MATERIAL_ORDER_FAILED')

const executeWeeklyPlanMaterialsOrderRequest = () => ({ type: EXECUTE_WEEKLY_PLAN_MATERIAL_ORDER_REQUEST })
const executeWeeklyPlanMaterialsOrderSuccess = (data, week) => ({ type: EXECUTE_WEEKLY_PLAN_MATERIAL_ORDER_SUCCESS, data, week })
const executeWeeklyPlanMaterialsOrderFailed = () => ({ type: EXECUTE_WEEKLY_PLAN_MATERIAL_ORDER_FAILED })

export const executeWeeklyPlanMaterialsOrder = ({ week, year }) => {
  return async dispatch => {
    dispatch(executeWeeklyPlanMaterialsOrderRequest())
    try {
      await WeeklyPlansService.executeWeeklyPlanMaterialsOrder({ week, year })
      dispatch(executeWeeklyPlanMaterialsOrderSuccess())
      return Promise.resolve()
    } catch (e) {
      dispatch(executeWeeklyPlanMaterialsOrderFailed(e))
      return Promise.reject(e)
    }
  }
}

const initState = {
  entries: {},
  dailyPlansEntries: {},
  availableInWarehouse: {},
  fetchingYears: []
}

export default (state = initState, action) => {
  switch (action.type) {
    case GET_BOM_ITEMS_REQUEST:
      return R.evolve({
        fetchingYears: R.append(action.year)
      }, state)
    case GET_BOM_ITEMS_SUCCESS:
      const sortWeeklyPlansByYear = item => R.evolve({
        parts: R.map(sortWeeklyPlansByYear),
        weeklyPlans: R.applySpec({
          [action.year]: R.identity
        })
      }, item)

      const newData = R.pipe(R.map(sortWeeklyPlansByYear), transformById)(action.data)

      const mergeWithParts = (key, left, right) => {
        if (key === 'parts') {
          return left.map((part, index) => R.mergeDeepWithKey(mergeWithParts, left[index], right[index]))
        }
        return left
      }

      return R.evolve({
        entries: R.mergeDeepWithKey(mergeWithParts, newData),
        fetchingYears: R.reject(R.equals(action.year))
      }, state)
    case GET_BOM_ITEMS_QUANTITIES_SUCCESS:
      return {
        ...state,
        availableInWarehouse: transformById(action.data, 'itemRevisionId')
      }
    case GET_DAILY_BOM_ITEMS_SUCCESS:
      return {
        ...state,
        dailyPlansEntries: {
          ...state.dailyPlansEntries,
          [action.week]: transformById(action.data)
        }
      }
    case UPDATE_DAILY_PLANS_SUCCESS:
      const newDailyPlansData = transformById(action.data)
      const makeReplacingDailyPlanFunctionWithValue = value => R.assoc('quantity', value.quantity)
      const mapValuesToNewValue = R.pipe(
        transformById,
        R.evolve(
          R.mapObjIndexed(makeReplacingDailyPlanFunctionWithValue, newDailyPlansData)
        ),
        transformToArray
      )
      const deeplyEvolveDailyPlansToNewData = item => R.evolve({
        dailyPlans: mapValuesToNewValue
      }, item)
      const transformedDailyPlanEntries = R.map(deeplyEvolveDailyPlansToNewData, state.dailyPlansEntries[action.week])
      return {
        ...state,
        dailyPlansEntries: {
          ...state.dailyPlansEntries,
          [action.week]: transformedDailyPlanEntries
        }
      }
    default:
      return state
  }
}
