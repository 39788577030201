/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useEffect, useState } from 'react'
import { Box, Flex } from '@rebass/emotion'
import theme from 'config/theme'
import LoadingOverlay from 'components/LoadingOverlay'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { useTranslation } from 'react-i18next'
import {
  centerStyles,
  containerStyles,
  flexStartStyles,
  getFlexStyles,
  modalContentStyles,
  wrapperStyles,
  spanStyles
} from 'views/Users/styles'
import UsersEditFormConnector from 'connectors/forms/UsersEditFormConnector'
import * as R from 'ramda'
import { includesInArray } from 'helpers/array'
import { Roles } from 'constants/Roles'

const UserRow = ({ user, index, onEdit }) => {
  const { firstname, lastname, roles } = user
  const { t } = useTranslation()

  const TextField = ({ children }) => <span css={spanStyles}>{children}</span>

  return (
    <Flex css={getFlexStyles()}>
      <Flex css={centerStyles}>
        <TextField>{index}</TextField>
      </Flex>
      <Flex css={flexStartStyles}>
        <TextField>{firstname}</TextField>
      </Flex>
      <Flex css={flexStartStyles}>
        <TextField>{lastname}</TextField>
      </Flex>
      <Flex css={flexStartStyles}>
        {roles && roles.map(role => (
          <TextField key={role}>{t(`userEdit.roles.${role}`)}</TextField>
        ))}
      </Flex>
      <Flex css={centerStyles}>
        <Button
          color={theme.colors.navyBlue}
          onClick={onEdit}
        >
          {t(`userEdit.edit`)}
        </Button>
      </Flex>
    </Flex>
  )
}

const userModal = (user, closeModal) => {
  const userWithoutArray = {
    ...user,
    role: R.path(['roles', 0], user),
    assemblyPosts: R.map(assemblyPost => ({
      value: assemblyPost.id,
      label: assemblyPost.number
        ? `${assemblyPost.number} ${assemblyPost.name} ${assemblyPost.subindex || ''}`
        : assemblyPost.name
    }), user.assemblyPosts)
  }

  return (
    <Flex css={modalContentStyles}>
      <UsersEditFormConnector user={userWithoutArray} initialValues={userWithoutArray} closeModal={closeModal} />
    </Flex>
  )
}

const Users = ({ users, getUsers, wasFetched, userRoles }) => {
  const [userModalData, setIsUserModalOpened] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    getUsers()
  }, [])

  const isAdmin = includesInArray(userRoles, [Roles.ADMIN])

  const emptyUser = {
    lastname: '',
    firstname: '',
    roles: [],
    name: '',
    assemblyPosts: []
  }

  const setUserModalData = user => () => setIsUserModalOpened(user)
  const closeModal = () => {
    getUsers()
    setIsUserModalOpened(false)
  }

  return (!wasFetched) ? <LoadingOverlay /> : (
    <Box mx={10} my={10}>
      <Flex css={containerStyles}>
        <Flex css={wrapperStyles}>
          <Flex css={getFlexStyles(true)}>
            <Flex css={centerStyles}>{t(`userEdit.index`)}</Flex>
            <Flex css={flexStartStyles}>{t(`userEdit.firstname`)}</Flex>
            <Flex css={flexStartStyles}>{t(`userEdit.lastname`)}</Flex>
            <Flex css={flexStartStyles}>{t(`userEdit.roles.roles`)}</Flex>
            <Flex css={centerStyles}>
              {isAdmin && (
                <Button
                  color={theme.colors.successGreen}
                  onClick={setUserModalData(emptyUser)}>
                  {t(`userEdit.add`)}
                </Button>
              )}
            </Flex>
          </Flex>
          {!users.length ? <Flex p={10} css={centerStyles}>{t(`userEdit.noUsers`)}</Flex> : (
            users.map((user, i) => {
              return (
                <UserRow
                  onEdit={setUserModalData(user)}
                  user={user}
                  index={i + 1}
                  key={i} />
              )
            })
          )}
        </Flex>
        {userModalData && (
          <Modal
            headerTitle={userModalData.id
              ? `${t(`userEdit.title`)}: ${userModalData.fullName}`
              : t(`userEdit.addUser`)}
            onClose={() => setIsUserModalOpened(false)}
          >
            {userModal(userModalData, closeModal)}
          </Modal>
        )}
      </Flex>
    </Box>
  )
}

export default Users
