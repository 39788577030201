import { useCallback, useState } from 'react'

const useOpenState = (defaultState = false) => {
  const [open, setOpen] = useState(defaultState)

  const handleOpen = useCallback(() => {
    setOpen(true)
  }, [setOpen])

  const handleClose = useCallback(() => {
    setOpen(false)
  }, [setOpen])
  return [open, handleOpen, handleClose]
}

export default useOpenState
