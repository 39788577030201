import { connect } from 'react-redux'
import MainMenu from 'views/MainMenu'
import { receiveInput } from 'ducks/searchInput'

const mapStateToProps = state => {
  return {
    userRole: state.user.roles[0]
  }
}

const mapDispatchToProps = dispatch => ({
  resetSearchInput: () => dispatch(receiveInput(''))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu)
