/** @jsx jsx */
import * as PropTypes from 'prop-types'
import { jsx, css } from '@emotion/core'
import { useTranslation } from 'react-i18next'
import { Fragment, useState } from 'react'
import { Flex } from '@rebass/emotion'
import { ReactComponent as Cancel } from 'assets/cancel.svg'
import { ReactComponent as Loader } from 'assets/loader.svg'
import theme from 'config/theme'
import stocktakingStatuses from 'constants/stocktakingStatuses'
import * as styles from 'views/WarehouseState/styles'

const differenceCss = {
  color: theme.colors.orange
}

const removeIconCss = css`
    cursor: pointer;
    width: 20px;
    height: 20px;
`
const StocktakingItem = (props) => {
  const { t } = useTranslation()
  const [isDeleting, setDeleting] = useState(false)
  const handleOrderDelete = async () => {
    try {
      setDeleting(true)
      await props.delete(props.stocktakingIssue.id)
    } catch (e) {
      setDeleting(false)
    }
  }

  const difference = Number(props.stocktakingIssue.difference)
  const shouldDisplayDelete = props.stocktakingIssue.status === stocktakingStatuses.PENDING
  return <Flex width={9 / 12} p={6} css={styles.rowWrapperCssNoPointer} alignItems='center'>
    <Flex width={0.5 / 9}>
      {shouldDisplayDelete && (
        isDeleting
          ? <Loader stroke={theme.colors.black} />
          : <Cancel css={removeIconCss} onClick={handleOrderDelete} />
      )}
    </Flex>
    <Flex width={1.5 / 9} as='h3'>{props.stocktakingIssue.itemRevision.fullIndex}</Flex>
    <Flex width={3 / 9} as='p'>{props.stocktakingIssue.itemRevision.name}</Flex>
    <Flex width={1 / 9} as='h3'>
      {props.stocktakingIssue.status === 'finished' &&
      <Fragment>
        {difference
          ? <Fragment>
            {props.stocktakingIssue.expectedQuantity}&nbsp;&nbsp;
            <span css={differenceCss}>{difference}</span>
          </Fragment>
          : <span>-&nbsp;-</span>

        }
      </Fragment>
      }
    </Flex>
    <Flex width={3 / 9} as='h3'>{t(`warehouse.stocktaking.statuses.${props.stocktakingIssue.status}`)}</Flex>
  </Flex>
}

export default StocktakingItem

StocktakingItem.propTypes = {
  delete: PropTypes.func.isRequired,
  stocktakingIssue: PropTypes.object.isRequired
}
