/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import theme from 'config/theme'

const ProgressBar = (props) => {
  const countProgress = (Number(props.current) / Number(props.max)) * 100

  const wrapperCss = css`
  display: flex;
  align-items: center;
  height: 20px;
  padding: 0 10px;
  width: 100%;
  text-align: center;
  position: relative;
`
  const barCss = css`
  height: 50%;
  background: #98e698;
  opacity: ${props.depth && props.depth > 1 ? 0.5 : 1};
  width: ${Math.min(countProgress, 100) || 0}%;
`

  const percentageCss = css`
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    color: ${props.depth && props.depth > 1 ? theme.colors.grey : theme.colors.black};
    opacity: ${props.depth && props.depth > 1 ? 0.5 : 1};
`
  return (
    <div css={wrapperCss}>
      <span css={percentageCss}>{`${parseInt(countProgress) || 0}%`}</span>
      <div css={barCss} />
    </div>
  )
}

export default ProgressBar
