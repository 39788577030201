import HandOverView from 'views/HandOverView'
import { connect } from 'react-redux'
import { getInventoryStateForItem } from 'ducks/warehouseState'
import { getCurrentItem } from 'selectors/warehouseState'

const mapStateToProps = state => ({
  currentItem: getCurrentItem(state)
})

const mapDispatchToProps = dispatch => ({
  getInventoryStateForItem: (itemCode) => dispatch(getInventoryStateForItem(itemCode))
})

export default connect(mapStateToProps, mapDispatchToProps)(HandOverView)
