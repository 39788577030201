/** @jsx jsx */
import { css } from '@emotion/core'
import theme from 'config/theme'

export const weekCell = (row, year, weekIndex) => {
  const isOnWarehouse = row.original.weeklyPlans[year]?.[weekIndex]?.canProduce
  const isDifferentThanZero = row.original.weeklyPlans[year]?.[weekIndex]?.quantity !== 0
  const hasChildren = row.original.hasOwnProperty('parts')
  const previousPlanCanBeProduced = row.original.weeklyPlans[weekIndex - 1]?.canProduce
  const depth = row.original.depth
  const isFirstRow = depth === 0
  const previousPlanCanBeProducedForOtherRow = hasChildren && row.original.parts.every(part => part.weeklyPlans[weekIndex - 1]?.canProduce)
  const everyChildCanBeProduced = hasChildren
    ? row.original.parts?.every(child => child.weeklyPlans[year]?.[weekIndex]?.canProduce)
    : false
  const cannotProduceWeekI = hasChildren && row?.original?.parts?.some(part => !part.weeklyPlans[year]?.[weekIndex]?.canProduce)
  const cannotProduceAny = row.original.weeklyPlans[year]?.every(plan => !plan.canProduce)
  const thickness = row.original.depth === 0 ? 600 : 400
  const selectCellCircleColor = () => {
    const depth = row.original.depth
    const canProduce = (parts, w = weekIndex) => parts.every(part => part.weeklyPlans[year]?.[w]?.canProduce || (part.parts && canProduce(part.parts, w)))
    switch (true) {
      case isFirstRow && canProduce(row.original.parts) && isDifferentThanZero:
        return css`
        color: ${isFirstRow ? theme.colors.darkGrey : theme.colors.grey};
        opacity: ${depth > 1 ? 0.5 : 1};
        
          &:after {
           content: '';
           position: absolute;
           width: 10px;
           height: 10px;
           top: 5px;
           right: 5px;
           border-radius: 50%;
           background-color: rgba(87, 170, 98, 1);
           opacity: ${!isFirstRow ? 0.5 : 1};
           }
         `
      case isFirstRow && !canProduce(row.original.parts) && isDifferentThanZero && !everyChildCanBeProduced && canProduce(row.original.parts, weekIndex - 1):
        return css`
           background-color: rgba(255, 88, 84, 1);
           opacity: ${!isFirstRow ? 0.5 : 1};
           color: ${theme.colors.white};
         `
      case !isFirstRow && isOnWarehouse && !everyChildCanBeProduced && isDifferentThanZero && hasChildren:
        return css`
        color: ${isFirstRow ? theme.colors.darkGrey : theme.colors.grey};
        opacity: ${depth > 1 ? 0.5 : 1};
        
        &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          top: 5px;
          right: 5px;
          border-radius: 50%;
          background-color: #ff9400;
          opacity: ${!isFirstRow ? 0.5 : 1};
          }
        `
      case (isOnWarehouse || everyChildCanBeProduced) && isDifferentThanZero:
        return css`
        color: ${isFirstRow ? theme.colors.darkGrey : theme.colors.grey};
          opacity: ${depth > 1 ? 0.5 : 1};        
          &:after {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          top: 5px;
          right: 5px;
          border-radius: 50%;
          background-color: rgb(87, 170, 98);
          opacity: ${!isFirstRow ? 0.5 : 1};
          }
        `
      case !isOnWarehouse && !everyChildCanBeProduced && previousPlanCanBeProduced && isDifferentThanZero:
        return css`
          background-color: rgb(255, 88, 84);
          color: ${theme.colors.white};
          opacity: ${!isFirstRow ? 0.5 : 1};
        `
      case !isOnWarehouse && cannotProduceWeekI && isDifferentThanZero && previousPlanCanBeProducedForOtherRow:
        return css`
          background-color: rgb(255, 88, 84);
          color: ${theme.colors.white};
          opacity: ${!isFirstRow ? 0.5 : 1};
        `
      case !isFirstRow && cannotProduceAny && isDifferentThanZero:
        return css`
          background-color: rgb(255, 88, 84);
          color: ${theme.colors.white};
          opacity: ${!isFirstRow ? 0.5 : 1};
        `
      default:
        return css`
        background-color: transparent;
        color: ${isFirstRow ? theme.colors.darkGrey : theme.colors.grey};
        opacity: ${depth > 1 ? 0.5 : 1};
        `
    }
  }

  return css`
    height: 32px;
    padding: 0;
    line-height: 32px;
    width: 100%;
    font-weight: ${thickness};
    position: relative;
    ${selectCellCircleColor()}
  `
}

export const weekCellDisabled = css`
  height: 32px;
  line-height: 32px;
  width: 100%;
  background-color: ${theme.colors.lightGrey};
  color: white;
  cursor: help;
`
export const header = css`
  display: grid;
  width: 100%;
  grid-template-columns: 551px 833px 407px;
  
  & > div {
    border-bottom: none;
    padding: 10px 0;
    text-align: center;
    border-top: none;
    font-weight: 700;
    border-right: 3px solid ${theme.colors.darkGrey};
    
    &:not(:first-of-type) {
      border-bottom: 3px solid ${theme.colors.darkGrey};
    }
  }
`

export const headerDetails = css`
  display: grid;
  position: sticky;
  top: 70px;
  border: none;
  width: 100%;
  grid-template-columns: 200px 348px 82px repeat(9, 75px) 76px 105px 200px 105px;
  z-index: 1;
  box-shadow: 0 1px 3px ${theme.colors.lightGrey};
  
  & > div {
    padding: 10px 0;
    text-align: center;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.catskillWhite};
  }
  `

export const headerDetailsForeman = css`
  display: grid;
  position: sticky;
  top: 70px;
  width: 100%;
  grid-template-columns: 151px 370px 70px 80px 90px 90px 50px 70px repeat(4, 100px) 99px 110px 105px 106px;
  z-index: 1;
  box-shadow: 0 1px 3px ${theme.colors.lightGrey};
  
  & > div {
    padding: 10px 0;
    text-align: center;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.catskillWhite};
  }
  `

export const foremanHeader = css`
  display: grid;
  width: 100%;
  grid-template-columns: 524px 147px 300px 612px 208px;

  & > div {
    border-bottom: none;
    border-top: none;
    padding: 10px 0;
    text-align: center;
    font-weight: 700;
    border-right: 3px solid ${theme.colors.darkGrey};
    
    &:not(:first-of-type) {
      border-bottom: 3px solid ${theme.colors.darkGrey};
    }
  }
`
export const nameCell = (depth, hasChildren) => css`
  padding-left: ${depth * 10}px;
  cursor: ${hasChildren ? 'pointer' : 'default'};
  height: 32px;
  line-height: 32px;
  width: 100%;
  font-weight: ${depth === 0 ? 600 : 400};
  color: ${depth === 0 ? theme.colors.darkGrey : theme.colors.grey};
  opacity: ${depth > 1 ? 0.5 : 1};
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  
`
export const commonCell = depth => css`
  height: 32px;
  line-height: 32px;
  width: 100%;
  font-weight: ${depth === 0 ? 600 : 400};
  color: ${depth === 0 ? theme.colors.darkGrey : theme.colors.grey};
  opacity: ${depth > 1 ? 0.5 : 1};
`

export const weeklyPlanColumn = (depth) => css`
  height: 32px;
  line-height: 32px;
  width: 100%;
  font-weight: ${depth === 0 ? 600 : 400};
  color: ${depth === 0 ? theme.colors.darkGrey : theme.colors.grey};
  position: relative;
`

export const componentPlannedIndicatorCss = (wholePlanPlannedCorrectly) => css`
  position: absolute;
  width: 7px;
  height: 7px;
  top: 0;
  right: 0;
  border-radius: 50%;
  background-color: ${wholePlanPlannedCorrectly ? theme.colors.successGreen : theme.colors.error};
`

export const componentPlannedIndicatorTooltipCss = css`
  position: absolute;
  top: 5px;
  right: 5px;
`

export const treeLines = (depth, i, isLast) => css`
  height: ${isLast ? 16 : 32}px;
  width: 1px;
  background-color: ${theme.colors.black};
  position: absolute;
  left: ${10 + ((i + 1) * 20)}px;

  &:before {
    opacity: ${i + 1 === depth ? 1 : 0};
    content: '';
    position: absolute;
    top: ${isLast ? 100 : 50}%;
    left: 0;
    width: 10px;
    height: 1px;
    border-top: 1px solid ${theme.colors.black};
  }
`
export const expanderCell = (isItemVisible, depth, hasChildren) => css`
  font-weight: ${depth === 0 ? 600 : 400};
  color: ${depth === 0 ? theme.colors.darkGrey : theme.colors.grey};
  height: 32px;
  line-height: 32px;
  width: 100%;
  position: relative;
    &:before {
    opacity: ${hasChildren ? 1 : 0};
    content: "";
    position: absolute;
    top: ${isItemVisible ? '30%' : '38%'};
    left: ${5 + (depth > 0 ? 10 : 0)}px;
    width: 5px;
    height: 5px;
    border: 2px solid ${theme.colors.darkGrey};
    border-top: none;
    border-left: none;
    transform: ${isItemVisible ? 'rotate(45deg)' : 'rotate(-45deg)'}
  `
export const indexCell = (depth, hasChildren) => css`
  padding-left: 15px;
  left: ${10 + (depth * 20)}px;
  cursor: ${hasChildren ? 'pointer' : 'default'};
  position: relative;
  height: 32px;
`

export const headerCellCss = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const tableProps = {
  getTdProps: () => ({
    style: {
      borderRight: '1px solid lightgray',
      borderBottom: '1px solid lightgray'
    }
  }),
  getTheadGroupProps: () => ({
    style: {
      display: 'none'
    }
  }),
  getTheadTrProps: () => ({
    style: {
      display: 'none'
    }
  }),
  getTheadProps: () => ({
    style: {
      position: 'relative',
      borderBottom: `3px solid ${theme.colors.darkGrey}`,
      borderRight: `2px solid ${theme.colors.darkGrey}`
    }
  }),
  getTheadThProps: () => ({
    style: {
      lineHeight: '32px'
    }
  }),
  getTrGroupProps: () => ({
    style: {
      border: 'none'
    }
  }),
  getTbodyProps: () => ({
    style: {
      overflowX: 'hidden'
    }
  }),
  getTableProps: () => ({
    style: {
      overflowX: 'hidden'
    }
  })
}
