/** @jsx jsx */
import { jsx, css } from '@emotion/core'
import APIInterceptor from 'services/APIInterceptor'
import { Fragment, useEffect, useRef, useState } from 'react'
import Button from 'components/Button'
import getSerialNumberFromUrl from 'helpers/getSerialNumberFromUrl'
import theme from 'config/theme'
import useInput from 'hooks/useInput'
import * as R from 'ramda'
import LoadingOverlay from 'components/LoadingOverlay'
import Routes from 'constants/Routes'
import { confirmAlert } from 'react-confirm-alert'
import { useTranslation } from 'react-i18next'
import useKeyHandler from 'hooks/useKeyHandler'
import SteamJetGenerator from 'components/SteamJetGenerator'
import { errorHandler } from 'helpers/errorHandler'
import print from 'print-js'
import { toast } from 'react-toastify'

const flippedPath = R.flip(R.path)
const isNotEmpty = R.complement(R.isEmpty)

const AssemblyDetailsScreen = (props) => {
  const { t } = useTranslation()
  const [value, onChange] = useInput()
  const [assemblyData, setAssemblyData] = useState({})
  const [loading, setLoading] = useState(false)

  const fetchData = async serialNumber => {
    try {
      setLoading(true)
      const { data } = await APIInterceptor.get(`/assembly/serial/${serialNumber}`)
      setLoading(false)
      if (data) {
        setAssemblyData(data)
      }
    } catch (e) {
      setLoading(false)
      errorHandler(e)
    }
  }

  const scanHandler = (scannedCode) => {
    const serialNumberForSteamJet = getSerialNumberFromUrl(scannedCode)
    onChange('')
    props.history.push(Routes.assemblyDetails.replace(':serialNumber', serialNumberForSteamJet || scannedCode))
  }

  useEffect(() => {
    props.match.params.serialNumber && fetchData(props.match.params.serialNumber)
  }, [props.match.params.serialNumber])

  const deleteAssembly = (SN) => async () => {
    try {
      await APIInterceptor.delete(`/assembly/serial`, { serial_number: SN })
      props.history.push(Routes.assemblyDetails.replace('/:serialNumber', ''))
      setAssemblyData({})
    } catch (e) {
      console.error(e)
    }
  }

  const handleClick = () => {
    scanHandler(value)
  }

  const inputRef = useRef(null)

  useKeyHandler(scanHandler, inputRef, true)

  const getMappedItem = (data, key = 'assembly', retrieveData = R.identity, mapPartsFunction = R.identity) => R.pipe(
    (data) => R.or(R.path(['assembledElements', 0], data), R.path(['externalParts', 0], data)),
    R.evolve({
      parts: R.pipe(
        R.filter(R.propSatisfies(isNotEmpty, key)),
        R.map(mapPartsFunction)
      )
    }),
    retrieveData
  )(data)

  const item = getMappedItem(assemblyData, 'assembly', R.identity, R.path(['assembly', 0]))

  const itemExternalParts = getMappedItem(
    assemblyData,
    'externalParts',
    R.prop('parts'),
    R.pipe(
      item => R.assoc('registeredBy', R.path(['externalParts', 0, 'user', 'fullName'], item), item),
      item => R.assoc('registeredAt', R.path(['externalParts', 0, 'createdAt'], item), item),
      item => R.assoc('serialNumber', R.path(['externalParts', 0, 'serialNumber'], item), item)
    )
  )

  const usedFor = R.propOr({}, 'usedFor', item)
  const itemPath = flippedPath(item)
  const usedForPath = flippedPath(usedFor)
  const isFinalAssembly = item?.itemRevision?.finalAssemblyMmi

  const confirmDeleteModal = () => confirmAlert({
    title: t('assemblyDetails.confirmDelete') + ` ${itemPath(['serialNumber'])}`,
    message: t('assemblyDetails.confirmDeleteQuestion'),
    buttons: [
      {
        label: t('catalogue.confirmationDialogue.yes'),
        onClick: deleteAssembly(itemPath(['serialNumber']))
      },
      {
        label: t('catalogue.confirmationDialogue.no')
      }
    ]
  })

  const printReport = () => print({
    printable: process.env.REACT_APP_API_URL + `/assembly/product_card/${itemPath(['serialNumber'])}`,
    onError: error => toast.error(error, { containerId: 'statuses' })
  })

  const headers = (itemGetter) => {
    const isAssembled = itemGetter(['assembledAt'])
    return (
      <Fragment>
        <div css={headerCss}>{t('assemblyDetails.index')}</div>
        <div css={headerCss}>{t('assemblyDetails.name')}</div>
        <div css={headerCss}>{t('assemblyDetails.serialNumber')}</div>
        <div css={headerCss}>{isAssembled ? t('assemblyDetails.producedBy') : t('assemblyDetails.receivedBy')}</div>
        <div css={headerCss}>{isAssembled ? t('assemblyDetails.productionDate') : t('assemblyDetails.receivedDate')}</div>
        {isAssembled ? <div css={headerCss}>{t('assemblyDetails.workstation')}</div> : <div />}
      </Fragment>
    )
  }

  const getDetails = (itemGetter, withRedirect) => {
    const isZeroAssembly = itemGetter(['serialNumber']).match(/^00000000-0000-0000-0000-000000000000$/ig)
    return (
      <Fragment key={itemGetter(['itemRevision', 'id'])}>
        <div css={cellCss}>{itemGetter(['itemRevision', 'fullIndex'])}</div>
        <div css={cellCss}>{itemGetter(['itemRevision', 'productionName']) || itemGetter(['itemRevision', 'name'])}</div>
        {withRedirect
          ? (
            <div css={isZeroAssembly ? cellCss : cellLinkCss} onClick={() => isZeroAssembly ? null : scanHandler(itemGetter(['serialNumber']))}>
              {itemGetter(['serialNumber'])}
            </div>
          )
          : <div css={cellCss}>{itemGetter(['serialNumber'])}</div>
        }
        <div css={cellCss}>{R.or(itemGetter(['assembledBy']), itemGetter(['registeredBy']))}</div>
        <div css={cellCss}>{R.or(itemGetter(['assembledAt']), itemGetter(['registeredAt']))}</div>
        {itemGetter(['assembledAt']) ? <div css={cellCss}>{itemGetter(['assemblyPost'])}</div> : <div />}
      </Fragment>
    )
  }
  return (
    <div css={wrapperCss}>
      {process.env.NODE_ENV === 'development' && <SteamJetGenerator code={'1-8-43435C3'} />}
      <div css={inputWrapperCss}>
        <h3>{t('assemblyDetails.scanOrTypeSerialNumber')}:</h3>
        <input
          ref={inputRef}
          css={{ padding: '10px 5px', border: `2px solid ${theme.colors.darkGrey}`, borderRadius: '7.5px' }}
          value={value}
          onChange={onChange}
          type='text'
          name='handOverQuantityInput'
        />
        <Button css={actionButtonCss} onClick={handleClick}>{t('assemblyDetails.search')}</Button>
      </div>
      {isNotEmpty(item) &&
        <Fragment>
          <h1>{t('assemblyDetails.details')}:</h1>
          <div css={gridCss}>
            {headers(itemPath)}
            {getDetails(itemPath)}
          </div>
          <div css={actionsCss}>
            {isFinalAssembly && <Button onClick={printReport} css={actionButtonCss} color={theme.colors.successGreen}>{t('assemblyDetails.printReport')}</Button>}
            {R.isEmpty(usedFor) && props.isAdmin && <Button onClick={confirmDeleteModal} css={actionButtonCss} color={theme.colors.error}>{t('assemblyDetails.delete')}</Button>}
          </div>
          {isNotEmpty(usedFor) && (
            <Fragment>
              <h1>Użyto do wyprodukowania</h1>
              <div css={gridCss}>
                {headers(usedForPath)}
                {getDetails(usedForPath, true)}
              </div>
            </Fragment>
          )}
          {R.has('parts', item) && isNotEmpty(item.parts) && (
            <Fragment>
              <h1>{t('assemblyDetails.usedParts')}</h1>
              <div css={gridCss}>
                {headers(flippedPath(R.pathOr({}, ['parts', 0], item)))}
                {item.parts.map(part => {
                  const partPath = flippedPath(part)
                  return getDetails(partPath, true)
                })}
              </div>
            </Fragment>
          )}
          {itemExternalParts && isNotEmpty(itemExternalParts) && (
            <Fragment>
              <h1>{t('assemblyDetails.usedExternalParts')}</h1>
              <div css={gridCss}>
                {headers(flippedPath(R.propOr({}, 0, itemExternalParts)))}
                {itemExternalParts.map(part => {
                  const partPath = flippedPath(part)
                  return getDetails(partPath, true)
                })}
              </div>
            </Fragment>
          )}
        </Fragment>
      }
      {loading && (
        <div>
          <LoadingOverlay />
        </div>
      )}
    </div>
  )
}

const gridCss = css`
  display: grid;
  grid-template-columns: 1fr repeat(5, 2fr);
  padding: 20px;
`

const cellCss = css`
  padding: 16px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
`

const actionsCss = css`
  padding: 16px;
  display: flex;
`

const actionButtonCss = css`
  width: unset;
  margin: unset;
`

const cellLinkCss = css`
  ${cellCss};
  cursor: pointer;
  transition: all .2s;
  &:hover {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.7);
  }
`

const headerCss = css`
  padding: 16px;
`

const wrapperCss = css`
  padding: 64px;
`

const inputWrapperCss = css`
  display: flex;
  padding: 20px;
  align-items: center;

  & > * {
    margin: 5px;
  }
`

export default AssemblyDetailsScreen
