export const EMPTY_WORKSTATION = {
  name: '',
  number: '',
  breakLength: '',
  isServiceAllowed: false,
  subindex: '',
  startsAt: '',
  worktime: '',
  parentAssemblyPostId: ''
}
