import { css } from '@emotion/core'
import theme from 'config/theme'

export const rowWrapperCss = css`
  border-bottom: 1px solid #DADADA;
  cursor: pointer;
  margin-bottom: 4px;
  color: #444;
`

export const rowWrapperCssNoPointer = css`
  border-bottom: 1px solid #DADADA;
  cursor: default;
  color: #444;
  
  &:hover {
    position: relative;
    margin: -2px 0 -1px;
    border-top: 2px solid #AAA;
    border-bottom: 2px solid #AAA;
  }
`

export const rowAboveSelectedCss = css`
  border-bottom: unset;
  cursor: pointer;
  color: #444;
  margin-bottom: 0;
`

export const selectedRowCss = css`
  border-top: 2px solid #AAA;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    border-bottom: 2px solid #AAA;
    width: 68%;
    bottom: 0;
    left: 0;
  }

  color: #444;
`

export const selectAllCss = css`
  position: relative;
  
  &:after {
    position: absolute;
    content: '';
    border-bottom: 1px solid #DADADA;
    width: calc(100% + 32px);
    bottom: -16px;
    left: -16px;
  }
`

export const restContentCss = css`
  position: absolute;
  top: 200%;
  left: 0;
  width: 100%;
`

export const buttonCss = css`
  width: auto;
  padding: 5px 10px;
  
  &:disabled {
    pointer-events: none;
    cursor: default;
    background-color: ${theme.colors.grey};
  }
`

export const searchBarCss = css`
  border: none;
  border-bottom: 3px solid black;
  padding: 10px;
  font-size: 18px;
  width: 300px;
`

export const resetCss = css`
  display: inline-block;
  position: relative;
  cursor: pointer;
  right: 25px;
  top: 5px;
`

export const searchWrapperCss = css`
  position: relative;
`

export const linkCss = css`
  text-decoration: underline;
  color: ${theme.colors.black};
  margin-left: 20px;
`

export const arrowCss = css`
  position: relative;
  top: 3px;
`

export const linkWrapperCss = css`
  display: flex;
`

export const iconDimensions = {
  width: 20,
  height: 20
}

export const nameCss = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 40px;
`

export const errorMsgCss = css`
  text-align: center;
  color: ${theme.colors.error};
  padding-top: 10px;
`
export const selectOptionsCss = css`
  width: 150px;
  margin:  0 15px;
`
export const filterBoxCss = css`
  height: 80;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  alignItems: center;
`
