import APIInterceptor from './APIInterceptor'
import StorageService, { LocalStorageKeys } from './StorageService'
import ProductionStationsService from './ProductionStationsService'

const interceptor = APIInterceptor

const AuthService = {
  login: async ({ pin }) => {
    let body
    const stationId = StorageService.get(LocalStorageKeys.stationId)
    if (stationId) {
      const { data: productionStations } = await ProductionStationsService.getProductionStations()
      const isStationIdInProductionStations = productionStations
        .reduce((stations, next) =>
          [
            ...stations,
            next.id,
            ...next.subPosts?.map(post => post.id) || []
          ], [])
        .some(id => id === stationId)
      !isStationIdInProductionStations && StorageService.remove(LocalStorageKeys.stationId)
      body = isStationIdInProductionStations ? { pin, assembly_post_id: stationId } : { pin }
    } else {
      body = { pin }
    }
    const loginResponse = await interceptor.post('/login', body)
    StorageService.set(LocalStorageKeys.token, loginResponse?.headers?.authorization)
    return loginResponse
  },
  logout: () => interceptor.get('/logout')
}

export default AuthService
