import MainCatalogueView from 'views/Catalogue/MainCatalogueView'
import { connect } from 'react-redux'
import { addImageToWarehouseStateItem, getWarehouseStateWithBlankItems, removeImageFromWarehouseStateItem } from 'ducks/warehouseState'
import { getWarehouseStateAllItemsAsArray } from 'selectors/warehouseState'
import { getItemsConfigIfNeeded } from 'ducks/itemsConfig'
import { getUserRoles } from 'selectors/user'

const mapStateToProps = state => ({
  items: getWarehouseStateAllItemsAsArray(state),
  userRoles: getUserRoles(state)
})

const mapDispatchToProps = dispatch => ({
  getWarehouseStateWithBlankItems: () => dispatch(getWarehouseStateWithBlankItems({ include_boms: 1 })),
  getItemsConfigIfNeeded: () => dispatch(getItemsConfigIfNeeded()),
  addImage: data => dispatch(addImageToWarehouseStateItem(data)),
  removeImage: data => dispatch(removeImageFromWarehouseStateItem(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MainCatalogueView)
