import RecalculateForm from 'forms/RecalculateForm'
import { connect } from 'react-redux'
import { reduxForm } from 'redux-form'
import { isEmpty } from 'ramda'
import i18n from 'i18next'

const formName = 'RecalculateForm'

const Form = reduxForm({
  form: formName,
  enableReinitialize: true,
  validate: (values) => {
    const errors = {}
    const requiredFields = ['quantity']
    requiredFields.forEach(field => {
      if (!values[field] || isEmpty(values[field])) {
        errors[field] = i18n.t('common.fieldRequired')
      }
    })

    if (values['quantity'] && values['quantity'] < '0') {
      errors['quantity'] = i18n.t('common.cantBeNegative')
    }
    return errors
  }
})(RecalculateForm)

export default connect()(Form)
